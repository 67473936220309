class Hooks {
    dati_pers_4(router) {
        router.push({ name: "ClubConso" })
    }
    club(router) {
        router.push({ name: "ClubConsoConsent" })
    }
    club_ko(router) {
        router.push({ name: "ClubConsoWarning" })
    }
    club_consent(router) {
        router.push({ name: "PrivacyConso" })
    }
    club_consent_back(router) {
        router.push({ name: "ClubConso" })
    }
    club_warning(router) {
        router.push({ name: "ClubConsoConsent" })
    }
    club_warning_ko(router) {
        router.push({ name: "PrivacyConso" })
    }
}
export default new Hooks();