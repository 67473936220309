class Hooks {
    rata(router) {
        router.push({ name: "RataFastCredit" })
    }

    dati_lav(router, {state}) {
        if (!state.userData.can_request && !state.userData.is_creditline)
            router.push({ name: "MetlifePrecompiled" })

        else if (state.userData.document_expired) 
            router.push({ name: "DocumentsFastCredit" })

        else if (state.userData.can_request) 
            router.push({ name: "ClubFastCredit" })
        
        else
            router.push({ name: "PrivacyFastCredit" })
    }

    dati_pers_4(router, {state}) {
        if (state.userData.can_request)
            router.push({ name: "ClubFastCredit" })

        else if (!state.userData.is_creditline)
            router.push({ name: "MetlifeFastCredit" })
        
        else
            router.push({ name: "PrivacyFastCredit" })
    }

    club(router) {
        router.push({ name: "ClubFastCreditConsent" })
    }
    club_ko(router) {
        router.push({ name: "ClubFastCreditWarning" })
    }
    club_consent(router, {getters}) {
        if(getters.promoAvailable) 
            router.push({name: "PromoFastCreditLoading"})
        else
            router.push({ name: "PrivacyFastCredit" })
    }
    club_consent_back(router) {
        router.push({ name: "ClubFastCredit" })
    }
    club_warning(router) {
        router.push({ name: "ClubFastCreditConsent" })
    }
    club_warning_ko(router, {getters}) {
        if(getters.promoAvailable) 
            router.push({name: "PromoFastCreditLoading"})
        else
            router.push({ name: "PrivacyFastCredit" })
    }
    
    club_plus(router) {
        router.push({ name: "ClubPlusFastCreditConsent" })
    }
    club_plus_ko(router) {
        router.push({ name: "ClubPlusFastCreditWarning" })
    }
    club_plus_consent(router) {
        router.push({ name: "PrivacyFastCredit" })
    }
    club_plus_warning(router) {
        router.push({ name: "ClubPlusFastCreditConsent" })
    }
    club_plus_warning_ko(router) {
        router.push({ name: "PrivacyFastCredit" })
    }

    dati_metlife_1(router, {state}) {
        if (state.userData.document_expired) 
            router.push({ name: "DocumentsFastCredit" })
        else
            router.push({ name: "MetlifeFastCredit"})
    }
    metlife(router) {
        router.push({ name: "MetlifeFastCreditConsent"})
    }
    metlife_ko(router) {
        router.push({ name: "MetlifeFastCreditWarning"})
    }
    metlife_warning(router) {
        router.push({ name: "MetlifeFastCreditConsent"})
    }
    metlife_submit(router) {
        router.push({ name: "PrivacyFastCredit"})
    }
    metlife_consent(router) {
        router.push({ name: "PrivacyFastCredit"})
    }
    metlife_back(router) {
        router.push({ name: "MetlifeFastCredit"})
    }

    dati_privacy(router, {state}) {
        if (state.userData.can_request)
            router.push({name: "ThankYou"})

        else if(state.userData.insurance && state.userData.insurance_pack == 'Metlife' )
            router.push({name: "TypMetlife"})

        else if(state.userData.service )
            router.push({name: "TypClub"})

        else 
            router.push({name: "RequestKO"})
    }

    promo_loading(router) {
        router.push({name: "PromoFastCredit"})
    }
    promo(router) {
        router.push({ name: "PrivacyFastCredit" })
    }
    promo_ko(router) {
        router.push({name: "PromoFastCreditConfirm"})
    }
    promo_confirm(router) {
        router.push({ name: "PrivacyFastCredit" })
    }
    promo_terms(router) {
        router.push({ name: "PromoFastCreditTerms" })
    }
}
export default new Hooks();