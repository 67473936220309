<template>
    <footer class="bg-white">
        <div class="w-full p-6 mx-auto sm:max-w-7xl relative">
            <ScrollTopButton />
            <div class="footer-content mt-4">
                <div class="w-full">
                    <div class="font-medium text-gray-5 cursor-default outline-none text-left leading-0 my-2" :active="activeFooter">
                        <p class="text-base">
                            Messaggio pubblicitario con finalità promozionale.
                        </p>
                        <p class="text-base mt-4">
                            Per le condizioni economiche e contrattuali si rimanda alla documentazione in tuo possesso.<br>
                            Offerta valida fino al <span class="fine_campagna">{{ $store.state.userData.exp_date }}</span>.
                        </p>
                    </div>
                </div>
                <div class="text-center sm:text-left text-base mb-24 mt-4">                    
                    <a href="https://www.cofidis.it/it/privacy-policy.html" class="underline text-base" target="_blank">Privacy Policy</a> |
                    <a href="https://cofidis-docs-pp.s3.eu-central-1.amazonaws.com/agid.pdf" class="underline text-base" target="_blank">Accessibilità: non conforme</a>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import $ from "jquery";
import {mapGetters} from "vuex";
import ScrollTopButton from "@/components/scrollTopButton.vue";

export default {
    data() {
        return {
            activeFooter: false
        };
    },
    async mounted() {
        $(document).on("click", ".to-top", function() {
            $("html, body").animate({ scrollTop: 0 }, 1000);
            return false;
        });
    },
    computed: {
        ...mapGetters(["uniquePrices"]),
    },
    components: { ScrollTopButton }
};
</script>
