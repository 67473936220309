<template>
    <div class="mx-auto lg:container text-sm sm:text-base leading-tight text-gray-700 py-4">
        <div class="absolute inset-0 flex items-center justify-center z-10  top-24 sm:top-0">
            <div class="modal-wrapper bg-white mx-4 text-center rounded-3xl mb-64 max-w-xl text-gray-700 shadow-xl">
                <div class="p-4">
                    <i class="icon clock block mx-auto"></i>
                    <h1 class="text-3xl sm:text-3xl font-semibold md:w-2/3 mx-auto mt-4">Peccato, questa offerta è scaduta!</h1>
                </div>
                <div class="bg-yellow-4 p-4 rounded-b-3xl">
                    <p>Noi non smettiamo di darti <strong>fiducia</strong>. Scopre le nostre altre soluzioni e trova <strong>quella giusta per te</strong>.</p>
                    <a class="rounded-full border border-black py-2 px-8 mt-4 inline-block mx-auto" href="https://www.creditocofidis.it">
                        <span class="mr-4">Scopri le soluzioni Cofidis</span>
                        <i class="icon arrowright inline-block"></i>
                    </a>
                    <p class="my-6">Ti stiamo per reindirizzare alle nostre soluzioni di credito</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        mounted() {
            window.setTimeout(() => {
                location.href = 'https://www.creditocofidis.it'
            }, 5000)
        }
    }
</script>
