<template>
    <FormBox title="Non perdere questa occasione!" @submit="onSubmit()" declineText="Procedi senza Club" @decline="onDecline()" >
        
        <ProgressPz slot="form-header" progress="80" />

        <p class="mb-4 text-base">
            Con il servizio CLUB PLATINUM, per 12 mesi puoi:<br>
            • Usufruire di <strong>esperienze uniche in formula 2x1</strong>: paghi per uno e invita chi vuoi (ad esempio cinema, ristoranti e hotel)<br>
            • Beneficiare di sconti, buoni spesa e vantaggi del programma Lucky Days<br>
            Non lasciarti sfuggire questa occasione!
        </p>

    </FormBox>

</template>
<script>
import FormBox from "./FormBox";
import ProgressPz from "./ProgressPz";

export default {
    components: { FormBox, ProgressPz },
    mounted() {
        this.$store.dispatch("sendData", { step: "club_warning" });
    },
    methods: {
        onSubmit() {
            this.$store.commit("setUserData", { key: 'service', value: true });
            this.$store.dispatch("redirect", { step: "club_consent", route: "DocumentsPz" });
        },
        onDecline() {
            this.$store.commit("setUserData", { key: 'service', value: false });
            this.$store.dispatch("redirect", { step: "club_warning_ko", route: "DocumentsPz" });
        }
    }
};
</script>