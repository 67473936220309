<template>
    <main>
        <Loader v-if="loading" />
        <div v-else>
            <!-- Header -->
            <header class="w-full relative">
                <Logo :fullScreen="false" />
            </header>
            <form class="w-full mx-auto text-center px-6 lg:px-0">
                <!-- Hero -->
                <div class="bg-white rounded-3xl shadow-2xl mx-auto pb-8">
                    <div class="px-10">
                        <p class="text-xl text-gray-5 font-semibold pt-4 sm:pt-6 px-4 text-2xl sm:px-8 lg:text-xl">{{ name }},</p>
                        <p class="font-medium mb-3">solo per te che sei già cliente una nuova liquidità di</p>
                        <p class="text-6xl text-green font-semibold">{{ extraAmount|num }}<span class="text-3xl">€</span></p>
                    </div>
                    <div class="bg-gray flex flex-wrap flex-col lg:flex-row mt-6 px-2 pt-2 pb-4">
                        <div class="w-full lg:w-1/3 text-center lg:py-5">
                            <h4 class="block text-lg xl:inline-block lg:px-2 mt-3 font-semibold leading-none">Rata Prestito attuale:</h4>
                            <p id="rata-attuale" class="line-through font-semibold text-xl">{{ currentInstallment|num2 }}€</p>
                        </div>
                        <div class="w-full lg:w-1/3 text-center lg:py-5">
                            <h4 class="block text-xl xl:inline-block lg:px-2 mt-3 font-semibold leading-none">Rata del nuovo Prestito</h4>
                            <p id="nuova-rata" class="text-2xl text-green font-semibold">{{ installmentAmount|num2 }}€</p>
                        </div>
                        <div class="w-full lg:w-1/3 text-center lg:py-5">
                            <h4 class="block text-xl xl:inline-block lg:px-2 lg:mt-3 font-semibold leading-none">{{ installmentsDiff|num }}€ <span id="nuova-rata" class="text-lg italic lg:hidden">in più al mese</span></h4>
                            <p v-if="parseInt(installmentsDiff) >= 0" id="nuova-rata" class="text-lg italic hidden lg:block">in più al mese</p>
                            <p v-if="parseInt(installmentsDiff) < 0" id="nuova-rata" class="text-lg italic hidden lg:block">in meno al mese</p>
                        </div>
                    </div>
                </div>
            </form>
            <a @click="submit()" class="cta w-4/6 sm:w-auto font-semibold text-lg text-gray-4 bg-orange-1 max-w-xs rounded-full shadow-2xl px-6 sm:px-10 py-2 sm:max-w-none fixed bottom-6 left-1/2 transform -translate-x-1/2 z-50 cursor-pointer">
                <span class="block text-center text-xl">RICHIEDI GRATIS ORA</span>
                <span class="block font-light text-base text-center -mt-1">Solo fino al <strong class="font-bold uppercase">{{ expDate }}</strong></span>
            </a>

            <!-- Section vantaggi -->
            <section class="section-feat py-6 text-center cursor-pointer" @click="scrollToAnchor()">
                <div class="max-w-4xl lg:max-w-5xl xl:max-w-7xl mx-auto">
                    <div class="w-full relative flex flex-wrap items-center px-6">
                        <h3 class="w-3/4 flex-grow font-semibold text-lg text-left px-2">Scopri il Prestito Personale con Consolidamento: una sola rata, zero pensieri</h3>
                        <i class="icon arrowdown"></i>
                    </div>
                </div>
            </section>

            <!-- Section steps -->
            <section class="section-steps w-full bg-gray-100 pt-8 pb-24 lg:pt-16">
                <div class="">
                    <!-- Title -->
                    <div class="text-center mb-8 sm:mb-6">
                        <h3 class="font-semibold text-3xl">
                            La soddisfazione dei nostri clienti
                        </h3>
                    </div>
                    <!-- Steps -->
                    <Carousel />
                </div>
            </section>

            <!-- Section contest -->
            <ClubContest class="bg-white" />

            <!-- Section faq -->
            <section id="anchor" class="section-faq text-center">
                <div class="sm:container px-6 py-6 mx-auto">
                    <h3 class="font-semibold text-3xl text-gray-5 leading-none px-6 mb-6 text-center">
                        Come funziona?
                    </h3>
                    <div class="faq-container w-full xl:w-2/3 flex flex-wrap hover:text-orange cursor-pointer bg-white rounded-xl py-3 px-6 mx-auto mb-3">
                        <div class="w-full">
                            <div class="flex flex-wrap">
                                <div class="faq-question outline-none w-4/5 sm:flex-grow text-left" :class="{ active: activeFaq == 0 }" @click="toggleFaq(0)">
                                    <span class="font-semibold text-lg text-gray-5 leading-none">Più liquidità sul tuo conto corrente</span>
                                </div>
                                <div class="flex-grow text-right rounded-full leading-10 outline-none flex items-center justify-end"  @click="toggleFaq(0)">
                                    <svg v-if="activeFaq != 0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                                    </svg>
                                </div>
                            </div>
                            <SlideUpDown class="faq-content font-medium text-gray-5 cursor-default outline-none text-left leading-0 my-2" :active="activeFaq == 0">
                                <span class="font-light text-base text-gray-5 leading-none">
                                    Ti bastano pochi passaggi per ottenere più liquidità. Verifica i
                                        dati del tuo profilo, procedi con la richiesta online e ricevi la
                                        richiesta di finanziamento da firmare digitalmente. Entro 48 H
                                        dall'approvazione avrai l'importo sul tuo conto corrente.
                                </span>
                            </SlideUpDown>
                        </div>
                    </div>
                    <div class="faq-container w-full xl:w-2/3 flex flex-wrap hover:text-orange cursor-pointer bg-white rounded-xl py-3 px-6 mx-auto mb-3">
                        <div class="w-full">
                            <div class="flex flex-wrap">
                                <div class="faq-question outline-none w-4/5 sm:flex-grow text-left" :class="{ active: activeFaq == 1 }" @click="toggleFaq(1)">
                                    <span class="font-semibold text-lg text-gray-5 leading-none">Dettagli dell’offerta</span>
                                </div>
                                <div class="flex-grow text-right rounded-full leading-10 outline-none flex items-center justify-end"  @click="toggleFaq(1)">
                                    <svg v-if="activeFaq != 1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                                    </svg>
                                </div>
                            </div>
                            <SlideUpDown class="faq-content font-medium text-gray-5 cursor-default outline-none text-left leading-0 my-2" :active="activeFaq == 1">
                                <span class="font-light text-base text-gray-5 leading-none">
                                    Ricevi un’extra liquidità di {{ extraAmount|num }}€ sul tuo conto corrente entro
                                    48H dall’approvazione. Sottoscrivendo il nuovo contratto
                                    l’erogazione del finanziamento sarà, in parte destinata alla
                                    contestuale estinzione del tuo prestito in corso e in parte
                                    destinata a te. La tua nuova rata mensile sarà di {{ installmentAmount|num2 }}€. Non verrà
                                    applicata nessuna penale di estinzione del finanziamento in
                                    corso e nessuna spesa di istruttoria del nuovo.
                                </span>
                            </SlideUpDown>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Section trust -->
            <section class="section-feat2 py-6">
                <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <h3 class="font-semibold text-3xl text-gray-5 leading-none mb-6 text-center">Cofidis vuol dire fiducia!</h3>
                    <p class="text-base font-medium px-4">Siamo <strong class="font-bold">il credito al consumo che dà e ispira fiducia immediata</strong> grazie a soluzioni semplici, veloci e 100% digitali in grado di
                        fornire un aiuto immediato nella vita delle persone.</p>
                    <p class="text-base font-medium px-4 mt-4">Proprio come abbiamo fatto con te, che <strong class="font-bold">ci hai già scelto</strong> per acquistare ciò che ami. Ora vogliamo darti ulteriore fiducia
                        con questa <strong class="font-bold">soluzione pensata per te.</strong></p>
                </div>
            </section>

            <!-- Section widget -->
            <section class="section-widget md:mt-0 py-6 sm:py-10 lg:py-8 bg-white">
                <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <!-- Title -->
                    <div class="text-center mb-4 sm:mb-8">
                        <h3 class="font-semibold text-4xl text-gray-5 leading-none">
                            Cosa dicono i <br class="sm:hidden" />
                            nostri clienti
                        </h3>
                    </div>
                    <Trustpilot />
                </div>
            </section>
        </div>
    </main>
</template>
<script>
// @ is an alias to /src
import Trustpilot from "@/components/TrustpilotSwiper"
import Loader from "@/components/Loader"
import Logo from "@/components/Logo"
import ClubContest from "@/components/ClubContest"
import loadData from "@/dataService.js";
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import moment from "moment/moment";
import Carousel from "./Carousel.vue";
import testimonialsLists from "./models/testimonials";

const redirectHomepage = () => {    
    location.href = '/consolidamento-crm'
}

export default {
    name: "Home",
    components: { Carousel, Loader, Trustpilot, Logo, ClubContest },
    methods: {
        async loadClientData(data) {
            return new Promise((resolve, reject) => {
                axios.post(this.crmUrl, data)
                    .then(function(response) {
                        if(response.data && response.data.dt_end_conso)
                            resolve(response.data)
                        else
                            reject(false)
                    })
                    .catch(function() {
                        reject(false)
                    })
            })
        },
        scrollToAnchor() {
            window.scrollTo({
                top: document.getElementById('anchor').offsetTop,
                left: 0,
                behavior: "smooth",
            });
        },
        toggleFaq(i) {
            if (this.activeFaq == i) this.activeFaq = null;
            else this.activeFaq = i;
        },
        async submit() {
            window.removeEventListener('popstate', redirectHomepage)
            await this.$store.dispatch("sendData", { step: "dati_anag" });
            this.$store.dispatch("redirect", { step: "homepage", route: "Income" });
        },
        reloadOnBrowserBack() {            
            window.popStateDetected = false
            window.addEventListener('popstate', redirectHomepage)
        }
    },
    computed: {
        ...mapGetters(["uniquePrices"]),
        ...mapState(["userData"]),
        crmUrl() {
            return this.$store.state.crmUrl;
        }
        // bgHeader() {
        //     const env = process.env.VUE_APP_ENV == "production" ? 'prod' : 'stage'
        //     return {
        //         '--bg-header': `#fff url("../views/themes/consolidamento-crm/images/header.jpg") 55% 0% no-repeat`,
        //         '--bg-header-sm': `#fff url("./images/header_mobile.jpg") center top no-repeat`,
        //     }
        // },
        // bgBanner() {
        //     return {
        //         '--bg-banner': `#fff url("${this.distributionUrl}/credito-cofidis-banner.png") 60% top no-repeat`,
        //         '--bg-banner-sm': `#fff url("${this.distributionUrl}/credito-cofidis-banner-sm.png") 60% top no-repeat`,
        //     }
        // }
    },
    data() {
        return {
            activeFaq: null,
            activeSolutionIndex: 0,
            commonDistributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/common",
            currentInstallment: null,
            distributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/consolidamento-crm",
            expDate: null,
            extraAmount: null,
            installmentAmount: null,
            installmentsDiff: null,
            loading: true,
            maxCredit: null,
            name: null,
            prices: loadData("price.json").prices.reverse(),
            testimonialsLists,
        };
    },
    async created() {
        this.reloadOnBrowserBack()

        // get params
        const urlParts = this.$store.state.urlParts
        let userId, codeCampaign, trackCode;

        if(urlParts[1] && urlParts[2]) {
            userId = urlParts[1]
            codeCampaign = urlParts[2]
            trackCode = urlParts[3] ?? null
        }
        else {
            userId = localStorage.getItem('conso_user_id')
            codeCampaign = localStorage.getItem('conso_code_campaign')
            trackCode = localStorage.getItem('conso_track_code') ?? null
            // fix localstorage cast type issue
            if(trackCode === "null") trackCode = null;
        }

        // redirect to pending modal if already compiled
        if(localStorage.getItem('conso_request_evaluation')) {
            this.loading = false;
            this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
            return;
        }

        try {
            localStorage.setItem('conso_user_id', userId)
            localStorage.setItem('conso_code_campaign', codeCampaign)
            if(trackCode) localStorage.setItem('conso_track_code', trackCode)

            const data = await this.loadClientData({userId, codeCampaign, trackCode})
            console.log(data)
            this.$store.commit("setUserData", { key: 'user_id', value: userId });
            this.$store.commit("setUserData", { key: 'client_id', value: data.cliente_id });
            this.$store.commit("setUserData", { key: 'code_campaign', value: codeCampaign });
            this.$store.commit("setUserData", { key: 'name', value: data.Nome });
            this.$store.commit("setUserData", { key: 'surname', value: data.Cognome });
            this.$store.commit("setUserData", { key: 'email', value: data.EMail });
            this.$store.commit("setUserData", { key: 'iban', value: data.Iban });
            this.$store.commit("setUserData", { key: 'fiscal_code', value: data.CF });
            this.$store.commit("setUserData", { key: 'cellphone', value: data.Phone });
            this.$store.commit("setUserData", { key: 'day_in_month', value: 1 });
            this.$store.commit("setUserData", { key: 'piano_f', value: data.tab_finanz_conso});
            this.$store.commit("setUserData", { key: 'provided_amount', value: data.finanziato_conso});
            this.$store.commit("setUserData", { key: 'extra_amount', value: data.extra_cash_conso});
            this.$store.commit("setUserData", { key: 'installments_diff', value: data.extra_rata_conso});
            this.$store.commit("setUserData", { key: 'installment_amount', value: data.imp_rata_conso});
            this.$store.commit("setUserData", { key: 'amount_1', value: data.importo_1_conso});
            this.$store.commit("setUserData", { key: 'amount_2', value: data.importo_2_conso});
            this.$store.commit("setUserData", { key: 'amount_owed', value: data.importo_totale_dovuto_conso});
            this.$store.commit("setUserData", { key: 'cod_camp_conso', value: data.cod_camp_conso});
            this.$store.commit("setUserData", { key: 'rate', value: data.rate_conso});
            this.$store.commit("setUserData", { key: 'taeg', value: data.taeg_conso});
            this.$store.commit("setUserData", { key: 'tan', value: data.tan_conso});
            this.$store.commit("setUserData", { key: 'dt_end_conso', value: data.dt_end_conso});
            this.$store.commit("setUserData", { key: 'pratica_conso', value: data.pratica_conso});

            // set expiry date
            this.expDate = moment(data.dt_end_conso).locale("it").format('D MMMM');
            this.$store.commit("setUserData", { key: 'exp_date', value: this.expDate });

            // offer expired
            if(moment(data.dt_end_conso).endOf('day') < moment()) {
                this.$store.dispatch("redirect", {step: "income", route: "RequestExpired"});
                return;
            }
            // pending procedure
            if(data.page_block && data.page_block === 'evaluation') {
                this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
                return;
            }

            // init robot
            this.$store.dispatch("sendData", { step: "enter_landing" });

            this.extraAmount = data.extra_cash_conso
            this.installmentAmount = data.imp_rata_conso
            this.installmentsDiff = data.extra_rata_conso
            this.currentInstallment = data.imp_rata_conso - data.extra_rata_conso
            this.name = data.Nome

        } catch (error) {
            this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
        }
        this.loading = false;
    },
    async mounted() {
        // 
    }
};
</script>
