<template>
    <FormCard @submit="onSubmit" :title="title" :submitClass="`submit`">
        <div v-for="(data, i) in loans_data" :key="i" class="w-full flex flex-wrap">
            <div v-if="loans_count > 1" class="w-full flex justify-center sm:justify-start">
                <div class="steps relative overflow-hidden">
                    <ul class="flex items-center mb-4">
                        <li v-for="k in loans_count" :key="k" :class="{'active': k == (i + 1) }">{{ k }}</li>
                    </ul>
                </div>
            </div>

            <div class="w-5/6 sm:w-5/12 mb-2 sm:mb-0">
                <FloatingLabelInput type="tel" :step="0.01" :name="`amount[]`" v-model="data.amount" :mask="currencyMask" :label="amountLabel(i)" :error="$v.loans_data.$each[i].amount.$error" />
                <div class="w-full text-red" v-if="$v.loans_data.$each[i].amount.$error">
                    <small v-if="!$v.loans_data.$each[i].amount.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                </div>
            </div>
            <div class="w-1/6 sm:w-1/12 mb-2 flex items-start justify-center sm:justify-start pt-2 sm:pl-2 relative">
                <a class="cursor-pointer block w-8 h-8 rounded-full bg-yellow text-white font-bold text-center text-2xl"
                   @click="tooltips[i].amount = true" >?</a>
                <Tooltip @hide="tooltips[i].amount = false" :visible="tooltips[i].amount" class="absolute -top-6 right-12 md:right-24">Inserisci l’importo totale del prestito che avevi richiesto.</Tooltip>
            </div>

            <div class="w-5/6 sm:w-5/12 mb-2 sm:mb-0">
                <FloatingLabelInput type="text" :step="0.01" :name="`installments_amount[]`" v-model="data.installments_amount" :mask="currencyMask" :label="`Importo rata attuale`" :error="$v.loans_data.$each[i].installments_amount.$error" />
                <div class="w-full text-red" v-if="$v.loans_data.$each[i].installments_amount.$error">
                    <small v-if="!$v.loans_data.$each[i].installments_amount.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                </div>
            </div>
            <div class="w-1/6 sm:w-1/12 mb-2 flex items-start justify-center sm:justify-start pt-2 sm:pl-2 relative">
                <a class="cursor-pointer block w-8 h-8 rounded-full bg-yellow text-white font-bold text-center text-2xl"
                   @click="tooltips[i].installments_amount = true" >?</a>
                <Tooltip @hide="tooltips[i].installments_amount = false" :visible="tooltips[i].installments_amount" class="absolute -top-6 right-12 md:right-24">Inserisci l’importo della rata che paghi attualmente.</Tooltip>
            </div>

            <div class="w-5/6 sm:w-5/12 mb-2 sm:mt-8">
                <FloatingLabelInput type="tel" :name="`installments_count[]`" v-model="data.installments_count" :mask="countMask" :label="`Numero rate iniziale`" :error="$v.loans_data.$each[i].installments_count.$error" />
                <div class="h-6 w-full text-red" v-if="$v.loans_data.$each[i].installments_count.$error">
                    <small v-if="!$v.loans_data.$each[i].installments_count.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                </div>
            </div>
            <div class="w-1/6 sm:w-1/12 mb-2 flex items-start justify-center sm:justify-start pt-2 sm:pl-2 relative">
                <a class="cursor-pointer block w-8 h-8 rounded-full bg-yellow text-white font-bold text-center text-2xl sm:mt-8"
                   @click="tooltips[i].installments_count = true" >?</a>
                <Tooltip @hide="tooltips[i].installments_count = false" :visible="tooltips[i].installments_count" class="absolute -top-6 right-12 md:top-6 md:right-24">Inserisci il numero totale delle rate previste dal tuo prestito.</Tooltip>
            </div>

            <div class="w-full sm:w-5/12 mb-2 sm:mt-2">
                <label class="text-xs">{{ $t("Quando hai iniziato a pagare la prima rata") }}</label>
                <DateSelect :value="data.first_installment" :showDay="false" :startYear="2000" :inputClass="{ 'border-red': $v.loans_data.$each[i].first_installment.$error }" @change="value => updateLoanFirstInstallment(value, i)" />
                <div class="h-6 w-full text-red" v-if="$v.loans_data.$each[i].first_installment.$error">
                    <small v-if="!$v.loans_data.$each[i].first_installment.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                    <small v-if="!$v.loans_data.$each[i].first_installment.maxValue">
                        {{ $t("Data non valida") }}
                    </small>
                </div>
            </div>
            <div class="w-full sm:w-5/12 sm:mt-2" :class="{'mb-6': i < loans_count}">
                <FloatingLabelSelect :name="`provider[]`" v-model="data.provider" :items="loan_providers" :label="`Con chi hai il prestito`" :error="$v.loans_data.$each[i].provider.$error" :disableZeroValue="true"/>
                <div class="text-red" v-if="$v.loans_data.$each[i].provider.$error">
                    <small v-if="!$v.loans_data.$each[i].provider.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                </div>
            </div>
        </div>
    </FormCard>
</template>
<style>
.label-floating {
    margin-bottom: 0px !important;
}
.steps ul:after {
    content: '';
    position: absolute;
    top: 24px;
    left: 4px;
    width: 160px;
    height: 1px;
    @apply bg-green-500
}
@screen sm {
    .steps ul:after {
        top: 28px;
    }
}
.steps li {
    @apply w-11 h-11 rounded-full bg-green-400 text-white font-light text-2xl flex items-center justify-center mr-6 relative z-10
}
.steps li.active {
    @apply w-12 h-12 sm:w-14 sm:h-14 text-4xl bg-green-500;
}
.steps li:last-child {
    @apply mr-0;
}
</style>
<script>
import Calculator from "@/views/themes/consolidamento/ext/calculator";
import DateSelect from "@/components/DateSelect";
import Tooltip from "@/components/Tooltip";
import FloatingLabelSelect from "@/components/FloatingLabelSelect";
import FloatingLabelInput from "@/components/FloatingLabelInput";
import { required  } from "vuelidate/lib/validators";
import { mapUserData  } from "@/utils";
import { currencyMask } from "@/masks";
import moment from "moment";
currencyMask.integerDigits = 6;
currencyMask.digits = 2;

export default {
    components: { FloatingLabelSelect, FloatingLabelInput, DateSelect, Tooltip },
    async mounted() {
        this.$ga.pageview()
    },
    data() {
        return {
            Calculator,
            capLoan: Calculator.capLoan(),
            showError: false,
            showFormImporto: false,
            currencyMask,
            countMask: {integerDigits: 4, alias: "numeric", rightAlign: false, placeholder: "_"},
            tooltips: [{
                amount: false,
                installments_amount: false,
                installments_count: false
            },{
                amount: false,
                installments_amount: false,
                installments_count: false
            },{
                amount: false,
                installments_amount: false,
                installments_count: false
            }]
        };
    },
    computed: {
        ...mapUserData([
            "loans_count",
            "loans_data"
        ]),
        title() {
            return this.loans_count > 1 ? `Dati dei tuoi prestiti` : `Dati del tuo prestito`
        },
        loan_providers() {
            return [
                {key: null, value: "Con chi hai il prestito"},
                {key: 0, value: "FINDOMESTIC"},
                {key: 1, value: "AGOS"},
                {key: 2, value: "COMPASS"},
                {key: 3, value: "YOUNITED CREDIT"},
                {key: 4, value: "BANCA"},
                {key: 5, value: "ALTRA FINANZIARIA"},
            ]
        },
        totalDebt() {
            return Number(Calculator.totalDebt(this.loans_data))
        },
        averageApr() {
            return Number(Calculator.averageApr(this.loans_data))
        }
    },
    validations: {
        loans_data: {
            $each: {
                amount: { required },
                installments_amount: { required },
                installments_count: { required },
                first_installment: {
                    required,
                    maxValue: (value) => {
                        return moment(value, 'MM/YYYY') <= moment()
                    }
                },
                provider: { required }
            }
        },
    },
    methods: {
        amountLabel(i) {
            if(i == 0) return `Importo iniziale primo prestito`
            else if(i == 1) return `Importo iniziale secondo prestito`
            else return `Importo iniziale terzo prestito`
        },
        updateLoanFirstInstallment(value, index) {
            this.loans_data[index].first_installment = value
        },
        onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                this.$store.commit("setChoosenSolution", null);
                this.$store.dispatch("event", { name: "ConsolidamentoInfoPrestiti" });
                if(this.totalDebt > 0 && this.totalDebt <= this.capLoan && this.averageApr > 0) {
                    this.$store.dispatch("sendData", {step: "dati_consolidamento"})
                    this.$store.dispatch("redirect", {step: "info_loans", route: "Solutions"});
                }
                else {
                    this.$store.dispatch("redirect", {step: "info_loans", route: "InfoLoansError"});
                }
            }
        }
    }
};
</script>