import { JOB_TYPES } from '@/constants.js';

class Hooks {
	importo(router, {commit, state}) {
        commit('resetEnvName')

        if(state.choosenCredit == 600) {
            router.push({ name: "RataPowerCreditUpgrade" })
        }
        else if(state.choosenCredit <= 2000) {
        	router.push({ name: "RataPowerCredit" })
        }
        else {
        	router.push({ name: "Rata" })
        }
    }
    dati_pers_1(router, {commit, state}) {
        // update debit days depending on path (pp/revolving)
        if(state.userData.importo <= 2000) {
            commit('setDebitDays', [{"value":1, "label": "15"}])
        }
        else {
            commit('setDebitDays', [{value:1, label: "1"}, {value:2, label: "10"}])
        }
        router.push({ name: "CreditRisk" })
    }

    dati_pers_3(router, {state}) {
        if(state.PATH == 'CQ') {
            router.push({ name: "PaymentDetails" })
        }
        else {
            router.push({ name: "IdentityCardData" })
        }
    }

    dati_pers_4(router) {
        // if(state.userData.importo > 2000) commit('setPrepaidCards', true)
        // else commit('setPrepaidCards', false)

        router.push({ name: "PaymentDetails" })
    }

    dati_lav(router, {state, getters}) {
        const userData = state.userData
        const IS = arr => arr.includes(userData.employment_type)

        switch(state.PATH) {
            case 'ML':
                //console.log('Residenza,Doc,Iban,ClubPlus,PP,TYPOK')
                router.push({ name: "DeliveryData" })
            break;
            case 'CQ':
                //console.log('Employer(CQ),PP,TYPA')
                if(IS([JOB_TYPES.PENSIONATO]))
                    router.push({ name: "CessioneQuinto" })
                else
                    router.push({ name: "EmployerData" })
            break;
            case 'FAM':
                //console.log('FamilyIncome')
                router.push({ name: "FamilyIncome" })
            break;
            case 'FE':
                //console.log('Employer,Ass,Club,Residenza,Doc,Iban,PP,TYPC')
                if ([JOB_TYPES.AUTONOMO, JOB_TYPES.DIPENDENTE, JOB_TYPES.STAGIONALE, JOB_TYPES.PROFESSIONISTA].includes(userData.employment_type))
                    router.push({ name: "EmployerData" })
                else if(getters.insuranceAvailable)
                    router.push({ name: "LoanProtection" })
                else
                    router.push({ name: "DeliveryData" })
            break;
        }
    }

    dati_lav_2(router, {state, getters}) {
        if(state.PATH == 'CQ') {
            router.push({ name: "DeliveryData" })
        } else if(state.PATH == 'FE') {
            if(getters.insuranceAvailable)
                router.push({ name: "LoanProtection" })
            else
                router.push({ name: "DeliveryData" })
        } else {
            if(getters.metlifeAvailable)
                router.push({ name: "MetlifeProtection" })
            else if(getters.clubPlusAvailable)
                router.push({ name: "ClubPlus" })
            else
                router.push({ name: "PrivacyConsent" })
        }
    }

    insurance_consent(router, {getters}) {
        if(getters.metlifeAvailable)
            router.push({ name: "MetlifeProtection" })
        else
            router.push({ name: "Club" })
    }

    insurance_warning(router, {getters}) {
        if(getters.metlifeAvailable)
            router.push({ name: "MetlifeProtection" })
        else
            router.push({ name: "Club" })
    }

    dati_paga(router, {state, getters}) {
        if(state.promo && getters.promoAvailable)
            router.push({ name: "PromoLoading" })
        else if(getters.isFeevo)
            router.push({ name: "PrivacyConsent" })
        else if(getters.metlifeAvailable)
            router.push({ name: "MetlifeProtection" })
        else if(getters.clubPlusAvailable)
            router.push({ name: "ClubPlus" })
        else
            router.push({ name: "PrivacyConsent" })
    }

    metlife_submit(router) {
        router.push({ name: "ClubLight" })
    }

    metlife_consent(router) {
        router.push({ name: "ClubLight" })
    }

    dati_privacy(router, {state}) {
        const PACK = state.userData.insurance_pack
        const METLIFE = state.userData.insurance && state.userData.insurance_pack == 'Metlife'
        const CLUBPLUS = state.userData.service && state.userData.service_pack == 'CLPLS'
        const PP = state.userData.importo > 2000

        switch(state.PATH) {
            case 'CQ':
                if(METLIFE) {
                    router.push({ name: "TypCQMetlife" })
                } else {
                    router.push({ name: "TypCQ" })
                }
            break;
            case 'ML':
                if(PACK == 'Metlife') {
                    if(state.userData.insurance) {
                        router.push({ name: "TypMetlifeOK" })
                    } else {
                        router.push({ name: "TypMetlifeKO" })
                    }
                } else {
                    // club plus registration
                    if(CLUBPLUS) {
                        router.push({ name: "TypClub" })
                    } else {
                        router.push({ name: "TypClubKO" })
                    }
                }
            break;
            case 'FE':
                if(METLIFE) {
                    if(PP)
                        router.push({ name: "TypMLpp" })
                    else
                        router.push({ name: "TypMLpc" })
                }
                else {
                    if(PP)
                        router.push({ name: "TypPP" })
                    else
                        router.push({ name: "TypPC" })
                }
            break;
        }
    }
}
export default new Hooks();
