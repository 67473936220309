<template>
    <FormBox title="Documento di identità" submitText="Conferma" @submit="submit()" @decline="goBack()" >

        <ProgressPz slot="form-header" progress="40" />

        <PrecompiledBox :editable="false" >
                <PrecompiledField label="Numero della patente" :value="document_number" class="lg:w-1/2" />
                <PrecompiledField label="Comune di rilascio" :value="document_city" class="lg:w-1/2" />
                <PrecompiledField label="Data di rilascio" :value="document_release" class="lg:w-1/2" />
        </PrecompiledBox>

        <div class="w-full lg:w-1/2 lg:pr-4 my-2">
            <label class="text-xs">Data di scadenza (punto 4b)</label>
            <DateSelect :value="document_expire" :inputClass="{ 'border-red': $v.document_expire.$error }" :startYear="documentExpireStartYear" :endYear="documentExpireEndYear" @change="updateDocumentExpireValue" ref="expireDateComponent" class="mt-1" />
            <div class="w-full text-red">
                <small v-if="$v.document_expire.$error && !$v.document_expire.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
                <small v-else-if="$v.document_expire.$error && !$v.document_expire.minValue">
                    {{ $t("Documento scaduto") }}
                </small>
            </div>
        </div>
        
    </FormBox>
</template>
<script>
import FormBox from "./FormBox"
import ProgressPz from "./ProgressPz";
import PrecompiledBox from "@/components/PrecompiledBox.vue";
import PrecompiledField from "@/components/PrecompiledField.vue";
import DateSelect from "@/components/DateSelect";
import { mapUserData } from "@/utils";
import { required } from "vuelidate/lib/validators";

import moment from "moment";
    
export default {
    components: { FormBox, ProgressPz, PrecompiledBox, PrecompiledField, DateSelect },
    computed: {
        ...mapUserData([
            "identity_type",
            "document_city",
            "document_number",
            "document_release",
            "document_expire"
        ]),
        documentExpireStartYear() {
            return parseInt(moment().format('YYYY'))
        },
        documentExpireEndYear() {
            return parseInt(moment().format('YYYY')) + 11
        },
    },
    validations: {
        document_expire: {
            required,
            minValue: value => {
                return moment(value, 'DD/MM/YYYY') >= moment()
            },
            maxValue: value => {
                return moment(value, 'DD/MM/YYYY') <= moment().add(11, "years")
            }
        }
    },
    methods: {
        updateDocumentExpireValue(value) {
            this.document_expire = value
        },
        submit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                this.$store.dispatch("sendData", { step: "dati_pers_4" });
                this.$store.dispatch("redirect", { step: "document", route: "IncomePz" });
            }
        }
    }
};
</script>