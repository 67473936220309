<template>
    <FormCard @submit="onSubmit" title="Nucleo familiare">
        <div class="w-full mb-6">
            <label class="text-base">{{ $t("Nel tuo nucleo familiare c'è qualcuno che percepisce un reddito dimostrabile, cioè è un lavoratore dipendente privato o pubblico o statale o pensionato?") }}</label>
            <div class="flex mt-6 justify-center">
                <div class="relative mr-6">
                    <input type="radio" name="family_income" v-model="family_income" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
                </div>
                <div class="relative">
                    <input type="radio" name="family_income" v-model="family_income" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
                </div>
            </div>
            <div class="text-red" v-if="$v.family_income.$error">
                <small v-if="!$v.family_income.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-full sm:w-1/2 sm:pr-4 mb-6" v-if="family_income">
            <FloatingLabelSelect :name="`family_income_activity`" v-model="family_income_activity" :items="family_income_activities" :label="`Tipo impiego`" :error="$v.family_income_activity.$error" :disableZeroValue="true"/>
            <div class="h-6 w-full text-red" v-if="$v.family_income_activity.$error">
                <small v-if="!$v.family_income_activity.nonZero">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
    </FormCard>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { mapUserData, nonZeroIf } from "@/utils";
import FloatingLabelSelect from "@/components/FloatingLabelSelect";

export default {
    components: { FloatingLabelSelect },
    mounted() {
        this.$ga.pageview()
    },
    computed: {
        ...mapUserData(["family_income", "family_income_activity"]),
        showFamilyIncomeActivity() {
            return this.family_income
        },
        family_income_activities() {
            return [
                {key: 0, value: 'Tipo impiego'},
                {key: 2, value: 'LAVORATORE DIPENDENTE'},
                {key: 6, value: 'PENSIONATO'},
            ]
        }  
    },
    validations: {
        family_income: {
            required
        },
        family_income_activity: {
            nonZero: nonZeroIf("showFamilyIncomeActivity")
        }
    },
    watch: {
        family_income() {
            this.family_income_activity = 0
            this.$v.$reset()
        }
    },
    methods: {
        onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                //this.$store.dispatch("event", { name: "FamilyIncomeSubmit" });
                this.$store.state.PATH = this.family_income ? 'CQ' : 'ML'
                this.$store.dispatch("sendData", { step: "dati_reddito_familiare" });                
                this.$store.dispatch("redirect", { step: "family_income", route: "DeliveryData" })
            }
        }
    }
};
</script>