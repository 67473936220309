<template>
    <ClubPlus />
</template>

<script>
import ClubPlus from "@/views/forms/ClubPlus.vue"

export default {
    components: { ClubPlus }
};
</script>