<template>
    <div v-if="percentage" class="bg-form h-5 text-sm mx-6 lg:mx-12 mt-6 rounded-full">
        <div class="progressbar h-5 text-right font-semibold pr-3 rounded-full min-w-10" :class="`bg-${colors.bg} text-${colors.text}`" :style="{ width: percentage + '%' }">
            {{ percentage }}%
        </div>
    </div>
</template>
<script>
export default {
    props: {
        percentage: {
            type: Number,
            default: null
        },
        colors: {
            type: Object,
            default: () => {
                return {
                    bg: 'yellow',
                    text: 'black'
                }
            }
        }
    },
    computed: {        
        visible() {
            return !!this.percentage;
        }
    }
};
</script>