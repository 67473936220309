class Hooks {
    dati_pers_4(router) {
        router.push({ name: "ClubFastCredit" })
    }
    club(router) {
        router.push({ name: "ClubFastCreditConsent" })
    }
    club_ko(router) {
        router.push({ name: "ClubFastCreditWarning" })
    }
    club_consent(router) {
        router.push({ name: "PrivacyFastCredit" })
    }
    club_consent_back(router) {
        router.push({ name: "ClubFastCredit" })
    }
    club_warning(router) {
        router.push({ name: "ClubFastCreditConsent" })
    }
    club_warning_ko(router) {
        router.push({ name: "PrivacyFastCredit" })
    }
}
export default new Hooks();