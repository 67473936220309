<template>
    <form action="next" method="POST">
        <div class="container text-center py-6 relative mx-auto">
            
        </div>

        <div class="form-wrapper">
            <PrivacyConsent :alert-data-consent="false"/>
        </div>
    </form>
</template>
<script>
import PrivacyConsent from "@/views/forms/PrivacyConsent";

export default {
    components: { PrivacyConsent },
    computed: {
        steps() {
            return 2
        }
    }
};
</script>