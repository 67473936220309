<template>
    <div class="w-11/12 sm:max-w-7xl mx-auto pb-3">
        <div class="flex w-full items-center">
            <p class="text-base font-semibold pr-3" :class="{'opacity-30': progress > 50 }">1</p>
            <div class="h-0.5 flex-grow" :style="`background: ${firstProgress}`" ></div>
            <p class="text-base font-semibold px-3" :class="{'opacity-30': progress <= 50 }">2</p>
            <div class="h-0.5 flex-grow" :style="`background: ${secondProgress}`"></div>
            <img src="./icons/process-complete.svg" />
        </div>
        <div class="flex">
            <p class="text-sm font-semibold tracking-wide leading-none w-1/2" :class="{'opacity-40': progress > 50 }" >Controlla i dati</p>
            <p class="text-sm font-semibold tracking-wide leading-none -ml-0.5" :class="{'opacity-40': progress <= 50 }" >Inserisci i dati</p>
        </div>
    </div>
</template>
<script>
import { integer } from 'vuelidate/lib/validators'

export default {
    name: "ProgressPz",
    props: {
        progress: {
            type: integer,
            default: 10
        }
    },
    data() {
        return {
            delta: 20
        }
    },
    mounted() {
        if (window.innerWidth > 768) {
            this.delta = 10
        }
    },
    computed: {
        firstProgress() {
            if (this.progress < 50) {
                return `linear-gradient(90deg, #10B981 0%, #10B981 ${this.progress * 2 - this.delta}%, #FFF ${this.progress * 2 + this.delta}%, #FFF 100%)`
            }
            else {
                return '#10B981'
            }
        },
        secondProgress() {
            if (this.progress < 50) {
                return '#FFFFFF'
            }
            else {
                return `linear-gradient(90deg, #10B981 0%, #10B981 ${(this.progress - 50) * 2 - this.delta}%, #FFF ${(this.progress - 50) * 2 + this.delta}%, #FFF 100%)`
            }
        }
    }
}

</script>