import loadData from "@/dataService.js";
import axios from "axios";
import steps from "./steps";
import events from "./events";
import router from '@/router'

const config = loadData("config.json");
const requireJs = require.context("@", true, /ext\/\w+\.js$/);

export default {
    redirect({commit, state, getters, dispatch}, { step, route }) {
        // log steps
        commit('logStep', step)
        // theme defined data append
        if (config.hooks && config.hooks[step] && config.hooks[step].includes(process.env.NODE_ENV)) {
            const hooksUrl = `./views/themes/${state.theme}/ext/hooks.js`
            let { default: hooks } = requireJs(hooksUrl)
            hooks[step](router, {commit, state, getters, dispatch}, route)
        }
        else router.push({ name: route })
    },

    event({ state }, { name, source = {}, model = null }) {
        const vm = this._vm
        // theme defined events override default events
        if (config.events && config.events[name] && config.events[name].includes(process.env.NODE_ENV)) {
            const eventUrl = `./views/themes/${state.theme}/ext/events.js`
            let { default: events } = requireJs(eventUrl)
            if(events[name]) {
                events[name](state, vm, model)
            }
        }
        // default events
        else if(events[name]) {
            events[name](state, vm, source, model)
        }
    },

    async verifyCode({ state }, { code = null} ) {
        let payload = {
            envType: config.envType,
            envName: config.envName
        }

        return new Promise((resolve, reject) => {
            axios.post(state.serverUrl + `access_code/${code}`, payload)
                .then(function(response) {
                    state.protected = !response.data.success
                    resolve(response.data.success)
                })
                .catch(function() {
                    state.protected = true;
                    reject(false)
                })
        })
    },

    async verifyCF({ state }, cf) {
        let payload = { cf }
        return new Promise((resolve, reject) => {
            axios.post(`${config.crm[process.env.NODE_ENV]}find/mega_crm_cf`, payload)
                .then(function(response) {
                    if(response.data.success) {
                        // open modal
                        state.redirect = true
                        if(response.data.prod === 'fast') {
                            const redirectUrl= `${window.location.origin}/fs/${response.data.id}/fast?utm_source=megathron&utm_medium=referral&utm_campaign=creditocofidis`
                            state.redirectType = 'fast'
                            state.redirectUrl = redirectUrl
                            // set redirect
                            state.redirectTimeout = window.setTimeout(() => {
                                location.href = redirectUrl
                            }, 7000)
                        }
                        else if(response.data.prod === 'conso') {
                            const redirectUrl= `${window.location.origin}/cs/${response.data.id}/conso?utm_source=megathron&utm_medium=referral&utm_campaign=creditocofidis`
                            state.redirectType = 'conso'
                            state.redirectUrl = redirectUrl
                            // set redirect
                            state.redirectTimeout = window.setTimeout(() => {
                                location.href = redirectUrl
                            }, 7000)
                        }
                        else {
                            reject(false)
                        }
                    }
                    resolve(response.data.success)
                })
                .catch(function() {
                    reject(false)
                })
        })
    },

    async subscribeClub({ state }) {
        return new Promise((resolve, reject) => {
            const data = state.userData
            // set source
            data.source = location.href.substring(0, location.href.lastIndexOf('/'))
            const payload = {...state.userData}
            payload.importo = state.choosenCredit

            axios.post(state.clubUrl, payload)
                .then(function(response) {
                    resolve(response)
                })
                .catch(function() {
                    reject(false)
                })
        })
    },

    async loadPrecompiledData({ state }, { url }) {
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function(response) {
                if (response.data.order.StartRobot)
                    state.userData.pendingOrder = true

                else if (response.data.success == true)
                    resolve(response.data.order.step_precompile)

                reject(response)
            })
            .catch(function(response) {
                state.userData.hasError = true
                reject(response)
            })
        });
    },

    async sendData({commit, state, getters}, { step, additionalData, method }) {
        let payload = {}

        // validate data
        if (config.required && config.required[step]) {
            config.required[step].map(el => {
                if (!state.userData[el]) {
                    state.userData.hasError = true
                    return false
                }
            })
        }
        // get payload from step
        if(steps[step]) {
            payload = steps[step](state);
        }
        if(additionalData && additionalData.init) {
            delete(additionalData["init"])
            payload["init_step"] = step
        } else {
            payload["step"] = step
        }
        payload["orderID"] = state.userData.orderID

        // theme defined data append
        if (config.append && config.append[step] && config.append[step].includes(process.env.NODE_ENV)) {
            const appendUrl = `./views/themes/${state.theme}/ext/append.js`
            let { default: append } = requireJs(appendUrl)
            append[step](payload, {commit, state, getters})
        }
        // add env type and name if set
        if (state.envType) payload["envType"] = state.envType
        if (state.envName) payload["envName"] = state.envName

        // aggregate additional data if set
        if(additionalData) {
            for(let key in additionalData) {
                payload[key] = additionalData[key]
            }
        }

        // Add mapped url vars to add querystring param to payload
        if(config.urlVars && config.urlVars.includes(process.env.NODE_ENV)) {
            let urlVars = JSON.parse(JSON.stringify(state.urlVars))
            // add ignore vars if set by config
            if(config.ignoreVars) config.ignoreVars.map(prop => { delete urlVars[prop] })
            payload.query_string = urlVars
        }
        // add locale if set by theme config
        if(config.locales) payload.Lang = state.lang

        // output to console in development env
        if (state.serverUrl === null) {
            console.log("Mock data sending", state.serverUrl, payload)
            return
        }
        // last call is POST
        if (step === "dati_privacy" || method === 'POST') {
            await axios.post(state.serverUrl, payload)
        } else {
            await axios.put(state.serverUrl, payload)
                .then(function(response) {
                    if (response.data.success != true)
                        state.userData.hasError = true
                })
                .catch(function() {
                    state.userData.hasError = true
                })
        }
    }
};
