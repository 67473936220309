<template>
    <div class="min-h-screen md:min-h-full">
        <header class="container mx-auto pt-5 px-2 sm:px-10 md:px-6">
            <div class="bg-dark rounded flex justify-center py-2">
                <img width="86" height="89" src="@/views/themes/club-crm/images/logo.svg" />
            </div>
        </header>
        <div class="w-11/12 sm:max-w-7xl container mx-auto px-6 sm:px-10 md:px-6 my-10 pt-4">
            <div class="text-sm sm:text-base leading-tight text-gray-700 py-4 shadow-lg rounded-3xl bg-white">
                <div class="px-6 py-6 text-center text-2xl sm:text-3xl font-semibold">
                    <i class="icon smile block mx-auto mb-4"></i>
                    <h2 class="px-2">Grazie per aver aderito a Club</h2>
                </div>
                <h1 class="text-xl sm:text-2xl font-semibold text-center sm:mt-4 mb-6 sm:mb-8 uppercase bg-orange-1 py-2">Cosa succede adesso?</h1>
                <div class="max-w-4xl mx-auto py-4 px-6 md:pr-6 md:pl-10 text-base" style="width: fit-content;">            
                    <div class="flex mb-6 items-center">
                        <div class="mr-2 lg:mx-5 text-center">
                            <i class="icon email block"></i>
                        </div>
                        <p class="pl-4 sm:pl-0">
                            Ricevi subito l'e-mail di benvenuto con le istruzioni per registrarti
                        </p>
                    </div>
                    <div class="flex mb-6 items-center">
                        <div class="mr-2 lg:mx-5 text-center">
                            <i class="icon contract block"></i>
                        </div>
                        <p class="pl-4 sm:pl-0">
                            Registrati alla piattaforma Club in pochi e semplici passaggi
                        </p>
                    </div>
                    <div class="flex mb-6 items-center">
                        <div class="mr-2 lg:mx-5 text-center">
                            <i class="icon spark block"></i>
                        </div>
                        <p class="pl-4 sm:pl-0">
                            Inizia a risparmiare con attività 2x1, sconti, gift card e molto altro
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapUserData } from "@/utils";

export default {    
    mounted() {
        localStorage.setItem('club_request_evaluaton', 1)
    },
    computed: {
        ...mapUserData(["name","importo"])
    }
};
</script>