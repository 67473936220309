<template>
  <form
    class=""
    action="next"
    method="POST"
    data-model="importo"
    @submit.prevent="onSubmit"
  >
    <div class="max-w-xl mx-auto">
      <div class="">
        <select name="Importo" class="text-base home-select" v-model="choosenCredit" @change="creditError=false">
          <option value="0">{{ $t("Scegli l'importo") }}</option>
          <option v-for="price in uniquePrices.reverse()" :key="price.value" :value="price.value">{{ price.name }}</option>
          <option value="custom">{{ $t("Più di € 10.000") }}</option>
        </select>
        <div
          class="h-6 w-full text-accent text-base mt-1"
          v-if="creditError"
        >
          {{ $t("* Campo obbligatorio") }}
        </div>
      </div>
      <button class="home-submit bg-accent" type="submit">
        <span class="block font-bold text-xl leading-5">{{ $t("Continua") }}</span>
        <span class="block leading-5 text-base">{{ $t("esito immediato") }}</span>
      </button>
    </div>
  </form>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

const greaterThanZero = (value) => value > 0

export default {
  data() {
    return {
      creditError: false
    };
  },
  computed: {     
    ...mapGetters(["uniquePrices"]),   
    choosenCredit: {
      get() {
        return this.$store.state.choosenCredit;
      },
      set(val) {
        if(val !== 'custom') val = parseInt(val)
        this.$store.commit("setChoosenCredit", val);
      }
    }
  },
  mounted() {
    this.choosenCredit = 0;
  },
  validations: {
    choosenCredit: {
      required,
      greaterThanZero
    }
  },
  methods: {
    onSubmit() {
      if(this.choosenCredit == 0) {
        this.creditError = true
        return
      }
      if(this.choosenCredit == 'custom') {
        this.$router.push("/filiali");
        return
      }

      // this.$router.push("/rata");
      this.$store.dispatch("redirect", { step: "importo", route: "Rata" });
      // this.$gtag.dispatch("creditChoosen", { value: this.choosenCredit });
      // this.$adf.event("Continua_1", { sales: this.choosenCredit });
      // this.$fbq.event('trackCustom',"Continua_1", { value: this.choosenCredit });
      // this.$quantcast.event("_fp.event.Continua_1")      
    }
  }
};
</script>
