<template>
    <footer class="bg-white">
        <div class="w-full p-6 mx-auto sm:max-w-7xl relative">
            <ScrollTopButton />
            <div class="footer-content mt-4">
                <div class="w-full">
                    <div class="flex flex-wrap cursor-pointer">
                        <div class="faq-question outline-none w-4/5 sm:flex-grow text-left" :class="{ active: activeFooter }" @click="activeFooter = !activeFooter">
                            <span class="text-base text-gray-5 leading-none">Messaggio pubblicitario con finalità promozionale.</span>
                        </div>
                        <div class="flex-grow outline-none flex items-center justify-center sm:justify-end sm:mr-6"  @click="activeFooter = !activeFooter">
                            <svg v-if="!activeFooter" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                            </svg>
                        </div>
                    </div>
                    <SlideUpDown class="faq-content font-medium text-gray-5 cursor-default outline-none text-left leading-0 my-2" :active="activeFooter">
                        <p class="text-base">
                            Per tutte le condizioni economiche e contrattuali, fare riferimento alle Informazioni Europee di
                            Base sul Credito ai Consumatori (cd. SECCI) sul sito <a class="underline" href="https://www.cofidis.it/trasparenza" target="_blank">www.cofidis.it/trasparenza</a>.
                        </p>
                        <p class="text-base mt-4">
                            Esempio rappresentativo di prestito personale di importo pari a € 4.000 (Importo totale del credito) da rimborsare in 60 rate mensili da €89,99 (TAN fisso 12,499% - TAEG 13,37%) mediante addebito in conto corrente.
                            Spese accessorie azzerate (spese di istruttoria €0, spese di incasso rata €0, spese per rendiconto periodico €0, spese invio rendiconto €0) imposta di bollo/imposta sostitutiva sul contratto pari a €10, imposta di bollo su rendiconto pari a €0.
                            Importo totale dovuto €5.409,40.
                            <br/>
                            TAEG massimo applicabile per offerte di prestito personale diverse dall'esempio su indicato: 14,11%. Esempio rappresentativo di prestito personale di importo pari a € 3.000 (Importo totale del credito) 
                            da rimborsare in 18 rate mensili da €183,64 (TAN fisso 12,497% - TAEG 14,11%) mediante addebito in conto corrente. 
                            Spese accessorie: spesa di istruttoria € 0,00, spesa di incasso rata € 0,00, spesa per Rendiconto periodico € 0,00, imposta sostitutiva sul contratto € 16,00, spese invio Rendiconto € 0,00 oltre imposta di bollo pari a € 2,00 per importi superiori a € 77,47 per ogni invio. 
                            Importo totale dovuto €3.323,52. La richiesta di finanziamento è soggetta alla valutazione da parte di Cofidis S.A..
                            Offerta valida sino al <span class="fine_campagna">{{ expDate }}</span>.
                        </p>
                    </SlideUpDown>
                </div>
                <div class="text-center sm:text-left text-base mb-24 mt-4">
                    <a href="https://www.cofidis.it/it/privacy-policy.html" class="underline text-base" target="_blank">Privacy Policy</a> |
                    <a href="https://cofidis-docs-pp.s3.eu-central-1.amazonaws.com/agid.pdf" class="underline text-base" target="_blank">Accessibilità: non conforme</a>
                    <a :href="secciUrl" class="text-yellow-4 underline mt-4 block" target="_blank">SECCI</a>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import ScrollTopButton from "@/components/scrollTopButton.vue";
import moment from "moment";

export default {
    components: { ScrollTopButton },
    data() {
        return {
            activeFooter: false
        };
    },
    computed: {
        secciUrl() {
            const credit = this.$store.state.choosenCredit
            const baseUrl = this.$store.state.secciUrl
            const rate = this.$store.state.userData.rate
            if (credit)
                return `${baseUrl}${credit}_${rate}.pdf`
            else
                return ``
        },
        expDate() {
            return moment(this.$store.state.userData.exp_date).locale("it").format('D/M/Y')
        },
    },
};
</script>
