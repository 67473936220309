import Cookies from "js-cookie";

class Append {
    dati_anag(payload, {state}) {
        const expireDays = 90
        // get querystring param
        const gclid = state.urlVars['gclid'] || Cookies.get("gclid")
        // add gclid to payload
        if(gclid) payload.gclid = gclid
        // store gclid in cookies if set in querystring
        if(state.urlVars['gclid']) Cookies.set("gclid", gclid, { expires: expireDays });
        // remove Rate for powercredit
        if(state.userData.importo <= 2000) delete payload['Rate']
    }

    dati_pers_3(payload, {state}) {
        payload.Abitazione = parseInt(state.userData.dwelling_type) - 1
        payload.Affitto = state.userData.monthly_rent
    }

    dati_lav_2(payload, {state}) {
        switch(state.PATH) {
            case 'FE':
                payload.Famigliari = parseInt(state.userData.family_member_count) - 1
                payload.Famigliari_Reddito = parseInt(state.userData.employed_family_member_count) - 1
            break;
            case 'CQ':
                payload.Cessione_Quinto = state.userData.cessione_quinto
            break;
        }
    }

    dati_disguidi_finanziari(payload, {state}) {
        payload.Disguidi_Finanziari = state.userData.financial_problems
    }

    dati_cessione_quinto(payload, {state}) {
        payload.Cessione_Quinto = state.userData.cessione_quinto
    }

    dati_reddito_familiare(payload, {state}) {
        payload.Reddito_Dimostrabile = state.userData.family_income
        payload.Attivita_Parent = state.userData.family_income_activity
    }

    dati_privacy(payload, {state, commit}) {
        payload.validStep = state.steps
        switch(state.PATH) {
            case 'ML':
                payload.TPY = 'B'
                commit('setEnvName', 'metlife')
            break;
            case 'FE':
                payload.TPY = 'C'
                commit('setEnvName', state.userData.importo > 2000 ? 'pp' : 'revolving')
            break;
            case 'CQ':
                payload.TPY = 'A'
                commit('setEnvName', 'cq')
            break;
        }
    }

    promo(_, {state}) {
        if(state.userData.promoUpgraded) {
            window.dataLayer.push({
                event: 'path',
                eventModel: {value: 6000, type: 'promo-upgrade', request_type: 'Prestito Personale'}
            })
        } else {
            window.dataLayer.push({
                event: 'path',
                eventModel: {value: 5000, type: 'promo', request_type: 'Prestito Personale'}
            })
        }
    }

}
export default new Append();
