<template>
    <div class="bg-gray-1 px-4 py-4 rounded-lg w-full flex justify-between mb-3" >
        <div class="flex flex-col gap-4 lg:flex-row flex-wrap w-full lg:gap-x-0 lg:pl-2 lg:py-2">
            <slot></slot>
        </div>
        <button v-show="editable" class="icon pencil" @click="$emit('input', true)">
        </button>
    </div>
</template>

<script>
export default {
    name: "PrecompiledBox",
    props: {
        value: Boolean,
        editable: {
            type: Boolean,
            default: true
        }
    },
    emits: ["update:value"]
}
</script>