<template>
    <form class="club" action="next" method="POST">
        <div class="container text-center py-6 relative mx-auto">
            <i class="icon arrowleft absolute left-4 sm:left-0 top-1/2 transform -translate-y-1/2 cursor-pointer" @click="goBack()"></i>
            <Steps :count="steps" :active="steps - 1"></Steps>
        </div>

        <div class="form-wrapper">
            <Club />
        </div>
    </form>
</template>
<script>
import Club from "@/views/forms/ClubConsent";
import Steps from '@/components/Steps'
import { mapUserData } from "@/utils";
export default {
    components: { Steps, Club },
    computed: {
        ...mapUserData([
            "document_expired"
        ]),
        steps() {
            return 3
        }
    }
};
</script>