<template>
    <section class="metlife pb-8 md:pb-5 pt-5">
        <Steps :count="step_number" :active="step_active" />
        <div class="w-11/12 sm:max-w-7xl mx-auto shadow-lg rounded-3xl bg-white mt-5 mb-10 pb-2">
            <Precompiled />
        </div>
    </section>
</template>
<script>
import Precompiled from "@/views/forms/MetlifePrecompiled";
import Steps from '@/components/Steps'

export default {
    components: { Steps, Precompiled },
    data() {
        return {
            step_number: 4,
            step_active: 2
        };
    }
};
</script>