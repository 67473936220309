<template>
    <div class="form-box pb-8 md:pb-5 pt-3">
        <slot name="form-header"></slot>
        <div class="w-11/12 sm:max-w-7xl mx-auto shadow-lg rounded-3xl bg-white mt-5 mb-10 pb-10">
            <FormHeader class="pt-8 pb-5 text-center">{{ title }}</FormHeader>
            <form class="relative text-sm container mx-auto pl-5 pr-6 md:px-12 text-gray-700" @submit.prevent>
                <div class="px-2 pt-4 pb-6 md:px-8 flex flex-wrap items-start" >
                    <slot></slot>
                </div>
                <div class="flex items-center flex-col mt-4">
                    <StickyButton :clickHandler="submit" class="w-fit relative flex justify-center">
                        <span class="text-center text-xl font-semibold uppercase text-white">{{ submitText }}</span>
                    </StickyButton>
                    <button class="text-center text-sm text-gray-700 mt-8 underline block mx-auto text-white" @click="decline">{{ declineText }}</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import StickyButton from "@/components/StickyButton.vue"
import FormHeader from "@/components/FormHeader.vue";

export default {
    name: "FormBox",
    components: { FormHeader, StickyButton },
    props: {
        title: {
            type: String,
            default: ""
        },
        submitText: {
            type: String,
            default: "CONTINUA"
        },
        declineText: {
            type: String,
            default: "Torna indietro"
        }
    },
    methods: {
        submit(e) {
            this.$emit('submit', e)
        },
        decline(e) {
            this.$emit('decline', e)
        },
    }
}

</script>