<template> 
    <div>
        <div class="w-full bg-white flex justify-center py-3">
            <img src="@/views/themes/civibank/images/Civibank_Logo.svg" />
        </div>
        <div class="bg-gray-100">
            <div class="md:container mx-auto text-center px-6 sm:px-10 md:px-6">
                <div class="bg-white my-16 rounded-3xl shadow-2xl px-4 mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <p class="text-xl text-gray-5 font-semibold pt-4 sm:pt-10 pb-2 mb-4 px-4 text-2xl sm:px-8 lg:text-3xl">
                        {{ $t("Prestiti fino a 10.000€") }}
                    </p>
                    <!-- Form importo -->
                    <FormImporto />
                    <h2 class="mt-6 sm:mt-8 text-lg xs:text-xl text-gray-5 font-light">
                        {{ $t("Ti bastano 3 minuti ovunque tu sia") }}
                    </h2>
                    <div class="grid grid-cols-3 py-4 sm:py-4 lg:px-40">
                        <!-- Icon 1 -->
                        <div class="text-center my-4">
                            <i class="icon phone align-middle w-10 h-10 block rounded-full mb-1 sm:mt-0 inline-block"></i>
                            <h4 class="block text-sm xl:inline-block lg:px-2" v-html="$t('Procedura <br class=\'md:hidden\' />100% online')">
                            </h4>
                        </div>
                        <!-- Icon 2   -->
                        <div class="text-center my-4">
                            <i class="icon rata align-middle w-10 h-10 block rounded-full mb-1 sm:mt-0 inline-block"></i>
                            <h4 class="block text-sm xl:inline-block lg:px-2" v-html="$t('Rata <br class=\'md:hidden\' />fissa')">
                            </h4>
                        </div>
                        <!-- Icon 3 -->
                        <div class="text-center my-4">
                            <i class="icon flash align-middle w-10 h-10 block rounded-full mb-1 sm:mt-0 inline-block"></i>
                            <h4 class="block text-sm xl:inline-block lg:px-2" v-html="$t('Prevalutazione <br class=\'md:hidden\' />immediata')">
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="relative">
                <div class="absolute w-full h-1/2 bg-white z-0 top-1/2"></div>
                <div class="md:container mx-auto px-6 sm:px-10 md:px-6 z-10 relative">
                    <div class="bg-accent text-white py-16 text-center leading-none flex flex-wrap justify-center rounded-3xl">
                        <div class="w-full pb-10 sm:p-0 sm:w-1/3 sm:border-r border-white">
                            <h1 class="text-xl lg:text-2xl xl:text-3xl">
                                <span class="text-xl lg:text-2xl xl:text-3xl block mb-1">{{ $t('Presente con') }}</span>
                                <span class="text-5xl sm:text-3xl lg:text-5xl font-semibold">64 </span>
                                <span class="text-xl lg:text-2xl xl:text-3xl font-semibold">{{ $t('filiali') }}</span>
                            </h1>             
                            <hr class="sm:hidden w-1/3 mx-auto border-white mt-10">   
                        </div>
                        <div class="w-full pb-10 sm:p-0 sm:w-1/3 sm:border-r border-white">
                            <h1 class="text-xl sm:m-0 lg:text-2xl xl:text-3xl">
                                {{ $t("Attiva dal") }} <strong class="text-5xl sm:text-3xl lg:text-5xl block mt-1 font-semibold">1886</strong>
                            </h1>
                            <hr class="sm:hidden w-1/3 mx-auto border-white mt-10">   
                        </div>
                        <div class="w-full sm:w-1/3">
                            <h1 class="text-xl lg:text-2xl xl:text-3xl">
                                <span class="text-xl lg:text-2xl xl:text-3xl block mb-1">{{ $t('Oltre') }}</span>
                                <span class="text-5xl sm:text-3xl lg:text-5xl font-semibold">100.000 </span>
                                <span class="text-xl lg:text-2xl xl:text-3xl font-semibold" v-html="$t('clienti')" ></span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Section steps -->
            <section class="section-steps bg-white py-8 sm:py-20">
                <div class="container mx-auto">
                    <!-- Title -->
                    <div class="text-center mb-8 sm:mb-14">
                        <h3 class="font-semibold text-3xl  md:text-4xl" v-html="$t('Come richiedere il tuo prestito, 100% online')">
                        </h3>
                    </div>
                    <!-- Steps -->
                    <div class="mb-4 sm:mb-10 flex flex-wrap">
                        <!-- Step 1 -->
                        <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-3 lg:w-1/4 lg:flex lg:flex-1">
                            <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-5 pb-6 px-9 sm:pt-4 sm:px-6 sm:h-40 lg:h-auto animate__animated animateFadeInUp animate__1 border border-gray-200">
                                <span class="block font-bold text-sm text-gray-4 mb-2">Step 1</span>
                                <p class="font-semibold text-3xl text-accent">{{ $t("Scegli l’importo") }}</p>
                                <p class="font-light text-gray-4 mt-2">{{ $t('Richiedi da 3.000€ a 10.000€')  }}</p>
                            </div>
                        </div>
                        <!-- Step 2 -->
                        <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-3 lg:w-1/4 lg:flex lg:flex-1">
                            <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-5 pb-6 px-9 sm:pt-4 sm:px-6 sm:h-40 lg:h-auto animate__animated animateFadeInUp animate__2 border border-gray-200">
                                <span class="block font-bold text-sm text-gray-4 mb-2">Step 2</span>
                                <p class="font-semibold text-3xl text-accent">
                                    {{ $t('Invia la richiesta')  }}
                                </p>
                                <p class="font-light text-gray-4 mt-2">
                                    {{ $t("Inserisci i tuoi dati. Bastano solo pochi minuti!") }}
                                </p>
                            </div>
                        </div>
                        <!-- Step 3 -->
                        <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-3 lg:w-1/4 lg:flex lg:flex-1">
                            <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-5 pb-6 px-9 sm:pt-4 sm:px-6 sm:h-40 lg:h-auto animate__animated animateFadeInUp animate__2 border border-gray-200">
                                <span class="block font-bold text-sm text-gray-4 mb-2">Step 3</span>
                                <p class="font-semibold text-3xl text-accent">
                                    {{ $t('Ricevi l’esito')  }}
                                </p>
                                <p class="font-light text-gray-4 mt-2">
                                    {{ $t("Prevalutazione immediata con esito in 24 ore") }}
                                </p>
                            </div>
                        </div>
                        <!-- Step 4 -->
                        <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-3 lg:w-1/4 lg:flex lg:flex-1">
                            <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-5 pb-6 px-9 sm:pt-4 sm:px-6 sm:h-40 lg:h-auto animate__animated animateFadeInUp animate__3 border border-gray-200">
                                <span class="block font-bold text-sm text-gray-4 mb-2">Step 4</span>
                                <p class="font-semibold text-3xl text-accent">{{ $t('Firma con un sms') }}</p>
                                <p class="font-light text-gray-4 mt-2">
                                    {{ $t('Firma il contratto, direttamente dal tuo smartphone')  }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- Button scrollToTop -->
                    <div class="text-center">
                        <button class="font-semibold text-lg text-white bg-accent uppercase max-w-xs rounded-full shadow-2xl px-8 py-3 sm:max-w-none sm:w-auto animate__animated animateFadeInUp animate__5" @click.prevent="scrollToTop()">
                            {{ $t('Richiedi subito')  }}
                        </button>
                    </div>
                </div>
            </section>
            <section class="section-amount py-16">
                <div class="container mx-auto px-2 sm:px-10 text-center">
                    <h3 class="font-semibold text-3xl  leading-tight px-8 mb-4 sm:px-0 md:text-4xl">
                        {{  $t('Facile, basta un click') }}
                    </h3>
                    <p class="font-light text-lg  mb-5 lg:text-xl">
                        {{  $t('Indica qui sotto l’importo che ti serve') }}
                    </p>
                    <FormImporto class="mx-4" />
                    <p class="mt-8">{{ $t('Ti bastano 3 minuti dovunque tu sia')  }}</p>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import FormImporto from "@/views/themes/civibank/FormImporto.vue";

export default {
    components: { FormImporto },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        },
    }
};
</script>