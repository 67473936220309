<template>
    <div class="pb-8 md:pb-5 pt-8 md:pt-11">
        <div class="w-11/12 sm:max-w-7xl mx-auto shadow-lg rounded-3xl bg-white mt-5 mb-10 pb-10">
            <form class="relative text-sm container mx-auto pl-5 pr-6 md:px-12 text-gray-700" @submit.prevent>
                <FormHeader class="pt-8 pb-5 text-center">Non perdere questa occasione!</FormHeader>
                <div class="mb-8 text-base max-w-4xl mx-auto">
                    <p class="text-center md:text-left">Con il servizio CLUB, per 12 mesi puoi:</p>
                    <ul class="mt-2 mb-3 mr-2 flex flex-col gap-3">
                        <li class="max-w-sm px-4 md:px-0 md:max-w-none w-fit flex items-start md:items-center">
                            <img src="@/views/themes/riutilizzi-club/icons/medical-chart-sm.svg" class="mr-3 md:mr-2 h-fit mt-1" /> 
                            <p class="">Accedere a <strong>prestazioni sanitarie a prezzi scontati</strong> fino al 65%, in più di 12.000 strutture in Italia</p>
                        </li>
                        <li class="max-w-sm px-4 md:px-0 md:max-w-none w-fit flex items-start md:items-center">
                            <img src="@/views/themes/riutilizzi-club/icons/restaurant-sm.svg" class="mr-3 md:mr-2 h-fit mt-1" /> 
                            <p class="">Usufruire di <strong>esperienze uniche in formula 2x1</strong>: paghi per uno e invita chi vuoi (ad esempio cinema, ristoranti e hotel)</p>
                        </li>
                        <li class="max-w-sm px-4 md:px-0 md:max-w-none w-fit flex items-start md:items-center">
                            <img src="@/views/themes/riutilizzi-club/icons/shopping-trolley-sm.svg" class="mr-3 md:mr-2 mt-1" /> 
                            <p class="">Risparmiare sullo shopping, grazie a <strong>sconti e gift card</strong> sui prodotti dei <strong>migliori brand</strong></p>
                        </li>
                    </ul>
                    <p class="mt-4 text-center md:text-left">Non lasciarti sfuggire questa occasione!</p>
                </div>
                <StickyButton :clickHandler="submit">
                    <span class="text-center text-xl font-semibold">CONTINUA</span>
                </StickyButton>
                <div class="text-center text-gray-700 mt-8 md:mt-8">
                    <button @click="submit(false)" class="underline text-base">Procedi senza Club</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>

import FormHeader from '@/components/FormHeader.vue'
import StickyButton from '@/components/StickyButton.vue'

export default {
    components: { FormHeader, StickyButton },
    methods: {
        submit(wantClub = true) {
            if(wantClub) {
                this.$store.dispatch("sendData", { step: "club" });
                this.$store.dispatch("sendData", { step: "dati_anag" });
                this.$store.dispatch("redirect", { step: "club_warning", route: "ClubPowerConsent" });
            } else {
                this.$store.dispatch("sendData", { step: "dati_anag" });
                this.$store.dispatch("redirect", { step: "club_warning", route: "Income" });
            }
        },
    }
};
</script>