<template>
    <MetlifeBestCareConsent />
</template>

<style scoped type="text/css">
@media screen and (max-width: 639px) {
    div >>> .form-header { font-size: 1rem !important; }
}
</style>

<script>
import MetlifeBestCareConsent from "@/views/forms/MetlifeBestCareConsent";
export default {
    components: { MetlifeBestCareConsent },
};
</script>