<template>
    <main>
        <Loader v-if="loading" />
        <div v-else>
            <!-- Header -->
            <header class="w-full relative">
                <!-- Logo -->
                <div class="logo flex justify-end container mx-auto px-6 max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <div class="z-50 w-2/3 h-14 md:w-1/3 sm:h-20 flex justify-around bg-white rounded-b-2xl py-2 lg:-mr-6 2xl:-mr-24 shadow-sm">
                        <img class="header-logo m-auto inline-block sm:hidden" src="@/assets/images/logo.svg" alt="Cofidis Logo" title="Cofidis Logo" />
                        <img class="header-logo m-auto hidden sm:inline-block" src="@/assets/images/logo-claim.svg" alt="Cofidis Logo" title="Cofidis Logo" />
                        <div class="h-full border-r border-solid border-black border-opacity-25"></div>
                        <img class="header-logo m-auto inline-block sm:hidden" src="@/assets/images/logo-power-credit.svg" alt="Power Credit Logo" title="Power Credit Logo" />
                        <img class="header-logo m-auto hidden sm:inline-block" src="@/assets/images/logo-power-credit.svg" alt="Power Credit Logo" title="Power Credit Logo" />
                    </div>
                </div>
            </header>
            <form class="w-full mx-auto text-center px-6 lg:px-0" @submit.prevent>
                <!-- Hero -->
                <div class="bg-white rounded-3xl shadow-2xl mx-auto pb-8">
                    <div class="px-10">
                        <p class="text-xl text-gray-5 font-semibold pt-4 sm:pt-6 px-4 text-2xl sm:px-8 lg:text-xl">{{ name }},</p>
                        <p class="font-medium mb-2">continua a utilizzare Power Credit, la tua Linea di Credito Ricaricabile</p>
                        <!--<ConsoInput :name="`choosenCredit`" v-model.trim="choosenCredit" :mask="currencyMask" :error="$v.choosenCredit.$error" :type="`tel`" @click="count++"/>-->
                        <input id="credit-select" class="home-select text-3xl text-center w-60 border-2 mt-2" :class="{'error' : $v.choosenCredit.$error && !message}" v-mask="currencyMask" inputmode="numeric" v-model.trim="choosenCredit" @blur="$emit('blur', $event.target.value), $v.choosenCredit.$touch(), message = false" @click="message = true" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
                        <div class="flex flex-wrap justify-center mb-2" v-if="$v.choosenCredit.$error && !message">
                            <div id="error-display" class="flex flex-wrap w-full md:w-60">
                                <div class="w-1/4 mt-2">
                                    <img src="./images/alert.svg" alt="" v-if="$v.choosenCredit.required">
                                </div>
                                <div class="w-3/4 text-left self-center">
                                    <p class="req mt-2 text-sm error" v-if="!$v.choosenCredit.required">Campo obbligatorio</p>
                                    <p class="min mt-2 text-sm error" v-if="!$v.choosenCredit.minValue">L'importo deve essere maggiore o uguale a 100€</p>
                                    <p class="max mt-2 text-sm error" v-if="!$v.choosenCredit.maxValue">L'importo non deve superare i {{ maxAmount|num }}€</p>
                                    <p class="div mt-2 text-sm error" v-if="!$v.choosenCredit.divisibleBy100 && $v.choosenCredit.maxValue">La cifra deve essere arrotondata alle centinaia (es. 200, 300, 400…)</p>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap justify-center mb-2" v-if="message">
                            <div id="error-display" class="flex flex-wrap w-full md:w-60">
                                <div class="w-1/4 mt-2">
                                    <img src="./images/alert.svg" alt="">
                                </div>
                                <div class="w-3/4 text-left self-center">
                                    <p class="text-sm mt-2 text-left">
                                        Inserisci una cifra compresa tra 100€ e {{ maxAmount|num }}€;
                                        la cifra deve essere arrotondata alle centinaia (es. 200, 300, 400…)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p class="text-center text-xl font-semibold mt-4"><b>Da usare come e quando vuoi e li ricevi sul tuo conto corrente</b></p>
                    </div> 
                </div>
            </form>
            <a @click="submit()" class="cta w-4/6 sm:w-auto font-semibold text-lg text-gray-4 bg-orange-1 max-w-xs rounded-full shadow-2xl px-6 sm:px-10 py-2 sm:max-w-none fixed bottom-6 left-1/2 transform -translate-x-1/2 z-50 cursor-pointer">
                <span class="block text-center text-xl">RICHIEDI ORA</span>
                <span class="block font-light text-base text-center -mt-1">Solo fino al <strong class="font-bold uppercase">{{ expDate }}</strong></span>
            </a>

            <!-- Section faq -->
            <section id="anchor" class="section-faq text-center">
                <div class="sm:container px-6 py-6 mx-auto">
                    <h3 class="font-semibold text-3xl text-gray-5 leading-none px-6 mb-6 text-center">
                        Come funziona?
                    </h3>
                    <div class="faq-container w-full xl:w-2/3 flex flex-wrap hover:text-orange cursor-pointer bg-white rounded-xl py-3 px-6 mx-auto mb-3">
                        <div class="w-full">
                            <div class="flex flex-wrap">
                                <div class="faq-question outline-none w-4/5 sm:flex-grow text-left" :class="{ active: activeFaq == 0 }" @click="toggleFaq(0)">
                                    <span class="font-semibold text-lg text-gray-5 leading-none">Riutilizza Power Credit per i tuoi acquisti</span>
                                </div>
                                <div class="flex-grow text-right rounded-full leading-10 outline-none flex items-center justify-end"  @click="toggleFaq(0)">
                                    <svg v-if="activeFaq != 0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                                    </svg>
                                </div>
                            </div>
                            <SlideUpDown class="faq-content font-medium text-gray-5 cursor-default outline-none text-left leading-0 my-2" :active="activeFaq == 0">
                                <span class="font-light text-base text-gray-5 leading-none">
                                    Continua a fare acquisti in modo semplice e veloce grazie alla disponibilità della 
                                    tua Linea di Credito Ricaricabile.Invia la richiesta in pochi click e ricevi una nuova 
                                    somma di denaro sul tuo conto corrente.
                                </span>
                            </SlideUpDown>
                        </div>
                    </div>
                    <div class="faq-container w-full xl:w-2/3 flex flex-wrap hover:text-orange cursor-pointer bg-white rounded-xl py-3 px-6 mx-auto mb-3">
                        <div class="w-full">
                            <div class="flex flex-wrap">
                                <div class="faq-question outline-none w-4/5 sm:flex-grow text-left" :class="{ active: activeFaq == 1 }" @click="toggleFaq(1)">
                                    <span class="font-semibold text-lg text-gray-5 leading-none">I dettagli della tua offerta</span>
                                </div>
                                <div class="flex-grow text-right rounded-full leading-10 outline-none flex items-center justify-end"  @click="toggleFaq(1)">
                                    <svg v-if="activeFaq != 1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                                    </svg>
                                </div>
                            </div>
                            <SlideUpDown class="faq-content font-medium text-gray-5 cursor-default outline-none text-left leading-0 my-2" :active="activeFaq == 1">
                                <span class="font-light text-base text-gray-5 leading-none">
                                    Hai utilizzato {{ owedAmount |num }}€ della tua Linea di Credito Ricaricabile e puoi richiedere fino a {{ maxAmount|num }}€.
                                    Con questa richiesta avrai nuova disponibilità. Per le condizioni economiche
                                    e contrattuali si rimanda alla documentazione in tuo possesso.
                                </span>
                            </SlideUpDown>
                        </div>
                    </div>
                </div>
            </section>

            <div class="text-center py-6">
                <h3 class="text-3xl font-semibold text-gray-900 leading-tight mx-auto mt-2">Controlla i tuoi dati</h3>
            </div>

            <div class="bg-white p-6">
                <div class="max-w-4xl xl:max-w-6xl mx-auto flex flex-wrap">
                    <label class="w-1/4 text-lg text-gray-900 font-medium">Cognome</label>
                    <span class="w-3/4 text-lg text-gray-700 font-semibold uppercase">{{ surname }}</span>
                    <label class="w-1/4 text-lg text-gray-900 font-medium mt-2">Nome</label>
                    <span class="w-3/4 text-lg text-gray-700 font-semibold uppercase mt-2">{{ name }}</span>
                    <label class="w-1/4 text-lg text-gray-900 font-medium mt-2">IBAN</label>
                    <span class="w-3/4 text-lg text-gray-700 font-semibold uppercase mt-2">{{ maskedIban }}</span>
                </div>
            </div>

            <div id="email-container" class="bg-yellow text-center p-6">
                <p class="text-center font-semibold text-xl text-gray-900 leading-none">Verifica che la tua e-mail sia corretta oppure inseriscila.</p>
                <div class="sm:w-96 text-center mx-auto mt-4">
                    <div class="relative">
                        <input type="email" id="email" name="email" class="w-full py-2 pl-4 pr-10 text-lg" v-model.trim="email" v-mask="{ placeholder: '', greedy: false, casing: 'lower' }"
                            placeholder="Se non appare la tua email, inseriscila" :class="{ 'border border-red': $v.email.$error }" @blur="$v.email.$touch">
                        <label for="email"><i class="icon pencil absolute top-3 right-2 cursor-pointer"></i></label>
                    </div>
                    <div class="w-full text-red text-left" v-if="$v.email.$error">
                        <small v-if="!$v.email.required">
                            Campo obbligatorio
                        </small>
                        <small v-else-if="!$v.email.email">
                            Formato e-mail non valido
                        </small>
                    </div>
                </div>
            </div>

            <div class="p-6">
                <div class="max-w-4xl xl:max-w-6xl mx-auto flex flex-wrap text-base">
                    <span><strong class="font-bold">Perché conosciamo i tuoi dati?</strong>
                        La risposta è <strong class="font-bold">semplice</strong> e <strong class="font-bold">trasparente</strong>:
                        hai già una delle soluzioni di credito con Cofidis</span>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
// @ is an alias to /src
import Loader from "@/components/Loader"
import { mapUserData } from "@/utils";
import loadData from "@/dataService.js";
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import moment from "moment/moment";
import { required, email, minValue, maxValue} from "vuelidate/lib/validators";
import { currencyMask } from "@/masks";
// import ConsoInput from "./ConsoInput.vue";

const divisibleBy100 = (value) => {
    value = parseInt(value)
    if(value > 100 && value != '' && value % 100 != 0) return false
    else return true
}

const redirectHomepage = () => {    
    location.href = '/riutilizzi-crm'
}

export default {
    name: "Home",
    components: { Loader },
    data() {
        return {
            activeFaq: null,
            activeSolutionIndex: 0,
            commonDistributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/common",
            count: 0,
            currencyMask,
            disponibilita: null,
            distributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/powercredit-crm",
            expDate: null,
            loading: true,
            message: false,
            owedAmount: null,
            prices: loadData("price.json").prices.reverse(),
        };
    },
    methods: {
        async loadClientData(data) {
            return new Promise((resolve, reject) => {
                axios.post(this.crmUrl, data)
                    .then(function(response) {
                        console.log(response.data)
                        if(response.data && response.data.dt_end_riu)
                            resolve(response.data)
                        else
                            reject(false)
                    })
                    .catch(function() {
                        reject(false)
                    })
            })
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        },
        scrollToAnchor() {
            window.scrollTo({
                top: document.getElementById('anchor').offsetTop,
                left: 0,
                behavior: "smooth",
            });
        },
        scrollToEmail() {
            window.scrollTo({
                top: document.getElementById('email-container').offsetTop,
                left: 0,
                behavior: "smooth",
            });
        },
        toggleFaq(i) {
            if (this.activeFaq == i) this.activeFaq = null;
            else this.activeFaq = i;
        },
        async submit() {
            window.removeEventListener('popstate', redirectHomepage)
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.$store.dispatch("sendData", { step: "dati_privacy" });
                this.$store.dispatch("redirect", { step: "homepage", route: "ThankYou" });
            }else{
                if(this.$v.choosenCredit.$error){
                    this.scrollToTop();
                    console.log('error')
                }if(this.$v.email.$error){
                    this.scrollToEmail()
                }
            }
        },
        reloadOnBrowserBack() {            
            window.popStateDetected = false
            window.addEventListener('popstate', redirectHomepage)
        }
    },
    computed: {
        ...mapGetters(["uniquePrices"]),
        ...mapState(["userData"]),
        ...mapState(["choosenCredit"]),
        ...mapUserData([
            "name",
            "surname",
            "iban",
            "email",
            "max_amount"
        ]),
        maxAmount: {
            get() {
                return this.$store.state.userData.max_amount;
            },
            set(val) {
                this.$store.commit("setUserData", { key: 'max_amount', value: val});
            }
        },
        choosenCredit: {
            get() {
                return this.$store.state.choosenCredit;
            },
            set(val) {
                this.$store.commit("setChoosenCredit", val);
            }
        },
        maskedIban() {
            return `${this.iban[0]}${this.iban[1]}****************${this.iban.slice(-4)}`
        },
        crmUrl() {
            return this.$store.state.crmUrl;
        }
    },
    async created() {
        this.reloadOnBrowserBack()

        // get params
        const urlParts = this.$store.state.urlParts
        let userId, codeCampaign, trackCode;

        if(urlParts[1] && urlParts[2]) {
            userId = urlParts[1]
            codeCampaign = urlParts[2]
            trackCode = urlParts[3] ?? null
        }
        else {
            userId = localStorage.getItem('riutilizzi_user_id')
            codeCampaign = localStorage.getItem('riutilizzi_code_campaign')
            trackCode = localStorage.getItem('riutilizzi_track_code') ?? null
            // fix localstorage cast type issue
            if(trackCode === "null") trackCode = null;
        }

        // redirect to pending modal if already compiled
        // if(localStorage.getItem('riu_request_evaluation')) {
        //     this.loading = false;
        //     this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
        //     return;
        // }

        try {

            localStorage.setItem('riutilizzi_user_id', userId)
            localStorage.setItem('riutilizzi_code_campaign', codeCampaign)
            if(trackCode) localStorage.setItem('riutilizzi_track_code', trackCode)

            const data = await this.loadClientData({userId, codeCampaign, trackCode});
            console.log(data);
            this.$store.commit("setUserData", { key: 'user_id', value: userId });
            this.$store.commit("setUserData", { key: 'client_id', value: data.cliente_id });
            this.$store.commit("setUserData", { key: 'code_campaign', value: codeCampaign });
            this.$store.commit("setUserData", { key: 'name', value: data.Nome });
            this.$store.commit("setUserData", { key: 'surname', value: data.Cognome });
            this.$store.commit("setUserData", { key: 'email', value: data.EMail });
            this.$store.commit("setUserData", { key: 'iban', value: data.Iban });
            this.$store.commit("setUserData", { key: 'fiscal_code', value: data.CF });
            this.$store.commit("setUserData", { key: 'cellphone', value: data.Phone });
            this.$store.commit("setUserData", { key: 'day_in_month', value: 1 });
            this.$store.commit("setUserData", { key: 'owed_amount', value: data.importo_tot_dovuto_riu});
            this.$store.commit("setUserData", { key: 'max_amount', value: data.disponibilita_riu});
            this.$store.commit("setUserData", { key: 'cod_camp_rev', value: data.cod_camp_rev});
            this.$store.commit("setUserData", { key: 'dt_start_riu', value: moment(data.dt_start_riu).locale("it").format('DD-MM-YYYY')});
            this.$store.commit("setUserData", { key: 'dossier_id', value: data.pratica_id_rev});
            this.$store.commit("setUserData", { key: 'output_date', value: moment(new Date()).locale("it").format('DD-MM-YYYY')});
            this.$store.commit("setChoosenCredit", data.disponibilita_riu);

            // set expiry date
            this.expDate = moment(data.dt_end_riu).locale("it").format('D MMMM');
            this.$store.commit("setUserData", { key: 'exp_date', value: this.expDate });

            // offer expired
            if(moment(data.dt_end_riu).endOf('day') < moment()) {
                this.$store.dispatch("redirect", {step: "income", route: "RequestExpired"});
                return;
            }
            // pending procedure
            if(data.page_block && data.page_block === 'evaluation') {
                this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
                return;
            }

            // init robot
            this.$store.dispatch("sendData", { step: "enter_landing" });

            this.owedAmount = data.importo_tot_dovuto_riu;
            this.disponibilita = data.disponibilita_riu;
            this.name = data.Nome;
            // this.maxAmount = data.extra_cash_conso

        } catch (error) {
            // if(localStorage.getItem('riu_request_evaluation')) {
            //     // this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
            //     // console.log(error)
            // }
            // else {
                this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
            // }
        }
        this.loading = false
    },
    validations: {
            email: {
                required,
                email,
            },
            choosenCredit: {
                required,
                minValue: minValue(100),
                divisibleBy100,
                maxValue(value){
                    return maxValue(this.maxAmount)(value)
                },
            },
    },
};
</script>