<template>
    <form action="next" method="POST">
        <div class="container text-center py-6 relative mx-auto">
            <i class="icon arrowleft absolute left-4 sm:left-0 top-1/2 transform -translate-y-1/2 cursor-pointer" @click="goBack()"></i>
            <Steps :count="4" :active="2"></Steps>
        </div>

        <div class="form-wrapper">
            <IdentityCardData />
        </div>
    </form>
</template>
<script>
import IdentityCardData from "@/views/forms/IdentityCardData";
import Steps from '@/components/Steps'

export default {
    components: { Steps, IdentityCardData },
    mounted() {
        this.$store.dispatch("sendData", { step: "documents", additionalData: {
            init: true,
            codeCampaign: localStorage.getItem('fastcredit_code_campaign'), 
            userId: localStorage.getItem('fastcredit_user_id') 
        }});
    },
};
</script>