<template>
    <div class="mx-auto lg:container text-sm sm:text-base leading-tight text-gray-700 pt-4">
        <div class="px-6 py-6 text-center text-2xl sm:text-3xl font-semibold">
            <i class="icon smile block mx-auto mb-4"></i>
            <span v-if="service">
                <p>Complimenti,</p> la tua richiesta di riutilizzo è stata presa in carico
            </span>
            <span v-else>
                <p class="max-w-md mx-auto">Ti confermiamo che abbiamo ricevuto la tua richiesta di riutilizzo per un importo di {{ choosenCredit|num }}€</p>
            </span>
        </div>
        <h1 class="text-xl sm:text-2xl font-semibold text-center sm:mt-4 mb-6 sm:mb-8 uppercase bg-orange-1 py-2">Cosa succede adesso?</h1>
        <div class="max-w-4xl text-lg mx-auto py-4 px-6">            
            <div class="flex mb-6 items-center">
                <div class="w-1/5 text-center">
                    <i class="icon sms block mx-auto"></i>
                </div>
                <p class="w-4/5 pl-4 sm:pl-0 text-sm">
                    Riceverai un SMS con l’esito dell’operazione
                </p>
            </div>
            <div class="flex mb-6 items-center">
                <div class="w-1/5 text-center">
                    <i class="icon coins block mx-auto"></i>
                </div>
                <p class="w-4/5 pl-4 sm:pl-0 text-sm">
                    Entro 24 ore dall’approvazione, vedrai l’importo accreditato sul tuo conto corrente
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import { mapUserData } from "@/utils";

export default {
    mounted() {
        localStorage.setItem('riu_ar_request_evaluation', 1)
    },
    computed: {
        ...mapUserData(["name","importo", "service"]),
        choosenCredit: {
            get() {
                return this.$store.state.choosenCredit;
            }
        }
    }
};
</script>