<template>
    <div>
        <ConsentItemCheck v-model="consent_club5"  :v="$v.consent_club5" :mustBeTrueError="''" @input="handleConsent5Input">
            <span class="text-sm leading-6">Acconsento ai trattamenti di cui al punto 2.3 e 2.4 dell'Informativa Privacy. Questi consensi sono facoltativi,
                <button type="button" class="underline" @click="showOptionalConsents = !showOptionalConsents">clicca qui</button>
                per visionarli e selezionarli individualmente.</span>
        </ConsentItemCheck>
        <SlideUpDown :active="showOptionalConsents" class="w-full pl-10 pt-4 lg:pt-2">
            <OptionalItemCheck  v-model="consent_club5a" @input="handleChildConsentsInput">
                <span class="text-xs leading-6">Acconsento al trattamento dei miei dati personali per l'invio di comunicazioni promozionali o per ricerche di mercato e sondaggi tra i consumatori che includono contenuti di marketing
                    (finalità di cui al punto 3. a. della presente <a class="underline" :href="privacyClubUrl" target="_blank">Informativa</a>).</span>
            </OptionalItemCheck>
            <OptionalItemCheck  v-model="consent_club5b" @input="handleChildConsentsInput">
                <span class="text-xs leading-6">Acconsento al trattamento dei miei dati personali al fine di far comprendere meglio le mie abitudini e le mie preferenze ed inviarmi, di conseguenza, comunicazioni personalizzate in linea con le mie specifiche esigenze
                    (finalità di cui al punto 3.b. della presente <a class="underline" :href="privacyClubUrl" target="_blank">Informativa</a>).</span>
            </OptionalItemCheck>
            <OptionalItemCheck  v-model="consent_club5c" @input="handleChildConsentsInput">
                <span class="text-xs leading-6">Acconsento alla condivisione dei miei dati di contatto con partner terzi selezionati, per i loro autonomi scopi di marketing
                    (finalità di cui al punto 3. c. della presente <a class="underline" :href="privacyClubUrl" target="_blank">Informativa</a>).</span>
            </OptionalItemCheck>
            <OptionalItemCheck  v-model="consent_club5d" @input="handleChildConsentsInput">
                <span class="text-xs leading-6">Acconsento alla condivisione dei miei dati personali con partner terzi selezionati, per i loro autonomi scopi di profilazione
                    (finalità di cui al punto 3. d. della presente <a class="underline" :href="privacyClubUrl" target="_blank">Informativa</a>).</span>
            </OptionalItemCheck>
        </SlideUpDown>
    </div>
</template>

<script>
import { mapUserData } from "@/utils";
import ConsentItemCheck from "@/components/RequiredItemCheck.vue";
import OptionalItemCheck from "@/components/OptionalItemCheck.vue";

export default {
    data() {
        return {
            privacyClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Informativa_Servizio.pdf',
            showOptionalConsents: false
        }
    },
    components: { ConsentItemCheck, OptionalItemCheck },
    computed: {
        ...mapUserData([
            "consent_club5",
            "consent_club5a",
            "consent_club5b",
            "consent_club5c",
            "consent_club5d"
        ]),
        allChildConsents: {
            get() {
                return [this.consent_club5a, this.consent_club5b, this.consent_club5c, this.consent_club5d]
            },
            set(val) {
                this.consent_club5a = val
                this.consent_club5b = val
                this.consent_club5c = val
                this.consent_club5d = val 
                return [this.consent_club5a, this.consent_club5b, this.consent_club5c, this.consent_club5d]
            }
        }
    },
    validations: {
        consent_club5: {}
    },
    methods: {
        handleConsent5Input(val) {
            this.allChildConsents = val
        },
        handleChildConsentsInput(val) {
            if (!val) this.consent_club5 = false
            else {
                const countTrue = this.allChildConsents.reduce((count, value) => count + (value === true ? 1 : 0), 0);
                if (countTrue === this.allChildConsents.length) this.consent_club5 = true
            }
        }
    },
};
</script>