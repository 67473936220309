<template>
    <div>
        <FormCard @submit.prevent="onSubmit" title="Non perdere questa occasione!">
            <p class="mb-4">
                Cofidis ti regala il primo mese di <strong>accesso gratuito</strong> al CLUB PLUS, in cui puoi usufruire di esperienze uniche in formula 2x1:
                paghi per uno e invita chi vuoi! Dopo il mese gratuito, soli 9,90 € al mese. Non lasciarti sfuggire questa occasione!
            </p>
            <div slot="link" class="text-center text-gray-700 mt-4 mb-8">
                <button @click="onDecline()" class="underline">Procedi senza Club</button>
            </div>
        </FormCard>
    </div>
</template>
<style scoped type="text/css">
@media screen and (max-width: 639px) {
    div >>> .form-header { font-size: 1rem !important; }
}
</style>
<script>
export default {
    mounted() {
        this.$ga.pageview()
        this.$store.dispatch("sendData", { step: "club_warning" })
    },
    methods: {
        onDecline() {
            this.$store.commit("setUserData", { key: 'service', value: false });
            this.$store.dispatch("redirect", { step: "club_plus_warning_ko", route: "PrivacyConsent" })
        },
        onSubmit() {
            this.$store.dispatch("redirect", { step: "club_plus_warning", route: "ClubPlusConsent" });
        }
    }
};
</script>