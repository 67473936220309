<template>
    <MetlifeBestCare />
</template>

<style scoped type="text/css">
@media screen and (max-width: 639px) {
    div >>> .form-header { font-size: 1em !important; }
}
</style>

<script>
import MetlifeBestCare from '@/views/forms/MetlifeBestCare';
export default {
    components: { MetlifeBestCare }
};
</script>