<template>
    <section>

        <div class="text-center py-6">
            <Steps :count="steps"></Steps>
            <h3 class="form-title font-semibold text-gray-900 mx-auto mt-3">Controlla i tuoi dati</h3>
        </div>

        <div class="bg-white py-6">
            <div class="px-6 max-w-4xl xl:max-w-6xl mx-auto flex flex-wrap justify-center">
                <div class="w-full flex justify-center">
                    <span class="block md:w-1/6"></span>
                    <label class="w-1/4 text-lg text-gray-900 font-medium pr-5">Cognome</label>
                    <span class="w-3/4 md:w-1/4 text-lg text-gray-700 font-semibold uppercase pl-5">{{ surname }}</span>
                </div>
                <div class="w-full flex justify-center">
                    <span class="block md:w-1/6"></span>
                    <label class="w-1/4 text-lg text-gray-900 font-medium pr-5 mt-5">Nome</label>
                    <span class="w-3/4 md:w-1/4 text-lg text-gray-700 font-semibold uppercase pl-5 mt-5">{{ name }}</span>
                </div>
                <div class="w-full flex justify-center">
                    <span class="block md:w-1/6"></span>
                    <label class="w-1/4 text-lg text-gray-900 font-medium pr-5 mt-5">IBAN</label>
                    <span class="w-3/4 md:w-1/4 text-lg text-gray-700 font-semibold uppercase pl-5 mt-5">{{ maskedIban }}</span>
                </div>
            </div>
        </div>

        <div class="bg-yellow text-center p-6">
            <p class="text-center font-semibold text-xl text-gray-900 leading-none">Verifica che la tua e-mail sia corretta oppure inseriscila.</p>
            <div class="sm:w-96 text-center mx-auto mt-4">
                <div class="relative">
                    <input type="email" name="email" class="w-full py-2 pl-4 pr-10 text-lg" v-model="email" v-mask="{ placeholder: '', greedy: false, casing: 'lower' }"
                           placeholder="Se non appare la tua email, inseriscila" :class="{ 'border border-red': $v.email.$error }">
                    <i class="icon pencil absolute top-3 right-2"></i>
                </div>
                <div class="w-full text-red text-left" v-if="$v.email.$error">
                    <small v-if="!$v.email.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                    <small v-else-if="!$v.email.email">
                        {{ $t("Formato e-mail non valido") }}
                    </small>
                </div>
            </div>
        </div>

        <div class="bg-white p-6">
            <div class="max-w-4xl xl:max-w-6xl mx-auto flex flex-wrap">
                <span><strong class="font-bold">Perché conosciamo i tuoi dati?</strong>
                    La risposta è <strong class="font-bold">semplice</strong> e <strong class="font-bold">trasparente</strong>:
                    hai già una delle soluzioni di credito con Cofidis</span>
            </div>
        </div>

        <div class="text-center pt-10 mb-6">
            <h1 class="form-title font-semibold text-gray-900 mx-auto">Completa i tuoi dati</h1>
        </div>

        <div class="form-wrapper py-6 px-4">
            <div class="w-full lg:w-1/3 lg:pr-4 mb-2" :class="{'lg:mt-6': !showOccupations }">
                <FloatingLabelSelect :name="`employment_type`" v-model="employment_type" :items="employment_types" :label="`Tipo impiego`" :disableZeroValue="true" :error="$v.employment_type.$error"/>
                <div class="h-6 w-full text-red" v-if="$v.employment_type.$error">
                    <small v-if="!$v.employment_type.nonZero">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                </div>
            </div>
            <div v-if="showEmploymentSector" class="w-full lg:w-1/3 lg:pr-4 mb-2" :class="{'lg:mt-6': !showOccupations }">
                <FloatingLabelSelect :name="`employment_sector`" v-model="employment_sector" :items="employmentSectors" :label="`Settore impiego`" :disableZeroValue="true" :error="$v.employment_sector.$error"/>
                <div class="h-6 w-full text-red" v-if="$v.employment_sector.$error">
                    <small v-if="!$v.employment_sector.nonZero">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                </div>
            </div>
            <div v-if="showOccupations" class="w-full lg:w-1/3 lg:pr-4 mb-2">
                <FloatingLabelSelect :name="`occupation`" v-model="occupation" :items="occupations" :label="`Impiego attuale`" :disableZeroValue="true" :error="$v.occupation.$error"/>
                <div class="h-6 w-full text-red" v-if="$v.occupation.$error">
                    <small v-if="!$v.occupation.nonZero">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                </div>
            </div>
            <div v-if="showHiringType" class="w-full lg:w-1/3 lg:pr-4 mb-2" :class="{'lg:mt-6': showEmployedSince}">
                <FloatingLabelSelect :name="`hiring_type`" v-model="hiring_type" :items="hiring_types" :label="`Assunzione a tempo`" :disableZeroValue="true" :error="$v.hiring_type.$error"/>
                <div class="h-6 w-full text-red" v-if="$v.hiring_type.$error">
                    <small v-if="!$v.hiring_type.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                </div>
            </div>
            <div v-if="showEmployedSince && (hiring_type || !showHiringType)" class="w-full lg:w-1/3 lg:pr-4 mb-2">
                <label class="text-xs">{{ $t("Occupazione dal") }}</label>
                <div class="date-select" :class="{ 'border-red': $v.employed_since.$error }" >
                    <DateSelect :value="employed_since" :showDay="false" :showMonth="false" :inputClass="{ 'border-red': $v.employed_since.$error }" :startYear="employedSinceStartYear" @change="updateEmployedSinceValue" ref="emplyoedSinceComponent" />
                </div>
                <div class="h-6 w-full text-red" v-if="$v.employed_since.$error">
                    <small v-if="!$v.employed_since.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                    <small v-else-if="!$v.employed_since.minValue">
                        {{ $t("Data non valida") }}
                    </small>
                    <small v-else-if="!$v.employed_since.maxValue">
                        {{ $t("Data non valida") }}
                    </small>
                </div>
            </div>
            <div v-if="showMonthlyIncome" class="w-full lg:w-1/3 lg:pr-4  mb-2" :class="{'lg:mt-6': showOccupations, 'lg:mt-0': !showOccupations }">
                <FloatingLabelInput :name="`monthly_income`" :type="`tel`" v-model="monthly_income" :mask="currencyMask" :label="`Reddito mensile netto`" :error="$v.monthly_income.$error"/>
                <div class="h-6 w-full text-red" v-if="$v.monthly_income.$error">
                    <small v-if="!$v.monthly_income.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                </div>
                <div class="text-xs">Inserisci il reddito mensile netto che risulta dalla tua ultima busta paga, ci sarà utile per valutare correttamente la tua richiesta.</div>
            </div>
        </div>

        <div class="flex justify-center pt-2 pb-12">
            <StickyButton :clickHandler="submit">CONTINUA</StickyButton>
        </div>


    </section>
</template>
<script>
import FloatingLabelInput from "@/components/FloatingLabelInput";
import FloatingLabelSelect from "@/components/FloatingLabelSelect";
import DateSelect from "@/components/DateSelect";
import Steps from '@/components/Steps'
import StickyButton from "@/components/StickyButton.vue";

import { getBirthday, mapUserData, mapJsonToObject, nonZero, nonZeroIf } from "@/utils";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import { currencyMask, shortDateMask } from "@/masks";
import { JOB_TYPES, OCCUPATIONS } from '@/constants.js';
import loadData from "@/dataService.js";
import moment from "moment/moment";

export default {
    components: { Steps, FloatingLabelInput, FloatingLabelSelect, DateSelect, StickyButton },
    mounted() {
        this.$ga.pageview()
        this.$store.dispatch("sendData", { step: "income", additionalData: {
            init: true,
            codeCampaign: localStorage.getItem('fastcredit_code_campaign'), 
            userId: localStorage.getItem('fastcredit_user_id') 
        }});
    },
    data() {
        return {
            employment_types: loadData("attivita.json"),
            all_employment_sectors: loadData("settore.json"),
            all_occupations: loadData("occupazione.json"),
            hiring_types: loadData("assunzione.json"),
            monthly_payments: loadData("mensilita.json"),
            currencyMask,
            shortDateMask
        };
    },
    computed: {
        ...mapUserData([
            "name",
            "surname",
            "iban",
            "email",
            "employment_type",
            "employment_sector",
            "occupation",
            "monthly_income",
            "hiring_type",
            "employed_since",
            "document_expired"
        ]),
        steps() {
            return this.document_expired ? 4 : 3
        },
        maskedIban() {
            return `${this.iban[0]}${this.iban[1]}****************${this.iban.slice(-4)}`
        },
        choosenCredit() {
            return this.$store.state.choosenCredit;
        },
        showEmploymentSector() {
            return !!(
                [JOB_TYPES.AUTONOMO,JOB_TYPES.DIPENDENTE,JOB_TYPES.STAGIONALE,JOB_TYPES.PROFESSIONISTA].includes(this.employment_type) &&
                this.all_employment_sectors[this.employment_type].length
            );
        },
        showMonthlyPayment() {
            return [JOB_TYPES.PENSIONATO,JOB_TYPES.PENSIONATO_INVALIDO].includes(this.employment_type) ||
                (   this.employment_type === JOB_TYPES.DIPENDENTE && this.occupation)
        },
        showEmployedSince() {
            return ([JOB_TYPES.AUTONOMO,JOB_TYPES.DIPENDENTE,JOB_TYPES.STAGIONALE].includes(this.employment_type) && this.occupation) ||
                (this.employment_type === JOB_TYPES.PROFESSIONISTA && this.employment_sector) ||
                (this.employment_type === JOB_TYPES.PENSIONATO)
        },
        showHiringType() {
            return this.employment_type === JOB_TYPES.DIPENDENTE && this.occupation
        },
        showOccupations() {
            return !!this.occupations.length
        },
        showMonthlyIncome() {
            return this.employed_since && (
                [JOB_TYPES.PENSIONATO,JOB_TYPES.PENSIONATO_INVALIDO,JOB_TYPES.REDDITIERE].includes(this.employment_type) ||
                (this.employment_type === JOB_TYPES.PROFESSIONISTA && this.employment_sector) ||
                this.occupation
            )
        },
        employmentSectors() {
            return mapJsonToObject(this.all_employment_sectors[this.employment_type])
        },
        occupations() {
            let occupations = this.all_occupations[this.employment_type][this.employment_sector];
            if (!Array.isArray(occupations)) return [];

            return mapJsonToObject(occupations)
        },
        employedSinceStartYear() {
            return parseInt(moment(getBirthday(), 'MM/YYYY').format('YYYY'))
        }
    },
    validations: {
        email: {
            required,
            email
        },
        employment_type: {
            nonZero
        },
        employment_sector: {
            nonZero: nonZeroIf("showEmploymentSector")
        },
        occupation: {
            nonZero: nonZeroIf("showOccupations")
        },
        monthly_income: {
            required: requiredIf("showMonthlyIncome")
        },
        hiring_type: {
            nonZero: nonZeroIf("showHiringType")
        },
        employed_since: {
            required: requiredIf("showEmployedSince"),
            minValue: (value, vm) => {
                return vm.showEmployedSince ? moment(value, 'YYYY') >= moment(getBirthday(), 'MM/YYYY') : true
            },
            maxValue: (value, vm) => {
                return vm.showEmployedSince ? moment(value, 'YYYY') <= moment() : true
            }
        },
    },
    watch: {
        hiring_type() {
            this.employed_to = null
        },
        showEmploymentSector(val) {
            if (val) {
                this.$v.employment_sector.$reset();
            }
        },
        showOccupations(val) {
            if (val) {
                this.$v.occupation.$reset();
            }
        },
        showMonthlyIncome(val) {
            if (val) {
                this.$v.monthly_income.$reset();
            }
        },
        showHiringType(val) {
            if (val) {
                this.$v.hiring_type.$reset();
            }
        },
        employment_type() {
            this.employment_sector = 0;
            this.occupation = 0;
            this.monthly_payment = 0;
            this.monthly_income = null;
            this.employed_since = null;
            this.employed_to = null;
            this.hiring_type = 0;
            if (this.$refs.emplyoedSinceComponent) this.$refs.emplyoedSinceComponent.reset();
            if (this.$refs.emplyoedToComponent) this.$refs.emplyoedToComponent.reset();
        },
        employment_sector() {
            this.occupation = 0;
        },
        showEmployedSince(val) {
            if (val) {
                this.$v.employed_since.$reset();
            }
        },
    },
    methods: {
        updateEmployedSinceValue(value) {
            this.employed_since = value
        },
        canRequest() {
            const employed_since = moment(`01/${this.employed_since}`, 'MM/YYYY');
            // Pensionato
            if([JOB_TYPES.PENSIONATO].includes(this.employment_type)) {
                return true
            }
            // Autonomo/Professionista > 24 months
            if([JOB_TYPES.AUTONOMO,JOB_TYPES.PROFESSIONISTA].includes(this.employment_type) && moment().diff(employed_since, 'months') >= 24) {
                return true
            }
            // Dipendente, Non apprendista, > 8 months
            if([JOB_TYPES.DIPENDENTE].includes(this.employment_type)) {                
                const occupation = this.all_occupations[this.employment_type][this.employment_sector][this.occupation];
                if (occupation !== OCCUPATIONS.APPRENDISTA && moment().diff(employed_since, 'months') >= 8) {
                    return true
                }
            }
            return false
        },
        submit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                const canRequest = this.canRequest()
                //this.$store.dispatch("event", { name: "ConsolidamentoEmail" });
                this.$store.commit("setUserData", { key: 'can_request', value: canRequest });
                this.$store.dispatch("sendData", { step: "dati_paga" });
                this.$store.dispatch("sendData", { step: "dati_lav" });

                this.$store.dispatch("redirect", {step: "dati_lav", route: "ClubFastCredit"});
            }
        }
    }
};
</script>