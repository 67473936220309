import { JOB_TYPES } from '@/constants.js';

class Hooks {
    precompiled(router, {state}) {
        if (!state.userData.document_expired && !state.userData.document_expire) {
            router.push({ name: "PrecompiledDocument" })
        } else {
            router.push({ name: "IncomePz" })
        }
    }
    dati_lav(router, {state, getters}) {
        if(state.PATH === 'FE') {
            if ([JOB_TYPES.AUTONOMO, JOB_TYPES.DIPENDENTE, JOB_TYPES.STAGIONALE, JOB_TYPES.PROFESSIONISTA].includes(state.userData.employment_type))
                router.push({ name: "EmployerDataPz" })
            else if(getters.insuranceAvailable)
                router.push({ name: "LoanProtectionPz" })        
            else
                router.push({ name: "PersonalDataPz" })
        } else {
            if(!state.userData.is_creditline) {
                router.push({ name: "ClubPlusPz" })
            }
            else {
                if (state.userData.document_expired)
                    router.push({ name: "DocumentsPz" })
                else
                    router.push({ name: "PrivacyPz" })
            }
        }
    }
    dati_lav_2(router, {getters}) {
        if(getters.insuranceAvailable)
            router.push({ name: "LoanProtectionPz" })        
        else
            router.push({ name: "PersonalDataPz" })
    }

    club_consent(router, {state, getters}) {
        if (state.userData.document_expired)
            router.push({ name: "DocumentsPz" })

        else if(getters.promoAvailable)
            router.push({ name: "PromoPzLoading" })

        else 
            router.push({ name: "PrivacyPz" })
    }

    club_warning_ko(router, {state, getters}) {
        if (state.userData.document_expired)
            router.push({ name: "DocumentsPz" })

        else if(getters.promoAvailable)
            router.push({ name: "PromoPzLoading" })

        else
            router.push({ name: "PrivacyPz" })
    }

    dati_pers_4(router, {getters}) {
        if(getters.promoAvailable)
            router.push({ name: "PromoPzLoading" })
        else
            router.push({ name: "PrivacyPz" })
    }

    promo_loading(router) {
        router.push({name: "PromoPz"})
    }
    promo(router) {
        router.push({ name: "PrivacyPz" })
    }
    promo_ko(router) {
        router.push({name: "PromoPzConfirm"})
    }
    promo_confirm(router) {
        router.push({ name: "PrivacyPz" })
    }
    promo_terms(router) {
        router.push({ name: "PromoPzTerms" })
    }

    dati_privacy(router, {state}) {
        const CLUBPLUS = state.userData.service && state.userData.service_pack == 'CLPLS'

        switch(state.PATH) {
            case 'FE':
                router.push({ name: 'ThankYou' })
            break;
            case 'ML':
                if (CLUBPLUS)
                    router.push({ name: "TypClub" })
                else
                    router.push({ name: 'RequestKO' })
            break;
            default:
                router.push({ name: "RequestKO" })
            break;
        }
    }
}
export default new Hooks();