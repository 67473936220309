class Hooks {
    dati_anag(router, {state}) {
        if(state.userData.document_expired)
            router.push({ name: "Documents" })
        else {
            router.push({ name: "Income" })
        }
    }

    dati_pers_4(router) {
        router.push({ name: "Income" })
    }
}
export default new Hooks();