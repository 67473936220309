import Cookies from "js-cookie";

class Append {

    dati_privacy_consolidamento(payload, {state}) {
        payload.EMail = state.userData.email
        payload.privacy1 = +state.userData.privacy1
        payload.privacy2 = +state.userData.privacy2
        payload.privacy3 = +state.userData.privacy4
        payload.privacy4 = +state.userData.privacy3
    }

    dati_consolidamento(payload, {state}) {
        let index = 1
        state.userData.loans_data.map(data => {
            payload[`Importo${index}`] = parseFloat(data.amount)
            payload[`Rata${index}`] = parseFloat(data.installments_amount.replace(',','.'))
            payload[`Prima_Rata${index}`] = data.first_installment
            payload[`Numero_Rate${index}`] = parseInt(data.installments_count)
            payload[`Assicurazione${index}`] = data.provider
            index++
        })
        return payload
    }

    dati_anag(payload, {state}) {
        const expireDays = 90
        // get querystring param
        const gclid = state.urlVars['gclid'] || Cookies.get("gclid")
        // add gclid to payload
        if(gclid) payload.gclid = gclid                    
        // store gclid in cookies if set in querystring
        if(state.urlVars['gclid']) Cookies.set("gclid", gclid, { expires: expireDays });
        // remove Rate for powercredit
        if(state.userData.importo <= 2000) delete payload['Rate']
    }    

    dati_pers_3(payload, {state}) { 
        payload.Abitazione = parseInt(state.userData.dwelling_type) - 1
        payload.Affitto = state.userData.monthly_rent
    }

    dati_lav_2(payload, {state}) { 
        switch(state.PATH) {
            case 'FE': 
                payload.Famigliari = parseInt(state.userData.family_member_count) - 1
                payload.Famigliari_Reddito = parseInt(state.userData.employed_family_member_count) - 1
            break;
            case 'CQ':
                payload.Cessione_Quinto = state.userData.cessione_quinto
            break;
        }
    }

    assicurazione_metlife(payload) {
        payload.assicurazione_pack = 'Metlife'
    }

    assicurazione_metlife_consent(payload) {
        payload.assicurazione_pack = 'Metlife'
    }

    assicurazione_metlife_warning(payload) {
        payload.assicurazione_pack = 'Metlife'
    }
}
export default new Append();