import moment from "moment";
import { RATE } from "@formulajs/formulajs";

class Calculator {
    capLoan() {
        return 30000
    }
    totalDebt(loans_data) {
        let totalDebt = 0;

        loans_data.map(data => {
            const loanStartDate = moment(data.first_installment, 'MM/YYYY')

            let diffInMonth = 1
            // increment diff until loan start date exceed current date
            while(loanStartDate.add(1, 'month') < moment()) diffInMonth += 1

            const installmentsAmount = parseFloat(data.installments_amount.replace(',','.'))
            const installmentsCount = parseInt(data.installments_count);
            totalDebt += (installmentsAmount * installmentsCount - installmentsAmount * diffInMonth)
        })

        return Number(totalDebt);
    }
    averageApr(loans_data) {
        let sumAPR = 0;
        let totalAmount = 0;
        loans_data.map(data => {
            const amount = parseFloat(data.amount.replace(',','.'))
            const installmentsAmount = parseFloat(data.installments_amount.replace(',','.'))
            const installmentsCount = parseInt(data.installments_count);
            sumAPR += RATE(installmentsCount, -installmentsAmount, amount) * 12 * amount
            totalAmount += amount
        })

        return Number((sumAPR / totalAmount) * 100).toFixed(2);
    }
    totalInstallment(loans_data) {
        let totalInstallment = 0;
        loans_data.map(data => {
            const installmentsAmount = parseFloat(data.installments_amount.replace(',','.'))
            totalInstallment += installmentsAmount
        })

        return Number(totalInstallment);
    }
}

export default new Calculator();