<template>
    <form class="rata" @submit.prevent="$emit('submit')">
        <div class="container text-center py-6 relative mx-auto">
        </div>

        <div class="form-wrapper">
            <FormHeader class="mt-3 mb-8 md:mt-5 md:mb-10">
                Hai scelto l'offerta promozionale
            </FormHeader>
            <div class="w-full bg-yellow py-2 text-center text-base md:text-lg font-semibold">Riepilogo dell'offerta</div>

            <div class="px-3 sm:px-16 sm:px-4 mx-auto lg:container py-8 md:py-10 lg:pt-12">
                <div class="proposal text-base sm:text-lg text-xl flex flex-wrap bg-gray-1 border border-gray-6 w-full rounded-3xl max-w-xl mx-auto">
                    <div class="w-full flex items-center px-2 py-2 rounded-t-3xl bg-white"  >
                        <span class="ml-2 mr-auto p-2 whitespace-no-wrap font-semibold text-lg md:text-xl">{{ model.imp_finanz_text }}</span>
                        <a class="text-base underline mr-4" target="_blank" :href="model.secciUrl">SECCI</a>
                    </div>
                    <div class="w-full flex mt-0 items-center justify-center px-2 py-4">
                        <p class="text-lg md:text-xl">
                            <span class="mr-1 sm:hidden">{{ $t("Rata") }}</span>
                            <span class="font-semibold">{{ model.imp_rata }} €</span> {{ $t("per") }}
                            <span class="font-semibold">{{ model.rate }}</span> {{ $t("mesi") }}
                            <span class="font-semibold">{{ $t("TAEG") }} {{ model.taeg }}</span>%
                        </p>
                    </div>
                    <div class="w-full  text-center  -mb-5 ">
                        <button class="sticky-cta cta rounded-full px-12 py-3 text-base font-semibold sm:mt-0.5 sm:mr-0.5 shadow-xl  bg-accent" @click="$emit('submit')">
                            {{ $t("CONTINUA") }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="px-5 sm:px-16 pt-6 mb-16 lg:pt-8 lg:mb-16 lg:container">
                <div class="max-w-4xl xl:max-w-6xl lg:w-1/2 mx-auto text-center font-sm">
                    <p class="text-11px sm:text-11.5px">
                    Cliccando su Continua prendo atto che le condizioni economiche dell’offerta promozionale possono variare in base al
                    <router-link to="promo-terms" class="underline">profilo</router-link> del richiedente.
                    Se il profilo non soddisfa i requisiti, il TAEG proposto sarà del {{ promoKO.taeg }}%.
                    <a :href="promoKO.secciUrl" target="_blank" class="underline">SECCI</a>
                    </p> 
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import FormHeader from '@/components/FormHeader.vue';
import { mapGetters } from 'vuex';

export default {
    components: { FormHeader },
    computed: {
        ...mapGetters(["promoKO"]),
        model() {
            return this.getPromoOffer(false)
        }
    },
    mounted() {
        this.$ga.pageview()
    }
};
</script>