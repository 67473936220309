<template>
    <FormCard @submit="onSubmit" title="Dati di recapito">
        <div class="w-full sm:w-3/6 sm:pr-4 mb-2">
            <FloatingLabelInput :name="`address`" v-model="address" :mask="{
                  mask: 'A{+}',
                  placeholder: '',
                  definitions: { A: { validator: '[A-Z0-9ÀÈÌÒÙÁÉÍÓÚ .\'°^/-]' } },
                  casing: 'upper'
                }" :label="`Indirizzo di residenza`" :error="$v.address.$error" />
            <div class="h-6 w-full text-red" v-if="$v.address.$error">
                <small v-if="!$v.address.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-1/3 sm:w-1/6 sm:w-1/4 mb-2">
            <FloatingLabelInput :name="`address_number`" v-model="address_number" :mask="{
              placeholder: '',
              mask: 'A{*}',
              definitions: { A: { validator: '[A-Z0-9ÀÈÌÒÙÁÉÍÓÚ°^/-]' } },
              casing: 'upper'
            }" :label="`Civico`" :error="$v.address_number.$error" :className="`placeholder-sm`" />
            <div class="h-6 w-full text-red" v-if="$v.address_number.$error">
                <small v-if="!$v.address_number.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-2/3 sm:w-2/6 pl-4 mb-2">
            <div class="label-floating" :class="{ 'border-red': $v.address_city.$error,'border-gray-700': focusInput == 'address_city' }" >                
                <Autocomplete :class="{ 'border-red': $v.address_city.$error,'border-gray-700': focusInput == 'address_city' }" :placeholder="$t('Comune residenza')" v-model="address_city" :search="citySearch" @focus="setFocusInput('address_city')" @blur="unsetFocusInput()">
                </Autocomplete>
                <label>{{ $t("Comune residenza") }}</label>
            </div>
            <div class="h-6 w-full text-red" v-if="$v.address_city.$error">
                <small v-if="!$v.address_city.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-1/2 sm:pr-4 mb-2 sm:mt-6">
            <FloatingLabelSelect v-if="address_multicap.length" :name="`address_cap`" v-model="address_cap" :items="address_multicap" :label="`CAP`" :error="$v.address_cap.$error" :disableZeroValue="true"/>
            <FloatingLabelInput v-else :name="`address_cap`" v-model="address_cap" :error="$v.address_cap.$error" :type="`tel`" :className="`placeholder-sm`" :disabled="true" :label="`CAP`"/>
            <div class="h-6 w-full text-red" v-if="$v.address_cap.$error">
                <small v-if="!$v.address_cap.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div> 
        <div class="w-full sm:w-1/2 mb-2">
            <label class="text-xs">{{ $t("Residente dal") }}</label>
            <DateSelect :value="resident_since" :showDay="false" :inputClass="{ 'border-red': $v.resident_since.$error }" :startYear="residentSinceStartYear" @change="updateResidentSinceValue" />
            <div class="h-6 w-full text-red" v-if="$v.resident_since.$error">
                <small v-if="!$v.resident_since.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
                <small v-else-if="!$v.resident_since.minValue">
                    {{ $t("Data non valida") }}
                </small>
                <small v-else-if="!$v.resident_since.maxValue">
                    {{ $t("Data non valida") }}
                </small>
            </div>
        </div>        
        <div class="w-full sm:w-1/2 sm:pr-4 mb-2" v-if="showDwellingType">
            <FloatingLabelSelect :name="`dwelling_type`" v-model="dwelling_type" :items="dwelling_types" :label="`Tipologia di abitazione`" :error="$v.dwelling_type.$error" :disableZeroValue="true"/>
            <div class="text-red" v-if="$v.dwelling_type.$error">
                <small v-if="!$v.dwelling_type.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-full sm:w-1/2 mb-2" v-if="showMonthlyRent">
            <FloatingLabelInput :name="`monthly_rent`" v-model="monthly_rent" :mask="currencyMask" :label="`Affitto mensile`" :error="$v.monthly_rent.$error" :type="`tel`" />
            <div class="text-red" v-if="$v.monthly_rent.$error">
                <small v-if="!$v.monthly_rent.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
                <small v-else-if="!$v.monthly_rent.nonZero">
                    {{ $t("Il valore 0 non è ammesso") }}
                </small>
            </div>
        </div>
        <div class="w-full mb-4 mb-2">
            <label class="text-xs">{{ $t("Il domicilio coincide con la residenza") }}</label>
            <ConsentItem v-model="same_addresses" :v="$v.same_addresses" :mustBeTrueError="''" />
            <div class="cont_fail fail_Domicilio"></div>
        </div>
        <div slot="after">
            <SlideUpDown :active="!same_addresses">
                <div class="mt-2 px-4 sm:px-6 md:px-8 pb-12 rounded-b-lg flex flex-wrap">
                    <div class="w-full sm:w-3/6 sm:pr-4 mb-2"> 
                        <FloatingLabelInput :name="`address2`" v-model="address2" :mask="{
                          mask: 'A{+}',
                          placeholder: '',
                          definitions: {
                            A: { validator: '[A-Z0-9ÀÈÌÒÙÁÉÍÓÚ .\'°^/-]' }
                          },
                          casing: 'upper'
                        }" :label="`Indirizzo di domicilio`" :error="$v.address2.$error" />
                        <div class="h-6 w-full text-red" v-if="$v.address2.$error">
                            <small v-if="!$v.address2.required">
                                {{ $t("Campo obbligatorio") }}
                            </small>
                        </div>
                    </div>
                    <div class="w-1/3 sm:w-1/6 sm:w-1/4 mb-2">
                        <FloatingLabelInput :name="`address_number2`" v-model="address_number2" :mask="{
                            placeholder: '',
                            mask: 'A{*}',
                            definitions: { A: { validator: '[A-Z0-9ÀÈÌÒÙÁÉÍÓÚ°^/-]' } },
                            casing: 'upper'
                          }" :label="`Civico`" :error="$v.address_number2.$error" />
                        <div class="h-6 w-full text-red" v-if="$v.address_number2.$error">
                            <small v-if="!$v.address_number2.required">
                                {{ $t("Campo obbligatorio") }}
                            </small>
                        </div>
                    </div>
                    <div class="w-2/3 sm:w-2/6 pl-4 mb-2">
                        <div class="label-floating" :class="{ 'border-red': $v.address_city2.$error,'border-gray-700': focusInput == 'address_city2' }" >                
                            <Autocomplete :class="{ 'border-red': $v.address_city2.$error,'border-gray-700': focusInput == 'address_city2' }" :placeholder="$t('Comune domicilio')" v-model="address_city2" :search="citySearch" @focus="setFocusInput('address_city2')" @blur="unsetFocusInput()">
                            </Autocomplete>
                            <label>{{ $t("Comune domicilio") }}</label>
                        </div>
                        <div class="h-6 w-full text-red" v-if="$v.address_city2.$error">
                            <small v-if="!$v.address_city2.required">
                                {{ $t("Campo obbligatorio") }}
                            </small>
                        </div>
                    </div>
                    <div class="w-1/2 sm:pr-4 mb-2">
                        <FloatingLabelSelect v-if="address_multicap2.length" :name="`address_cap2`" v-model="address_cap2" :items="address_multicap2" :label="`CAP`" :error="$v.address_cap2.$error" :disableZeroValue="true"/>
                        <FloatingLabelInput v-else :name="`address_cap2`" v-model="address_cap2" :label="`CAP`" :error="$v.address_cap2.$error" :type="`tel`" :disabled="true"/>
                        <div class="h-6 w-full text-red" v-if="$v.address_cap2.$error">
                            <small v-if="!$v.address_cap2.required">
                                {{ $t("Campo obbligatorio") }}
                            </small>
                        </div>
                    </div>
                </div>
            </SlideUpDown>
        </div>
    </FormCard>
</template>
<script>
import DateSelect from "@/components/DateSelect";
import FloatingLabelSelect from "@/components/FloatingLabelSelect";
import FloatingLabelInput from "@/components/FloatingLabelInput";
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapUserData, getBirthday, nonZeroIf } from "@/utils";
import { currencyMask } from "@/masks";

import $ from "jquery";
import Fuse from "fuse.js";
import moment from "moment";
import ConsentItem from "@/components/ConsentItem.vue";

export default {
    components: { FloatingLabelSelect, FloatingLabelInput, DateSelect, ConsentItem },
    async mounted() {
        this.$ga.pageview()

        const city_feevo = await $.getJSON("https://cofidis-settings.s3.eu-central-1.amazonaws.com/city_feevo_2022.json")
        this.fuser = new Fuse(city_feevo, {
            keys: ["Citta"],
            threshold: 0.0,
            location: 0,
            distance: 0,
            maxPatternLength: 32,
            minMatchCharLength: 0,
            caseSensitive: false
        })  
    },
    data() {
        return {
            search: [],
            fuser: null,
            currencyMask
        };
    },
    computed: {
        ...mapUserData([
            "address",
            "same_addresses",
            "address_number",
            "address_cap",
            "address_multicap",
            "address_city",
            "resident_since",
            "address2",
            "address_number2",
            "address_cap2",
            "address_multicap2",
            "address_city2",
            "dwelling_type",
            "monthly_rent"
        ]),
        residentSinceStartYear() {
            return parseInt(moment(getBirthday(), 'MM/YYYY').format('YYYY'))
        },
        showDwellingType() {
            return this.$store.state.version == 2
        },
        showMonthlyRent() {
            return this.dwelling_type == 1
        },
        dwelling_types() {            
            return [
                {key: null, value: "Tipologia di abitazione"},
                {key: 1, value: "IN AFFITTO"},
                {key: 2, value: "CON GENITORI, PARENTI"},
                {key: 5, value: "DI PROPRIETÀ"},
            ]
        }
    },
    validations: {
        address: {
            required
        },
        address_number: {
            required
        },
        address_cap: {
            required
        },
        resident_since: {
            required,
            minValue: value => {
                return moment(value, 'MM/YYYY') >= moment(getBirthday(), 'MM/YYYY')
            },
            maxValue: value => {
                return moment(value, 'MM/YYYY') <= moment()
            }
        },
        address_city: {
            required
        },
        address2: {
            required: requiredIf(function() {
                return !this.same_addresses;
            })
        },
        address_number2: {
            required: requiredIf(function() {
                return !this.same_addresses;
            })
        },
        address_cap2: {
            required: requiredIf(function() {
                return !this.same_addresses;
            })
        },
        address_city2: {
            required: requiredIf(function() {
                return !this.same_addresses;
            })
        },
        same_addresses: {
            required
        },
        dwelling_type: {
            required: requiredIf("showDwellingType")
        },
        monthly_rent: {
            required: requiredIf("showMonthlyRent"),
            nonZero: nonZeroIf("showMonthlyRent")
        }
    },
    watch: {
        address_city(val) {
            const city = val.slice(0, -5)   
            this.address_cap = null
            this.address_multicap = []
            this.search.map(res => {
                if(res.item.Citta == city) {                    
                    if(res.item.Multicap) {
                        this.address_multicap.push({key: null, value: 'CAP'})
                        res.item.Multicap.split(',').map(cap => {
                            const value = '0'.repeat(5 - cap.toString().length) + cap.toString()
                            this.address_multicap.push({key: cap, value })
                        })
                    }
                    else {
                        this.address_cap = res.item.Cap
                    }
                }
            })
        },
        address_city2(val) {
            const city = val.slice(0, -5)   
            this.address_cap2 = null
            this.address_multicap2 = []                   
            this.search.map(res => {
                if(res.item.Citta == city) {                    
                    if(res.item.Multicap) {
                        this.address_multicap2.push({key: null, value: 'CAP'})
                        res.item.Multicap.split(',').map(cap => {
                            const value = '0'.repeat(5 - cap.toString().length) + cap.toString()
                            this.address_multicap2.push({key: cap, value })
                        })
                    }
                    else {
                        this.address_cap2 = res.item.Cap
                    }
                }
            })
        },
        same_addresses(val) {
            if (val === false) {
                this.$v.address2.$reset();
                this.$v.address_number2.$reset();
                this.$v.address_cap2.$reset();
                this.$v.address_city2.$reset();
            }
        },
        dwelling_type() {
            this.monthly_rent = null
            this.$v.$reset()
        }
    },
    methods: {
        updateResidentSinceValue(value) {
            this.resident_since = value
        },
        onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                this.$store.dispatch("event", { name: "DeliveryDataSubmit" });
                this.$store.dispatch("sendData", {step: "dati_pers_2" });
                this.$store.dispatch("sendData", { step: "dati_pers_3" });
                this.$store.dispatch("redirect", { step: "dati_pers_3", route: "IdentityCardData" });
            }
        },
        citySearch(input) {
            if (input.length < 2) return [];
            this.search = this.fuser.search(input)
            return this.search.map(i => `${i.item.Citta} (${i.item.Prov})`);
        }
    }
};
</script>