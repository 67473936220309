<template>
    <div>
        <div class="text-center py-6">
            <div class="container relative mx-auto">
                <i class="icon arrowleft absolute left-4 sm:left-0 top-1/2 transform -translate-y-1/2 cursor-pointer" @click="goBack()"></i>
                <Steps :count="steps"></Steps>
            </div>
            <h3 class="form-title font-semibold text-gray-900 mx-auto mt-3">Controlla i tuoi dati</h3>
        </div>

        <div class="form-wrapper">
            <Precompiled />
        </div>
    </div>
</template>
<script>
import Precompiled from "@/views/forms/MetlifePrecompiled";
import Steps from '@/components/Steps'
import { mapUserData } from "@/utils";

export default {
    components: { Steps, Precompiled },
    computed: {
        ...mapUserData([
            "document_expired"
        ]),
        steps() {
            return this.document_expired ? 4 : 3
        }
    }
};
</script>