<template>
    <section class="section-faq text-center">
    <div class="sm:container px-6 py-6 mx-auto">
        <h3 class="font-semibold text-3xl text-gray-5 leading-none px-6 mb-6 text-center">
            {{ title }}
        </h3>
        <div v-for="(tab, index) in tabs" :key="index" class="faq-container w-full xl:w-2/3 flex flex-wrap hover:text-orange cursor-pointer bg-white rounded-xl pt-1 px-6 mx-auto mb-3" :class="index === active ? 'pb-4' : 'pb-2' ">
            <div class="w-full">
                <div class="flex flex-wrap">
                    <div class="faq-question outline-none w-4/5 sm:flex-grow text-left py-2" :class="{ active: index === active }" @click="toggleTab(index)">
                        <span class="font-semibold text-lg text-gray-5 leading-none">
                            {{ tab.title }}
                        </span>
                    </div>
                    <div class="flex-grow text-right rounded-full leading-10 outline-none flex items-center justify-end"  @click="toggleTab(index)">
                        <svg v-if="index !== active" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                        <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                        </svg>
                    </div>
                </div>
                <SlideUpDown class="faq-content font-medium text-gray-5 cursor-default outline-none text-left leading-0" :active="index === active">
                    <span class="font-light text-base text-gray-5 leading-none" v-html="tab.content">
                    </span>
                </SlideUpDown>
            </div>
        </div>
    </div>
</section>
</template>

<script>

export default {
    name: 'Accordion',
    props: {
        title: {
            type: String,
            default: 'Come funziona?'
        },
        tabs: {
            type: Array
        }
    },
    data() {
        return {
            active: null
        }
    },
    methods: {
        toggleTab(i) {
            if (this.active == i) this.active = null;
            else this.active = i;
        }
    }
}
</script>