<template>
    <div ref="widget" class="trustpilot-widget" data-locale="it-IT" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="5d0a61ad3bcbc70001701a39" data-style-height="240px" data-style-width="100%" data-theme="Organization" data-stars="4,5" data-review-languages="it">
        <a href="https://it.trustpilot.com/review/www.prestitocofidis.com" target="_blank" rel="noopener">Trustpilot</a>
    </div>
</template>

<script>
import Swiper from "swiper";

export default {
    name: "TruspilotSwiper",
    methods: {
        loadTrustPilot() {
            // load trustpilot script
            const script = document.createElement("script");
            script.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
            document.head.appendChild(script);
            //reload widget
            const widget = this.$refs.widget;
            if (window.Trustpilot) window.Trustpilot.loadFromElement(widget);

            new Swiper("#trustpilot-sw.swiper-container", {
                slidesPerView: 1,
                spaceBetween: 30,
                pagination: {
                    el: "#trustpilot-sw .swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: "#trustpilot-sw .swiper-button-next",
                    prevEl: "#trustpilot-sw .swiper-button-prev",
                },
            });
        }
    },
    mounted() {
        this.loadTrustPilot();
    }
}
</script>