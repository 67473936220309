<template>
    <MetlifeBestCareWarning />
</template>

<style scoped type="text/css">
@media screen and (max-width: 639px) {
    div >>> .form-header { font-size: 1rem !important; }
}
</style>

<script>
import MetlifeBestCareWarning from "@/views/forms/MetlifeBestCareWarning";

export default {
    components: { MetlifeBestCareWarning }
};
</script>