<template>
    <Promo ctaText="INVIA RICHIESTA" >
        <template #subtitle>
            <p class="text-base text-center sm:text-left">Ultimo step: richiedi più liquidità o conferma la scelta precedente</p>
        </template>
    </Promo>
</template>
<script>
import Promo from "@/views/forms/Promo.vue"

export default {
    components: { Promo }
};
</script>
