<template>
    <section class="metlife pb-8 md:pb-5 pt-5">
        <Steps :count="step_number" :active="step_active" />
        <div class="w-11/12 sm:max-w-7xl mx-auto shadow-lg rounded-3xl bg-white mt-5 mb-10 pb-2">
            <MetlifeConsent />
        </div>
    </section>
</template>

<script>
import MetlifeConsent from "@/views/forms/MetlifeBestCareConsent";
import Steps from '@/components/Steps'
import { mapUserData } from "@/utils";

export default {
    components: { Steps, MetlifeConsent },
    data() {
        return {
            step_number: 4,
            step_active: 3
        };
    },
    computed: {
        ...mapUserData([
            "service",
        ]),
    },
    mounted() {
        this.$store.dispatch("sendData", { step: "dati_metlife_2" });
    },
};
</script>