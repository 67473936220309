<template>
    <footer class="bg-white">
        <div class="w-full p-6 mx-auto sm:max-w-7xl relative">
            <ScrollTopButton />
            <div class="footer-content mt-4">
                <div class="w-full">
                    <div class="flex flex-wrap cursor-pointer">
                        <div class="faq-question outline-none w-4/5 sm:flex-grow text-left" :class="{ active: activeFooter }" @click="activeFooter = !activeFooter">
                            <span class="text-gray-5 leading-none">Messaggio pubblicitario con finalità promozionale.</span>
                        </div>
                        <div class="flex-grow outline-none flex items-center justify-center sm:justify-end sm:mr-6"  @click="activeFooter = !activeFooter">
                            <svg v-if="!activeFooter" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                            </svg>
                        </div>
                    </div>
                    <SlideUpDown class="faq-content font-medium text-gray-5 cursor-default outline-none text-left leading-0 my-2" :active="activeFooter">
                        <p class="">
                            Per tutte le condizioni economiche e contrattuali, fare riferimento alle Informazioni Europee di
                            Base sul Credito ai Consumatori (cd. SECCI) sul sito <a class="underline" href="https://www.cofidis.it/trasparenza" target="_blank">www.cofidis.it/trasparenza</a>.
                        </p>
                        <p class=" mt-4">
                            Esempio rappresentativo di un prestito personale di importo pari a {{ promoModel.imp_finanz_text }} (Importo totale del credito) da
                            rimborsare in {{ promoModel.rate }} mesi, con rate mensili di € {{ promoModel.tot_rata }}, TAN fisso {{ promoModel.tan }}% - TAEG {{ promoModel.taeg }}%. L’offerta del
                            prestito personale è valida fino al {{ expFullDate }} ed è disponibile solo per i già clienti Cofidis in
                            possesso di <router-link @click="$emit('close')" to="promo-terms" class="underline">determinati requisiti</router-link>.
                            Spese accessorie: spesa di istruttoria € 0,00, spesa di incasso
                            rata € 0,00, spesa per Rendiconto periodico € 0,00, imposta sostitutiva sul contratto € {{ promoModel.imposta }}, spese
                            invio Rendiconto € 0,00 oltre imposta di bollo pari a € 0,00 per importi superiori a € 77,47 per
                            ogni invio. Totale da rimborsare € {{ promoModel.dovuto }} (Importo totale dovuto).<br>
                            Le condizioni economiche del prestito personale potranno variare se il richiedente non possiede i
                            <router-link @click="$emit('close')" to="promo-terms" class="underline">requisiti richiesti</router-link>.
                            Se il profilo del cliente non soddisfa i requisiti l'offerta verrà modificata e potrà avere un TAEG
                            massimo al 13,37%. In tali casi verranno applicate le seguenti condizioni: importo pari a {{ promoKO.imp_finanz_text }}
                            (Importo totale del credito) da rimborsare in {{ promoKO.rate }} mesi, con rate mensili di € {{ promoKO.imp_rata }}, TAN fisso
                            {{ promoKO.tan }}% - TAEG {{ promoKO.taeg }}%. Spese accessorie: spesa di istruttoria € 0,00, spesa di incasso rata € 0,00,
                            spesa per Rendiconto periodico € 0,00, imposta sostitutiva sul contratto € {{ promoKO.imposta }}, spese invio
                            Rendiconto € 0,00 oltre imposta di bollo pari a € 0,00 per importi superiori a € 77,47 per ogni
                            invio.
                            Totale da rimborsare {{ promoKO.dovuto }} (Importo totale dovuto).
                            La richiesta di finanziamento è soggetta alla valutazione da parte di Cofidis S.A..
                            Offerta valida sino al <span class="fine_campagna">{{ expFullDate }}</span>.
                        </p>
                    </SlideUpDown>
                </div>
                <div class="text-center sm:text-left mt-4 mb-20 text-gray-4">
                    <a href="https://www.cofidis.it/it/privacy-policy.html" class="underline" target="_blank">Privacy Policy</a> |
                    <a href="https://cofidis-docs-pp.s3.eu-central-1.amazonaws.com/agid.pdf" class="underline" target="_blank">Accessibilità: non conforme</a>
                    <a :href="secciUrl" class="text-yellow-4 underline mt-4 block text-base" target="_blank">SECCI</a>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import {mapGetters} from "vuex";
import {mapUserData} from "@/utils.js";
import ScrollTopButton from "@/components/scrollTopButton.vue";
import moment from "moment";

export default {
    components: { ScrollTopButton },
    data() {
        return {
            activeFooter: false
        };
    },
    computed: {
        ...mapGetters(["promoKO"]),
        ...mapUserData(["exp_date"]),
        secciUrl() {
            if (this.model && this.model.imp_finanz === this.promo) {
                return this.promoModel.secciUrl
            }
            else if(this.model) {
                const baseUrl = this.$store.state.secciUrl
                return `${baseUrl}${this.model.imp_finanz}_${this.model.rate}.pdf`
            }
            else
                return ``
        },
        expFullDate() {
            return moment(this.exp_date).locale('it').format('DD/MM/Y');
        },
        model() {
            const credit = this.$store.state.choosenCredit
            if (credit) 
                return this.$store.getters.rateOffers({importo: credit})[0];
            else 
                return {}
        },
        promoModel() {
            return this.$store.getters.promoOffer({upgrade: false, services: false})
        },
    },
};
</script>
