<template>
    <form class="form_dati_anag" action="next" method="POST">
        <div class="bg-yellow text-center lg:text-left mt-4 flex flex-wrap lg:flex-no-wrap items-center py-2 px-4 sm:px-12">
            <h1 class="text-center lg:text-left text-xl sm:text-3xl font-semibold text-gray-700 leading-tight px-6">
                <span v-if="importo">Hai scelto un importo di {{ importo|num }} €</span>
            </h1>
            <div class="hidden sm:flex w-full sm:w-auto justify-center sm:ml-auto mt-2 sm:mt-0">
                <img class="block h-8 mr-3" src="@/assets/images/contatti-esito.svg" />
                <p>
                    <strong class="text-orange block font-semibold text-sm">{{ $t("Primo esito immediato") }}</strong>
                    <span class="text-orange font-light text-sm block -mt-1">{{ $t("in pochi minuti") }}</span>
                </p>
            </div>
        </div>

        <div class="flex flex-wrap text-gray-700 p-4 pb-4 px-4 sm:px-12 rounded-b-lg">
            <p class="text-center text-lg sm:text-left mt-2 mb-4 w-full">{{ $t("Ora completa la richiesta con i tuoi dati") }}</p>
            <div class="w-full lg:w-1/2 lg:pr-2 mb-6">
                <EmailAutocomplete v-model="email" placeholder="E-mail (dove invieremo i documenti)" :error="$v.email.$error" label="E-mail (dove invieremo i documenti)" />
                <div class="w-full text-red" v-if="$v.email.$error">
                    <small v-if="!$v.email.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                    <small v-else-if="!$v.email.email">
                        {{ $t("Formato e-mail non valido") }}
                    </small>
                </div>
            </div>

            <div class="flex flex-wrap sm:flex-nowrap">
                <div class="relative sm:flex-shrink-0 mr-4 order-2 sm:order-1">
                    <input type="radio" v-model="privacy1" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
                </div>
                <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                    <input type="radio" v-model="privacy1" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
                </div>
                <div class="w-full sm:flex-grow order-1 sm:order-3 mb-2" v-html="$t('Lette e comprese l\’<a target=\'_blank\' href=\'https://www.cofidis.it/it/download/Informativa-Privacy.pdf\' class=\'underline\'>Informativa Privacy</a>, resa ai sensi degli artt. 13 e 14 del Regolamento UE 679/2016, e l\’<a target=\'_blank\' href=\'https://www.cofidis.it/it/download/Informativa-Privacy.pdf\' class=\'underline\'>Informativa per tutti gli Operatori di SIC</a> - Sistema di Informazioni Creditizie -, resa ai sensi dell’art. 6 del Codice di Condotta per i Sistemi Informativi gestiti da soggetti privati in tema di crediti al consumo, affidabilità e puntualità nei pagamenti, consapevole che, in mancanza di tale conferma, Cofidis non potrà dar corso alle operazioni o servizi richiesti. Puoi gestire i seguenti consensi facoltativi:')">
                </div>
            </div>
            <div class="w-full mb-4 text-red">
                <div v-if="$v.privacy1.$error && !$v.privacy1.required">
                    {{ $t("Campo obbligatorio") }}
                </div>
                <div v-if="privacy1 === false">
                    {{ $t("Non è possibile proseguire senza questo consenso.") }}
                </div>
            </div>
            <div class="flex flex-wrap sm:flex-nowrap">
                <div class="relative sm:flex-shrink-0 mr-4 order-2 sm:order-1">
                    <input type="radio" v-model="privacy2" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
                </div>
                <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                    <input type="radio" v-model="privacy2" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
                </div>
                <div class="w-full sm:flex-grow order-1 sm:order-3 mb-2" v-html="$t('Acconsento al trattamento dei miei dati personali per finalità di marketing per prodotti e servizi di Cofidis, di società appartenenti al Gruppo di Cofidis ed al Gruppo Crédit Mutuel, o di società terze')">
                </div>
            </div>
            <div class="w-full mb-4 text-red">
                <div v-if="$v.privacy2.$error && !$v.privacy2.required">
                    {{ $t("Campo obbligatorio") }}
                </div>
                <div v-if="privacy2 === false">
                    {{ $t("Attenzione! Se scegli “no” non potrai partecipare alle nostre attività promozionali, a concorsi, né ricevere offerte di altri prodotti Cofidis.") }}
                </div>
            </div>
            <div class="flex flex-wrap sm:flex-nowrap">
                <div class="relative sm:flex-shrink-0 mr-4 order-2 sm:order-1">
                    <input type="radio" v-model="privacy3" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
                </div>
                <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                    <input type="radio" v-model="privacy3" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
                </div>
                <div class="w-full sm:flex-grow order-1 sm:order-3 mb-2" v-html="$t('Acconsento al trattamento dei miei dati personali per finalità di profilazione delle mie preferenze, caratteristiche, abitudini o scelte di consumo al fine di ricevere offerte, promozioni ed informazioni che tengano conto dei miei interessi')">
                </div>
            </div>
            <div class="w-full mb-4 text-red">
                <div v-if="$v.privacy3.$error && !$v.privacy3.required">
                    {{ $t("Campo obbligatorio") }}
                </div>
                <div v-if="privacy3 === false">
                    {{ $t("Attenzione! Se scegli “no” non potremo proporti offerte e servizi personalizzati che tengano conto delle tue esigenze ed interessi specifici.") }}
                </div>
            </div>
            <div class="flex flex-wrap sm:flex-nowrap">
                <div class="relative sm:flex-shrink-0 mr-4 order-2 sm:order-1">
                    <input type="radio" v-model="privacy4" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
                </div>
                <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                    <input type="radio" v-model="privacy4" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
                </div>
                <div class="w-full sm:flex-grow order-1 sm:order-3 mb-2">
                    Acconsento al trattamento dei miei dati per comunicazione a <router-link :to="{ name: 'Partners' }" target="_blank" class="text-gray-400">terzi</router-link>, a società appartenenti al Gruppo Cofidis ed al Gruppo Crédit Mutuel, per proprie finalità di marketing
                </div>
            </div>
            <div class="w-full mb-4 text-red">
                <div v-if="$v.privacy4.$error && !$v.privacy4.required">
                    {{ $t("Campo obbligatorio") }}
                </div>
                <div v-if="privacy4 === false">
                    {{ $t("Attenzione! Se scegli “no” non potrai ricevere le offerte speciali da parte dei nostri partner.") }}
                </div>
            </div>
        </div>

        <div class="form-footer text-center relative mb-6">
            <button class="bg-yellow rounded-full p-3 px-16 font-semibold shadow-xl hover:bg-yellow-4" @click="submit" type="button">
                <span>{{ $t("RICHIEDI") }}</span>
            </button>
        </div>
    </form>
</template>
<script>
import { mapUserData } from "@/utils";
import { required, email } from "vuelidate/lib/validators";
import EmailAutocomplete from "@/components/EmailAutocomplete.vue";

export default {
    components: { EmailAutocomplete },
    mounted() {
        this.$ga.pageview()
    },
    data() {
        return {

        };
    },
    computed: {
        ...mapUserData(["loans_count", "email", "privacy1", "privacy2", "privacy3", "privacy4", "politics", "importo"]),
        email: {
            get() {
                return this.$store.state.userData.email;
            },
            set(value) {
                return this.$store.commit("setUserData", { key: "email", value });
            }
        },
        title() {
            const importo = this.$options.filters.eur(this.importo)
            return this.$t("HAI SCELTO UN IMPORTO DI", [importo])
        },
    },
    validations: {
        email: {
            required,
            email
        },
        privacy1: {
            required
        },
        privacy2: {
            required
        },
        privacy3: {
            required
        },
        privacy4: {
            required
        }
    },
    methods: {
        submit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid && this.privacy1 === true) {
                this.$store.dispatch("event", { name: "EmailPrivacySubmit" });
                this.$store.dispatch("sendData", { step: "dati_email_privacy" });
                this.$store.dispatch("redirect", { step: "email_privacy", route: "Contatti" });
            }
        }
    }
};
</script>