<template>
    <FormBox title="CLUB" @submit="onSubmit()" declineText="Procedi senza Club" @decline="onDecline()" >
    
        <ProgressPz slot="form-header" progress="80" />

        <p class="text-base mb-4">
            Cofidis ti propone il servizio CLUB PLATINUM.
            Per 12 mesi puoi scegliere <strong>esperienze uniche in formula 2x1</strong>: paghi per uno e invita chi vuoi (ad esempio cinema, ristoranti e hotel)!
            <strong>Puoi beneficiare anche di sconti, buoni spesa e vantaggi del programma Lucky Days.</strong>
            Il tutto ad un prezzo esclusivo per i clienti Cofidis: circa {{ installment|num2 }} € al mese applicati automaticamente alla tua rata.
            <a :href="infoClubUrl" target="_blank" class="underline">Scopri qui</a> tutti i vantaggi del CLUB PLATINUM.
        </p>
        <p class="text-base mb-4">
            Prima di procedere, ti invitiamo a prendere visione del <a :href="rulesClubUrl" target="_blank" class="underline">Regolamento</a>
            che riporta nel dettaglio le caratteristiche del prodotto, nonché
            l’<a :href="privacyClubUrl" target="_blank" class="underline">Informativa Privacy</a>.
            Il servizio CLUB <strong>è facoltativo e non è necessario per ottenere il prestito o per ottenerlo a condizioni più vantaggiose.</strong>
        </p>
        <ClubOptionalConsents class="club-consents"/>

    </FormBox>
</template>
<script>
import FormBox from "./FormBox";
import ProgressPz from "./ProgressPz";
import { CLUB_PLATINUM } from '@/constants';
import ClubOptionalConsents from '@/components/ClubOptionalConsents.vue';


export default {
    components: { FormBox, ProgressPz, ClubOptionalConsents },
    data() {
        return {
            infoClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Cofidis_Club_PLATINUM.pdf',
            rulesClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Regolamento_Servizio_CLUB.pdf',
            privacyClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Informativa_Servizio.pdf'
        }
    },
    mounted() {
        this.$store.commit("setUserData", { key: 'service_available', value: true });
        this.$store.commit("setUserData", { key: 'service_pack', value: CLUB_PLATINUM.code });
        this.$store.commit("setUserData", { key: 'service_amount', value: CLUB_PLATINUM.value/12 });
        
        this.$store.dispatch("sendData", { step: "club" });
    },
    computed: {
        installment() {
            const rate = +this.$store.state.userData.rate
            return parseFloat((CLUB_PLATINUM.value/rate) + 1).toFixed(2)
        }
    },
    methods: {
        onDecline() {
            this.$store.dispatch("redirect", { step: "club_ko", route: "ClubPzWarning" });
        },
        onSubmit() {
            this.$store.commit("setUserData", { key: 'service', value: true });
            this.$store.dispatch("redirect", { step: "club_consent", route: "PrivacyPz" });
        }
    },
};
</script>