export const CLUB_PLATINUM = {
    code: 'CL390',
    value: 390
}

export const JOB_TYPES = {
    AUTONOMO: 1,
    PROFESSIONISTA: 11,
    DIPENDENTE: 2,
    STAGIONALE: 3,
    CASALINGA: 4,
    DISOCCUPATO: 5,
    PENSIONATO: 6,
    PENSIONATO_INVALIDO: 7,
    REDDITIERE: 9,
    STUDENTE: 10
}

export const HIRING_TYPES = {
    DETERMINATO: 1,
    INDETERMINATO: 2
}

export const DWELLING_TYPES = {
    AFFITTO: 1,
    PARENTI: 2,
    PROPRIETARIO: 5
}

export const OCCUPATIONS = {
    APPRENDISTA: 'APPRENDISTA/INTERINALE/STAGE'
}

export const METLIFE_PRV = ['MT','PZ','CS','CZ','KR','RC','VV','AV','BN','CE','NA','SA','CB','IS','BA','BR','BT','FG','LE','TA','AG','CL','CT','EN','ME','PA','RG','SR','TP']

export default {JOB_TYPES, HIRING_TYPES, DWELLING_TYPES, OCCUPATIONS, METLIFE_PRV}
