<template>
    <div class="mx-auto lg:container text-sm sm:text-base leading-tight text-gray-700 pt-4">
        <div class="px-6 py-6 text-center text-2xl sm:text-3xl font-semibold">
            <i class="icon smile block mx-auto mb-4"></i>
            <p>Complimenti,</p> la tua richiesta è stata presa in carico
        </div>
        <h1 class="text-xl sm:text-2xl font-semibold text-center sm:mt-4 mb-6 sm:mb-8 uppercase bg-orange-1 py-2">Cosa succede adesso?</h1>
        <div class="max-w-4xl mx-auto py-4 px-6 md:pr-6 md:pl-10 text-base" style="width: fit-content;">            
                    <div class="flex mb-6 items-center">
                        <div class="mr-2 lg:mx-5 text-center">
                            <i class="icon email block"></i>
                        </div>
                        <p class="pl-4 sm:pl-0">
                            Nei prossimi giorni riceverai un'e-mail di benvenuto con le istruzioni
                        </p>
                    </div>
                    <div class="flex mb-6 items-center">
                        <div class="mr-2 lg:mx-5 text-center">
                            <i class="icon contract block"></i>
                        </div>
                        <p class="pl-4 sm:pl-0">
                            Registrati alla piattaforma
                        </p>
                    </div>
                    <div class="flex mb-6 items-center">
                        <div class="mr-2 lg:mx-5 text-center">
                            <i class="icon spark block"></i>
                        </div>
                        <p class="pl-4 sm:pl-0">
                            Inizia a risparmiare sul tuo shopping e il tuo tempo libero!
                        </p>
                    </div>
                </div>
    </div>
</template>
<script>

export default {
    mounted() {
        localStorage.setItem('pp_cl_request_evaluation', 1)
    },
};
</script>