<template>
    <FormCard @submit="onSubmit" title="Cessione quinto">
        <div class="w-full mb-8">
            <label class="text-base">{{ $t("Hai una cessione del quinto in corso?") }}</label>
            <div class="flex mt-6 justify-center">
              <div class="relative mr-6">
                  <input type="radio" name="cessione_quinto" v-model="cessione_quinto" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
              </div>
              <div class="relative">
                  <input type="radio" name="cessione_quinto" v-model="cessione_quinto" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
              </div>
            </div>
            <div class="text-red text-center" v-if="$v.cessione_quinto.$error">
                <small v-if="!$v.cessione_quinto.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
    </FormCard>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { mapUserData } from "@/utils";

export default {
    mounted() {
        this.$ga.pageview()
    },
    computed: mapUserData(["cessione_quinto"]),
    validations: {
        cessione_quinto: {
            required
        }
    },
    watch: {
      cessione_quinto() {
        this.$v.$reset()
      }
    },
    methods: {
        onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                //this.$store.dispatch("event", { name: "PersonalData2Submit" });
                this.$store.dispatch("sendData", { step: "dati_cessione_quinto" });
                this.$store.dispatch("redirect", { step: "cessione_quinto", route: "DeliveryData" });
            }
        }
    }
};
</script>