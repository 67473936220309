<template>
     <div class="mx-auto lg:container text-sm sm:text-base leading-tight text-gray-700">
        <div class="px-6 py-6 text-center text-2xl sm:text-3xl font-semibold">
            {{ $t("Gentile") }} <strong>{{ name }}</strong>, {{ $t("grazie per averci scelto!") }}
        </div>
        <h1 class="text-xl sm:text-2xl font-semibold text-center sm:mt-4 mb-6 sm:mb-8 uppercase bg-neutral   py-2">{{ $t("I PROSSIMI PASSI") }}</h1>
        <div class="max-w-4xl text-lg mx-auto py-4 px-6">            
            <div class="flex mb-6 items-center">
                <div class="w-1/5 text-center">
                    <img class="h-12 mx-auto" src="@/assets/images/typ-envelope.svg" />
                </div>
                <p class="w-4/5 pl-4 sm:pl-0 text-sm">
                    {{ $t("A breve riceverai l’e-mail con il primo esito di fattibilità e, in caso di esito positivo, riceverai nella tua casella di posta elettronica il contratto. Per firmarlo bastano pochi clic e l’inserimento di un codice che riceverai via SMS.") }}
                </p>
            </div>
            <div class="flex mb-6 items-center">
                <div class="w-1/5 text-center">
                    <img class="h-12 mx-auto" src="@/assets/images/typ-contract.svg" />
                </div>
                <p class="w-4/5 pl-4 sm:pl-0 text-sm">
                    {{ $t("Firma subito il contratto in pochi click allegando le foto dei documenti richiesti (puoi anche scattare una foto dal tuo cellulare).") }}
                </p>
            </div>
            <div class="flex mb-6 items-center">
                <div class="w-1/5 text-center">
                    <img class="h-12 mx-auto" src="@/assets/images/typ-smile.svg" />
                </div>
                <p class="w-4/5 pl-4 sm:pl-0 text-sm">
                    {{ $t("Una volta inviata la documentazione completa e corretta, riceverai l’esito definitivo della tua richiesta.") }}
                </p>
            </div>
            <div class="text-center mt-10 mb-5">
                {{ $t("A presto") }}<br />
                <strong>{{ $t("La tua Banca") }}</strong>
            </div>
        </div>
    </div>
</template>
<script>
import { mapUserData } from "@/utils";

export default {
    computed: {
        ...mapUserData(["name"])
    }
};
</script>
