<template>
    <div class="logo flex justify-end">
        <div class="flex items-center bg-white shadow-sm p-2 pr-3 lg:pl-5 lg:pr-8 lg:pb-3 lg:pt-2 rounded-bl-2xl">
            <img class="header-logo" src="@/assets/images/logo.svg" alt="Cofidis Logo" title="Cofidis Logo" />
            <div class="separator h-4/5 bg-gray-2 mx-4 lg:mx-5"></div>
            <img class="header-logo-power" src="@/assets/images/logo-power-credit.svg" alt="Power Credit Logo" title="Power Credit Logo" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'LogoPowerCredit',
}
</script>
<style scoped>
    .header-logo {
        height: 55px
    }

    .header-logo-power {
        height: 40px
    }

    @screen lg {
        .header-logo {
            height: 93px
        }
        
        .header-logo-power {
            height: 55px
        }
    }

    .logo .separator {
        width: 1px;
    }
</style>