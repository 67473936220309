<template>
    <FormBox title="Documento d'identità" @submit="onSubmit()" @decline="goBack()" >
    
        <ProgressPz slot="form-header" progress="85" />

        <div class="w-full flex flex-wrap justify-between mb-4">          
            <div class="w-full lg:w-2/3 mx-auto flex flex-wrap">
                <div v-for="card in cards" :key="card.value" class="w-1/3 pr-1">
                    <IdentityCard v-model="identity_type" :label="card.label" :hasError="$v.identity_type.$error" :value="card.value"></IdentityCard>
                </div>
            </div>
            <div class="h-6 w-full text-center sm:mb-2" v-if="$v.identity_type.$error && !$v.identity_type.required">
                <small class="text-red">
                    Campo obbligatorio
                </small>
            </div>
        </div>

        <div class="w-full lg:w-1/2 lg:pr-4 mb-3">
            <FloatingLabelInput :name="`document_number`" v-model="document_number" :mask="identityCardMask" :label="`Numero del documento`" :error="$v.document_number.$error" :class="{ 'hidden': identity_type == 3 || identity_type == 4 }" />
            <FloatingLabelInput :name="`document_number`" v-model="document_number" :mask="passportMask" :label="`Numero del documento`" :error="$v.document_number.$error" :class="{ 'hidden': identity_type != 3 }" />
            <FloatingLabelInput :name="`document_number`" v-model="document_number" :mask="drivingLicenseMask" :label="`Numero del documento`" :error="$v.document_number.$error" :class="{ 'hidden': identity_type != 4 }" />
            <div class="text-xs mt-2">
                <span class="block sm:inline mr-1">{{ $t("Non sai qual è il numero del documento?") }}</span>
                <a href="#" @click.prevent="showDocNumHelp = !showDocNumHelp" class="underline">{{ $t("Clicca qui") }}</a>
                {{ $t("per un aiuto") }}
            </div>
            <SlideUpDown :active="showDocNumHelp" class="w-full">
                <div class="text-gray-700 border-t border-b my-5 pt-5">
                    <div v-if="identity_type == 1">
                        <p class="text-xs mb-4 sm:h-10">
                            {{ $t("Se hai la Carta di Identità Elettronica 3.0, il numero è quello stampato sul fronte in alto a destra (ad esempio CA00000AA).") }}
                        </p>
                        <img src="@/assets/images/docs/fig_ci_num.png" class="w-full mx-auto max-w-xs">
                        <p class="text-xs my-4 sm:h-10">
                            {{ $t("Se hai la Carta di Identità Elettronica di precedente generazione, il numero è quello stampato sul fronte sotto la bandiera italiana (ad esempio 1234567AB).") }}
                        </p>
                        <img src="@/assets/images/docs/fig_ci2_num.png" class="w-full mx-auto max-w-xs">
                        <p class="text-xs my-4 sm:h-10">
                            {{ $t("Se hai la Carta di Identità Cartacea, il numero è quello posto sul fronte (ad esempio AB1234567).") }}
                        </p>
                        <img src="@/assets/images/docs/fig_ci3_num.png" class="w-full mx-auto max-w-xs mb-4">
                    </div>
                    <div v-if="identity_type == 3">
                        <p class="text-xs mb-4 h-10">
                            {{ $t("Il numero del Passaporto è quello presente a pagina 2 (quella con la tua foto) in corrispondenza della voce «Passaporto N°» (ad esempio AA0000000)") }}
                        </p>
                        <img src="@/assets/images/docs/fig_passport_num.png" class="w-full mx-auto max-w-xs mb-4">
                    </div>
                    <div v-if="identity_type == 4">
                        <p class="text-xs mb-4">
                            {{ $t("Il numero della Patente è quello presente sul fronte della tessera al punto 5 (ad esempio MI1234567U)") }}
                        </p>
                        <img src="@/assets/images/docs/fig_dl_num.png" class="w-full mx-auto max-w-xs mb-4">
                    </div>
                </div>
            </SlideUpDown>
            <div class="w-full text-red">
                <small v-if="documentNumberError">
                    {{ $t("Numero di documento non valido") }}
                </small>
                <small v-else-if="$v.document_number.$error && !$v.document_number.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
                <small v-else-if="$v.document_number.$error && !$v.document_number.document_number_valid">
                    {{ $t("Numero patente non corretto") }}
                </small>
            </div>
        </div>
        <div class="w-full lg:w-1/2 mb-3">
            <div class="label-floating" :class="{ 'border-red': $v.document_city.$error,'border-gray-700': focusInput == 'document_city' }">
                <Autocomplete :search="citySearch" v-model="document_city" :class="{ 'border-red': $v.document_city.$error }" :placeholder="$t('Località di rilascio')" @focus="setFocusInput('document_city')" @blur="unsetFocusInput()"></Autocomplete>
                <label class="text-xs">{{ $t("Località di rilascio") }}</label>
            </div>
            <div class="text-xs mt-2">
                <span class="block sm:inline mr-1">{{ $t("Non sai qual è la località di rilascio?") }}</span>
                <a href="#" @click.prevent="showDocLocHelp = !showDocLocHelp" class="underline">{{ $t("Clicca qui") }}</a>
                {{ $t("per un aiuto") }}
            </div>
            <SlideUpDown :active="showDocLocHelp" class="w-full">
                <div class="text-gray-700 border-t border-b my-5 pt-5">
                    <div v-if="identity_type == 1">
                        <p class="text-xs mb-4 sm:h-10">
                            {{ $t("La località di rilascio è quella del comune che ti ha rilasciato la Carta di Identità.") }}<br>
                            {{ $t("Se hai la Carta di Identità Elettronica 3.0:") }}
                        </p>
                        <img src="@/assets/images/docs/fig_ci_loc.png" class="w-full mx-auto max-w-xs">
                        <p class="text-xs my-4 sm:h-6">
                            {{ $t("Se hai la Carta di Identità Elettronica di precedente generazione:") }}
                        </p>
                        <img src="@/assets/images/docs/fig_ci2_loc.png" class="w-full mx-auto max-w-xs">
                        <p class="text-xs my-4 sm:h-6">
                            {{ $t("Se hai la Carta di Identità Cartacea:") }}
                        </p>
                        <img src="@/assets/images/docs/fig_ci3_loc.png" class="w-full mx-auto max-w-xs mb-4">
                    </div>
                    <div v-if="identity_type == 3">
                        <p class="text-xs mb-4 h-10">
                            {{ $t("La località di rilascio è quella della questura che ti ha rilasciato il passaporto (pagina 4).") }}
                        </p>
                        <img src="@/assets/images/docs/fig_passport_loc.png" class="w-full mx-auto max-w-xs mb-4">
                    </div>
                    <div v-if="identity_type == 4">
                        <p class="text-xs mb-4 sm:h-10">
                            {{ $t("La località di rilascio non è presente sul documento. Devi inserire la località dell’ente che ti ha rilasciato la patente. Se non ricordi la località, inserisci la tua provincia di residenza.") }}
                        </p>
                    </div>
                </div>
            </SlideUpDown>
            <div class="w-full text-red">
                <small v-if="$v.document_city.$error && !$v.document_city.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-full lg:w-1/2 lg:pr-4 mb-4">
            <label class="text-xs" v-if="identity_type == 1">{{ $t("Data di rilascio") }}</label>
            <label class="text-xs" v-if="identity_type == 3">{{ $t("Data di rilascio (punto 7 del Passaporto)") }}</label>
            <label class="text-xs" v-if="identity_type == 4">{{ $t("Data di rilascio (punto 4a)") }}</label>
            <DateSelect :value="document_release" :inputClass="{ 'border-red': $v.document_release.$error }" :startYear="documentReleaseStartYear" @change="updateDocumentReleaseValue" ref="releaseDateComponent" />
            <div class="w-full text-red">
                <small v-if="$v.document_release.$error && !$v.document_release.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
                <small v-else-if="$v.document_release.$error && !$v.document_release.minValue">
                    {{ $t("Data non valida") }}
                </small>
                <small v-else-if="$v.document_release.$error && !$v.document_release.maxValue">
                    {{ $t("Documento non emesso") }}
                </small>
            </div>
        </div>
        <div class="w-full lg:w-1/2 lg:pr-4 mb-4">
            <label class="text-xs" v-if="identity_type == 1">{{ $t("Data di scadenza") }}</label>
            <label class="text-xs" v-if="identity_type == 3">{{ $t("Data di scadenza (punto 8 del Passaporto)") }}</label>
            <label class="text-xs" v-if="identity_type == 4">{{ $t("Data di scadenza (punto 4b)") }}</label>
            <DateSelect :value="document_expire" :inputClass="{ 'border-red': $v.document_expire.$error }" :startYear="documentExpireStartYear" :endYear="documentExpireEndYear" @change="updateDocumentExpireValue" ref="expireDateComponent" />
            <div class="w-full text-red">
                <small v-if="$v.document_expire.$error && !$v.document_expire.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
                <small v-else-if="$v.document_expire.$error && !$v.document_expire.minValue">
                    {{ $t("Documento scaduto") }}
                </small>
            </div>
        </div>

    </FormBox>
</template>
<script>
import FormBox from "./FormBox";
import ProgressPz from "./ProgressPz";

import { required, helpers } from "vuelidate/lib/validators";
import { mapUserData } from "@/utils";
import { identityCardMask, drivingLicenseMask, passportMask } from "@/masks";
import IdentityCard from "@/components/IdentityCard";
import DateSelect from "@/components/DateSelect";
import FloatingLabelInput from "@/components/FloatingLabelInput";

import $ from "jquery";
import Fuse from "fuse.js";
import moment from "moment";

const document_number_valid = (number, vm) => {
    if (!helpers.req(number)) return true;
    const matched = vm.document_city.match(/\([A-Z][A-Z]\)$/);
    const province = matched && matched.length ? matched[0].slice(1, 3) : null;
    if (!province) return true;

    if (
        vm.identity_type == 4 &&
        number.substr(0, 2) !== "U1" &&
        number.substr(0, 2) != province
    ) {
        return false;
    }
    return true;
};

export default {
    components: { FormBox, ProgressPz, FloatingLabelInput, IdentityCard, DateSelect },
    async mounted() {
        // this.$store.dispatch("sendData", { step: "documents", additionalData: {
        //     init: true,
        //     codeCampaign: localStorage.getItem('fastcredit_code_campaign'), 
        //     userId: localStorage.getItem('fastcredit_user_id') 
        // }});
        if ( !this.identity_type ) {
            document.getElementsByClassName('form-body')[0]?.classList.add("hidden")
            document.getElementsByClassName('form-footer')[0]?.classList.add("hidden")
            document.getElementsByClassName('back-btn')[0]?.classList.add("hidden")
        }

        const city_feevo = await $.getJSON("https://cofidis-settings.s3.eu-central-1.amazonaws.com/city_feevo.json")
        this.fuser = new Fuse(city_feevo, {
            keys: ["Citta"],
            threshold: 0.0,
            location: 0,
            distance: 0,
            maxPatternLength: 32,
            minMatchCharLength: 0,
            caseSensitive: false
        })
    },
    beforeDestroy() {
        if ( !this.identity_type ) {
            document.getElementsByClassName('form-body')[0]?.classList.remove("hidden")
            document.getElementsByClassName('form-footer')[0]?.classList.remove("hidden")
            document.getElementsByClassName('back-btn')[0]?.classList.remove("hidden")
        }
    },
    data() {
        return {
            city: "",
            fuser: null,
            cards: [{
                    value: 1,
                    label: "Carta d'identità"
                },
                {
                    value: 3,
                    label: "Passaporto"
                },
                {
                    value: 4,
                    label: "Patente di guida"
                }
            ],
            documentNumberError: false,
            identityCardMask,
            drivingLicenseMask,
            passportMask,
            documentMasks: {
                1: identityCardMask.regex,
                3: passportMask.regex,
                4: drivingLicenseMask.regex
            },
            showDocNumHelp: false,
            showDocLocHelp: false
        };
    },
    computed: {
        documentReleaseStartYear() {
            return parseInt(this.$store.getters.fiscalCodeExtracted.year)
        },
        documentExpireStartYear() {
            return parseInt(moment().format('YYYY'))
        },
        documentExpireEndYear() {
            return parseInt(moment().format('YYYY')) + 11
        },
        ...mapUserData([
            "identity_type",
            "document_city",
            "document_number",
            "document_release",
            "document_expire"
        ]),
        showReleaseDate() {
            return this.identity_type == 3 || this.identity_type == 4;
        }
    },
    watch: {
        identity_type() {
            this.document_city = "";
            this.document_number = "";
            this.document_expire = null;
            this.document_release = null;
            if (this.$refs.expireDateComponent) this.$refs.expireDateComponent.reset();
            if (this.$refs.releaseDateComponent) this.$refs.releaseDateComponent.reset();
            this.$v.$reset();
            this.showDocNumHelp = false
            this.showDocLocHelp = false

            document.getElementsByClassName('form-body')[0]?.classList.remove("hidden")
            document.getElementsByClassName('form-footer')[0]?.classList.remove("hidden")
            document.getElementsByClassName('back-btn')[0]?.classList.remove("hidden")
        }
    },
    validations: {
        identity_type: {
            required
        },
        document_city: {
            required
        },
        document_number: {
            required,
            document_number_valid
        },
        document_expire: {
            required,
            minValue: value => {
                return moment(value, 'DD/MM/YYYY') >= moment()
            },
            maxValue: value => {
                return moment(value, 'DD/MM/YYYY') <= moment().add(11, "years")
            }
        },
        document_release: {
            required,
            minValue: (value, vm) => {
                const birthday = vm.$store.getters.fiscalCodeExtracted.birthday                
                return moment(value, 'DD/MM/YYYY') >= moment(birthday, 'YYYY/MM/DD')
            },
            maxValue: value => {
                return moment(value, 'DD/MM/YYYY') <= moment()
            }
        },
    },
    methods: {
        updateDocumentReleaseValue(value) {
            this.document_release = value
        },
        updateDocumentExpireValue(value) {
            this.document_expire = value
        },
        // add validation enforce for Android Chrome bug
        checkDocumentNumber() {
            const regex = RegExp(this.documentMasks[this.identity_type])
            if (regex.test(this.document_number)) {
                return true
            } else {
                this.document_number = null
                this.documentNumberError = true
                return false
            }
        },
        onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            this.documentNumberError = false
            if (!this.$v.$invalid && this.checkDocumentNumber()) {
                this.$store.dispatch("sendData", { step: "dati_pers_4" });
                this.$store.dispatch("redirect", { step: "dati_pers_4", route: "PrivacyPz" });
            }
        },
        citySearch(input) {
            if (input.length < 2) return [];
            let res = this.fuser.search(input);
            return res.map(i => `${i.item.Citta} (${i.item.Prov})`);
        }
    }
};
</script>