<template>
    <FormCard @submit.prevent title="Buone notizie per te!" class="w-full" >
        <div class="w-full mb-6">
            <slot name="subtitle"></slot>
            <div class="mt-14">
                <PromoBox
                    :model="promoModel"
                    :hasDetails="service || insurance"
                    :flagText="flagText"
                    :loading="loading"
                    :cta="ctaText"
                    @click="submit"
                >
                    <template #details>
                        <span v-html="detailsHtml"></span>
                    </template>
                </PromoBox>
            </div>
    
            <div class="text-center text-gray-700 mt-16">
                <button @click="decline()" class="underline text-sm md:text-base">Oppure conferma la scelta precedente</button>
            </div>
        </div>
    </FormCard>
    
</template>
<style scoped type="text/css">
    div >>> .form-body { @apply p-0 pb-6 sm:px-6 md:px-8; }
    div >>> .form-footer { display: none !important; }
</style>
<script>
import { mapUserData } from "@/utils";
import PromoBox from "@/components/PromoBox.vue";

export default {
    props: {
        ctaText: {
            type: String,
            default: "CONTINUA"
        }
    },
    data() {
        return {
            loading: false,
            showDetails: false,
            infoSetUrl: "https://cofidis-docs-pp.s3.eu-central-1.amazonaws.com/Set_informativi_assicurazione.pdf"
        }
    },
    mounted() {
        this.$ga.pageview()
    },
    components: {
        PromoBox
    },
    computed: {
        ...mapUserData(["name", "importo", "service", "insurance"]),
        promoModel() {
            return this.getPromoOffer(true)
        },
        flagText() {
            if (this.promoModel.type == 'rata') return 'Rata più leggera'
            return 'Più liquidità per i tuoi progetti'
        },
        detailsHtml() {
            const model = this.promoModel;
            let html = `Rata composta da ${model.imp_rata}€ di rimborso prestito`
            if (this.insurance && this.service) {
                html += `, circa ${model.insurance}€ di premio assicurativo
                    (<a href="${this.infoSetUrl}" target="_blank" class="underline">set informativo</a>)
                    e circa ${model.club_rata}€ di servizio Club. Totale dovuto: ${model.dovuto}€.
                    Se vuoi modificare le tue scelte sui servizi assicurativi e su Club torna indietro e ricomincia`
            }
            else if (this.insurance) {
                html += ` e circa ${model.insurance}€ di premio assicurativo
                    (<a href="${this.infoSetUrl}" target="_blank" class="underline">set informativo</a>).
                    Totale dovuto: ${model.dovuto}€.
                    Se vuoi modificare le tue scelte sui servizi assicurativi torna indietro e ricomincia`
            }
            else if (this.service) {
                html += ` e circa ${model.club_rata}€ di servizio Club.
                    Totale dovuto: ${model.dovuto}€.
                    Se vuoi modificare le tue scelte sul servizio Club torna indietro e ricomincia`
            }
            return html;
        }
    },
    methods: {
        async submit() {
            this.loading = true
            this.$store.commit("setUserData", { key: 'promoUpgraded', value: true });
            this.$store.commit("setChoosenCredit", this.promoModel.imp_finanz);
            this.$store.commit("setUserData", { key: 'rate', value: this.promoModel.rate });
            await this.$store.dispatch("sendData", { step: "promo" });
            this.$store.dispatch("redirect", { step: "promo", route: "PrivacyConsent" });
        },
        decline() {
            this.$store.dispatch("sendData", { step: "promo_offer"});
            this.$store.dispatch("redirect", { step: "promo_ko", route: "PromoConfirm" });
        }
    }
};
</script>
