class Append {
    enter_landing(payload, {state}) {
        payload['CF'] = state.userData.fiscal_code
        payload['PianoF'] = state.userData.piano_f
        payload['cliente_id'] = state.userData.client_id
        payload['userId'] = state.userData.user_id
        payload['codeCampaign'] = state.userData.code_campaign
    }

    importo(payload, {state}) {
        payload['Importo'] = state.userData.importo
        payload['Rate'] = state.userData.rate
    }

    dati_anag(payload) {
        delete(payload['CF'])
    }

    dati_pers_1(payload) {
        delete(payload['Permesso_Dal'])
        delete(payload['Permesso_End'])
    }

    dati_pers_2(payload, {state}) {
        payload['Abitazione_Dal'] = state.userData.resident_since
    }

    dati_pers_3(payload) {
        delete(payload['N'])
        delete(payload['N_Dom'])
    }
    
    esito_ko(payload, {state}) {
        payload['userId'] = state.userData.user_id
        payload['codeCampaign'] = state.userData.code_campaign
        payload['Esito'] = 'reddito_not_allowed'

        payload['privacy1'] = +state.userData.privacy1
        payload['privacy2'] = +state.userData.privacy2
        payload['privacy3'] = +state.userData.privacy3
        payload['privacy4'] = +state.userData.privacy4
        payload['Politico'] = +state.userData.politics
        payload['OldClients'] = false

        if (state.userData.insurance_available) {
            payload['assicurazione'] = +state.userData.insurance,
            payload['assicurazione_pack'] = state.userData.insurance_pack
        }

        if (state.userData.service_available) {
            payload['service'] = +state.userData.service,
            payload['service_pack'] = state.userData.service_pack
        }
    }

}
export default new Append();