<template>
    <FormCard @submit="onSubmit" title="Datore di lavoro">
        <div class="w-full sm:w-1/2 sm:pr-4 mb-2">
            <FloatingLabelInput :name="`employer_name`" v-model="employer_name" :mask="{ casing: 'upper' }" :label="`Denominazione`" :error="$v.employer_name.$error" />
            <div class="h-6 w-full text-red" v-if="$v.employer_name.$error">
                <small v-if="!$v.employer_name.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-2/3 sm:w-1/3 sm:pr-4 mb-2">
            <FloatingLabelInput :name="`employer_address`" v-model="employer_address" :mask="{
              mask: 'A{+}',
              placeholder: '',
              definitions: { A: { validator: '[A-Z0-9ÀÈÌÒÙÁÉÍÓÚ .\'°^/-]' } },
              casing: 'upper'
            }" :label="`Indirizzo`" :error="$v.employer_address.$error" />
            <div class="h-6 w-full text-red" v-if="$v.employer_address.$error">
                <small v-if="!$v.employer_address.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-1/3 pl-4 sm:pl-0 sm:pr-0 sm:w-1/6 mb-2">
            <FloatingLabelInput :name="`employer_street_number`" v-model="employer_street_number" :mask="{
                mask: 'A{*}',
                definitions: { A: { validator: '[A-Z0-9ÀÈÌÒÙÁÉÍÓÚ°^/-]' } },
                clearIncomplete: true,
                casing: 'upper'
              }" :label="`Civico`" :error="$v.employer_street_number.$error" />
            <div class="h-6 w-full text-red" v-if="$v.employer_street_number.$error">
                <small v-if="!$v.employer_street_number.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-2/3 sm:w-1/2 sm:pr-4 mb-2">
            <div class="label-floating" :class="{ 'border-red': $v.employer_city.$error,'border-gray-700': focusInput == 'employer_city' }" >                
                <Autocomplete :class="{ 'border-red': $v.employer_city.$error }" :placeholder="$t('Comune')" v-model="employer_city" :search="citySearch" @focus="setFocusInput('employer_city')" @blur="unsetFocusInput()">
                </Autocomplete>
                <label>{{ $t("Comune") }}</label>
            </div>
            <div class="h-6 w-full text-red" v-if="$v.employer_city.$error">
                <small v-if="!$v.employer_city.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-1/3 sm:w-1/6 pl-4 sm:pl-0 sm:pr-4 mb-2">
            <FloatingLabelSelect v-if="employer_multicap.length" :name="`employer_postal_code`" v-model="employer_postal_code" :items="employer_multicap" :label="`CAP`" :error="$v.employer_postal_code.$error" :disableZeroValue="true"/>
            <FloatingLabelInput v-else :name="`employer_postal_code`" v-model="employer_postal_code" :label="`CAP`" :error="$v.employer_postal_code.$error" :disabled="true" />
            <div class="h-6 w-full text-red" v-if="$v.employer_postal_code.$error">
                <small v-if="!$v.employer_postal_code.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div> 
        <div class="w-full sm:w-1/3 mb-2">
            <FloatingLabelInput :name="`employer_phone`" :type="`tel`" v-model="employer_phone" :mask="phoneMask" :label="`Telefono`" :error="$v.employer_phone.$error" />
            <div class="h-6 w-full text-red" v-if="$v.employer_phone.$error">
                <small v-if="!$v.employer_phone.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
                <small v-else-if="!$v.employer_phone.phone">
                    {{ $t("Numero di telefono non valido") }}
                </small>
            </div>
        </div>
        <div class="w-full mb-4 mb-2 text-center mt-2" v-if="showCessioneQuinto">
            <label>{{ $t("Hai una cessione del quinto in corso?") }}</label>
            <div class="flex justify-center mt-3">
                <div class="relative mr-4">
                    <input type="radio" name="cessione_quinto" v-model="cessione_quinto" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
                </div>
                <div class="relative">
                    <input type="radio" name="cessione_quinto" v-model="cessione_quinto" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
                </div>
            </div>
            <div class="text-red" v-if="$v.cessione_quinto.$error">
                <small v-if="!$v.cessione_quinto.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-full md:w-full lg:w-1/2 xl:w-1/3 lg:pr-4 mb-2" v-if="showFamilyMember">
            <FloatingLabelSelect :name="`family_member_count`" v-model="family_member_count" :items="family_member_counts" :label="`Numero di familiari, incluso te stesso`" :error="$v.family_member_count.$error" :disableZeroValue="true"/>
            <div class="h-6 w-full text-red" v-if="$v.family_member_count.$error">
                <small v-if="!$v.family_member_count.nonZero">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-full md:w-full lg:w-1/2 xl:w-1/3 xl:pr-4 mb-2" v-if="showFamilyMemberCount">
            <FloatingLabelSelect :name="`employed_family_member_count`" v-model="employed_family_member_count" :items="employed_family_member_counts" :label="`Familiari con Reddito, incluso te stesso`" :error="$v.employed_family_member_count.$error" :disableZeroValue="true"/>
            <div class="h-6 w-full text-red" v-if="$v.employed_family_member_count.$error">
                <small v-if="!$v.employed_family_member_count.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
    </FormCard>
</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapUserData, nonZeroIf } from "@/utils";
import { phoneMask } from "@/masks";

import $ from "jquery";
import Fuse from "fuse.js";
import FloatingLabelSelect from "@/components/FloatingLabelSelect";
import FloatingLabelInput from "@/components/FloatingLabelInput";

const phone = value => {
    let re = /^(\+39[. ]??)??[3|0]\d{5,9}$/
    return re.test(value)
}

export default {
    components: { FloatingLabelSelect, FloatingLabelInput },
    data() {
        return {
            search: [],
            fuser: null,
            phoneMask
        }
    },
    async mounted() {
        this.$ga.pageview()

        const city_feevo = await $.getJSON("https://cofidis-settings.s3.eu-central-1.amazonaws.com/city_feevo_2022.json")
        this.fuser = new Fuse(city_feevo, {
            keys: ["Citta"],
            threshold: 0.0,
            location: 0,
            distance: 0,
            maxPatternLength: 32,
            minMatchCharLength: 0,
            caseSensitive: false
        })
    },
    computed: {
        showCessioneQuinto() {
            return this.$store.state.version == 2 && this.$store.state.PATH == 'CQ'
        },
        showFamilyMember() {
            return this.$store.state.version == 2 && this.$store.state.PATH == 'FE'
        },
        showFamilyMemberCount() {
            return this.family_member_count
        },
        family_member_counts() {            
            let arr = [1,2,3,4,5,6,7,8,9].map(i => ({key: i, value: i}))
            arr.unshift({key: 0, value: 'Numero di familiari, incluso te stesso'})        
            return arr
        },
        employed_family_member_counts() {   
            let arr = [{key: 0, value: 'Familiari con Reddito, incluso te stesso'}]
            for(var i = 1; i <= this.family_member_count; i++) {
                arr.push({key: i, value: i})
            }    
            return arr
        },
        ...mapUserData([
            "employer_name",
            "employer_address",
            "employer_street_number",
            "employer_postal_code",
            "employer_multicap",
            "employer_city",
            "employer_phone",
            "cessione_quinto",
            "family_member_count",
            "employed_family_member_count"            
        ])
    },
    validations: {
        employer_name: {
            required
        },
        employer_address: {
            required
        },
        employer_street_number: {
            required
        },
        employer_postal_code: {
            required
        },
        employer_city: {
            required
        },
        employer_phone: {
            required,
            phone
        },
        cessione_quinto: {
            required: requiredIf(function() {
                return this.showCessioneQuinto
            })
        },
        family_member_count: {
            nonZero: nonZeroIf("showFamilyMember")
        },
        employed_family_member_count: {
            nonZero: nonZeroIf("showFamilyMember")
        }
    },
    watch: {
        employer_city(val) {
            const city = val.slice(0, -5)   
            this.employer_postal_code = null
            this.employer_multicap = []
            this.search.map(res => {
                if(res.item.Citta == city) {                    
                    if(res.item.Multicap) {
                        this.employer_multicap.push({key: null, value: 'CAP'})
                        res.item.Multicap.split(',').map(cap => {
                            const value = '0'.repeat(5 - cap.toString().length) + cap.toString()
                            this.employer_multicap.push({key: cap, value })
                        })
                    }
                    else {
                        this.employer_postal_code = res.item.Cap
                    }
                }
            })
        },
        family_member_count(val) {
            if (val === 1) {
                this.employed_family_member_count = 1;
                return;
            }
            if (val < this.employed_family_member_count)
                this.employed_family_member_count = val;
        },
    },
    methods: {
        onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                this.$store.dispatch("event", { name: "EmployerDataSubmit" });
                this.$store.dispatch("sendData", { step: "dati_lav_2" });
                this.$store.dispatch("redirect", { step: "dati_lav_2", route: "PaymentDetails" });
            }
        },
        citySearch(input) {
            if (input.length < 2) return [];
            this.search = this.fuser.search(input)
            return this.search.map(i => `${i.item.Citta} (${i.item.Prov})`);
        }
    }
};
</script>