<template>
    <div class="pb-8 md:pb-5 pt-5">
        <Steps :count="step_number" :active="1" />
        <div class="w-11/12 sm:max-w-7xl mx-auto shadow-lg rounded-3xl bg-white mt-5 mb-10 pb-10">
            
            <FormHeader class="pt-8 pb-5 text-center">Completa la tua richiesta</FormHeader>
            
            <form class="relative text-sm container mx-auto pl-5 pr-6 md:px-12 text-gray-700" @submit.prevent>
                <p class="text-base mb-2">
                    Power Credit ti offre di più con il servizio CLUB. Accedi a un mondo di sconti e vantaggi per le tue passioni e il tempo libero.
                </p>
                <p class="text-base mb-4">
                    Prima di procedere ti invitiamo a prendere visione del <a :href="rulesClubUrl" target="_blank" class="underline">Regolamento</a>
                    che riporta nel dettaglio le caratteristiche del prodotto, nonché l’<a :href="privacyClubUrl" target="_blank" class="underline">Informativa Privacy</a>. Il servizio CLUB è facoltativo e non è necessario per ottenere la Linea di Credito, né per ottenerla a condizioni più vantaggiose.
                </p>
                <ConsentItemCheck v-model="consent_club0"  :v="$v.consent_club0" :mustBeTrueError="mustBeTrueError" @input="handleConsent0Input">
                    <span class="text-sm leading-6">Confermo i trattamenti di dati e tutte le dichiarazioni obbligatorie.
                        <button type="button" class="underline" @click="showConsents = !showConsents">Clicca qui</button>
                        per vederle tutte.</span>
                </ConsentItemCheck>
                <SlideUpDown :active="showConsents" class="w-full pl-10 pt-2">
                    <ConsentItemCheck v-model="consent_club1" :v="$v.consent_club1" :mustBeTrueError="mustBeTrueError" @input="handleChildConsentsInput">
                        <span class="text-xs leading-6">
                            Ho letto e compreso le condizioni del Servizio CLUB contenute nel <a :href="rulesClubUrl" target="_blank" class="underline">Regolamento contrattuale</a>, accettando integralmente le sue condizioni senza riserva alcuna, compresa l’autorizzazione a Cofidis a procedere al pagamento per mio conto del costo annuale del servizio anche in caso di rinnovo a Quattrolinee S.r.l. con sede in corso Vittorio Emanuele II 25, Torino, e dichiaro di essere interessato a sottoscrivere la proposta del servizio.
                        </span>
                    </ConsentItemCheck>
                    <ConsentItemCheck v-model="consent_club2" :v="$v.consent_club2" :mustBeTrueError="mustBeTrueError" @input="handleChildConsentsInput">
                        <span class="text-xs leading-6">
                            Ai sensi degli articoli 1341 e 1342 del Codice Civile dichiaro di avere attentamente esaminato e di accettare integralmente le clausole del Contratto riportate nel <a :href="rulesClubUrl" target="_blank" class="underline">Regolamento contrattuale</a>, di seguito elecante: <i>Art. 4 Obblighi delle Parti, Art. 5 Gestione Dati, Art. 6 Corrispettivo e modalità di pagamento, Art. 7 Responsabilità, Art. 8 Recesso, Art. 9 Risoluzione, Art. 10 Foro competente.</i>
                        </span>
                    </ConsentItemCheck>
                    <ConsentItemCheck v-model="consent_club3" :v="$v.consent_club3" :mustBeTrueError="mustBeTrueError" @input="handleChildConsentsInput">
                        <span class="text-xs leading-6">
                            Dichiaro di aver ricevuto e aver ben compreso l’<a :href="privacyClubUrl" target="_blank" class="underline">Informativa</a> fornita ai sensi dell’art. 13 del Regolamento UE 2016/679, dalla quale risulta che, nell’ambito dell’esecuzione delle operazioni e del servizio richiesto, si intendono perseguire le finalità di cui al punto 2.1. “Finalità Necessarie”. In particolare, dichiaro di aver ben compreso dalla predetta Informativa che è previsto il trattamento, da parte di Quattrolinee S.r.l., nella sua qualità di Titolare, dei miei dati personali.                
                        </span>
                    </ConsentItemCheck>
                </SlideUpDown>
                <ClubOptionalConsents class="mt-2" />
                <StickyButton :clickHandler="submit" class="mt-8">
                    <span class="text-center text-xl font-semibold">CONFERMA</span>
                </StickyButton>
                <button class="text-center text-sm text-gray-700 mt-8 underline block mx-auto" @click="goBack()">Torna indietro</button>
            </form>
        </div>
    </div>
</template>

<script>
import StickyButton from "@/components/StickyButton.vue"
import Steps from "@/components/Steps"
import ConsentItemCheck from "@/components/RequiredItemCheck.vue";
import ClubOptionalConsents from "@/components/ClubOptionalConsents.vue";
import FormHeader from "@/components/FormHeader.vue";
import { mapUserData } from "@/utils";
import { required } from "vuelidate/lib/validators";

const mustBeTrue = value => value === true;

export default {
    name: "ClubPowerConsent",
    components: {StickyButton, Steps, ConsentItemCheck, ClubOptionalConsents, FormHeader },
    data() {
       return {
            consent_club0: null,
            step_number: 3,
            showConsents: false,
            rulesClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Regolamento_Servizio_CLUB.pdf',
            privacyClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Informativa_Servizio.pdf',
            mustBeTrueError: 'ATTENZIONE: non sarà possibile procedere all’adesione al servizio',
        } 
    },
    computed: {
        ...mapUserData([
          "consent_club1",
          "consent_club2",
          "consent_club3",
          "document_expired",
        ]),
        allChildConsents: {
            get() {
                return [this.consent_club1, this.consent_club2, this.consent_club3]
            },
            set(val) {
                this.consent_club1 = val
                this.consent_club2 = val
                this.consent_club3 = val
                return [this.consent_club1, this.consent_club2, this.consent_club3]
            }
        },
    },
    created() {
        if (this.consent_club1 && this.consent_club2 && this.consent_club3) {
            this.consent_club0 = true;
        }
        if (this.document_expired) this.step_number = 4
    },
    validations: {
        consent_club0: {
            required,
            mustBeTrue
        },
        consent_club1: {
            required,
            mustBeTrue
        },
        consent_club2: {
            required,
            mustBeTrue
        },
        consent_club3: {
            required,
            mustBeTrue
        },
    },
    methods: {
        handleConsent0Input(val) {
            this.allChildConsents = val
        },
        handleChildConsentsInput(val) {
            if (!val) this.consent_club0 = false
            else {
                const countTrue = this.allChildConsents.reduce((count, value) => count + (value === true ? 1 : 0), 0);
                if (countTrue === this.allChildConsents.length) this.consent_club0 = true
            }
        },
        async submit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                this.$store.commit("setUserData", { key: "service", value: 1 });
                this.$store.dispatch("sendData", { step: "club_consent" });
                this.$store.dispatch("redirect", { step: "club_consent", route: "Income" });
            }
        },
    },
}
</script>