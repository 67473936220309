<template>
    <form action="next" method="POST">
        <div class="container text-center py-6 relative mx-auto">
            <i class="icon arrowleft absolute left-4 sm:left-0 top-1/2 transform -translate-y-1/2 cursor-pointer" @click="goBack()"></i>
            <Steps :count="steps" :active="steps"></Steps>
        </div>

        <div class="form-wrapper">
            <PrivacyConsent :alert-data-consent="false"/>
        </div>
    </form>
</template>
<script>
import PrivacyConsent from "@/views/forms/PrivacyConsent";
import Steps from '@/components/Steps'

export default {
    components: { Steps, PrivacyConsent },
    computed: {
        steps() {
            return this.$store.state.userData.document_expired ? 3 : 2
        }
    }
};
</script>