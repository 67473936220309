<template>
    <div>
        <MetlifeBestCareConsent @submit="onSubmit" />
    </div>
</template>
<style scoped type="text/css">
@media screen and (max-width: 639px) {
    div >>> .form-header { font-size: 1rem !important; }
}
</style>
<script>
import MetlifeBestCareConsent from "@/views/forms/MetlifeBestCareConsent";

export default {
    mounted() {
        this.$ga.pageview()
        this.$store.dispatch("sendData", { step: "assicurazione_metlife_consent" })
    },
    components: { MetlifeBestCareConsent },
    methods: {
        onSubmit() {     
            this.$store.dispatch("event", { name: "LoanProtectionConsentSubmit" });
            this.$store.dispatch("sendData", { step: "dati_privacy" });
        }
    }
};
</script>