import getData from "./data/default.js";
import getDataDev from "./data/dev.js";
import router from "@/router";
import loadData from "@/dataService";

const config = loadData("config.json")

export default {
  logStep(state, value) {
    state.steps.push(value)
  },
  unlogStep(state) {
    state.steps.pop()
  },
  setUrlVars(state, value) {
    state.urlVars = value;
  },
  setUrlParts(state, value) {
    state.urlParts = value;
  },
  setChoosenCredit(state, value) {
    state.choosenCredit = value;
  },
  setChoosenSolution(state, value) {
    state.choosenSolution = value;
  },
  setLimitedCredit(state, value) {
    state.limitedCredit = value;
  },
  setUpdatedCredit(state, value) {    
    state.updatedCredit = value;
  },
  setChoosenRata(state, value) {
    state.choosenRata = value;
  },
  setUserData(state, { key, value }) {
    state.userData[key] = value;    
  },
  setFlashMessage(state, value) {
    state.flashMessage = value;
    state.flashMessageShowed = true;
  },
  resetFlashMessage(state) {
    state.flashMessage = null;
  },
  resetState(state) {
    state.userData = process.env.NODE_ENV === "development" ? getDataDev() : getData()
    state.protected = config.protected ?? false
    state.allowForms = false
  },
  loadTestData(state) {
    state.userData = getDataDev();
  },
  loadEmptyData(state) {
    state.userData = getData();
  },
  upadateUserCreditData(state) {
    if (router.currentRoute.name === "RataUpgrade") {
      state.userData.importo = state.limitedCredit;
    } else {
      state.userData.importo = state.choosenCredit;
    }
    state.userData.rate = state.choosenRata;
  },
  setAllowForms(state, value) {
    state.allowForms = value;
  },
  setProtected(state, value) {
    state.protected = value;
  },
  setPrecompiled(state, value) {
    state.precompiled = value;
  },  
  setPrepaidCards(state, value) {
    state.prepaidCards = value
  },
  setInsurance(state, value) {
    state.userData.insurance = value
  },
  setInsuranceAvailable(state, value) {
    state.userData.insurance_available = value
  },
  setInsurancePack(state, value) {
    state.userData.insurance_pack = value
  },
  setInsuranceRate(state, value) {
    state.userData.insurance_amount = value
  },
  setPath(state, value) {
    state.PATH = value
  },  
  setDebitDays(state, value) {
    state.debitDays = value
  },
  setEnvName(state, value) {
    state.envName = value
  },
  resetEnvName(state) {
    state.envName = null
  },  
  resetInsuranceConsent(state) {
    state.userData.insurance = false
    state.userData.insurance_amount = null
    state.userData.consent_main = null
    state.userData.consent1 = null
    state.userData.consent2 = null
    state.userData.consent3 = null
    state.userData.consent4 = null
    state.userData.consent5 = null
    state.userData.consent6 = null
    state.userData.consent7 = null
    state.userData.consent8 = null
  },
  resetPrivacy(state) {
    state.userData.privacy1 = null
    state.userData.privacy2 = null
    state.userData.privacy3 = null
    state.userData.privacy4 = null
    state.userData.politics = null
  },
};
