<template>
    <div class="container">
        <portal to="top-form">
            <div class="w-11/12 sm:max-w-7xl mx-auto -mt-8 sm:-mt-4 pt-12 px-2">
                <span @click.prevent="goBack()" class="cursor-pointer">
                    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="inline-block mr-2">
                        <path d="M16 7.84515C16.4668 7.84515 16.8452 7.46677 16.8452 7C16.8452 6.53323 16.4668 6.15485 16 6.15485V7.84515ZM0.402386 6.40239C0.0723324 6.73244 0.0723324 7.26756 0.402386 7.59761L5.78091 12.9761C6.11097 13.3062 6.64609 13.3062 6.97614 12.9761C7.3062 12.6461 7.3062 12.111 6.97614 11.7809L2.19523 7L6.97614 2.21909C7.3062 1.88903 7.3062 1.35391 6.97614 1.02386C6.64609 0.693803 6.11097 0.693803 5.78091 1.02386L0.402386 6.40239ZM16 6.15485L1 6.15485V7.84515L16 7.84515V6.15485Z" fill="black"/>
                    </svg>
                    <a href="#" class="text-sm font-semibold">{{ $t("Torna all'offerta") }}</a>
                </span>
            </div>
        </portal>
        <FormCard @submit="onSubmit" title="Le nostre condizioni" :defaultSubmit="false">
            <div class="w-full text-base">
                <p class="mt-2 text-center sm:text-left">Cofidis propone un’offerta di prestito personale con condizioni promozionali.</p>
                <p class="mt-2">Le condizioni economiche del prestito personale a tasso promozionale possono essere ottenute a condizione che il richiedente possieda i seguenti requisiti:</p>
                <ul class="list-disc px-4 my-2">
                    <li class="mt-2">Essere già un cliente Cofidis ossia un cliente che ha in corso o ha estinto, negli ultimi 5 anni, un finanziamento o un PagoDIL ed è sempre stato regolare nei pagamenti (ma non può avere un Prestito Personale Cofidis già in corso)</li>
                    <li class="mt-2">Richiedere un importo di €5.000 in 60 mesi</li>
                    <li class="mt-2">
                        Avere un profilo che rientra nei seguenti parametri:
                        <p class="ml-6 leading-tight">
                            <small class="text-13px block mt-2">Lavoratore dipendente con contratto a tempo indeterminato;</small>
                            <small class="text-13px block mt-2">Reddito mensile netto superiore o uguale a 2.500€;</small>
                            <small class="text-13px block mt-2">Casa di Proprietà.</small>
                        </p>
                    </li>
                </ul>
                <p class="mt-4">Ricorda che la richiesta di prestito è sempre soggetta ad approvazione di Cofidis e le
                    condizioni promozionali degli esempi forniti - su questo sito o attraverso altri canali di comunicazione
                    (es. TAEG “promo”) - potranno cambiare in base al profilo del richiedente e/o in funzione dell’importo e
                    della durata richiesti.</p>
                <p class="mt-4">Per coloro che non rientrano nei requisiti, saranno offerte condizioni differenti. A titolo
                    esemplificativo: importo pari a € 5.000 (Importo totale del credito) da rimborsare in 60 mesi, con rate
                    mensili di € 112,49, TAN fisso 12,50% - TAEG 13,37%. </p>
                <p class="mt-4">Per proporti un’offerta su misura, Cofidis consulterà i Sistemi di Informazione Creditizia
                    (SIC) indicati nell’<a href="https://www.cofidis.it/it/download/Informativa-Privacy.pdf" target="_blank" class="underline">Informativa Privacy</a>;
                    pertanto, alcuni dati personali che inserirai nel processo di richiesta saranno registrati nei SIC nel rispetto della normativa vigente.</p>
            </div>
        </FormCard>
    </div>
</template>
<script>
export default {
};
</script>
