import loadData from "@/dataService";
const config = loadData("config.json");

class GA {
    constructor(config) {
        this.enabled = config.enabled ? config.enabled : false;
        this.id = config.id ? config.id : -1;   
    }
    pageview() {
        if (this.enabled) {
            if (process.env.NODE_ENV === "development") {
                console.log("Mock GA pageview tracking " + window.location.pathname);
            } 
            else {                
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'Pageview',
                    'pagePath': window.location.pathname,
                    'pageTitle': window.location.pathname.replace('/','')
                });
            }
        }
    }
    event(title, data) {
        if (this.enabled) {
            if (process.env.NODE_ENV === "development") {
                console.log("Mock GA event tracking", title, data);
            } 
            else {                
                //window.gtag('event', title, data)
            }
        }   
    }
}
export default new GA(config.tracking.ga ?? {});