<template>
    <div>
        <FormCard @submit="onSubmit" title="Acquista la polizza facoltativa: 1° mese gratis!" submit-text="Continua con la polizza" :submit-class="$v.$invalid ? 'rounded-full py-3 px-16 font-semibold shadow-xl z-100 bg-gray-500 text-white cursor-not-allowed' : 'bg-yellow rounded-full py-3 px-16 font-semibold shadow-xl z-100 hover:bg-yellow-4'">
            <p class="font-bold mb-4">Valutazione delle tue esigenze assicurative e della coerenza della polizza</p>
            <p class="mb-4">
                Per proseguire occorre compilare il presente questionario che ha lo scopo di acquisire le informazioni utili a identificare le tue richieste ed esigenze assicurative e valutare la coerenza della polizza. In caso di mancata risposta non sarà possibile procedere con l’acquisto della polizza.
            </p>
            <ConsentItem v-model="consent_main" :v="$v.consent_main" :mustBeTrueError="mustBeTrueError">
                Sei interessato ad aquistare una polizza infortuni facoltativa per la protezione della tua persona?
            </ConsentItem>        
            <ConsentItem v-model="consent1" :v="$v.consent1" :mustBeTrueError="mustBeTrueError">
                Sei interessato ad acquistare una polizza che prevede un indennizzo a tuo favore in caso di ricovero ospedaliero, convalescenza successiva ad un ricovero, fratture, ustioni o lussazioni?
            </ConsentItem>
            <ConsentItem v-model="consent2" :v="$v.consent2" :mustBeTrueError="mustBeTrueError">
                La polizza presenta massimali (cioè la somma massima che l'Impresa assicuratrice si impegna a pagare) ed esclusioni di garanzia (cioè i rischi non coperti dalla polizza) che possono comportare il mancato pagamento della prestazione assicurata. Sei interessato ad una polizza che prevede tali limitazioni?
            </ConsentItem>
            <ConsentItem v-model="consent3" :v="$v.consent3" :mustBeTrueError="mustBeTrueError">
                Confermi di non essere portatore di invalidità permanente di grado pari o superiore ai 2/3 della capacità generica all'esercizio di un qualsiasi lavoro proficuo e remunerabile riconosciuta dall'INPS o da altro ente previdenziale pubblico?
            </ConsentItem>

            <p class="font-bold mb-4">Dichiarazioni e consensi relativi alla polizza assicurativa «MetLife Best Care»</p>
            <ConsentItemCheck v-model="consent4" :v="$v.consent4" :mustBeTrueError="mustBeTrueError">
                Dichiaro di aver preso visione del <a class="underline" :href="infoPdfUrl" target="_blank">set informativo</a> e acconsento all’invio della documentazione contrattuale e precontrattuale all’indirizzo e-mail fornito, con facoltà di chiederne gratuitamente copia in formato cartaceo alla Compagnia, come indicato all’art 2.1. delle Condizioni di assicurazione. Sono inoltre a conoscenza che eventuali comunicazioni rese in corso di contratto potranno essere effettuate via e-mail o attraverso il sito internet www.metlife.it.
            </ConsentItemCheck>
            <ConsentItemCheck v-model="consent5" :v="$v.consent5" :mustBeTrueError="mustBeTrueError">
                Autorizzo: i) MetLife Europe d.a.c. Rappresentanza generale per l’Italia a richiedere alla banca del sottoscritto l’addebito del proprio conto corrente fornito in occasione della richiesta di finanziamento; ii) la banca del sottoscritto a procedere all’addebito secondo le disposizioni impartite dalla Compagnia. Sono consapevole di aver diritto ad ottenere il rimborso dell’addebito in conto, secondo gli accordi ed alle condizioni che regolano il rapporto con la mia banca, richiedendolo entro 8 settimane a partire dalla data di addebito.
            </ConsentItemCheck>
            <ConsentItemCheck v-model="consent6" :v="$v.consent6" :mustBeTrueError="mustBeTrueError">
                Dichiaro di aver letto e compreso le finalità e modalità del trattamento dei dati da parte della Compagnia MetLife Europe d.a.c. Rappresentanza Generale per l’Italia, come indicato nell’<a class="underline" :href="privacyPdfUrl" target="_blank">informativa privacy</a> e acconsento al trattamento di categorie particolari di dati personali per l’emissione e gestione del contratto.
            </ConsentItemCheck>
            <ConsentItemCheck v-model="consent7" :v="$v.consent7" :mustBeTrueError="mustBeTrueError">
                Dichiaro di approvare specificamente, ai sensi degli artt. 1341 e 1342 c.c., i seguenti articoli: Art. 2 – Conclusione del Contratto / Inizio e termine del Programma Assicurativo; Art. 3 – Premio assicurativo; Art. 7 - Prescrizione; art. 8 - Risoluzione delle controversie; Art. 13 – Validità territoriale delle garanzie; Art. 14 – Persone assicurabili; Art. 15 – Esclusioni valide per tutte le garanzie; Artt. 16.2 e 19.2 – Esclusioni; Artt. 16.3, 17.2, 18.2 e 19.3 – Prestazione massima; Art. 20 – Denuncia di Sinistro; Art. 21 – Documenti da allegare alla denuncia di Sinistro.
            </ConsentItemCheck>
            <p class="font-bold my-4 uppercase">Completa l’acquisto della polizza «MetLife Best Care»</p>
            <ConsentItemCheck v-model="consent8" :v="$v.consent8" :mustBeTrueError="mustBeTrueError">
                 Dichiaro di aver compreso che la polizza è facoltativa e non è collegata al finanziamento e di voler procedere in ogni caso con l’acquisto della polizza. <strong class="uppercase">Se vuoi procedere con l’acquisto della polizza facoltativa clicca sul tasto continua qui sotto</strong>
            </ConsentItemCheck>
            <div slot="link" class="text-center text-gray-700 mt-4 mb-8">
                <span @click="onDecline()" class="underline uppercase cursor-pointer">Continua senza polizza</span>
            </div>
        </FormCard>
    </div>
</template>
<script>
import { mapUserData } from "@/utils";
import ConsentItem from "@/components/ConsentItem";
import ConsentItemCheck from "@/components/ConsentItemCheck";
import { required, helpers } from "vuelidate/lib/validators";

const mustBeTrue = value => !helpers.req(value) || value === true;
export default {
    data() {
        return {
            mustBeTrueError: 'ATTENZIONE: non sarà possibile procedere all’adesione alla polizza in quanto non coerente con le tue esigenze assicurative',
            infoPdfUrl: 'https://d4ybvu6hnmspp.cloudfront.net/metlife-best-care-set-informativo.pdf',
            privacyPdfUrl: 'https://d4ybvu6hnmspp.cloudfront.net/metlife-informativa-privacy.pdf'
        }
    },
    components: { ConsentItem, ConsentItemCheck },
    computed: {
        ...mapUserData([
          "employment_type", 
          "insurance", 
          "insurance_pack", 
          "insurance_amount",
          "consent_main",
          "consent1",
          "consent2",
          "consent3",
          "consent4",
          "consent5",
          "consent6",
          "consent7",
          "consent8"          
        ])
    },
    validations: {
        consent_main: {
            required,
            mustBeTrue
        },
        consent1: {
            required,
            mustBeTrue
        },
        consent2: {
            required,
            mustBeTrue
        },
        consent3: {
            required,
            mustBeTrue
        },
        consent4: {
            required,
            mustBeTrue
        },
        consent5: {
            required,
            mustBeTrue
        },
        consent6: {
            required,
            mustBeTrue
        },
        consent7: {
            required,
            mustBeTrue
        },
        consent8: {
            required,
            mustBeTrue
        }
    },
    methods: {
        onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid && this.consent_main === true) {
                this.$store.commit('setInsurance', true)
                this.$emit('submit', this.$event)

                //this.$store.dispatch("event", { name: "LoanProtectionConsentSubmit" });                
                this.$store.dispatch("redirect", { step: "metlife_consent", route: "PrivacyConsent" });                                    
            }
        },
        onDecline() {
            this.$emit('decline', this.$event)
            this.$store.dispatch("redirect", { step: "metlife_back", route: "MetlifeProtection" });
        }
    }
};
</script>