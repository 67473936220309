class Append {
    enter_landing(payload, {state}) {
        payload['CF'] = state.userData.fiscal_code
        payload['Iniz'] = state.userData.iniz
        payload['PianoF'] = state.userData.piano_f
        payload['userId'] = state.userData.user_id
        payload['codeCampaign'] = state.userData.code_campaign
        payload['nameCampaign'] = 'top-up'
    }

    dati_anag(payload) {
        delete(payload['EMail'])
        delete(payload['CF'])
        payload['stepNumber'] = 0
    }

    dati_lav(payload, {state}) {
        delete(payload['Mensilita'])
        payload['EMail'] = state.userData.email
        payload['stepNumber'] = 1
        // convert yyyy to mm/yyyy if needed
        if(payload['Occupato_Dal']?.toString().length === 4)
            payload['Occupato_Dal'] = `01/${payload['Occupato_Dal']}`
    }

    dati_pers_4(payload) {
        payload['stepNumber'] = 2
    }

    dati_privacy(payload, {state}) {
        if(state.userData.document_expired) {
            payload['stepNumber'] = 3
        }
        else {
            payload['stepNumber'] = 2
        }
    }

}
export default new Append();