class Append {
    enter_landing(payload, {state}) {
        payload['CF'] = state.userData.fiscal_code
        payload['userId'] = state.userData.user_id
        payload['codeCampaign'] = state.userData.code_campaign
    }

    vote(payload, {state}) {
        payload['cliente_id'] = state.userData.client_id
        payload['EMail'] = state.userData.email
        payload['Nome'] = state.userData.name
        payload['Cognome'] = state.userData.surname
        payload['data_call'] = state.userData.call_date
        payload['operatore'] = state.userData.operatore
        payload['dt_end_scs'] = state.userData.dt_end_scs
        payload['score'] = state.userData.vote
    }
}
export default new Append();