<template>
    <FormCard @submit="onSubmit" title="Dati di pagamento" :submitText="'CONTINUA'">
        <div class="text-sm w-full md:w-1/2 xl:pt-2 mb-4 md:mb-0 sm:flex flex-wrap items-center text-gray-700">
            <div class="md:pr-4">
                {{ $t("Scegli il giorno del mese per l'addebito della rata") }}
            </div>
            <div class="flex mx-auto md:ml-0 justify-center mt-2 sm:mt-0">
                <DayInMonth v-for="day in debitDays" v-model="day_in_month" class="mr-2" :hasError="$v.day_in_month.$error" :disabled="disabled.day_in_month" :value="day.value" :label="day.label" :key="day.value"></DayInMonth>
            </div>
            <div class="h-6 w-full text-red">
                <small v-if="$v.day_in_month.$error && !$v.day_in_month.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-full md:w-1/2 text-gray-700">
            <FloatingLabelInput :name="`iban`" v-model="iban" :mask="ibanMask" :label="`IBAN del tuo conto corrente`" :error="$v.iban.$error" :disabled="disabled.iban" />
            <div class="h-auto w-full text-red leading-4 sm:leading-5 mt-1 sm:mt-0">
                <small v-if="$v.iban.$error && !$v.iban.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
                <small v-else-if="$v.iban.$error && !$v.iban.validIBAN">
                    {{ $t("Codice IBAN non valido") }}
                </small>
                <small v-else-if="$v.iban.$error && !$v.iban.prepaidIBAN">
                    {{ $t("Codice IBAN non valido") }}
                </small>
            </div>
            <small v-if="!prepaidCards" class="block mt-2 mb-1">{{ $t("(IBAN carte prepagate e conti deposito non accettati)") }}</small>
            <div class="text-xs mt-2">
                {{ $t("Non ricordi l’IBAN?") }}
                <a href="#" @click.prevent="showHelp = !showHelp" class="underline">{{ $t("Clicca qui") }}</a>
                {{ $t("per un aiuto") }}
            </div>
        </div>
        <SlideUpDown :active="showHelp" class="w-full">
            <div class="text-gray-700 border-t mt-5 pt-5 flex flex-wrap">
                <div class="w-full md:w-1/3">
                    <h3 class="font-semibold mb-4">{{ $t("Dove posso trovare il codice IBAN?") }}</h3>
                </div>
                <div class="w-full md:w-1/3">
                    <p class="text-sm">{{ $t("Puoi trovare il tuo codice IBAN:") }}</p>
                    <ul class="list-disc px-6 my-2 text-sm">
                        <li>{{ $t("all’interno del servizio home banking della tua banca") }}</li>
                        <li>{{ $t("sull’estratto conto fornito dalla tua banca") }}</li>
                        <li>{{ $t("su una tua recente busta paga") }}</li>
                    </ul>
                </div>
                <div class="w-full md:w-1/3">
                    <span class="block pb-2 text-sm">{{ $t("Ad esempio, un codice IBAN corretto è:") }}<br>{{ $t(" IT 12 A 12345 54321 123456789012*") }}</span>
                    <span class="block pb-4 text-xs">*Il formato del codice IBAN potrebbe variare in base alla nazione di appartenenza della tua banca</span>
                </div>
            </div>
        </SlideUpDown>
    </FormCard>
</template>
<script>
import { mapUserData } from "@/utils";
import { ibanMask } from "@/masks";
import { validIBAN, notPrepaidIBAN } from "@/utils";
import { required } from "vuelidate/lib/validators";
import DayInMonth from "@/components/DayInMonth";
import FloatingLabelInput from "@/components/FloatingLabelInput";

export default {
    components: { FloatingLabelInput, DayInMonth },
    mounted() {
        this.$ga.pageview()
        // select default when only one option is provided
        if (this.debitDays.length == 1) {
            this.$store.state.userData.day_in_month = this.debitDays[0].value
            this.disabled.day_in_month = true
        }
        if (this.$store.state.precompiled) {
            if (this.iban) this.disabled.iban = true
        }
    },
    data() {
        return {
            ibanMask,
            showHelp: false,
            showPEPExplanation: false,
            disabled: { 
                iban: false,
                day_in_month: false,
            }
        };
    },
    computed: {
        debitDays() {
            return this.$store.state.debitDays
        },
        prepaidCards() {
            return this.$store.state.prepaidCards
        },
        ...mapUserData(["day_in_month", "iban"])
    },
    validations: {
        day_in_month: {
            required
        },
        iban: {
            required,
            validIBAN,
            notPrepaidIBAN
        }
    },
    methods: {
        onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                this.$store.dispatch("event", { name: "PaymentDetailsSubmit" })
                this.$store.dispatch("sendData", { step: "dati_paga" });
                this.$store.dispatch("redirect", { step: "dati_paga", route: "PrivacyConsent" });
            }
        }
    }
};
</script>