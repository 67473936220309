class Hooks {
    dati_pers_4(router) {
        router.push({ name: "PrivacyPowerCredit" })
    }

    dati_metlife_1(router) {
        router.push({ name: "MetlifePower"})
    }
    metlife(router) {
        router.push({ name: "MetlifePowerConsent"})
    }
    metlife_ko(router) {
        router.push({ name: "MetlifePowerWarning"})
    }
    metlife_warning(router) {
        router.push({ name: "MetlifePowerConsent"})
    }
    metlife_submit(router) {
        router.push({ name: "PrivacyPowerCredit"})
    }
    metlife_consent(router) {
        router.push({ name: "PrivacyPowerCredit"})
    }
    metlife_back(router) {
        router.push({ name: "MetlifePower"})
    }

    dati_privacy(router, {state}) {
        if (state.userData.can_request)
            router.push({name: "ThankYou"})

        else if(state.userData.insurance && state.userData.insurance_pack == 'Metlife' )
            router.push({name: "TypMetlife"})

        else 
            router.push({name: "RequestKO"})
    }
}
export default new Hooks();