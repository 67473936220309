<template>
    <div v-if="closed" class="rounded-full py-1 px-6 border border-gray-3 w-full lg:w-1/2 mx-auto relative cursor-pointer xs:text-xs sm:text-lg" @click="$emit('open')">
        <p><strong>{{ model.imp_finanz_text }}</strong> rata <strong>{{ model.tot_rata }}€</strong> per <strong>{{ model.rate }}</strong> mesi <strong>TAEG {{ model.taeg }}%</strong></p>
        <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg" class="absolute top-2 sm:top-3 right-3">
            <path d="M13.5008 1.6116L7.35588 8.38867L1.21094 1.6116" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>
    <div v-else class="promobox rounded-3xl border border-yellow px-4 sm:px-6 pb-6 w-full lg:w-1/2 mx-auto relative text-base sm:text-lg lg:text-xl" :class="showFlag ? ' pt-5' : 'pt-1'">
        <template v-if="showFlag && flagText">
            <div class="promobox-flag absolute -top-3 -left-3 w-fit flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24" fill="none" class="absolute">
                    <path d="M3.43848 13.3723C0.938477 8.87225 -0.868824 6.52744 0.89583 0.755859L10.8884 1.16532C13.1638 1.25856 14.5405 3.77977 13.6647 5.88197C12.1806 9.44446 11.4385 12.8855 11.4385 15.3723V23.8723C9.93848 22.7056 5.50346 17.0892 3.43848 13.3723Z" fill="#208664"/>
                </svg>
                <div class="promobox-flag-bg absolute w-full h-full bg-green-1"></div>
                <span class="promobox-flag-text relative text-white pl-5 pr-3 pb-0.5 leading-none italic font-semibold text-sm">{{ flagText }}</span>
            </div>
        </template>

        <div class="flex flex-wrap border-b border-gray-3 w-full pb-3" :class="showFlag ? 'pt-1' : 'pt-3'">
            <div class="flex-none whitespace-no-wrap text-base sm:text-lg">
                <span v-if="model.type == 'rata'">
                    Rata <strong class="font-semibold">{{ model.tot_rata }} €</strong>
                </span>
                <span v-else class="font-semibold">
                    {{ model.imp_finanz_text }}
                </span>
            </div>
            <div class="flex-grow"></div>
            <div class="flex-none">
                <a class="sm:text-base underline mr-4" target="_blank" :href="model.secciUrl">SECCI</a>
            </div>
        </div>
        <div class="flex flex-wrap w-full py-3 leading-tight sm:text-lg items-center">
            <template v-if="model.type == 'rata'">
                <p class="flex-none"><strong>{{ model.imp_finanz_text }}</strong> per <strong>{{ model.rate }}</strong> mesi <strong>TAEG {{ model.taeg }}%</strong></p>
            </template>
            <template v-else>
                <p class="flex-none">Rata <strong>{{ model.tot_rata }}€</strong> per <strong>{{ model.rate }}</strong> mesi <strong>TAEG {{ model.taeg }}%</strong></p>
            </template>
            <div class="flex-grow"></div>
            <div v-if="hasDetails" class="flex-none sm:pt-1 cursor-pointer" @click="showDetails = !showDetails">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8.59766" cy="8.09766" r="7.40845" stroke="#252525" stroke-width="0.987793"/>
                    <path d="M8.00391 12.0494V6.6165H9.18827V12.0494H8.00391ZM8.00391 5.69292V4.44336H9.18827V5.69292H8.00391Z" fill="#252525"/>
                </svg>
            </div>
        </div>
        <SlideUpDown :active="showDetails">
            <small class="block text-sm leading-normal md:text-base pb-3">
                <slot name="details"></slot>
            </small>
        </SlideUpDown>
        <button :disabled="loading" class="bg-accent rounded-full py-3 w-3/4 sm:w-auto px-12 font-semibold shadow-xl z-100 text-sm absolute -bottom-6 left-1/2 transform -translate-x-1/2" @click="$emit('click')">
            <img src="@/assets/images/spinner.gif" class="w-6 h-6" v-if="loading"  />
            <span v-else>{{ $t(cta) }}</span>
        </button>
    </div>
</template>
<style scoped>
.promobox-flag svg {
    top: 18px;
}
.promobox-flag {
    height: 26px;
}
.promobox-flag-bg {
    border-radius: 15px 3px 3px 3px;
    transform: skewX(-13deg) translateX(2px);
    height: 100%;
}

</style>
<script>
    export default {
        name: "PromoBox",
        props: {
            model: {
                type: Object,
                required: true
            },
            hasDetails: {
                type: Boolean,
                required: true
            },
            loading: {
                type: Boolean,
                required: true
            },
            cta: {
                type: String,
                default: 'CONTINUA'
            },
            closed: {
                type: Boolean,
                default: false
            },
            showFlag: {
                type: Boolean,
                default: true
            },
            flagText: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                showDetails: false
            }
        }
    }
</script>