import i18n from './i18n'

export const phoneMask = {
    mask: '+3\\9 A9{5,9}',
    placeholder: '_',
    showMaskOnHover: false,
    definitions: { A: { validator: '[0|3]' } }
}
export const cellphoneMask = {
    mask: '+3\\9 A9{2} 9{6,7}',
    placeholder: '_',
    showMaskOnHover: false,
    definitions: { A: { validator: '[3]' } }
}
export const fiscalCodeMask = {
    mask: '(A{6}9{2}A99A9{3}A)|(A{6}9{2}A99A9A9A)|(A{6}9{2}A99A9{2}AA)|(A{6}9{2}A99A9A{3})|(A{6}9{2}A99A{5})|(A{6}9{2}A9A{6})|(A{6}9{2}A{8})|(A{6}9A{9})|(A{16})',
    definitions: { A: { validator: '[A-z]', casing: 'upper' } },
    showMaskOnHover: false
}
export const currencyMask = {
    // mask: ["€ A9{0,2}", "€ A.9{1,3}"],
    // definitions: { A: { validator: "[1-9]" } },
    // unmaskAsNumber: true,
    // removeMaskOnSubmit: true    
    prefix: "€ ",
    radixPoint: ",",
    groupSeparator: ".",
    integerDigits: 4,
    alias: "numeric",
    placeholder: "_",
    autoGroup: true,
    digits: 0,
    clearMaskOnLostFocus: true,
    clearIncomplete: true,
    showMaskOnHover: false,
    autoUnmask: true,
    rightAlign: false,
    onUnMask: function(maskedValue, unmaskedValue) {
        return unmaskedValue.replace(/€ /g, "").replace(/\./g, "")
    }
}
export const dateMask = {
    alias: "dd/mm/yyyy",
    inputFormat: "dd/mm/yyyy",
    placeholder: i18n.t("gg/mm/aaaa"),
    clearIncomplete: true
}
export const shortDateMask = {
    inputFormat: "mm/yyyy",
    placeholder: i18n.t("mm/aaaa"),
    alias: "mm/yyyy",
    clearIncomplete: true
}
export const ibanMask = {
    showMaskOnHover: false,
    mask: "AA 99 *{11,27}",
    casing: "upper"
}
export const identityCardMask = {
    regex: "([A-Z]{2}[0-9]{6})|([A-Z]{2}[0-9]{7})|([0-9]{7}[A-Z]{2})|([C][A-Z][0-9]{5}[A-Z]{2})",
    showMaskOnHover: false,
    clearIncomplete: true,
    casing: "upper"
}
export const passportMask = {
    regex: "[A-Z]{2}[0-9]{7}",
    showMaskOnHover: false,
    clearIncomplete: true,
    casing: "upper"
}
export const drivingLicenseMask = {
    regex: "([A-Z]{2}[0-9]{7}[A-Z])|([U][1]([0-9]|[A-Z])([0-9]|[A-Z])([0-9]|[A-Z])([0-9]|[A-Z])([0-9]|[A-Z])([0-9]|[A-Z])([0-9]|[A-Z])[A-Z])",
    showMaskOnHover: false,
    clearIncomplete: true,
    casing: "upper"
}