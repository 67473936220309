<template>
    <div class="w-full pt-3">
        <ProgressPz slot="form-header" progress="85" />
        <div class="w-11/12 sm:max-w-7xl mx-auto shadow-lg rounded-3xl bg-white mt-5 mb-10 pb-10">
            <Promo>
                <template #subtitle>
                    <p class="text-base text-center sm:text-left">Ultimo step: richiedi più liquidità o conferma la scelta precedente</p>
                </template>
            </Promo>
        </div>
    </div>
</template>
<script>
import Promo from "@/views/forms/Promo.vue"
import ProgressPz from "./ProgressPz.vue"

export default {
    components: { Promo, ProgressPz }
};
</script>
