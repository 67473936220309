import { v1 as uuidv1 } from "uuid";

export default function() {
  return {
    hasError: false,
    pendingOrder: false,

    orderID: uuidv1(),
    importo: 10000,
    rate: null,
    name: "TEST",
    email: "test@test.com",
    surname: "TEST",
    cellphone: "+39 333 1234567",
    fiscal_code: "TSTTST80A01F205J",

    stay_from: null,
    stay_to: null,

    marital_status: 1,
    financial_problems: false,
    citizenship: "ITA",
    dwelling_type: 5,
    monthly_rent: null,
    address: "TEST",
    address_number: "11/A",
    address_cap: "20133",
    address_multicap: [],
    address_city: "MILANO (MI)",
    resident_since: "01/1980",
    same_addresses: true,
    address2: "",
    address_number2: "",
    address_cap2: "",
    address_multicap2: [],
    address_city2: "",

    identity_type: 1,
    document_number: "AA1234567",
    document_city: "MILANO (MI)",
    document_expire: '01/01/2025',
    document_release: '01/01/2020',

    employment_type: 2,
    employment_type_text: 'LAVORATORE DIPENDENTE',
    family_member_count: 1,
    employed_family_member_count: 1,
    employment_sector: 2,
    employment_sector_text: 'BANCARIO, CREDITO',
    occupation: 2,
    occupation_text: 'DIRIGENTE/QUADRO',
    monthly_payment: 4,
    monthly_payment_text: '14',
    monthly_income: "3000",
    employed_since: "02/2015",
    employed_to: null,
    hiring_type: 2,
    hiring_type_text: 'Indeterminato',

    family_income: 1,
    family_income_activity: 1,

    employer_name: "TEST",
    employer_address: "TEST",
    employer_street_number: "3",
    employer_postal_code: "20133",
    employer_multicap: [],
    employer_city: "MILANO (MI)",
    employer_phone: "+39 3331234567",
    cessione_quinto: null,

    // loan insurance
    insurance: false,
    insurance_amount: null,
    insurance_pack: null,
    insurance_available: false,

    // club/club plus
    service: false,
    service_amount: null,
    service_pack: null,
    service_available: false,

    consent_main: null,
    consent1: null,
    consent2: null,
    consent3: null,
    consent4: null,
    consent5: null,
    consent6: null,
    consent7: null,
    consent8: null,

    consent_club1: null,
    consent_club2: null,
    consent_club3: null,
    consent_club4: null,
    consent_club5: null,
    consent_club5a: null,
    consent_club5b: null,
    consent_club5c: null,
    consent_club5d: null,

    day_in_month: 1,
    iban: "IT44C0300203280174225259217",

    privacy1: true,
    privacy2: true,
    privacy3: true,
    privacy4: true,
    politics: false,

    exp_date: null,

    // consolidamento
    loans_count: 1,
    loans_data: [{
      amount: "12000",
      installments_amount: "290",
      installments_count: "48",
      first_installment: '01/2022',
      provider: 0
    }],

    income_check: null,

    // consolidamento-crm
    provided_amount: null,
    extra_amount: null,
    taeg_conso: null,
    tan_conso: null,
    installments_diff: null,
    installment_amount: null,
    amount_1: null,
    amount_2: null,
    amount_owed: null,
    installments_number: null,
    taeg: null,
    tan: null,

    // riutilizzi-crm
    max_amount: null

  };
}
