<template>
    <div data-model="importo_2" action="next" method="POST" class="my-4 sm:my-8 proposals text-gray-700 lg:text-xl xl:text-2xl">
        <div class="px-2 sm:px-16 sm:px-4 mx-auto lg:container pt-6"> 
            <ul>
                <ProposalOur :importo="600" :text="text600" :textAlt="text600Alt" :active="!upgrade" @rataClick="rataClick" :url="secciUrl600" @click.native="upgrade = false"></ProposalOur>
            </ul>
            <h2 class="text-orange text-center font-semibold text-xl sm:text-3xl mb-4 mt-10">{{ $t("Ottieni subito un importo maggiore!") }}</h2>
            <h3 class="text-base text-center mb-6">{{ $t("La nostra proposta di linea di credito ricaricabile per te") }}</h3>
            <div class="mb-12">
                <ul>
                    <ProposalOur :importo="2000" :text="text2000" :textAlt="text2000Alt" :active="upgrade" @rataClick="rataClick" @click.native="upgrade = true" :url="secciUrl2000"></ProposalOur>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import ProposalOur from "@/components/ProposalOur";
export default {
    components: { ProposalOur },
    data() {
        return {
            upgrade: true
        }
    },
    computed: {
        text600() {
            return `Rata <strong>60 €</strong> per <strong>10</strong> mesi <strong>TAEG 0,00%</strong>`
        },
        text600Alt() {
            return `<span class="md:hidden"><strong>600</strong> €</span> <span class="md:capitalize">rata</span> <strong>60 €</strong> per <strong>10</strong> mesi TAEG <strong>0,00%</strong>`
        },
        text2000() {
            return `Rata <strong>60 €</strong> TAEG <strong>21,70%</strong>`
        },   
        text2000Alt() {
            return `<span class="md:hidden"><strong>2.000</strong> €</span> <span class="md:capitalize">rata</span> 60 € TAEG 21,70%`
        },     
        secciUrl600() {
            return process.env.VUE_APP_ENV == 'production' ?
                'https://cofidis-secci.s3.eu-central-1.amazonaws.com/revolving/prod/SECCI_Revolving.pdf' :
                'https://cofidis-secci.s3.eu-central-1.amazonaws.com/revolving/stage/SECCI_Revolving.pdf'
        },     
        secciUrl2000() {
            return process.env.VUE_APP_ENV == 'production' ?
                'https://cofidis-secci.s3.eu-central-1.amazonaws.com/revolving/prod/SECCI_REV_1120_compilato_no_promo.pdf' :
                'https://cofidis-secci.s3.eu-central-1.amazonaws.com/revolving/stage/SECCI_REV_1120_compilato_no_promo.pdf'
        }
    },
    methods: {
        rataClick(value, rate) {
            this.$store.commit("setUpdatedCredit", value == 2000)
            
            this.$store.state.userData.rate = rate
            this.$store.state.userData.importo = value
        }
    }
};
</script>