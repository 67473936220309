<template>
    <PromoLoading />
</template>
<script>
import PromoLoading from "@/views/forms/PromoLoading.vue"

export default {
    components: { PromoLoading }
};
</script>
