<template>
    <FormBox title="CLUB PLUS" @submit="onSubmit()" declineText="Procedi senza Club" @decline="onDecline()" >
    
        <ProgressPz slot="form-header" progress="80" />

        <p class="mb-4 text-base">
            Cofidis ti regala il primo mese di <strong>accesso gratuito al CLUB PLUS</strong>.
            Puoi scegliere <strong>esperienze uniche in formula 2x1</strong> (ad esempio cena al ristorante per 2 persone al prezzo di 1
            oppure 2 ingressi al cinema o alla SPA pagandone solo 1) a soli 9,90 euro/mese, con addebito diretto sul tuo conto corrente.
            <a :href="infoClubUrl" target="_blank" class="underline">Scopri qui</a> tutti i vantaggi del CLUB PLUS.
        </p>

    </FormBox>
</template>
<script>
import FormBox from "./FormBox";
import ProgressPz from "./ProgressPz";

export default {
    components: { FormBox, ProgressPz },
    data() {
        return {
            infoClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Cofidis_Club_PLUS.pdf'
        }
    },
    mounted() {
        this.$store.commit("setUserData", { key: 'service_available', value: true });
        this.$store.commit("setUserData", { key: 'service_pack', value: 'CLPLS' });
        this.$store.commit("setUserData", { key: 'service_amount', value: 9.90 });

        this.$store.dispatch("sendData", { step: "club" })
    },
    methods: {
        onSubmit() {
            this.$store.dispatch("redirect", { step: "club_plus", route: "ClubPlusPzConsent" });
        },
        onDecline() {
            this.$store.dispatch("redirect", { step: "club_plus_warning", route: "ClubPlusPzWarning" });
        }
    }
};
</script>