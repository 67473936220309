class Events {
    data(state) {
        let data = {
            event: 'pageview',
            details: {
                page_path: window.location.pathname,
                loans_number: state.userData.loans_count
            }
        }
        if(state.userData.email) {
            data.details.email = state.userData.email
        }
        if(state.choosenCredit) {
            data.details.flusso = 'B'
            data.details.amount = state.choosenCredit
        }
        if(state.choosenSolution) {
            data.details.flusso = 'A'
            data.details.solution = state.choosenSolution
        }
        if(state.PATH) {
            if(state.PATH == 'FE')
                data.path = 'Prestito Personale'
            else
                data.path = 'Metlife'
        }
        return data
    }
    // Events
    ConsolidamentoHome(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    ConsolidamentoEmail(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    ConsolidamentoInfoPrestiti(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    ConsolidamentoSoluzioni(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    FormImportoSubmit(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    RataClick(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    RataUpgradeClick(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    ProposalLimitedSubmit(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    ContattiSubmit(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    PersonalData1Submit(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    DeliveryDataSubmit(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    EmployerDataSubmit(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    IdentityCardSubmit(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    IncomeDataSubmit(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    LoanProtectionSubmit(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    LoanProtectionConsentSubmit(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    LoanProtectionWarningSubmit(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    PaymentDetailsSubmit(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    ConsolidamentoTyp(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    ConsolidamentoTypKO(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    ConsolidamentoTypMetlifeOK(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
    ConsolidamentoTypMetlifeKO(state, vm) {
        vm.$gtm.dispatch("pageview", this.data(state))
    }
}

export default new Events();