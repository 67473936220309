<template>
    <div>
        <FormCard @submit="onSubmit" title="Scegli la polizza facoltativa: 1° mese gratis!">
            <p class="text-red font-bold mb-4">
                Non perdere questa occasione! Proteggiti da circostanze impreviste della vita e rimani tranquillo contro incertezze future. Per te il primo mese di protezione è gratuito con la polizza infortuni «MetLife Best Care» di MetLife Italia.
            </p>
            <p class="mb-4">La soluzione che ti proponiamo, con soli 14,90 euro di premio mensile, ti protegge in caso di:</p>
            <ul class="list-disc ml-4 mb-4">
                <li>
                    <b>Ricovero:</b> ricevi una somma giornaliera pari a 100€ per ogni giorno di degenza in seguito ad infortunio
                </li>
                <li>
                    <b>Convalescenza post ricovero:</b> beneficia di una somma giornaliera pari a 50€ per ogni giorno di convalescenza 
                </li>
                <li>
                    <b>Ricovero in reparto di terapia intensiva:</b> ricevi una somma pari a 100€ per ogni giorno di ricovero 
                </li>
                <li>
                    <b>Fratture, ustioni e lussazioni:</b> ricevi un indennizzo pari a 500€ a seguito di questi infortuni.
                </li>
            </ul>
            <p class="mb-4">Consulta il <a class="underline" :href="infoPdfUrl" target="_blank">set informativo</a> per conoscere nel dettaglio tutte le caratteristiche del prodotto.</p>
            <div slot="link" class="text-center text-gray-700 mt-4 mb-8">
                <button @click="onDecline()" class="underline uppercase cursor-pointer">Continua senza polizza</button>
            </div>
        </FormCard>
    </div>
</template>
<script>
import { mapUserData } from "@/utils";

export default {
    data() {
        return {
            infoPdfUrl: 'https://d4ybvu6hnmspp.cloudfront.net/metlife-best-care-set-informativo.pdf',
        }
    },
    computed: {
        ...mapUserData([
          "insurance", 
          "insurance_pack", 
          "insurance_available"
        ])
    },
    mounted() {
        this.$ga.pageview()
        this.$store.dispatch("sendData", { step: "assicurazione_metlife_warning" })
    },
    methods: {
        onDecline() {
            this.$store.commit("resetInsuranceConsent")
            this.$emit('decline', this.$event)
            this.$store.dispatch("redirect", { step: "metlife_submit", route: "PrivacyConsent" })
        },
        onSubmit() {
            this.$emit('submit', this.$event)
            //this.$store.dispatch("event", { name: "LoanProtectionSubmit" });
            this.$store.dispatch("redirect", { step: "metlife_warning", route: "MetlifeProtectionConsent" });
        }
    }
};
</script>