<template>
    <div class="container mx-auto p-4 text-sm sm:text-base leading-tight text-gray-700">
        <div class="py-12 text-center text-2xl" :style="{
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundColor: '#F3F3F2'
          }">
            {{ $t("Gentile") }} <strong>{{ name }}</strong>, {{ $t("grazie per averci scelto!") }}
        </div>
        <div class="max-w-4xl text-lg mx-auto p-4">
            <h1 class="text-3xl font-bold text-center mt-12 mb-8">
                {{ $t("I PROSSIMI PASSI") }}
            </h1>
            <p class="mb-4">
                {{ $t("1. A breve riceverai l’e-mail con il primo esito di fattibilità e, in caso di esito positivo, riceverai nella tua casella di posta elettronica il contratto. Per firmarlo bastano pochi clic e l’inserimento di un codice che riceverai via SMS.") }}
            </p>
            <p class="mb-4">
                {{ $t("2. Firma subito il contratto in pochi click allegando le foto dei documenti richiesti (puoi anche scattare una foto dal tuo cellulare).") }}
            </p>
            <p class="mb-4">
                {{ $t("3. Una volta inviata la documentazione completa e corretta, riceverai l’esito definitivo della tua richiesta.") }}
            </p>
            <div class="text-center">
                {{ $t("A presto") }}<br />
                <strong>{{ $t("La tua Cassa di Risparmio") }}</strong>
            </div>
        </div>
    </div>
</template>
<script>
import { mapUserData } from "@/utils";

export default {
    mounted() {
        this.$ga.pageview()
    },
    computed: {
        ...mapUserData(["name"])
    }
};
</script>
