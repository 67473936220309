<template>
    <FormBox title="Stato civile e abitazione" @submit="onSubmit()" @decline="goBack()" >

        <ProgressPz slot="form-header" progress="70" />

        <!-- stato civile -->
        <div class="w-full grid grid-cols-3 gap-2 md:gap-0 md:grid-cols-6 lg:max-w-3xl mx-auto">                
            <MaritalStatus v-model="marital_status" :label="$t('Celibe/Nubile')" :hasError="$v.marital_status.$error" :value="1"></MaritalStatus>
            <MaritalStatus v-model="marital_status" :hasError="$v.marital_status.$error" :label="$t('Divorziato/a')" :value="2"></MaritalStatus>
            <MaritalStatus v-model="marital_status" :hasError="$v.marital_status.$error" :label="$t('Coniugato/a')" :value="3"></MaritalStatus>
            <MaritalStatus v-model="marital_status" :label="$t('Convivente')" :hasError="$v.marital_status.$error" :value="4"></MaritalStatus>
            <MaritalStatus v-model="marital_status" :label="$t('Separato/a')" :hasError="$v.marital_status.$error" :value="5"></MaritalStatus>
            <MaritalStatus v-model="marital_status" :hasError="$v.marital_status.$error" :label="$t('Vedovo/a')" :value="6"></MaritalStatus>
        </div>
        <div class="text-red text-center mb-10">
            <small v-if="$v.marital_status.$error && !$v.marital_status.required">
                {{ $t("Campo obbligatorio") }}
            </small>
        </div>

        <!-- abitazione -->
        <div class="mt-12 w-full flex flex-wrap">
            <div class="w-full sm:w-1/2 sm:pr-2 mb-2">
                <FloatingLabelSelect :name="`dwelling_type`" v-model="dwelling_type" :items="dwelling_types" :label="`Tipologia di abitazione`" :error="$v.dwelling_type.$error" :disableZeroValue="true"/>
                <div class="text-red" v-if="$v.dwelling_type.$error">
                    <small v-if="!$v.dwelling_type.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                </div>
            </div>
            <div class="w-full sm:w-1/2 mb-2" v-if="showMonthlyRent">
                <FloatingLabelInput :name="`monthly_rent`" v-model="monthly_rent" :mask="currencyMask" :label="`Affitto mensile`" :error="$v.monthly_rent.$error" :type="`tel`" />
                <div class="text-red" v-if="$v.monthly_rent.$error">
                    <small v-if="!$v.monthly_rent.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                    <small v-else-if="!$v.monthly_rent.nonZero">
                        {{ $t("Il valore 0 non è ammesso") }}
                    </small>
                </div>
            </div>
        </div>
        <div class="w-full sm:w-1/2 mt-3">
            <label class="text-xs">{{ $t("Residente dal") }}</label>
            <DateSelect :value="resident_since" :showDay="false" :inputClass="{ 'border-red': $v.resident_since.$error }" :startYear="residentSinceStartYear" @change="updateResidentSinceValue" />
            <div class="h-6 w-full text-red" v-if="$v.resident_since.$error">
                <small v-if="!$v.resident_since.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
                <small v-else-if="!$v.resident_since.minValue">
                    {{ $t("Data non valida") }}
                </small>
                <small v-else-if="!$v.resident_since.maxValue">
                    {{ $t("Data non valida") }}
                </small>
            </div>
        </div> 
    </FormBox>
</template>
<script>

import FormBox from "./FormBox"
import ProgressPz from "./ProgressPz";
import DateSelect from "@/components/DateSelect";
import FloatingLabelSelect from "@/components/FloatingLabelSelect";
import FloatingLabelInput from "@/components/FloatingLabelInput";
import MaritalStatus from "@/components/MaritalStatus";
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapUserData, getBirthday, nonZeroIf } from "@/utils";
import { currencyMask } from "@/masks";
import moment from "moment";

export default {
    components: { FormBox, ProgressPz, DateSelect, FloatingLabelSelect, FloatingLabelInput, MaritalStatus },
    mounted() {
    },
    data() {
        return {
            currencyMask
        };
    },
    computed: {
        ...mapUserData([
            "marital_status",
            "resident_since",
            "dwelling_type",
            "monthly_rent"
        ]),
        residentSinceStartYear() {
            return parseInt(moment(getBirthday(), 'MM/YYYY').format('YYYY'))
        },
        showMonthlyRent() {
            return this.dwelling_type == 1
        },
        dwelling_types() {            
            return [
                {key: null, value: "Tipologia di abitazione"},
                {key: 1, value: "IN AFFITTO"},
                {key: 2, value: "CON GENITORI, PARENTI"},
                {key: 5, value: "DI PROPRIETÀ"},
            ]
        }
    },
    validations: {
        marital_status: {
            required
        },
        resident_since: {
            required,
            minValue: value => {
                return moment(value, 'MM/YYYY') >= moment(getBirthday(), 'MM/YYYY')
            },
            maxValue: value => {
                return moment(value, 'MM/YYYY') <= moment()
            }
        },
        dwelling_type: {
            required
        },
        monthly_rent: {
            required: requiredIf("showMonthlyRent"),
            nonZero: nonZeroIf("showMonthlyRent")
        }
    },
    watch: {
        dwelling_type() {
            this.monthly_rent = null
            this.$v.$reset()
        }
    },
    methods: {
        updateResidentSinceValue(value) {
            this.resident_since = value
        },
        onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                this.$store.dispatch("sendData", {step: "dati_pers_1" });
                this.$store.dispatch("sendData", { step: "dati_pers_2" });
                this.$store.dispatch("redirect", { step: "dati_pers_1", route: "ClubPz" });
            }
        }
    }
};
</script>