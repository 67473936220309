<template>
    <div class="pb-8 md:pb-4">
        <Steps :count="stepCount" :active="1" class="my-5" />
        <form @submit.prevent class="relative text-sm pl-5 pr-6 pt-8 pb-10 md:px-12 md:pb-10 mb-12 mx-4 md:mx-auto bg-white lg:container max-w-4xl lg:max-w-5xl xl:max-w-7xl text-gray-700 shadow-2xl rounded-3xl">
            <h1 class="text-2xl md:text-3xl font-semibold text-center mb-8">Completa la tua richiesta</h1>
            <div class="mb-10">
                <p class="text-base mb-4">
                    Prima di procedere ti invitiamo a prendere visione del <a :href="rulesClubUrl" target="_blank" class="underline">Regolamento</a>
                    che riporta nel dettaglio, le caratteristiche del prodotto, nonché l’<a :href="privacyClubUrl" target="_blank" class="underline">Informativa Privacy</a>. Il servizio CLUB è facoltativo e non è necessario per ottenere l'aumento fido e il riutilizzo della linea di credito, né per ottenerli a condizioni più vantaggiose.
                </p>
                <ConsentItemCheck v-model="consent_club1" :v="$v.consent_club1" :mustBeTrueError="mustBeTrueError">
                    <span class="leading-6">
                        Ho letto e compreso le condizioni del Servizio CLUB contenute nel Regolamento contrattuale, accettando integralmente le sue condizioni senza riserva alcuna, compresa l’autorizzazione a Cofidis a procedere al pagamento per mio conto del costo annuale del servizio anche in caso di rinnovo a Quattrolinee S.r.l. con sede in corso Vittorio Emanuele II 25, Torino, e dichiaro di essere interessato a sottoscrivere la proposta del servizio.
                    </span>
                </ConsentItemCheck>
                <ConsentItemCheck v-model="consent_club2" :v="$v.consent_club2" :mustBeTrueError="mustBeTrueError">
                    <span class="leading-6">
                        Ai sensi degli articoli 1341 e 1342 del Codice Civile dichiaro di avere attentamente esaminato e di accettare integralmente le clausole del Contratto riportate nel Regolamento contrattuale, di seguito elecante: <i>Art. 4 Obblighi delle Parti, Art. 5 Gestione Dati, Art. 6 Corrispettivo e modalità di pagamento, Art. 7 Responsabilità, Art. 8 Recesso, Art. 9 Risoluzione, Art. 10 Foro competente.</i>
                    </span>
                </ConsentItemCheck>
                <ConsentItemCheck v-model="consent_club3" :v="$v.consent_club3" :mustBeTrueError="mustBeTrueError">
                    <span class="leading-6">
                        Dichiaro di aver ricevuto e aver ben compreso l’<a :href="privacyClubUrl" target="_blank" class="underline">Informativa</a> fornita ai sensi dell’art. 13 del Regolamento UE 2016/679, dalla quale risulta che, nell’ambito dell’esecuzione delle operazioni e del servizio richiesto, si intendono perseguire le finalità di cui al punto 2.1. “Finalità Necessarie”. In particolare, dichiaro di aver ben compreso dalla predetta Informativa che è previsto il trattamento, da parte di Quattrolinee S.r.l., nella sua qualità di Titolare, dei miei dati personali.                
                    </span>
                </ConsentItemCheck>
                <ClubOptionalConsents />
            </div>
            <StickyButton :clickHandler="submit">
                <span class="text-center text-xl font-semibold">CONFERMA</span>
            </StickyButton>
            <button class="text-center text-sm lg:text-base text-gray-700 mt-10 lg:mt-7 underline block mx-auto" @click="goBack()">Torna indietro</button>
        </form>
    </div>
</template>

<script>
import StickyButton from "@/components/StickyButton.vue"
import Steps from "@/components/Steps"
import { required } from "vuelidate/lib/validators";
import { mapUserData } from "@/utils";
import ConsentItemCheck from "@/components/RequiredItemCheck.vue"
import ClubOptionalConsents from "@/components/ClubOptionalConsents.vue";

const mustBeTrue = value => value === true;

export default {
    name: "Consents",
    components: { Steps, StickyButton, ConsentItemCheck, ClubOptionalConsents },
    data() {
       return {
            rulesClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Regolamento_Servizio_CLUB.pdf',
            privacyClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Informativa_Servizio.pdf',
            mustBeTrueError: 'ATTENZIONE: non sarà possibile procedere all\'adesione al servizio'
       } 
    },
    computed: {
        ...mapUserData([
            "consent_club1",
            "consent_club2",
            "consent_club3",
            "step_number",
            "document_expired"
        ]),
        stepCount() {
            return this.document_expired ? 3 : 2
        },
    },
    validations: {
        consent_club1: {
            required,
            mustBeTrue
        },
        consent_club2: {
            required,
            mustBeTrue
        },
        consent_club3: {
            required,
            mustBeTrue
        },
    },
    methods: {
        async submit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {

                this.$store.commit("setUserData", { key: "service", value: 1 });
                this.$store.commit("setUserData", { key: 'step_number', value: this.step_number + 1 });

                // send data
                this.$store.dispatch("sendData", { step: "dati_anag" });
                this.$store.dispatch("redirect", { step: "dati_anag", route: "Income" });
            }
        },
    }
}
</script>