<template>
<div class="carousel-container relative">
	<div v-if="screen > 1200" class="flex flex-wrap justify-center">
		<Card v-for="(testimonial, i) in testimonialsLists" :key="i" :index="i" />
	</div>
	<div v-if="(screen <= 1200)" class="relative">
		<Card v-for="index in 3" :key="index" :index="index"/>
	</div>
	<div v-if="(screen <= 1200)" class="flex carousel-dots justify-center absolute">
		<div v-for="(testimonial, i) in testimonialsLists" :key="i" :class="dot(i)" @click="dots(i)"></div>
	</div>
	<div v-if="(screen <= 1200)" class="carousel-nav flex flex-wrap justify-between px-5 absolute w-full">
		<div class="prev" @click="prev()"></div>
		<div class="next" @click="next()"></div>
	</div>
</div>
</template>

<script>

import Card from './Card.vue';
import testimonialsLists from './models/testimonials'
// import Vue2TouchEvents from 'vue2-touch-events'

export default {
    components:{
        Card,
		// Vue2TouchEvents
    },
	data(){
		return{
			i : 0,
			testimonialsLists,
			screen : window.innerWidth
		}
	},
    methods:{
        consoCarousel(i){
            var cards = document.getElementsByClassName('card')
            if(i == 0){
                /* elemento centrale */
                cards[0].children[0].children[0].setAttribute('src', testimonialsLists[i].img)
                cards[0].children[1].children[0].innerHTML = testimonialsLists[i].nome
                cards[0].children[1].children[1].innerHTML = testimonialsLists[i].testo
                /* elemento a destra */
                cards[2].children[0].children[0].setAttribute('src', testimonialsLists[i + 1].img) 
                cards[2].children[1].children[0].innerHTML = testimonialsLists[i + 1].nome
                cards[2].children[1].children[1].innerHTML = testimonialsLists[i + 1].testo
                /* elemento a sinistra */
                cards[1].children[0].children[0].setAttribute('src', testimonialsLists[testimonialsLists.length - 1].img)
                cards[1].children[1].children[0].innerHTML = testimonialsLists[testimonialsLists.length - 1].nome
                cards[1].children[1].children[1].innerHTML = testimonialsLists[testimonialsLists.length - 1].testo
            }else if(i == testimonialsLists.length - 1){
                /* elemento centrale */
                cards[0].children[0].children[0].setAttribute('src', testimonialsLists[i].img)
                cards[0].children[1].children[0].innerHTML = testimonialsLists[i].nome
                cards[0].children[1].children[1].innerHTML = testimonialsLists[i].testo
                /* elemento a destra */
                cards[2].children[0].children[0].setAttribute('src', testimonialsLists[0].img)
                cards[2].children[1].children[0].innerHTML = testimonialsLists[0].nome
                cards[2].children[1].children[1].innerHTML = testimonialsLists[0].testo
                /* elemento a sinistra */
                cards[1].children[0].children[0].setAttribute('src', testimonialsLists[i - 1].img)
                cards[1].children[1].children[0].innerHTML = testimonialsLists[i - 1].nome
                cards[1].children[1].children[1].innerHTML = testimonialsLists[i - 1].testo
            }else{
                /* elemento centrale */
                cards[0].children[0].children[0].setAttribute('src', testimonialsLists[i].img)
                cards[0].children[1].children[0].innerHTML = testimonialsLists[i].nome
                cards[0].children[1].children[1].innerHTML = testimonialsLists[i].testo
                /* elemento a destra */
                cards[2].children[0].children[0].setAttribute('src', testimonialsLists[i + 1].img)
                cards[2].children[1].children[0].innerHTML = testimonialsLists[i + 1].nome
                cards[2].children[1].children[1].innerHTML = testimonialsLists[i + 1].testo
                /* elemento a sinistra */
                cards[1].children[0].children[0].setAttribute('src', testimonialsLists[i - 1].img)
                cards[1].children[1].children[0].innerHTML = testimonialsLists[i - 1].nome
                cards[1].children[1].children[1].innerHTML = testimonialsLists[i - 1].testo
            }
    	},
		next(){
			if(this.i == 4){
				this.i = 0
				if(window.innerWidth < 1100)
					this.consoCarousel(this.i)
			}else{
				this.i++
				if(window.innerWidth < 1100)
					this.consoCarousel(this.i)
			}
		},
		prev(){
			if(this.i == 0){
				this.i = 4
				if(window.innerWidth < 1100)
					this.consoCarousel(this.i)
			}else{
				this.i--
				if(window.innerWidth < 1100)
					this.consoCarousel(this.i)
			}
		},
		dot(index){
			if(index == this.i){
				return 'dot active';
			}else{
				return 'dot';
			}
		},
		dots(index){
			this.i = index;
			this.consoCarousel(this.i)
		},
		swipeHandler(direction){
			// if(direction == 'right')
			console.log(direction)
		}
	},
	mounted(){
		this.consoCarousel(this.i)
	}
}

</script>