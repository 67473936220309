<template>
    <div class="metlife-precompiled w-full">
        <FormCard @submit="onSubmit" title="Controlla i tuoi dati">
            <div class="w-full lg:w-2/3 mx-auto px-8 pt-6 pb-6">
                <div class="w-full mb-2">
                    <FloatingLabelInput :name="`cellphone`" :type="`tel`" v-model="cellphone" :mask="cellphoneMask" :label="`Cellulare`" :error="$v.cellphone.$error" />
                    <div class="w-full text-red" v-if="$v.cellphone.$error">
                        <small v-if="!$v.cellphone.required">
                            {{ $t("Campo obbligatorio") }}
                        </small>
                        <small v-else-if="$v.cellphone.$error && !$v.cellphone.phone">
                        {{ $t("Numero di telefono non valido") }}
                        </small>
                    </div>
                </div>
                <div class="w-full mb-2">
                    <FloatingLabelInput :name="`address`" v-model="address" :mask="{
                        mask: 'A{+}',
                        placeholder: '',
                        definitions: { A: { validator: '[A-Z0-9ÀÈÌÒÙÁÉÍÓÚ .\'°^/-]' } },
                        casing: 'upper'
                        }" :label="`Indirizzo di residenza`" :error="$v.address.$error" />
                    <div class="h-6 w-full text-red" v-if="$v.address.$error">
                        <small v-if="!$v.address.required">
                            {{ $t("Campo obbligatorio") }}
                        </small>
                    </div>
                </div>
                <div class="w-full mb-2">
                    <div class="label-floating" :class="{ 'border-red': $v.address_city.$error,'border-gray-700': focusInput == 'address_city' }" >                
                        <Autocomplete :class="{ 'border-red': $v.address_city.$error,'border-gray-700': focusInput == 'address_city' }" :placeholder="$t('Comune residenza')" v-model="address_city" :search="citySearch" @focus="setFocusInput('address_city')" @blur="unsetFocusInput()">
                        </Autocomplete>
                        <label>{{ $t("Comune residenza") }}</label>
                    </div>
                    <div class="h-6 w-full text-red" v-if="$v.address_city.$error">
                        <small v-if="!$v.address_city.required">
                            {{ $t("Campo obbligatorio") }}
                        </small>
                    </div>
                </div>
                <div class="w-1/2">
                    <FloatingLabelSelect v-if="address_multicap.length" :name="`address_cap`" v-model="address_cap" :items="address_multicap" :label="`CAP`" :error="$v.address_cap.$error" :disableZeroValue="true"/>
                    <FloatingLabelInput v-else :name="`address_cap`" v-model="address_cap" :error="$v.address_cap.$error" :type="`tel`" :className="`placeholder-sm`" :disabled="true" :label="`CAP`"/>
                    <div class="h-6 w-full text-red" v-if="$v.address_cap.$error">
                        <small v-if="!$v.address_cap.required">
                            {{ $t("Campo obbligatorio") }}
                        </small>
                    </div>
                </div> 
            </div>
            <div class="w-full py-4 px-6 text-gray-4 bg-gray-1">
                <div class="mx-auto flex flex-wrap text-gray-5">
                    <span><strong class="font-bold">Perché conosciamo i tuoi dati?</strong>
                        La risposta è <strong class="font-bold">semplice</strong> e <strong class="font-bold">trasparente</strong>:
                        hai già una delle soluzioni di credito con Cofidis</span>
                </div>
            </div>
        </FormCard>
    </div>
</template>
<script>
import FloatingLabelInput from "@/components/FloatingLabelInput";
import FloatingLabelSelect from "@/components/FloatingLabelSelect";
import { required } from "vuelidate/lib/validators";
import { mapUserData, validPhone } from "@/utils";
import { cellphoneMask } from "@/masks";
import $ from "jquery";
import Fuse from "fuse.js";

export default {
    components: { FloatingLabelInput, FloatingLabelSelect },
    data() {
        return {
            cellphoneMask,
            search: [],
            fuser: null
        };
    },
    computed: {
        ...mapUserData([
            "address",
            "address_city",
            "address_cap",
            "address_multicap",
            "cellphone" 
        ])
    },
    validations: {
        cellphone: {
            required,
            validPhone
        },
        address: {
            required
        },
        address_cap: {
            required
        },
        address_city: {
            required
        }
    },
    watch: {
        address_city(val) {
            const city = val.slice(0, -5)   
            this.address_cap = null
            this.address_multicap = []
            this.search.map(res => {
                if(res.item.Citta == city) {                    
                    if(res.item.Multicap) {
                        this.address_multicap.push({key: null, value: 'CAP'})
                        res.item.Multicap.split(',').map(cap => {
                            const value = '0'.repeat(5 - cap.toString().length) + cap.toString()
                            this.address_multicap.push({key: cap, value })
                        })
                    }
                    else {
                        this.address_cap = res.item.Cap
                    }
                }
            })
        }
    },
    async mounted() {
        const city_feevo = await $.getJSON("https://cofidis-settings.s3.eu-central-1.amazonaws.com/city_feevo_2022.json")
        this.fuser = new Fuse(city_feevo, {
            keys: ["Citta"],
            threshold: 0.0,
            location: 0,
            distance: 0,
            maxPatternLength: 32,
            minMatchCharLength: 0,
            caseSensitive: false
        })  
    },
    methods: {
        onSubmit() {
            this.$v.$touch();
            this.focusFirstError();
            if (!this.$v.$invalid) {
                this.$store.dispatch("sendData", { step: "dati_metlife_1" });
                this.$store.dispatch("redirect", { step: "dati_metlife_1", route: "MetlifeBestCare" });
            }
        },
        citySearch(input) {
            if (input.length < 2) return [];
            this.search = this.fuser.search(input)
            return this.search.map(i => `${i.item.Citta} (${i.item.Prov})`);
        }
    }
}
</script>