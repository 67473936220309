<template>    
	<div class="container mx-auto pt-10 mb-20 text-sm sm:text-base text-gray-700 border-t">
        <div class="flag text-right relative">
            <span class="inline-block flag-icon w-5 h-3 bg-contain bg-no-repeat mr-1" :class="$i18n.locale"></span>
            <a href="#" v-if="$i18n.locale == 'de'" @click="setLocale('it')" class="no-wrap hover:underline">
                <span>Lingua italiana</span>
            </a>            
            <a href="#" v-if="$i18n.locale == 'it'" @click="setLocale('de')" class="no-wrap hover:underline">                
                <span>Deutsche Sprache</span>
            </a>
        </div>

        <h1 class="text-center text-2xl font-bold text-gray-900 mb-4 lg:mb-10">{{ $t("Benvenuto nella pagina di richiesta") }}<br>{{ $t("Prestito Personale CiviBank") }}</h1>
        <p class="text-center text-xl max-w-2xl mx-auto px-4">{{ $t("Inserisci il codice che ti è stato fornito per proseguire con la richiesta di Prestito Personale") }}</p>
        <form class="mt-6 px-4">
            <div class="max-w-xl flex flex-col items-center mx-auto">
                <div class="w-full mb-3 lg:w-3/5 xl:w-2/3">
                    <FloatingLabelInput 
                        id="code"
                        name="code" 
                        type="text" 
                        v-model="code"
                        :label="$t('Inserisci il tuo codice')" 
                        :error="$v.code.$error"
                        @keyup.enter="login"
                    />
                    <div class="w-full text-red mb-6" v-if="$v.code.$error">
                        <small>
                            {{ $t("ATTENZIONE! Il codice inserito non è valido") }}
                        </small>
                    </div>
                </div>
                <button @click="login" class="font-semibold text-lg text-white bg-accent uppercase max-w-xs rounded-full shadow-2xl px-8 py-3 sm:max-w-none sm:w-auto" type="button" :disabled="loading">
                    {{ $t("CONTINUA") }} 
                    <svg v-if="loading" class="inline-block animate-spin -mt-1 ml-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </button>
            </div>
        </form>
    </div>
</template>
<script>
import loadData from "@/dataService.js";
const config = loadData("config.json")
import FloatingLabelInput from "@/components/FloatingLabelInput"
import { required } from "vuelidate/lib/validators";

const isValidInput = val => {
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    if (!regex.test(val)) return false
    return true
}

export default {
    components: { FloatingLabelInput },
    data() {        
        return {       
            loading: false,
            code: null
        };
    },
    validations: {
        code: {
            required,
            isValidInput
        }
    },
    methods: {        
        login() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.loading = true
                this.$store.dispatch("verifyCode", { code: this.code })
                .then(success => {
                    if(success) this.$router.push({ name: "Homepage" })
                    this.$v.$reset()
                    this.loading = false
                    this.code = null
                })
                .catch(() => {
                    this.$v.$reset()
                    this.loading = false
                    this.code = null
                })
            }
        },
        setLocale(locale) {            
            this.$i18n.locale = locale
            this.$store.state.lang = locale
            document.title = this.$i18n.t(config.meta.title);
        }
    }
}
</script>