<template>
    <div class="mx-auto lg:container py-4 leading-tight">
        <form class="form_dati_anag" action="next" method="POST" @submit.prevent="onSubmit" data-model="dati_anag">
            <div class="banner bg-neutral text-center lg:text-left mt-4 flex flex-wrap lg:flex-no-wrap items-center py-2 px-4 sm:px-12">
                <h1 class="text-center lg:text-left text-2xl sm:text-3xl font-semibold leading-tight">
                    Hai scelto un importo di {{ importo|num }} €
                </h1>
                <div class="hidden sm:flex w-full sm:w-auto justify-center sm:ml-auto mt-2 sm:mt-0">
                    <img class="block h-8 mr-3" src="@/assets/images/contatti-esito.svg" />
                    <p>
                        <strong class="text-orange block font-semibold text-sm">{{ $t("Primo esito immediato") }}</strong>
                        <span class="text-orange font-light text-sm block -mt-1">{{ $t("in pochi minuti") }}</span>
                    </p>
                </div>
            </div>
            <div class="flex flex-wrap text-gray-700 p-4 pb-10 px-4 sm:px-12 rounded-b-lg">
                <p class="text-center sm:text-left mb-2 w-full">{{ $t("Ora completa la richiesta con i tuoi dati") }}</p>
                <div class="flex sm:hidden w-full w-auto justify-center mt-2">
                    <img class="block h-8 mr-3" src="@/assets/images/contatti-esito.svg" />
                    <p>
                        <strong class="text-orange block font-semibold text-sm">{{ $t("Primo esito immediato") }}</strong>
                        <span class="text-orange font-light text-sm block -mt-1">{{ $t("in pochi minuti") }}</span>
                    </p>
                </div>
                <p class="text-center sm:text-left mt-2 mb-6 w-full">{{ $t("Questi pochi dati sono utili anche per aiutarti durante la compilazione.") }}</p>
                <div class="w-full lg:w-1/2 lg:pr-2 mb-2 email-input">
                    <FloatingLabelInput :name="`email`" :type="`text`" v-model="email" :mask="{ placeholder: '', greedy: false, casing: 'lower' }" :label="`E-mail (dove invieremo i documenti)`" :error="$v.email.$error"/>
                    <div class="w-full text-red" v-if="$v.email.$error">
                        <small v-if="!$v.email.required">
                            {{ $t("Campo obbligatorio") }}
                        </small>
                        <small v-else-if="!$v.email.email">
                            {{ $t("Formato e-mail non valido") }}
                        </small>
                    </div>
                </div>

                <div class="w-full lg:w-1/2 lg:pl-2 mb-2">                                  
                    <FloatingLabelInput 
                        :name="`cellphone`" 
                        :type="`tel`" 
                        v-model="cellphone" 
                        :mask="cellphoneMask" 
                        :label="`Cellulare (per SMS di conferma)`" 
                        :error="$v.cellphone.$error" 
                    />
                    <div class="w-full text-red" v-if="$v.cellphone.$error">
                        <small v-if="!$v.cellphone.required">
                            {{ $t("Campo obbligatorio") }}
                        </small>
                        <small v-else-if="$v.cellphone.$error && !$v.cellphone.phone">
                          {{ $t("Numero di telefono non valido") }}
                        </small>
                    </div>
                </div>
                <div class="w-full lg:w-1/2 lg:pr-2 mb-2">
                    <FloatingLabelInput :name="`name`" :type="`text`" v-model="name" :mask="{
                          mask: 'N{*}',
                          placeholder: '',
                          greedy: false,
                          definitions: { N: { validator: '[A-Z\'ÀÈÌÒÙÁÉÍÓÚ -]' } },
                          casing: 'upper'
                        }" :label="`Nome`" :disabled="disabled.name" :error="$v.name.$error"/>
                    <div class="w-full text-red" v-if="$v.name.$error">
                        <small v-if="!$v.name.required">
                            {{ $t("Campo obbligatorio") }}
                        </small>
                    </div>
                </div>
                <div class="w-full lg:w-1/2 lg:pl-2 mb-2">
                    <FloatingLabelInput :name="`surname`" :type="`text`" v-model="surname" :mask="{
                          mask: 'N{*}',
                          placeholder: '',
                          greedy: false,
                          definitions: { N: { validator: '[A-Z\'ÀÈÌÒÙÁÉÍÓÚ -]' } },
                          casing: 'upper'
                        }" :label="`Cognome`" :disabled="disabled.surname" :error="$v.surname.$error"/>
                    <div class="w-full text-red" v-if="$v.surname.$error">
                        <small v-if="!$v.surname.required">
                            {{ $t("Campo obbligatorio") }}
                        </small>
                    </div>
                </div>
                <div class="w-full lg:w-1/2 lg:pr-2 mb-2">
                    <FloatingLabelInput :name="`fiscal_code`" :type="`text`" v-model="fiscal_code" :mask="fiscalCodeMask" :label="`Codice fiscale`" :disabled="disabled.fiscal_code" :error="$v.fiscal_code.$error"/>
                    <div class="w-full text-red" v-if="$v.fiscal_code.$error">
                        <small v-if="!$v.fiscal_code.required">
                            {{ $t("Campo obbligatorio") }}
                        </small>
                        <small v-else-if="!$v.fiscal_code.fiscal_code_valid">
                            {{ $t("Codice Fiscale errato") }}
                        </small>
                        <small v-else-if="!$v.fiscal_code.fiscal_code_name">
                            {{ $t("Codice fiscale incongruente con Nome e Cognome") }}
                        </small>
                        <small v-else-if="!$v.fiscal_code.age">
                            {{ $t("Il servizio è riservato ai soli maggiorenni") }}
                        </small>
                    </div>
                </div>
            </div>            
            <div class="form-footer text-center -mt-2 mb-4">
                <button class="bg-accent rounded-full p-3 px-16 font-semibold shadow-xl hover:bg-accent-hover" type="submit">
                    <img src="@/assets/images/spinner.gif" class="w-6 h-6" v-if="loading"  />
                    <span v-else>{{ $t("CONTINUA") }}</span>
                </button>
            </div>

            <portal to="sub-form">
                <div class="w-11/12 sm:max-w-7xl mx-auto shadow-lg rounded-3xl bg-white text-gray-700 px-2 sm:px-16 -mt-6 mb-10">
                    <div class="pt-6 mb-4 text-center sm:text-left">
                        {{ $t("Per procedere rapidamente con la richiesta, prepara i seguenti documenti:") }}
                    </div>
                    <div class="flex flex-wrap align-center justify-center">
                        <div class="flex items-center justify-center mb-4 w-full sm:w-1/2 lg:w-1/4 sm:flex-col">
                            <img class="h-6 sm:h-12" src="@/assets/images/contatti-doc.svg" />
                            <span class="pl-2 mt-2 sm:mt-0 text-sm">{{ $t("Documento d'identità valido") }}</span>
                        </div>
                        <div class="flex items-center justify-center mb-4 w-full sm:w-1/2 lg:w-1/4 sm:flex-col">
                            <img class="h-6 sm:h-12" src="@/assets/images/contatti-bank.svg" />
                            <span class="pl-2 mt-2 sm:mt-0 text-sm">{{ $t("IBAN del tuo conto corrente") }}</span>
                        </div>
                    </div>
                    <div class="text-center text-sm text-gray-700 pb-6 mt-2">
                        <a href="#" @click.prevent="goBack()" class="back-btn underline">{{ $t("Torna indietro") }}</a>
                    </div>
                </div>
            </portal>
        </form>
    </div>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
import { mapUserData, fiscal_code_valid, fiscal_code_name, fiscal_code_age, validPhone } from "@/utils";
import { fiscalCodeMask, cellphoneMask } from "@/masks";
import FloatingLabelInput from "@/components/FloatingLabelInput";

export default {
    components: { FloatingLabelInput },
    mounted() {
        this.$ga.pageview()
        
        if (this.$store.state.precompiled) {
            Object.keys(this.disabled).forEach(value => {
                if (this[value]) this.disabled[value] = true
            })
        }
    },
    data() {
        return {            
            cellphoneMask,
            fiscalCodeMask,
            focus: null,
            verified: false,
            loading: false,
            disabled: {
                name: false,
                surname: false,
                fiscal_code: false
            }
        };
    },
    computed: {
        title() {
            const qty = this.$options.filters.eur(this.importo)
            return this.$t("HAI SCELTO UN IMPORTO DI", [qty])            
        },
        importo() {
            return this.$store.state.userData.importo;
        },
        email: {
            get() {
                return this.$store.state.userData.email;
            },
            set(value) {
                return this.$store.commit("setUserData", { key: "email", value });
            }
        },
        crmRedirect: {
            get() {
                return this.$store.state.crmRedirect;
            },
            set(value) {
                return this.$store.state.crmRedirect = value;
            }
        },
        redirectVerified: {
            get() {
                return this.$store.state.redirectVerified;
            },
            set(value) {
                return this.$store.state.redirectVerified = value;
            }
        },
        ...mapUserData(["email", "name", "surname", "cellphone", "fiscal_code"])
    },
    validations: {
        email: {
            required,
            email
        },
        name: {
            required
        },
        surname: {
            required
        },
        cellphone: {
            required,
            validPhone
        },
        fiscal_code: {
            required,
            fiscal_code_valid,
            fiscal_code_name,
            fiscal_code_age
        }
    },
    methods: {        
        async verifyCF() {            
            return await this.$store.dispatch('verifyCF', this.fiscal_code)            
        },
        goBack() {            
            this.$store.dispatch("event", { name: "BackClick", model: this.$route.meta.model });
            this.$store.commit("unlogStep");
            this.$router.go(-1);
        },
        async onSubmit() {
            this.$v.$touch();
            this.focusFirstError();
            if (!this.$v.$invalid) {                
                if(this.crmRedirect && !this.redirectVerified && !this.isDev) {
                    this.loading = true
                    const res = await this.verifyCF()
                    this.redirectVerified = true
                    this.loading = false
                    if(res) return
                }
                this.$store.dispatch("event", { name: "ContattiSubmit" });
                this.$store.dispatch("sendData", { step: "dati_anag" });
                this.$store.dispatch("redirect", { step: "dati_anag", route: "PersonalData1" });                
            }
        },
        formatText(val) {
            val = val.toUpperCase();
            //val = val.replace(/[^A-Za-z\s!?]/g, "");
            val = val.replace(/[^a-zA-Z'ÀÈÌÒÙÁÉÍÓÚ -]/g, "");
            return val;
        },
        isLetter(e) {
            let s = e.key.replace(/[^a-zA-Z'ÀÈÌÒÙÁÉÍÓÚ -]/, "");
            if (s === "") e.preventDefault();
        }
    }
};
</script>