<template>
    <div>
        <FormCard @submit="onSubmit" title="CLUB LIGHT">
            <p class="mb-4">
                Cofidis ti propone il servizio CLUB LIGHT. <strong>Gratuitamente per 12 mesi puoi usufruire di sconti su grandi brand</strong>. 
                <a :href="infoClubUrl" target="_blank" class="underline">Scopri qui</a> tutti i vantaggi del CLUB LIGHT.
            </p>
            <p>
                Prima di procedere, ti invitiamo a prendere visione del <a :href="rulesClubUrl" target="_blank" class="underline">Regolamento</a>
                che riporta nel dettaglio le caratteristiche del prodotto, nonché
                l’<a :href="privacyClubUrl" target="_blank" class="underline">Informativa Privacy</a>.
                Il servizio CLUB LIGHT è facoltativo e non è necessario per ottenere il prestito o per ottenerlo a condizioni più vantaggiose.
            </p>
            <p class="mb-4">
                Cliccando su continua confermi di aver letto il <a :href="rulesClubUrl" target="_blank" class="underline">Regolamento contrattuale</a> 
                accettando integralmente le sue condizioni, e dichiari di essere interessato a sottoscrivere la proposta del servizio; 
                inoltre confermi di aver letto e compreso l'<a :href="privacyClubUrl" target="_blank" class="underline">Informativa</a> fornita ai sensi dell'art. 12 del Regolamento UE
                2016/679, dalla quale risulta che, nell'ambito dell'esecuzione delle operazioni e del servizio richiesto, si intendono perseguire le finalità di cui al punto 2.1 "Finalità Necessarie".
                In particolare, dichiari di aver ben compreso dalla predetta informativa che è previsto il trattamento, da parte di Quattrolinee s.r.l., nella sua qualità di Titolare, dei tuoi dati personali.
                Il servizio CLUB LIGHT è gratuito per un anno e non ti costerà nulla!
            </p>
            <ClubOptionalConsents />
            <div slot="link" class="text-center text-gray-700 mt-4 mb-8">
                <router-link :to="{ name: 'PrivacyConsent' }" class="underline">Procedi senza Club</router-link>
            </div>
        </FormCard>
    </div>
</template>
<style scoped type="text/css">
@media screen and (max-width: 639px) {
    div >>> .form-header { font-size: 1em !important; }
}
</style>
<script>

import ClubOptionalConsents from "@/components/ClubOptionalConsents"

export default {
    components: { ClubOptionalConsents },
    data() {
        return {
            rulesClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Regolamento_Servizio_CLUB_LIGHT.pdf',
            infoClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Cofidis_Club_LIGHT.pdf',
            privacyClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Informativa_Servizio.pdf',
        }
    },
    mounted() {
        this.$ga.pageview()
        this.$store.commit("setUserData", { key: 'service_available', value: true });
        this.$store.commit("setUserData", { key: 'service_pack', value: 'CLLGT' });
        this.$store.commit("setUserData", { key: 'service_amount', value: 0 });
        this.$store.dispatch("sendData", { step: "club" })
    },
    methods: {
        onSubmit() {
            this.$store.commit("setUserData", { key: 'service', value: true });
            this.$store.dispatch("redirect", { step: "club_consent", route: "PrivacyConsent" });                                    
        }
    }
};
</script>