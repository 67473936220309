<template>
    <div class="w-full">
        <div class="flex">
            <div class="relative flex-shrink-0 mr-4">
                <input type="checkbox" @change="v.$touch(); $emit('input', $event.target.checked);" :checked="value === true" class="form-radio" />
                <label>ok</label>
            </div>
            <div class="w-full">
                <slot></slot>
            </div>
        </div>
        <div class="w-full mb-4 text-red" v-if="v.$error">
            {{ $t(mustBeTrueError) }}
        </div>
    </div>
</template>
<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: null
        },
        v: {
            type: Object,
            required: true
        },
        mustBeTrueError: {
            type: String,
            default: "ATTENZIONE non sarà possibile procedere alla sottoscrizione della polizza."
        }
    }
};
</script>