<template>
    <CreditSummary @submit="onSubmit" ></CreditSummary>
</template>
<script>
import CreditSummary from '@/views/forms/CreditSummary.vue';

export default {
    components: { CreditSummary },
    methods: {
        onSubmit() {
            this.$store.dispatch("sendData", { step: "rata" });
            this.$store.dispatch("redirect", { step: "rata", route: "PrecompiledData" });
        }
    }
};
</script>
