<template>
	<div class="container mx-auto py-20 text-sm sm:text-base px-6">
		<p class="text-center text-xl">{{ $t("Per gli importi maggiori di 10.000€ contatta la tua filiale di riferimento ") }}</p>
		<p class="text-center text-xl"><a :href="$t('https://www.civibank.it/dove-siamo')" target="_blank" class="underline">{{ $t("Clicca qui") }}</a> {{ $t("e consulta l'elenco delle filiali di Banca CiviBank") }}</p>
	</div>
</template>
<script>
export default {
	name: "CustomCredit"
}
</script>