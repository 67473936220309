<template>
    <div class="label-floating" :class="{ 'border-red': error,'border-gray-700': focusInput == 'email' }" >  
        <input
            class="form-input autocomplete-input"
            :class="{ 'border-red': error }"
            :placeholder="$t(placeholder)"
            v-bind:value="value" 
            v-on:input="$emit('input', $event.target.value)" 
            v-on:change="$emit('input', $event.target.value)"
            :search="emailSearch"
            @focus="setFocusInput('email')" 
            @blur="unsetFocusInput()"
            ref="emailInput"
            type="email"
            :disabled="disabled"
        />
        <label>{{ $t(label) }}</label>
    </div>
</template>

<script>
import loadData from "@/dataService.js";
import Fuse from "fuse.js";
import autocomplete from "js-autocomplete";

export default {
    name: "EmailAutocomplete",
    props: {
        value: {
            required: true,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'E-mail'
        },
        label: {
            type: String,
            required: true
        },
        error: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            search: [],
            fuser: null,
            engine: null
        }
    },
    async mounted() {
        const email_providers = await loadData("email_providers.json")
        this.fuser = new Fuse(email_providers, {
            threshold: 0.0,
            location: 0,
            distance: 0,
            maxPatternLength: 16,
            minMatchCharLength: 0,
            caseSensitive: false
        })
        this.engine = new autocomplete({
            selector: this.$refs.emailInput,
            minChars: 2,
            delay: 200,
            cache: false,
            source: (term, response) => {
                response(this.emailSearch(term));
            },
            onSelect: (e, term) => {
                e.preventDefault()
                this.$emit("input", term);
            }
        });
    },
    methods: {
        emailSearch(input) {
            if(!input.includes('@')) return [];

            const [name, domain] = input.split('@');

            const domainSearch = this.fuser.search(domain)
            this.search = domainSearch.map(el => `${name}@${el.item}`)

            return this.search
        }
    },
    destroyed() {
        this.engine.destroy();
    }
};
</script>