class Append {
    enter_landing(payload, {state}) {
        payload['userId'] = state.userData.user_id
        payload['CF'] = state.userData.fiscal_code
        payload['codeCampaign'] = state.userData.code_campaign
        payload['disponibilita_riu'] = state.userData.max_amount
        payload['dt_start_riucl'] = state.userData.dt_start_riucl
        payload['stepNumber'] = 0
        payload['Phone'] = state.userData.cellphone.substring(3).replace(/ +/g, "")
        payload['Nome'] = state.userData.name
        payload['Cognome'] = state.userData.surname
        payload['Permesso'] = 1
    }

    dati_privacy(payload, {state}) {
        payload['stepNumber'] = state.userData.step_number
        delete(payload['privacy1'])
        delete(payload['privacy2'])
        delete(payload['privacy3'])
        delete(payload['privacy4'])
        delete(payload['Politico'])
        payload['EMail'] = state.userData.email
        payload['data_esito'] = state.userData.output_date
        payload['cliente_id'] = state.userData.client_id
        payload['cod_camp_rev'] = state.userData.cod_camp_rev
        payload['importo_tot_dovuto_riu'] = state.userData.owed_amount
        payload['pratica_id_rev'] = state.userData.dossier_id
        payload['Importo'] = state.userData.importo
        payload['service'] = +state.userData.service
        payload['service_pack'] = state.userData.service_pack
    }
}
export default new Append();