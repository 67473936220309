<template>
    <footer class="px-6 text-center">
        <p class="text-xs text-gray-5 leading-none">Messaggio pubblicitario con finalità promozionale.</p>
        <p class="text-xs mt-4">
            Concorso valido dal 11/11/2023 al 30/10/2024. 
            <a href="https://www.cofidis.it/it/download/trasparenza/Regolamento_Cofidis_Ti_Premia_2246_23_007.pdf" class="underline" target="_blank">Regolamento concorso</a>.
        </p>
        <div class="mb-4 text-xs mt-4">
            <a href="https://www.cofidis.it/it/privacy-policy.html" class="underline" target="_blank">Privacy Policy</a> |
            <a href="https://cofidis-docs-pp.s3.eu-central-1.amazonaws.com/agid.pdf" class="underline" target="_blank">Accessibilità: non conforme</a>
        </div>
    </footer>
</template>
<script>

export default {
    data() {
        return {
            activeFooter: false,
        };
    },
};
</script>
