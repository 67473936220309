<template>
    <footer class="bg-white">
        <div class="w-full p-6 mx-auto sm:max-w-7xl relative">
            <ScrollTopButton />
            <div class="footer-content mt-4">
                <div class="w-full">
                    <div class="flex flex-wrap cursor-pointer">
                        <div class="faq-question outline-none w-4/5 sm:flex-grow text-left" :class="{ active: activeFooter }" @click="activeFooter = !activeFooter">
                            <p class="text-base text-gray-5">Soggetto ad approvazione da parte di Cofidis S.A.</p>
                            <p class="text-base text-gray-5">Messaggio pubblicitario con finalità promozionale.</p>
                        </div>
                        <div class="flex-grow outline-none flex items-center justify-center sm:justify-end sm:mr-6"  @click="activeFooter = !activeFooter">
                            <svg v-if="!activeFooter" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                            </svg>
                        </div>
                    </div>
                    <SlideUpDown class="faq-content font-medium text-gray-5 cursor-default outline-none text-left leading-0 my-2" :active="activeFooter">
                        <p class="text-base">
                            Per tutte le condizioni economiche e contrattuali, fare riferimento alle Informazioni Europee di
                            Base sul Credito ai Consumatori (cd. SECCI) sul sito <a class="underline" href="https://www.cofidis.it/trasparenza" target="_blank">www.cofidis.it/trasparenza</a>.
                        </p>
                        <p class="text-base mt-4">
                            Esempio rappresentativo con TAEG calcolato sulla base delle ipotesi che l’Importo totale del credito, 
                            fornito per un anno, sia utilizzato da subito e per intero al primo utilizzo; che sia applicato 
                            unicamente il Tasso Standard e non anche quello promozionale; che il Contratto resti valido e che il 
                            consumatore adempia ai propri obblighi nei termini ed entro le date convenute nel Contratto; che siano 
                            pagate rate mensili conteggiate suddividendo l’importo totale del credito in 12 quote uguali 
                            (quota capitale costante); ad ogni quota è aggiunta una componente di interessi variabile maturata 
                            mensilmente e calcolata sul capitale residuo riferito al periodo precedente, sulla base del TAN FISSO - 
                            Importo totale del credito €2.000 - rate minime mensili €60 da rimborsare mediante addebito in conto 
                            corrente - TAN fisso standard 19,80% - TAEG 21,70%. Spese di istruttoria €0; spese di incasso rata €0; 
                            imposta di bollo sul contratto/imposta sostitutiva €0; spese di invio Rendiconto mensile €0; imposta di 
                            bollo su Rendiconto mensile €0. Importo totale dovuto €2.214,50. La richiesta di finanziamento e di ogni 
                            utilizzo successivo sono soggette alla valutazione da parte di Cofidis S.A. . Offerta valida sino al
                            <span class="fine_campagna">{{ $store.state.userData.exp_date }}</span>.
                        </p>
                        <p class="text-base mt-4">
                            Concorso valido dal 11/11/2023 al 30/10/2024. 
                            <a href="https://www.cofidis.it/it/download/trasparenza/Regolamento_Cofidis_Ti_Premia_2246_23_007.pdf" class="underline" target="_blank">Regolamento concorso</a>.
                        </p>
                    </SlideUpDown>
                </div>
                <div class="text-center sm:text-center text-base mb-12 mt-4">
                    <a href="https://www.cofidis.it/it/privacy-policy.html" class="underline text-base" target="_blank">Privacy Policy</a> |
                    <a href="https://cofidis-docs-pp.s3.eu-central-1.amazonaws.com/agid.pdf" class="underline text-base" target="_blank">Accessibilità: non conforme</a>
                    <a :href="secciUrl" class="text-yellow-4 underline mt-4 block" target="_blank">SECCI</a>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import {mapGetters} from "vuex";
import ScrollTopButton from "@/components/scrollTopButton.vue";

export default {
    components: { ScrollTopButton },
    data() {
        return {
            activeFooter: false,
            secciFolderUrl: "https://dameixqhecspq.cloudfront.net/powecredit-crm"
        };
    },
    computed: {
        ...mapGetters(["uniquePrices"]),
        secciUrl() {
            // const credit = this.$store.state.choosenCredit
            // if(credit)
            return `${this.secciFolderUrl}/SECCIpowerCredit.pdf`
            // else
            //     return ``
        }
    }
};
</script>