<template>
    <FormCard @submit="onSubmit" title="Datore di lavoro">
        <div class="w-full sm:w-1/2 sm:pr-4 mb-2">
            <FloatingLabelInput :name="`employer_name`" v-model="employer_name" :mask="{ casing: 'upper' }" :label="`Denominazione`" :error="$v.employer_name.$error" />
            <div class="h-6 w-full text-red" v-if="$v.employer_name.$error">
                <small v-if="!$v.employer_name.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-2/3 sm:w-1/3 sm:pr-4 mb-2">
            <FloatingLabelInput :name="`employer_address`" v-model="employer_address" :mask="{
              mask: 'A{+}',
              placeholder: '',
              definitions: { A: { validator: '[A-Z0-9ÀÈÌÒÙÁÉÍÓÚ .\'°^/-]' } },
              casing: 'upper'
            }" :label="`Indirizzo`" :error="$v.employer_address.$error" />
            <div class="h-6 w-full text-red" v-if="$v.employer_address.$error">
                <small v-if="!$v.employer_address.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-1/3 pl-4 sm:pl-0 sm:pr-0 sm:w-1/6 mb-2">
            <FloatingLabelInput :name="`employer_street_number`" v-model="employer_street_number" :mask="{
                mask: 'A{*}',
                definitions: { A: { validator: '[A-Z0-9ÀÈÌÒÙÁÉÍÓÚ°^/-]' } },
                clearIncomplete: true,
                casing: 'upper'
              }" :label="`Civico`" :error="$v.employer_street_number.$error" />
            <div class="h-6 w-full text-red" v-if="$v.employer_street_number.$error">
                <small v-if="!$v.employer_street_number.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-2/3 sm:w-1/2 sm:pr-4 mb-2">
            <div class="label-floating" :class="{ 'border-red': $v.employer_city.$error,'border-gray-700': focusInput == 'employer_city' }" >                
                <Autocomplete :class="{ 'border-red': $v.employer_city.$error }" :placeholder="$t('Comune')" v-model="employer_city" :search="citySearch" @focus="setFocusInput('employer_city')" @blur="unsetFocusInput()">
                </Autocomplete>
                <label>{{ $t("Comune") }}</label>
            </div>
            <div class="h-6 w-full text-red" v-if="$v.employer_city.$error">
                <small v-if="!$v.employer_city.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-1/3 sm:w-1/6 pl-4 sm:pl-0 sm:pr-4 mb-2">
            <FloatingLabelSelect v-if="employer_multicap.length" :name="`employer_postal_code`" v-model="employer_postal_code" :items="employer_multicap" :label="`CAP`" :error="$v.employer_postal_code.$error" :disableZeroValue="true"/>
            <FloatingLabelInput v-else :name="`employer_postal_code`" v-model="employer_postal_code" :label="`CAP`" :error="$v.employer_postal_code.$error" :disabled="true" />
            <div class="h-6 w-full text-red" v-if="$v.employer_postal_code.$error">
                <small v-if="!$v.employer_postal_code.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div> 
        <div class="w-full sm:w-1/3 mb-2">
            <FloatingLabelInput :name="`employer_phone`" :type="`tel`" v-model="employer_phone" :mask="phoneMask" :label="`Telefono`" :error="$v.employer_phone.$error" />
            <div class="h-6 w-full text-red" v-if="$v.employer_phone.$error">
                <small v-if="!$v.employer_phone.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
                <small v-else-if="!$v.employer_phone.phone">
                    {{ $t("Numero di telefono non valido") }}
                </small>
            </div>
        </div>
        
    </FormCard>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { mapUserData } from "@/utils";
import { phoneMask } from "@/masks";

import $ from "jquery";
import Fuse from "fuse.js";
import FloatingLabelSelect from "@/components/FloatingLabelSelect";
import FloatingLabelInput from "@/components/FloatingLabelInput";

const phone = value => {
    let re = /^(\+39[. ]??)??[3|0]\d{5,9}$/
    return re.test(value)
}

export default {
    components: { FloatingLabelSelect, FloatingLabelInput },
    data() {
        return {
            search: [],
            fuser: null,
            phoneMask
        }
    },
    async mounted() {
        this.$ga.pageview()

        const city_feevo = await $.getJSON("https://cofidis-settings.s3.eu-central-1.amazonaws.com/city_feevo_2022.json")
        this.fuser = new Fuse(city_feevo, {
            keys: ["Citta"],
            threshold: 0.0,
            location: 0,
            distance: 0,
            maxPatternLength: 32,
            minMatchCharLength: 0,
            caseSensitive: false
        })
    },
    computed: {
        ...mapUserData([
            "employer_name",
            "employer_address",
            "employer_street_number",
            "employer_postal_code",
            "employer_multicap",
            "employer_city",
            "employer_phone",
            "cessione_quinto",
            "family_member_count",
            "employed_family_member_count"            
        ])
    },
    validations: {
        employer_name: {
            required
        },
        employer_address: {
            required
        },
        employer_street_number: {
            required
        },
        employer_postal_code: {
            required
        },
        employer_city: {
            required
        },
        employer_phone: {
            required,
            phone
        }
    },
    watch: {
        employer_city(val) {
            const city = val.slice(0, -5)   
            this.employer_postal_code = null
            this.employer_multicap = []
            this.search.map(res => {
                if(res.item.Citta == city) {                    
                    if(res.item.Multicap) {
                        this.employer_multicap.push({key: null, value: 'CAP'})
                        res.item.Multicap.split(',').map(cap => {
                            const value = '0'.repeat(5 - cap.toString().length) + cap.toString()
                            this.employer_multicap.push({key: cap, value })
                        })
                    }
                    else {
                        this.employer_postal_code = res.item.Cap
                    }
                }
            })
        },
    },
    methods: {
        onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                this.$store.dispatch("event", { name: "EmployerDataSubmit" });
                this.$store.dispatch("sendData", { step: "dati_lav_2" });
                this.$store.dispatch("redirect", { step: "dati_lav_2", route: "PaymentDetails" });
            }
        },
        citySearch(input) {
            if (input.length < 2) return [];
            this.search = this.fuser.search(input)
            return this.search.map(i => `${i.item.Citta} (${i.item.Prov})`);
        }
    }
};
</script>