<template>
    <PromoConfirm ctaText="INVIA RICHIESTA" />
</template>

<script>
import PromoConfirm from "@/views/forms/PromoConfirm.vue"

export default {
    components: { PromoConfirm },
};
</script>
