<template>
    <div>
        <MetlifeBestCareWarning @submit="onSubmit" @decline="onDecline" />
    </div>
</template>
<style scoped type="text/css">
@media screen and (max-width: 639px) {
    div >>> .form-header { font-size: 1rem !important; }
}
</style>
<script>
import MetlifeBestCareWarning from "@/views/forms/MetlifeBestCareWarning";

export default {
    components: { MetlifeBestCareWarning },
    methods: {
        onDecline() {
            this.$store.dispatch("sendData", { step: "dati_privacy" });
        },
        onSubmit() {
            this.$store.dispatch("event", { name: "LoanProtectionSubmit" });
        }
    }
};
</script>