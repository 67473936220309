<template>
    <div data-model="importo_2" action="next" method="POST" class="my-4 sm:my-8 proposals text-gray-700 lg:text-xl xl:text-2xl">
        <div class="offers">
            <div class="text-center lg:text-left px-4 py-1 sm:px-16">
                <h1 class="text-xl sm:text-3xl font-semibold">{{ $t("Ora scopri la soluzione ideale") }}</h1>
                <h2 class="text-base lg:mt-4">{{ $t("Scegli l’importo, la rata e la durata che preferisci") }}</h2>
            </div>
            <div class="bg-yellow text-center lg:text-left py-1 mt-4">
                <h3 class="text-base mx-auto px-4 py-1 sm:px-16">{{ $t("La nostra proposta di linea di credito ricaricabile") }}</h3>
            </div>
            <div class="px-2 sm:px-16 sm:px-4 mx-auto lg:container pt-6">            
                <ul class="mb-4 lg:mb-6">
                    <ProposalOur class="our" :importo="importo" :text="ourText" :textAlt="ourTextAlt" :active="selected == importo" @rataClick="rataClick" @selected="toggleSelected" :url="secciUrl(importo)"></ProposalOur>
                </ul>
                <h3 class="text-base mb-4 lg:mb-6 mt-6 text-center lg:text-left">{{ $t("Altre proposte di linea di credito ricaricabile") }}</h3>            
                <ul class="lg:mb-6">
                    <ProposalOther v-if="importo != 600" :model="model600" :text="text600" :textAlt="text600Alt" :active="selected == 600" @rataClick="rataClick" @click.native="toggleSelected(600)" :url="secciUrl(600)"></ProposalOther>
                    <ProposalOther v-if="importo != 2000" :model="model2000" :text="text2000" :textAlt="text2000Alt" :active="selected == 2000" @rataClick="rataClick" @click.native="toggleSelected(2000)" :url="secciUrl(2000)"></ProposalOther>
                </ul>
            </div>
        </div>
        <portal to="sub-form">
            <div class="limited-offer w-11/12 sm:max-w-7xl mx-auto shadow-lg pt-2 rounded-3xl bg-limited-offer px-2 lg:px-16 -mt-6 mb-10">
                <div class="px-2 mx-auto lg:container pt-2 sm:pt-5 pb-2">
                    <h2 class="mb-2 sm:mb-4 lg:mb-6 text-white text-sm lg:text-3xl font-semibold justify-center lg:justify-start flex items-center flex-wrap">
                        <div class="mr-2 mb-2 sm:mb-0 w-full sm:w-auto text-center">
                            <img class="h-7 mx-auto" src="@/assets/images/rata-clock.svg" />
                        </div>
                        <p>{{ $t("Offerta limitata, solo 100 prestiti personali") }}</p>
                    </h2>
                    <ul>
                        <ProposalLimited :importo="6000" @rataClick="rataClick" :active="selected == 6000" @selected="toggleSelected" class="mb-2 lg:mb-3"></ProposalLimited>                    
                        <ProposalLimited :importo="7000" @rataClick="rataClick" :active="selected == 7000" @selected="toggleSelected"></ProposalLimited>
                    </ul>
                </div>
            </div>
        </portal>
    </div>
</template>
<script>
import ProposalOur from "@/components/ProposalOur";
import ProposalLimited from "@/components/ProposalLimited";
import ProposalOther from "@/components/ProposalOther";

export default {
    components: { ProposalOur, ProposalOther, ProposalLimited },
    data() {
        return {
            selected: this.$store.state.choosenCredit
        }
    },
    mounted() {
        this.showFlashMessage()
    },
    beforeDestroy() {
        this.resetFlashMessage()
    },
    computed: {
        ourText() {
            const taeg = this.importo == 600 ? '0,00%' : '21,70%'
            return `Rata <strong>60</strong> € TAEG <strong>${taeg}</strong>`
        },
        ourTextAlt() {
            const taeg = this.importo == 600 ? '0,00%' : '21,70%'
            //return `Rata <strong>60</strong> € TAEG <strong>${taeg}</strong>`
            return `<span class="md:hidden"><strong>${this.importo}</strong> €</span> <span class="md:capitalize">rata</span> <strong>60</strong> € TAEG <strong>${taeg}</strong>`
        },
        text600() {
            return `Rata <strong>60</strong> € per <strong>10</strong> mesi TAEG <strong>0,00%</strong>`
        },
        text600Alt() {
            return `<span class="md:hidden"><strong>600</strong> €</span> <span class="md:capitalize">rata</span> <strong>60</strong> € per <strong>10</strong> mesi TAEG <strong>0,00%</strong>`
        },
        text2000() {
            return `Rata <strong>60</strong> € TAEG <strong>21,70%</strong>`
        },
        text2000Alt() {
            return `<span class="md:hidden"><strong>2.000</strong> €</span> <span class="md:capitalize">rata</span> <strong>60</strong> € TAEG <strong>21,70%</strong>`
        },
        model600() {
            return this.$store.getters.rateOffers({ importo: 600 })[0]
        },
        model2000() {
            return this.$store.getters.rateOffers({ importo: 2000 })[0]
        },
        importo() {
            return this.$store.state.choosenCredit
        },        
        flashMessageShowed() {
            return this.$store.state.flashMessageShowed
        }
    },
    methods: {
        secciUrl(importo) {
            if(importo == 600)
                return process.env.VUE_APP_ENV == 'production' ?
                    'https://cofidis-secci.s3.eu-central-1.amazonaws.com/revolving/prod/SECCI_Revolving.pdf' :
                    'https://cofidis-secci.s3.eu-central-1.amazonaws.com/revolving/stage/SECCI_Revolving.pdf'
            else
                return process.env.VUE_APP_ENV == 'production' ?
                    'https://cofidis-secci.s3.eu-central-1.amazonaws.com/revolving/prod/SECCI_REV_1120_compilato_no_promo.pdf' :
                    'https://cofidis-secci.s3.eu-central-1.amazonaws.com/revolving/stage/SECCI_REV_1120_compilato_no_promo.pdf'
        },
        showFlashMessage() {
            if (!this.flashMessageShowed) {
                const vm = this
                const message = 'Affrettati! Un utente ha ricevuto esito positivo alla sua richiesta di prestito proprio ora.'
                this.flashTimeout = window.setTimeout(function() {
                    vm.$store.commit("setFlashMessage", message)
                }, 5000)
            }
        },
        resetFlashMessage() {
            window.clearTimeout(this.flashTimeout)
            this.$store.commit("resetFlashMessage");
        },
        toggleSelected(value) {
            if(window.innerWidth < 1024) this.selected = value
        },        
        rataClick(value, rate) {            
            this.$store.state.userData.rate = rate
            this.$store.state.userData.importo = value
        },
        displayOpen(value) {            
            return this.selected == value
        }
    }
};
</script>