<template>
    <FormCard @submit="onSubmit" title="Dati personali">
        <div class="w-full sm:pr-4 sm:w-1/2 mb-8">
            <label class="text-xs">{{ $t("Tipologia di abitazione") }}</label>
            <select name="Abitazione" class="form-select" :class="{ 'border-red-700': $v.dwelling_type.$error }" v-model="dwelling_type">
                <option :value="null" disabled>{{ $t("Tipologia di abitazione") }}</option>
                <option :value="1">{{ $t("IN AFFITTO") }}</option>
                <option :value="2">{{ $t("CON GENITORI, PARENTI") }}</option>
                <option :value="5">{{ $t("DI PROPRIETÀ") }}</option>
            </select>
            <div class="text-red">
                <small v-if="$v.dwelling_type.$error && !$v.dwelling_type.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div v-if="dwelling_type === 1" class="w-full sm:w-1/2 mb-4 sm:mb-0">
            <label class="text-xs">{{ $t("Affitto mensile") }}</label>
            <input type="text" class="form-input" v-mask="currencyMask" v-model="monthly_rent" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" />
            <div class="text-red">
                <small v-if="$v.monthly_rent.$error && !$v.monthly_rent.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
    </FormCard>
</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapUserData } from "@/utils";
import { currencyMask } from "@/masks";
export default {
    mounted() {
        this.$ga.pageview()
    },
    data() {
        return {
            currencyMask
        };
    },
    computed: mapUserData(["dwelling_type", "monthly_rent"]),
    validations: {
        dwelling_type: {
            required
        },
        monthly_rent: {
            required: requiredIf(function() {
                return this.dwelling_type === 1;
            })
        }
    },
    watch: {
        dwelling_type() {
            this.monthly_rent = null
            this.$v.$reset()
        }
    },
    methods: {
        onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                this.$store.dispatch("event", { name: "PersonalData2Submit" });
                this.$store.dispatch("sendData", { step: "dati_pers_2" });
                this.$store.dispatch("redirect", { step: "dati_pers_2", route: "DeliveryData" });
            }
        }
    }
};
</script>
