<template>
    <div class="w-full">
        <FormCard @submit="onSubmit" title="Non perdere questa occasione!">
            <p class="mb-4">
                Con il servizio CLUB PLATINUM, per 12 mesi puoi:<br>
                • Usufruire di <strong>esperienze uniche in formula 2x1</strong>: paghi per uno e invita chi vuoi (ad esempio cinema, ristoranti e hotel)<br>
                • Beneficiare di sconti, buoni spesa e vantaggi del programma Lucky Days<br>
                Non lasciarti sfuggire questa occasione!
            </p>
            <div slot="link" class="text-center text-gray-700 mt-4 mb-8">
                <button @click="onDecline()" class="underline">Procedi senza Club</button>
            </div>
        </FormCard>
    </div>
</template>
<style scoped type="text/css">
@media screen and (max-width: 639px) {
    div >>> .form-header { font-size: 1rem }
}
</style>
<script>
import { CLUB_PLATINUM } from '../../constants';

export default {
    mounted() {
        this.$ga.pageview()
        this.$store.dispatch("sendData", { step: "club_warning" })
    },
    methods: {
        onDecline() {
            this.$store.commit("setUserData", { key: 'service', value: false });
            this.$store.dispatch("redirect", { step: "club_warning_ko", route: "DeliveryData" });
        },
        onSubmit() {
            this.$store.commit("setUserData", { key: 'service', value: true });
            this.$store.commit("setUserData", { key: 'service_pack', value: CLUB_PLATINUM.code });
            this.$store.commit("setUserData", { key: 'service_amount', value: CLUB_PLATINUM.value/12 });
            this.$store.dispatch("redirect", { step: "club_consent", route: "DeliveryData" });
        }
    }
};
</script>