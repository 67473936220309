<template>
    <FormBox title="Protezione del pagamento" @submit="onSubmit()" declineText="Procedi senza proteggerti" @decline="nextStep()">
        
        <ProgressPz slot="form-header" progress="60" />

        <p class="text-red-1 text-base font-bold">
            Non perdere questa occasione! Difendi te e la tua famiglia dalle
            circostanze impreviste della vita.
        </p>
        <ul class="ml-4 mb-4 mt-2 sm:text-base sm:text-red-1">
            <li>
                - Assicura il rimborso del tuo prestito.
                    Rimani tranquillo contro incertezze future.
            </li>
            <li>
                - Altre tipologie di assicurazioni potrebbero non coprirti nel caso di
                problemi nella restituzione del prestito.
            </li>
        </ul>
        <p class="text-base">
            <strong>La soluzione che ti proponiamo</strong>, con soli {{ getInsurancaRate }} euro di
            premio mensile in più sulla rata, <strong>copre</strong>:
        </p>
        <ul class="list-disc ml-4 mb-2 mt-2 sm:text-base">
            <li>
                <b>Decesso</b> - in caso di decesso, l’assicurazione corrisponde una
                somma pari al capitale residuo del finanziamento alla data del decesso.
            </li>
            <li>
                <b>Perdita Totale e Irreversibile di Autonomia</b> – in caso di
                invalidità da infortunio o malattia, l’assicurazione corrisponde una
                somma pari al capitale residuo del finanziamento alla data di
                riconoscimento dell'invalidità.
            </li>
            <template v-if="insurancePackage == 'G'">
                <li>
                    <b>Perdita Involontaria di Impiego</b> - in caso di perdita del
                    lavoro, l’assicurazione corrisponde 9 mensilità alla data del
                    licenziamento.
                </li>
                <li>
                    <b>Inabilità Temporanea Totale al lavoro</b> - in caso di malattia e
                    impossibilità lavorativa, l’assicurazione rimborsa le residue
                    mensilità del finanziamento alla data del sinistro.
                </li>
            </template>
        </ul>
        <p class="my-4 text-base">
            Condizioni del contratto di polizza contenute nel
            <b>set informativo - in particolare presta attenzione alle limitazioni,
                alle franchigie, alle esclusioni e ai periodi carenza</b>
        </p>

    </FormBox>
</template>
<script>
import FormBox from "./FormBox";
import ProgressPz from "./ProgressPz";

import { mapGetters } from "vuex";
import { mapUserData } from "@/utils";

export default {
    components: { FormBox, ProgressPz },
    mounted() {
        this.$store.dispatch("sendData", { step: "assicurazione_warning" })
    },
    computed: {
        ...mapGetters(["insurancePackage", "insuranceRate"]),
        ...mapUserData(["employment_type", "insurance"]),
        getInsurancaRate() {
            return this.insuranceRate.replace('.', ',')
        }
    },
    methods: {
        onSubmit() {            
            this.$store.dispatch("redirect", { step: "insurance_warning_back", route: "LoanProtectionPzConsent" });
        },
        nextStep() {
            this.$store.commit("resetInsuranceConsent")
            this.$store.dispatch("redirect", { step: "insurance_warning_ko", route: "PersonalDataPz" });
        }
    }
};
</script>