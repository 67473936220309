<template>
    <li class="proposal proposal-our open text-base sm:text-lg lg:text-xl flex flex-wrap border border-t-0 lg:border-t lg:flex-no-wrap w-full text-gray-700 rounded-3xl lg:rounded-full lg:overflow-hidden" :class="`border-${color}`" :key="`p_${model.id}`" v-if="active">
        <div class="lg:w-1/4 w-full flex items-center lg:justify-between px-2 rounded-t-3xl lg:rounded-t-lg lg:rounded-tr-none" :class="`bg-${color}`" >
            <span class="ml-2 lg:ml-0 mr-auto lg:mr-0 p-2 whitespace-no-wrap font-semibold">{{ model.imp_finanz_text }}</span>
            <a class="sm:text-base underline mr-4" target="_blank" :href="secciUrl">SECCI</a>
        </div>
        <div class="lg:w-1/2 w-full flex mt-0 items-center justify-center p-2">
            <p v-if="text" v-html="text"></p>
            <p v-else >
                <span class="mr-1 sm:hidden">{{ $t("Rata") }}</span>
                <span class="font-semibold">{{ model.imp_rata }} €</span> {{ $t("per") }}
                <span class="font-semibold">{{ model.rate }}</span> {{ $t("mesi") }}
                <span class="font-semibold">{{ $t("TAEG") }} {{ model.taeg }}</span>%
            </p>
        </div>
        <div class="w-full lg:w-auto lg:ml-auto text-center lg:text-right -mb-5 lg:-mb-0">
            <button class="cta rounded-full p-2 px-16 text-base font-bold sm:mt-0.5 sm:mr-0.5 shadow-xl lg:shadow-none bg-accent" @click="onSubmit">
                {{ $t("CONTINUA") }}
            </button>
        </div>
    </li>
    <li class="proposal proposal-our close text-xs ipp:text-sm sm:text-lg lg:text-xl flex flex-wrap items-center bg-white lg:flex-no-wrap w-full text-gray-700 rounded-full border border-border lg:overflow-hidden" :key="`p_${model.id}`" v-else @click="$emit('selected', model.imp_finanz)">
        <div class="p-1 lg:flex-initial hidden lg:block">
            <strong class="xl:ml-10 0 p-2 whitespace-no-wrap">{{ model.imp_finanz_text }}</strong>
        </div>
        <div class="flex-1 lg:flex-initial pr-3 justify-start self-stretch lg:border-r flex items-center lg:justify-center lg:w-auto lg:ml-auto hidden lg:flex">
            <a class="sm:text-base underline mr-4" target="_blank" :href="secciUrl">SECCI</a>
        </div>
        <div class="btn-wrapper flex-1 lg:flex-initial text-right lg:w-1/4 lg:order-last hidden lg:block">
            <button class="cta bg-accent rounded-full p-2 px-16 text-base font-bold lg:mr-0.5 shadow-xl lg:shadow-none" @click="onSubmit()">
                {{ $t("CONTINUA") }}
            </button>
        </div>
        <div class="w-auto p-2 px-4 lg:flex-initial lg:w-1/2 lg:text-center">
            <p v-if="textAlt" v-html="textAlt"></p>
            <p v-else>
                <span class="font-semibold lg:hidden">{{ model.imp_finanz_text }}</span>
                <span class="font-semibold lg:hidden ml-1 sm:ml-4 mr-1">rata</span>
                <span class="font-semibold">{{ model.imp_rata }} €</span> {{ $t("per") }}
                <span class="font-semibold">{{ model.rate }}</span> {{ $t("mesi") }}
                <span class="font-semibold">{{ $t("TAEG") }} {{ model.taeg }}</span>%
            </p>
        </div>
    </li>
</template>
<script>
export default {
    props: {
        importo: {
            type: Number,
            required: true
        },
        text: {
            type: String,
            required: false
        },
        textAlt: {
            type: String,
            required: false
        },
        url: {
            type: String,
            default: null
        },
        active: {
            type: Boolean,
            required: false,
            default: true
        },
        color: {
            type: String,
            default: 'yellow'
        }
    },
    computed: {
        secciUrl() {
            if(this.promo === this.importo) {
                return this.getPromoOffer(false).secciUrl
            } else {
                return this.url ?? `${this.baseUrl}${this.model.imp_finanz}_${this.model.rate}.pdf`
            }
        },
        baseUrl() {
            return this.$store.state.secciUrl
        },
        model() {
            if(this.promo === this.importo) {
                return this.getPromoOffer(false)
            } else {
                return this.$store.getters.rateOffers({importo: this.importo})[0];
            }
        }
    },
    methods: {
        onSubmit() {
            this.$store.commit("setChoosenRata", this.model.rate);
            this.$store.commit("upadateUserCreditData");
            if (this.$route.name === "RataUpgrade") {
                this.$store.dispatch("event", { name: "RataUpgradeClick", source: this });
            } else {
                this.$store.dispatch("event", { name: "RataClick", source: this });
            }
            this.$emit('rataClick', this.importo, this.model.rate)
            this.$store.dispatch("redirect", { step: "proposal_our", route: "EmailPrivacyConsent" });
        }
    }
};
</script>
