<template>
    <div class="mx-auto lg:container text-sm sm:text-base leading-tight text-gray-700">
        <div class="px-6 py-6 text-center text-2xl sm:text-3xl font-semibold">
            Gentile <strong>{{ name }}</strong>, grazie per averci scelto!
        </div>
        <h1 class="text-xl sm:text-2xl font-semibold text-center sm:mt-4 mb-6 sm:mb-8 uppercase bg-orange-1 py-2">I PROSSIMI PASSI</h1>
        <div class="max-w-4xl text-lg mx-auto py-4 px-6">
            <div class="flex mb-6 items-center">
                <div class="w-1/5 text-center">
                    <img class="h-12 mx-auto" src="@/assets/images/typ-envelope.svg" />
                </div>
                <p class="w-4/5 pl-4 sm:pl-0 text-sm">
                    A breve riceverai l’e-mail con il primo esito di fattibilità e successivamente, in caso di esito positivo, riceverai nella tua casella di posta elettronica la richiesta di finanziamento da firmare.
                </p>
            </div>
            <div class="flex mb-6 items-center">
                <div class="w-1/5 text-center">
                    <img class="h-12 mx-auto" src="@/assets/images/typ-contract.svg" />
                </div>
                <p class="w-4/5 pl-4 sm:pl-0 text-sm">
                    Firma subito la richiesta di finanziamento in pochi click usando il codice OTP che riceverai via SMS e allega le foto dei documenti richiesti (puoi anche scattare una foto dal tuo cellulare).
                </p>
            </div>
            <div class="flex mb-6 items-center">
                <div class="w-1/5 text-center">
                    <img class="h-12 mx-auto" src="@/assets/images/typ-smile.svg" />
                </div>
                <p class="w-4/5 pl-4 sm:pl-0 text-sm">
                    Una volta inviata la documentazione completa e corretta, riceverai l’esito definitivo della tua richiesta.
                </p>
            </div>

            <h2 class="text-xl sm:text-3xl font-semibold text-center mt-6 mb-4 uppercase">Come apporre la firma digitale</h2>
            <div class="w-full sm:w-4/5 flex items-center justify-center mx-auto relative">
                <YouTubeInline :videoId="videoId" ref="video"/>
            </div>

            <div class="text-center mt-10">
                A presto<br />
                <strong>Il Team Cofidis</strong>
            </div>
            <div class="text-center mt-10">
                <span>Seguici anche su</span>
                <div class="social-links">
                    <a class="inline-block social-link p-4" target="_blank" rel="nofollow" href="https://www.facebook.com/CofidisItalia/">
                        <img class="h-12 mx-auto" src="@/assets/images/typ-fb.svg" />
                    </a>
                    <a class="inline-block social-link p-4" target="_blank" rel="nofollow" href="https://www.instagram.com/cofidisitalia/">
                        <img class="h-12 mx-auto" src="@/assets/images/typ-ig.svg" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapUserData } from "@/utils";
import YouTubeInline from "@/views/themes/prestito/YouTubeInline";

export default {
    mounted() {
        this.$ga.pageview()
        this.$refs.video.init()
    },
    components: {
        YouTubeInline
    },
    computed: {
        ...mapUserData(["name","importo"]),
        videoId() {
            if(this.importo > 2000)
                return `6f43JlNpZbQ`
            else
                return `7xj8ZX57zYo`
        }
    }
};
</script>
