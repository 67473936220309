<template>
    <FormBox title="Consensi privacy" submitText="Invia richiesta" @submit="onSubmit()" @decline="goBack()">

        <ProgressPz slot="form-header" progress="90" />

        <div class="flex flex-wrap sm:flex-nowrap">
            <div class="relative sm:flex-shrink-0 mr-4 order-2 sm:order-1">
                <input type="radio" v-model="privacy1" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
            </div>
            <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                <input type="radio" v-model="privacy1" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
            </div>
            <div class="w-full sm:flex-grow order-1 sm:order-3 mb-2" v-html="$t('Lette e comprese l\’<a target=\'_blank\' href=\'https://www.cofidis.it/it/download/Informativa-Privacy.pdf\' class=\'underline\'>Informativa Privacy</a>, resa ai sensi degli artt. 13 e 14 del Regolamento UE 679/2016, e l\’<a target=\'_blank\' href=\'https://www.cofidis.it/it/download/Informativa-Privacy.pdf\' class=\'underline\'>Informativa per tutti gli Operatori di SIC</a> - Sistema di Informazioni Creditizie -, resa ai sensi dell’art. 6 del Codice di Condotta per i Sistemi Informativi gestiti da soggetti privati in tema di crediti al consumo, affidabilità e puntualità nei pagamenti, consapevole che, in mancanza di tale conferma, Cofidis non potrà dar corso alle operazioni o servizi richiesti. Puoi gestire i seguenti consensi facoltativi:')">
            </div>
        </div>
        <div class="w-full mb-4 text-red">
            <div v-if="$v.privacy1.$error && !$v.privacy1.required">
                {{ $t("Campo obbligatorio") }}
            </div>
            <div v-if="privacy1 === false">
                {{ $t("Non è possibile proseguire senza questo consenso.") }}
            </div>
        </div>
        <div class="flex flex-wrap sm:flex-nowrap">
            <div class="relative sm:flex-shrink-0 mr-4 order-2 sm:order-1">
                <input type="radio" v-model="privacy2" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
            </div>
            <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                <input type="radio" v-model="privacy2" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
            </div>
            <div class="w-full sm:flex-grow order-1 sm:order-3 mb-2" v-html="$t('Acconsento al trattamento dei miei dati personali per finalità di marketing per prodotti e servizi di Cofidis, di società appartenenti al Gruppo di Cofidis ed al Gruppo Crédit Mutuel, o di società terze')">
            </div>
        </div>
        <div class="w-full mb-4 text-red">
            <div v-if="$v.privacy2.$error && !$v.privacy2.required">
                {{ $t("Campo obbligatorio") }}
            </div>
            <div v-if="privacy2 === false">
                {{ $t("Attenzione! Se scegli “no” non potrai partecipare alle nostre attività promozionali, a concorsi, né ricevere offerte di altri prodotti Cofidis.") }}
            </div>
        </div>
        <div class="flex flex-wrap sm:flex-nowrap">
            <div class="relative sm:flex-shrink-0 mr-4 order-2 sm:order-1">
                <input type="radio" v-model="privacy3" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
            </div>
            <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                <input type="radio" v-model="privacy3" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
            </div>
            <div class="w-full sm:flex-grow order-1 sm:order-3 mb-2" v-html="$t('acconsento al trattamento dei miei dati personali per finalità di profilazione delle mie preferenze, caratteristiche, abitudini o scelte di consumo al fine di ricevere offerte, promozioni ed informazioni che tengano conto dei miei interessi')">
            </div>
        </div>
        <div class="w-full mb-4 text-red">
            <div v-if="$v.privacy3.$error && !$v.privacy3.required">
                {{ $t("Campo obbligatorio") }}
            </div>
            <div v-if="privacy3 === false">
                {{ $t("Attenzione! Se scegli “no” non potremo proporti offerte e servizi personalizzati che tengano conto delle tue esigenze ed interessi specifici.") }}
            </div>
        </div>
        <div class="flex flex-wrap sm:flex-nowrap">
            <div class="relative sm:flex-shrink-0 mr-4 order-2 sm:order-1">
                <input type="radio" v-model="privacy4" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
            </div>
            <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                <input type="radio" v-model="privacy4" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
            </div>
            <div class="w-full sm:flex-grow order-1 sm:order-3 mb-2" v-html="$t('acconsento al trattamento dei miei dati per comunicazione a terzi, a società appartenenti al Gruppo Cofidis ed al Gruppo Crédit Mutuel, per proprie finalità di marketing')">
            </div>
        </div>
        <div class="w-full mb-4 text-red">
            <div v-if="$v.privacy4.$error && !$v.privacy4.required">
                {{ $t("Campo obbligatorio") }}
            </div>
            <div v-if="privacy4 === false">
                {{ $t("Attenzione! Se scegli “no” non potrai ricevere le offerte speciali da parte dei nostri partner.") }}
            </div>
        </div>
        <div class="w-full mt-10">
            <div class="w-full mb-2 text-base">
                Sono una persona politicamente esposta (PEP)
            </div>
            <div class="flex flex-wrap sm:flex-nowrap">
                <div class="relative sm:flex-shrink-0 mr-4 order-2 sm:order-1">
                    <input type="radio" v-model="politics" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
                </div>
                <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                    <input type="radio" v-model="politics" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
                </div>
            </div>
            <div class="w-full mb-2 text-red">
                <div v-if="$v.politics.$error && !$v.politics.required">
                    {{ $t("Campo obbligatorio") }}
                </div>
                <div v-if="politics === true">
                    {{ $t("Per favore, verifica la definizione di Persona Politicamente Esposta") }}
                </div>
            </div>
            <div class="text-xs text-gray-4">
                <a href="#" class="underline" @click.prevent="showExplanation = !showExplanation">{{ $t("Clicca qui") }}</a>
                {{ $t("per leggere la definizione di PEP") }}
            </div>
            <SlideUpDown :active="showExplanation" :class="{'mb-5': showExplanation}">
                <div class="py-5 text-gray-600 text-base border-t border-b mt-5">
                    {{ $t("Sono ”persone politicamente esposte” (PEP), le persone fisiche che occupano o hanno cessato di occupare da meno di un anno importanti cariche pubbliche, nonché i loro familiari e coloro che con i predetti soggetti intrattengono notoriamente stretti legami. A titolo esemplificativo (elenco completo indicato nel D.lgs. 231/2007 e successivi aggiornamenti): capi di Stato o di governo, ministri, vice-ministri o sottosegretari, parlamentari, Presidente di Regione, assessore regionale, Sindaco di capoluogo di provincia o città metropolitana, Sindaco di comune con popolazione non inferiore a 15.000 abitanti, nonché cariche analoghe in Stati esteri membri delle corti supreme, delle corti costituzionali o di altri organi giudiziari di alto livello, i membri delle Corti dei Conti e dei Consigli di Amministrazione delle Banche centrali, gli ambasciatori, gli incaricati d’affari e gli ufficiali di alto livello delle forze armate, i membri del Consiglio di Amministrazione, direzione o vigilanza delle imprese possedute dallo Stato, Regioni, Comuni, direttore generale di ATS (ex ASL) e di azienda ospedaliera, di azienda ospedaliera universitaria e degli altri enti del servizio sanitario nazionale.") }}
                </div>
            </SlideUpDown>
        </div>
        <div v-if="showPromoNotAvailable" class="mt-8">
            <p class="text-gray-700 text-base ">
                Cliccando su Invia richiesta prendo atto che il mio profilo non soddisfa i <span @click="showPromoModal" class="underline cursor-pointer">Termini e condizioni</span>
                per richiedere l’offerta promo e che invio richiesta di {{ promoKO.imp_finanz_text }} TAEG {{ promoKO.taeg }}%.
            </p>
        </div>
    </FormBox>
</template>
<script>
import ProgressPz from './ProgressPz'
import FormBox from './FormBox'
import { mapUserData } from "@/utils";
import { mapGetters } from 'vuex';
import { required } from "vuelidate/lib/validators";

export default {
    components: { ProgressPz, FormBox },
    data() {
        return {
            showExplanation: false
        };
    },
    computed: {
        ...mapUserData(["privacy1", "privacy2", "privacy3", "privacy4", "politics","service","importo"]),
        ...mapGetters(["promoAvailable", "promoKO"]),
        showPromoNotAvailable() {
            return this.$store.state.PATH === 'FE' && this.importo === this.promo && !this.promoAvailable
        },
    },
    validations: {
        privacy1: {
            required
        },
        privacy2: {
            required
        },
        privacy3: {
            required
        },
        privacy4: {
            required
        },
        politics: {
            required
        }
    },
    methods: {
        async onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid && this.privacy1 === true) {
                
                // subscribe club
                if(this.service) this.$store.dispatch('subscribeClub')

                if(this.$store.state.PATH === 'FE') {
                    this.$store.dispatch("sendData", { step: "dati_privacy" });
                } else {
                    this.$store.dispatch("sendData", { step: "esito_ko" });    
                }
                localStorage.setItem('pezzotto_request_evaluation', 1)
                this.$store.dispatch("redirect", { step: "dati_privacy", route: "ThankYou" });
            }
        }
    }
};
</script>