<template>
    <div>
        <button 
            class="sticky-btn cta text-lg rounded-full px-12 py-3 z-2 cursor-pointer block" 
            :class="{'fixed bottom-6 left-1/2 transform -translate-x-1/2 transition-opacity duration-200': isFixed, 'opacity-0': isHidden }" 
            @click="handleClick"
        >
            <slot></slot>
        </button>
        <div v-if="isFixed" :style="`height: ${buttonHeight}px`"></div>
    </div>
</template>


<script>

function debounce(callback, delay) {
  let timer;
  
  return function() {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(this, arguments);
    }, delay);
  };
}

export default {
    name: "StickyButton",
    data() {
       return {
            isFixed: false,
            isHidden: true,
            buttonY: 0,
            buttonHeight: 0,
            bottomMargin: 20,
       } 
    },
    props: {
        clickHandler: Function
    },
    mounted() {
        setTimeout(() => {
            this.buttonPosition()
            this.isHidden = false
        }, 60);
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        buttonPosition() {
            const button = this.$el.querySelector('.sticky-btn');
            this.buttonY = button.getBoundingClientRect().top + document.documentElement.scrollTop;
            this.buttonHeight = button.clientHeight
            if (window.innerHeight <= this.buttonY + this.buttonHeight)
            {
                this.isFixed = true;
                window.addEventListener('scroll', this.handleScroll);
            }
            else {
                this.isFixed = false;
                window.removeEventListener('scroll', this.handleScroll);
            }
        },
        handleScroll() {
            if ((window.scrollY + window.innerHeight - this.bottomMargin - this.buttonHeight) > this.buttonY && this.isFixed) {
                this.isFixed = false;
            } 
            if((window.scrollY + window.innerHeight - this.bottomMargin - this.buttonHeight) < this.buttonY && !this.isFixed) {
                this.isFixed = true;
            }

        },
        handleResize() {debounce(this.buttonPosition, 250)},
        handleClick() {
            this.clickHandler();
        }
    },
}
</script>