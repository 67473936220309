<template>
    <main>
        <Loader v-if="loading" />
        <div v-else>
            <!-- Header -->
            <header class="w-full relative" :style="bgHeader">
                <!-- Logo -->
                <div class="logo flex justify-end container mx-auto px-6 max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <div class="z-50 w-2/3 h-14 md:w-1/3 sm:h-20 flex justify-around bg-white rounded-b-2xl py-2 lg:-mr-6 2xl:-mr-24 shadow-sm">
                        <img class="header-logo m-auto inline-block sm:hidden" src="@/assets/images/logo.svg" alt="Cofidis Logo" title="Cofidis Logo" />
                        <img class="header-logo m-auto hidden sm:inline-block" src="@/assets/images/logo-claim.svg" alt="Cofidis Logo" title="Cofidis Logo" />
                        <div class="h-full border-r border-solid border-black border-opacity-25"></div>
                        <img class="header-logo m-auto inline-block sm:hidden" src="@/assets/images/logo-power-credit.svg" alt="Power Credit Logo" title="Power Credit Logo" />
                        <img class="header-logo m-auto hidden sm:inline-block" src="@/assets/images/logo-power-credit.svg" alt="Power Credit Logo" title="Power Credit Logo" />
                    </div>
                </div>
            </header>
            <form class="w-full mx-auto text-center px-6 lg:px-0">
                <div class="bg-white rounded-3xl shadow-2xl mx-auto">
                    <div class="px-10 py-10">
                        <p class="text-xl text-gray-5 font-semibold px-4 text-2xl sm:px-8 lg:text-xl">{{ name }},</p>
                        <p class="font-medium">per te nuova disponibilità!</p>
                        <select id="credit-select" class="home-select w-48 border-2 mt-3" v-model="choosenCredit" @change="activeSolutionIndex = $event.target.selectedIndex">
                            <option v-for="price in prices" :key="price.imp_finanz" :value="price.imp_finanz">{{ price.imp_finanz|num }}€</option>
                        </select>
                        <p class="font-semibold text-xl mt-3">Richiedila in pochi click: direttamente sul tuo conto corrente!</p>
                    </div>
                </div>
            </form>
            <a @click="submit()" class="cta w-4/6 sm:w-auto font-semibold text-lg text-gray-4 bg-orange-1 max-w-xs rounded-full shadow-2xl px-6 sm:px-10 py-2 sm:max-w-none fixed bottom-6 left-1/2 transform -translate-x-1/2 z-50 cursor-pointer">
                <span class="block text-center text-xl">RICHIEDI ORA</span>
                <span class="block font-light text-base text-center -mt-1">Solo fino al <strong class="font-bold uppercase">{{ expDate }}</strong></span>
            </a>

            <!-- Section faq -->
            <section v-if="prices.length" class="section-faq text-center">
                <div class="sm:container px-6 py-6 mx-auto">
                    <h3 class="font-semibold text-3xl text-gray-5 leading-none px-6 mb-6 text-center">
                        Come funziona?
                    </h3>
                    <div class="faq-container w-full xl:w-2/3 flex flex-wrap hover:text-orange cursor-pointer bg-white rounded-xl py-3 px-6 mx-auto mb-3">
                        <div class="w-full">
                            <div class="flex flex-wrap">
                                <div class="faq-question outline-none w-4/5 sm:flex-grow text-left" :class="{ active: activeFaq == 0 }" @click="toggleFaq(0)">
                                    <span class="font-semibold text-lg text-gray-5 leading-none">Continua ad utilizzare Power Credit per i tuoi acquisti</span>
                                </div>
                                <div class="flex-grow text-right rounded-full leading-10 outline-none flex items-center justify-end"  @click="toggleFaq(0)">
                                    <svg v-if="activeFaq != 0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                                    </svg>
                                </div>
                            </div>
                            <SlideUpDown class="faq-content font-medium text-gray-5 cursor-default outline-none text-left leading-0 my-2" :active="activeFaq == 0">
                                <span class="font-light text-base text-gray-5 leading-none">
                                    Continua a fare acquisti in modo semplice e veloce grazie alla disponibilità della tua Linea di Credito Ricaricabile. 
                                    Invia la richiesta di aumento fido in pochi click e se approvata ricevi una nuova somma di denaro sul tuo conto corrente.
                                </span>
                            </SlideUpDown>
                        </div>
                    </div>
                    <div class="faq-container w-full xl:w-2/3 flex flex-wrap hover:text-orange cursor-pointer bg-white rounded-xl py-3 px-6 mx-auto mb-3">
                        <div class="w-full">
                            <div class="flex flex-wrap">
                                <div class="faq-question outline-none w-4/5 sm:flex-grow text-left" :class="{ active: activeFaq == 1 }" @click="toggleFaq(1)">
                                    <span class="font-semibold text-lg text-gray-5 leading-none">I dettagli della tua offerta</span>
                                </div>
                                <div class="flex-grow text-right rounded-full leading-10 outline-none flex items-center justify-end"  @click="toggleFaq(1)">
                                    <svg v-if="activeFaq != 1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                                    </svg>
                                </div>
                            </div>
                            <SlideUpDown class="faq-content font-medium text-gray-5 cursor-default outline-none text-left leading-0 my-2" :active="activeFaq == 1">
                                <span class="font-light text-base text-gray-5 leading-none">
                                    Con la tua richiesta di aumento fido, l’importo della tua Linea di Credito Ricaricabile passerà da {{ prices[activeSolutionIndex].fido_old|num }} € a {{ prices[activeSolutionIndex].fido_new|num }} €, 
                                    con una nuova rata minima fissa di {{ prices[activeSolutionIndex].imp_rata|num2 }}€ al mese. 
                                    TAN FISSO {{ prices[activeSolutionIndex].tan|num }}% - TAEG max {{ prices[activeSolutionIndex].taeg|num2 }}%.
                                    Richiesta soggetta ad approvazione da parte di Cofidis S.A..
                                </span>
                            </SlideUpDown>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </main>
</template>
<script>
// @ is an alias to /src
import Loader from "@/components/Loader"
// import loadData from "@/dataService.js";
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment/moment";

const redirectHomepage = () => {    
    location.href = '/aumento-fido-crm'
}

export default {
    name: "Home",
    components: { Loader },
    methods: {
        async loadClientData(data) {
            return new Promise((resolve, reject) => {
                axios.post(this.crmUrl, data)
                    .then(function(response) {
                        if(response.data && response.data.dt_end_af)
                            resolve(response.data)
                        else
                            reject(false)
                    })
                    .catch(function() {
                        reject(false)
                    })
            })
        },
        scrollToAnchor() {
            window.scrollTo({
                top: document.getElementById('anchor').offsetTop,
                left: 0,
                behavior: "smooth",
            });
        },
        toggleFaq(i) {
            if (this.activeFaq == i) this.activeFaq = null;
            else this.activeFaq = i;
        },
        async submit() {
            this.$store.commit("setUserData", { key: 'importo', value: this.choosenCredit });
            this.$store.commit("setUserData", { key: 'rate', value: this.numInstallments });
            this.$store.dispatch("sendData", { step: "dati_anag" });
            this.$store.dispatch("redirect", { step: "homepage", route: "Income" });
        },
        reloadOnBrowserBack() {            
            window.popStateDetected = false
            window.addEventListener('popstate', redirectHomepage)
        },
    },
    computed: {
        ...mapGetters(["uniquePrices"]),
        choosenCredit: {
            get() {
                return this.$store.state.choosenCredit;
            },
            set(val) {
                this.$store.commit("setChoosenCredit", val);
            }
        },
        bgHeader() {
            const env = process.env.VUE_APP_ENV == "production" ? 'prod' : 'stage'
            return {
                '--bg-header': `#fff url("${this.distributionUrl}/${env}/header.jpg") 55% 0% no-repeat`,
                '--bg-header-sm': `#fff url("${this.distributionUrl}/${env}/header-sm.jpg") center top no-repeat`,
            }
        },
        bgBanner() {
            return {
                '--bg-banner': `#fff url("${this.distributionUrl}/credito-cofidis-banner.png") 60% top no-repeat`,
                '--bg-banner-sm': `#fff url("${this.distributionUrl}/credito-cofidis-banner-sm.png") 60% top no-repeat`,
            }
        },
        numInstallments() {
            return this.prices[this.activeSolutionIndex].rate
        },
        crmUrl() {
            return this.$store.state.crmUrl;
        }
    },
    data() {
        return {
            name: '',
            loading: true,
            expDate: null,
            activeFaq: null,
            activeSolutionIndex: 0,
            prices: [],
            distributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/aumento-fido-crm",
            commonDistributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/common"
        };
    },
    async created() {
        this.reloadOnBrowserBack()

        // get params
        const urlParts = this.$store.state.urlParts
        let userId, codeCampaign, trackCode;

        if(urlParts[1] && urlParts[2]) {
            userId = urlParts[1]
            codeCampaign = urlParts[2]
            trackCode = urlParts[3] ?? null
        }
        else {
            userId = localStorage.getItem('aumento_fido_user_id')
            codeCampaign = localStorage.getItem('aumento_fido_code_campaign')
            trackCode = localStorage.getItem('aumento_fido_track_code') ?? null
            // fix localstorage cast type issue
            if(trackCode === "null") trackCode = null;
        }

        try {
            localStorage.setItem('aumento_fido_user_id', userId)
            localStorage.setItem('aumento_fido_code_campaign', codeCampaign)
            if(trackCode) localStorage.setItem('aumento_fido_track_code', trackCode)

            const data = await this.loadClientData({userId, codeCampaign, trackCode})
            this.prices = [
                {"imp_finanz":data.importo_3_af,"fido_old":data.fido_old_af,"fido_new":data.fido_new_af,"imp_rata":data.rata_new_af,"tan":data.tan_af,"taeg":data.taeg_af},
                {"imp_finanz":data.importo_2_af,"fido_old":data.fido_old_af,"fido_new":data.fido_new_af,"imp_rata":data.rata_new_af,"tan":data.tan_af,"taeg":data.taeg_af},
                {"imp_finanz":data.importo_1_af,"fido_old":data.fido_old_af,"fido_new":data.fido_new_af,"imp_rata":data.rata_new_af,"tan":data.tan_af,"taeg":data.taeg_af}
            ];
            if(!this.choosenCredit) this.choosenCredit = this.prices[0].imp_finanz;
            this.$store.commit("setUserData", { key: 'user_id', value: userId });
            this.$store.commit("setUserData", { key: 'code_campaign', value: codeCampaign });
            this.$store.commit("setUserData", { key: 'code_campaign_rev', value: data.cod_camp_rev });
            this.$store.commit("setUserData", { key: 'prat_id_rev', value: data.pratica_id_rev });

            this.$store.commit("setUserData", { key: 'aum_fido', value: data.aumento_fido });
            this.$store.commit("setUserData", { key: 'fido_old', value: data.fido_old_af });
            this.$store.commit("setUserData", { key: 'fido_new', value: data.fido_new_af });
            this.$store.commit("setUserData", { key: 'rata_old', value: data.rata_old_af });
            this.$store.commit("setUserData", { key: 'rata_new', value: data.rata_new_af });
            this.$store.commit("setUserData", { key: 'tan', value: data.tan_af });
            this.$store.commit("setUserData", { key: 'taeg', value: data.taeg_af });
            this.$store.commit("setUserData", { key: 'dispo_old', value: data.dispo_old_af });
            this.$store.commit("setUserData", { key: 'dispo_new', value: data.dispo_new_af });
            this.$store.commit("setUserData", { key: 'dt_start', value: moment(data.dt_start_af).locale("it").format('DD-MM-YYYY') });
            this.$store.commit("setUserData", { key: 'dt_end', value: moment(data.dt_end_af).locale("it").format('DD-MM-YYYY') });
            this.$store.commit("setUserData", { key: 'scadenza_documento', value: moment(data.data_scadenza_documento).locale("it").format('DD-MM-YYYY') });

            this.$store.commit("setUserData", { key: 'name', value: data.Nome });
            this.$store.commit("setUserData", { key: 'surname', value: data.Cognome });
            this.$store.commit("setUserData", { key: 'email', value: data.EMail });
            this.$store.commit("setUserData", { key: 'iban', value: data.Iban });
            this.$store.commit("setUserData", { key: 'fiscal_code', value: data.CF });
            this.$store.commit("setUserData", { key: 'cellphone', value: data.Phone });
            this.$store.commit("setUserData", { key: 'day_in_month', value: 1 });

            // set name
            this.name = data.Nome

            // set expiry date
            this.expDate = moment(data.dt_end_af).locale("it").format('D MMMM')
            this.$store.commit("setUserData", { key: 'exp_date', value: this.expDate });

            // offer expired
            if(moment(data.dt_end_af).endOf('day') < moment()) {
                this.$store.dispatch("redirect", {step: "income", route: "RequestExpired"});
                return;
            }
            // pending procedure
            if(data.page_block && data.page_block === 'evaluation') {
                this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
                return;
            }
            // failed procedure
            if(data.page_block && data.page_block === 'reddito_not_allowed') {
                this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
                return;
            }

            // check expired document
            if(moment(data.data_scadenza_documento) < moment()) {
                this.$store.commit("setUserData", { key: 'document_expired', value: true });
            }
            else {
                this.$store.commit("setUserData", { key: 'document_expired', value: false });
            }

            // init robot
            this.$store.dispatch("sendData", { step: "enter_landing" });
        } catch (error) {
            // reload from thank you page
            if(localStorage.getItem('aumento_fido_request_evaluaton')) {
                this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
            }
            else {
                this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
            }
        }
        this.loading = false;
    },
    async mounted() {
        // animation interesection
        window.onscroll = function() {
            const animateFadeInUp = document.getElementsByClassName("animateFadeInUp");
            const windowOffsetTop = window.innerHeight + window.scrollY;

            Array.prototype.forEach.call(animateFadeInUp, (animatedBox) => {
                const animatedBoxOffsetTop = animatedBox.offsetTop;
                if (windowOffsetTop >= animatedBoxOffsetTop) {
                    animatedBox.classList.add('animate__fadeInUp')
                }
            });
        }
    }
};
</script>