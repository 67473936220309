<template>
    <div class="mx-auto lg:container text-sm sm:text-base leading-tight text-gray-700 py-4">
        <div class="px-6 py-6 text-center text-2xl sm:text-3xl font-semibold">
            <i class="icon smile block mx-auto mb-4"></i>
            La richiesta di Prestito Personale è stata inviata
        </div>
        <h1 class="text-xl sm:text-2xl font-semibold text-center sm:mt-4 mb-6 sm:mb-8 uppercase bg-orange-1 py-2">Cosa succede adesso?</h1>
        <div class="max-w-4xl text-lg mx-auto py-4 px-6">            
            <div class="flex mb-6 items-center">
                <div class="w-1/5 text-center">
                    <i class="icon email block mx-auto"></i>
                </div>
                <p class="w-4/5 pl-4 sm:pl-0 text-sm">
                    Ricevi subito via e-mail il primo esito della tua richiesta di Prestito Personale
                </p>
            </div>
            <div class="flex mb-6 items-center">
                <div class="w-1/5 text-center">
                    <i class="icon contract block mx-auto"></i>
                </div>
                <p class="w-4/5 pl-4 sm:pl-0 text-sm">
                    Se approvata, ricevi il contratto di Prestito Personale da firmare digitalmente (controlla la tua e-mail, anche lo spam!).
                </p>
            </div>
            <div class="flex mb-6 items-center">
                <div class="w-1/5 text-center">
                    <i class="icon signing block mx-auto"></i>
                </div>
                <p class="w-4/5 pl-4 sm:pl-0 text-sm">
                    Apri la richiesta di Prestito Personale e firma negli spazi indicati con il codice OTP che ricevi via SMS sul tuo numero di cellulare.
                </p>
            </div>

            <div class="text-center mt-10 text-base">
                <strong>Appena firmerai la richiesta di Prestito Personale, se accolta, riceverai entro 48h il credito sul tuo conto corrente.</strong>
            </div>
        </div>
    </div>
</template>
<script>
import { mapUserData } from "@/utils";

export default {    
    mounted() {
        localStorage.setItem('fastcredit_request_evaluaton', 1)
    },
    computed: {
        ...mapUserData(["name","importo"])
    }
};
</script>