import Vue from 'vue'

Vue.mixin({
	data() {
		return {
			focusInput: null
		}
	},
    computed: {
        isDev() {
            return this.$store.state.isDev
        },
        promo() {
            return this.$store.state.promo ?? 0
        }
    },
	methods: {
		setFocusInput(id) {
            this.focusInput = id
        },
        unsetFocusInput() {
            this.focusInput = null
        },
        focusFirstError() {
            Vue.nextTick(function() {
                var el = document.getElementsByClassName("border-red")
                if (el.length) el[0].parentElement.scrollIntoView({ behavior: 'smooth' })
            })
        },
        goBack() {
            this.$store.dispatch("event", { name: "BackClick", model: this.$route.meta.model });
            this.$store.commit("unlogStep");
            this.$router.back();
        },
        showPromoModal() {
            this.$store.state.promoModal = true
        },
        getPromoOffer(upgrade, services = true) {
            return this.$store.getters.promoOffer({upgrade, services})
        }
    }
})
