<template>
    <div class="mx-auto lg:container text-sm sm:text-base leading-tight text-gray-700 text-center pb-48 pt-36">
        <div class="w-full text-center">
            <div class="spinner mx-auto"></div>
        </div>

        <h1 class="text-lg font-semibold mt-6">Stiamo verificando i tuoi dati</h1>
        <p class="text-sm mt-2">Potrebbe volerci qualche secondo</p>
    </div>
</template>
<script>
import { mapUserData } from "@/utils";

export default {
    mounted() {
        this.$ga.pageview()
        window.setTimeout(() => {
            this.$store.dispatch("redirect", { step: "promo_loading", route: "Promo" });
        }, 1000);
    },
    computed: {
        ...mapUserData(["name","importo"]),
    }
};
</script>
