<template>
        <div class="container px-4 sm:px-16 py-10 text-center">
            <div v-if="showFormImporto">
                <h1 class="text-2xl sm:text-3xl font-semibold md:w-2/3 mx-auto">Prestito per consolidamento</h1>
                <p class="mb-6">Richiedilo in tre minuti</p>
                <FormImporto :default-submit="false" ref="form">
                    <button @click="submit()" type="button" class="submit w-full mt-4">richiedi</button>
                </FormImporto>
            </div>
            <div v-else>
                <img src="@/views/themes/consolidamento/images/feather-sad.png" class="mx-auto w-16 sm:w-24">
                <h1 class="text-2xl sm:text-3xl font-semibold md:w-2/3 mx-auto mt-4">Sei sicuro di aver inserito correttamente i dati?</h1>
                <p class="mt-2 mb-4">Sembra che qualcosa sia andato storto</p>
                <div class="mb-4">
                    <button @click="showFormImporto = true" class="w-full bg-yellow rounded-full p-4 px-16 font-semibold shadow-xl max-w-sm" type="button">{{ $t("Sì") }}</button>
                </div>
                <div class="mb-4">
                    <a @click="back()" class="underline cursor-pointer">Voglio inserirli di nuovo</a>
                </div>
            </div>
        </div>
</template>
<script>
import FormImporto from "@/components/FormImporto.vue";
import { mapUserData  } from "@/utils";

export default {
    components: { FormImporto },
    async mounted() {
        this.$ga.pageview()
    },
    data() {
        return {
            showError: false,
            showFormImporto: false,
        };
    },
    computed: {
        ...mapUserData([
            "loans_count",
            "loans_data"
        ])
    },
    validations: {

    },
    methods: {
        back() {
            this.$store.dispatch("redirect", { step: "info_loans_error", route: "InfoLoans" });
        },
        submit() {
            this.$refs.form.onSubmit()
        }
    }
};
</script>