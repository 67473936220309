<template>

    <div class="card flex flex-col m-auto py-5 xl:my-0 xl:mx-2.5 xl:p-0">
        <div class="card-image">
            <img class="h-full w-full" :src="testimonial.img">
        </div>
        <div class="card-text bg-white px-5 pb-5">
            <p class="font-semibold mt-2.5 mb-2 text-center">{{ testimonial.nome }}</p>
            <span>{{ testimonial.testo }}</span>
        </div>
    </div>
    
</template>

<script>

import testimonialsList from './models/testimonials'

export default {
    props: {
        index: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            testimonial : testimonialsList[this.index]
        }
    },
}
</script>