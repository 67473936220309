<template>
    <div>
        <MetlifeBestCare @submit="onSubmit" />
    </div>
</template>
<style scoped type="text/css">
@media screen and (max-width: 639px) {
    div >>> .form-header { font-size: 1rem !important; }
}
</style>
<script>
import MetlifeBestCare from '@/views/forms/MetlifeBestCare';

export default {
    components: { MetlifeBestCare },
    methods: {
        onSubmit() {
            this.$store.dispatch("event", { name: "LoanProtectionSubmit" });
        }
    }
};
</script>