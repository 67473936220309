<template>
  <main class="mx-auto">
    <Loader v-if="loading" />
    <div v-else>
      <div class="bg-gradient absolute w-full opacity-70 md:opacity-100"></div>
      <header class="container mx-auto pt-5 px-2 sm:px-10 md:px-6">
        <div class="bg-dark rounded flex justify-center py-2">
          <img width="86" height="89" src="@/views/themes/club-crm/images/logo.svg" />
        </div>
      </header>
      <section>
        <div class="pt-16 container mx-auto px-2 sm:px-10 md:px-6">
          <h1 class="text-neutral-800 text-center text-4-5xl lg:text-6xl font-bold leading-none">
            Accetta il nostro<br />
            invito a Club
          </h1>
          <div class="text-lg lg:text-2xl pt-5 text-center leading-6">
            <p class="">
              Non verrà addebitato alcun costo durante il <strong>periodo di prova gratuito</strong>.
            </p>
            <p>
              Dopo i primi 30 giorni gratis, Club Plus si rinnoverà automaticamente a 9,90€ al mese
            </p>
          </div>
        </div>
        <!-- Category tiles -->
        <div class="flex flex-wrap justify-between md:justify-center mt-9 px-2">
          <div class="category-swatch">
            <div class="mx-auto rounded-full border-2 flex justify-center items-center transition-shadow hover:shadow-round">
              <div class="rounded-full border-2 border-white overflow-hidden">
                <img src="@/views/themes/club-crm/images/Club-Categoria-Icona-Beauty.png" />
              </div>
            </div>
            <p class="text-dark text-xs md:text-sm font-bold mt-2">
              Shop&shy;ping
            </p>
          </div>
          <div class="category-swatch">
            <div class="mx-auto rounded-full border-2 flex justify-center items-center transition-shadow hover:shadow-round">
              <div class="rounded-full border-2 border-white overflow-hidden">
                <img src="@/views/themes/club-crm/images/Club-Categoria-Icona-Hotel.png" />
              </div>
            </div>
            <p class="text-dark text-xs md:text-sm font-bold mt-2">
              Tempo libero
            </p>
          </div>
          <div class="category-swatch">
            <div class="mx-auto rounded-full border-2 flex justify-center items-center transition-shadow hover:shadow-round">
              <div class="rounded-full border-2 border-white overflow-hidden">
                <img src="@/views/themes/club-crm/images/Club-Categoria-Icona-Cinema.png" />
              </div>
            </div>
            <p class="text-dark text-xs md:text-sm font-bold mt-2">
              Entertain&shy;ment
            </p>
          </div>
          <div class="category-swatch">
            <div class="mx-auto rounded-full border-2 flex justify-center items-center transition-shadow hover:shadow-round">
              <div class="rounded-full border-2 border-white overflow-hidden">
                <img src="@/views/themes/club-crm/images/Club-Categoria-Icona-Wellness.png" />
              </div>
            </div>
            <p class="text-dark text-xs md:text-sm font-bold mt-2">
              Well&shy;ness
            </p>
          </div>
        </div>
        <!-- button and flags -->
        <div class="sm:container mx-auto px-3 sm:px-10 md:px-6">
          <form class="pt-9">
            <button type="button" class="block bg-dark text-white rounded-full pt-2 pb-3 px-8 mx-auto" @click="submit()">
              <span class="uppercase">Iscriviti</span>
              <span class="block leading-none">Primi 30 giorni gratis</span>
            </button>
            <p class="text-sm md:text-base text-center mt-2">Puoi cancellare in qualsiasi momento</p>
            <div class="pt-8 text-xs club-flags">
              <p>
                Prima di procedere, ti invitiamo a prendere visione del
                <a :href="rulesClubUrl" target="_blank">Regolamento</a>
                che riporta nel dettaglio le caratteristiche del prodotto,
                nonché l’<a :href="privacyUrlClub" target="_blank">Informativa Privacy.</a>
              </p>
              <div class="mt-3 flex items-start" :class="{error: tos1.error}">
                <input v-model="tos1.checked" type="checkbox" id="check1" class="appearance-none" @change="setConsents(tos1)"/>
                <label for="check1" class="ml-2">Ho letto e compreso le condizioni del contratto CLUB PLUS contenute nel
                  <a :href="rulesClubUrl" target="_blank">Regolamento contrattuale</a>, accettando integralmente le sue condizioni, e dichiaro di
                  essere interessato a sottoscrivere la proposta del servizio.
                </label>
              </div>
              <div class="mt-3 flex items-start" :class="{error: tos2.error}">
                <input v-model="tos2.checked" type="checkbox" id="check2" class="appearance-none" @change="setConsents(tos2)"/>
                <label for="check2" class="ml-2">Autorizzo: Quattrolinee s.r.l. , con il codice identificativo
                  Cofidis Club (Creditor information DE56AQJ00002197951) con
                  sede in corso Vittorio Emanuele II 25, Torino, 10125, Italia,
                  a richiedere tramite mandato SEPA Direct Debit (SDD), alla
                  banca del sottoscritto l’addebito del proprio conto corrente
                  fornito in occasione della richiesta di prestito; II) la banca
                  del sottoscritto a procedere all’addebito ricorrente, secondo
                  le disposizioni impartite da Quattrolinee s.r.l. Sono
                  consapevole di aver diritto ad ottenere il rimborso
                  dell’addebito in conto, secondo gli accordi ed alle condizioni
                  che regolano il rapporto con la mia banca, richiedendolo entro
                  8 settimane a partire dalla data di addebito. In caso di
                  modifica della data di addebito, frequenza o importo, ti verrà
                  inviata una notifica almeno 3 giorni prima del successivo
                  addebito.I pagamenti sono gestiti da GoCardless (vedi privacy
                  notice
                  <a href="https://gocardless.com/privacy/payers" target="_blank">GoCardless</a>).
                </label>
              </div>
              <div class="mt-3 flex items-start" :class="{error: tos3.error}">
                <input v-model="tos3.checked" type="checkbox" id="check3" class="appearance-none" @change="setConsents(tos3)"/>
                <label for="check3" class="ml-2">Ai sensi degli articoli 1341 e 1342 del Codice Civile il
                  dichiaro di avere attentamente esaminato e di accettare
                  integralmente le clausole del Regolamento Contrattuale, di
                  seguito elencate: Art.4 Obblighi delle Parti, Art.5 Gestione
                  Dati, Art.7 Responsabilità, Art.8 Recesso, Art.9 Risoluzione,
                  Art.10 Durata.
                </label>
              </div>
              <div class="mt-3 flex items-start" :class="{error: tos4.error}" >
                <input v-model="tos4.checked" type="checkbox" id="check4" class="appearance-none" @change="setConsents(tos4)"/>
                <label for="check4" class="ml-2">Dichiaro di aver ricevuto e aver ben compreso l’informativa
                  fornita ai sensi dell’art.13 del Regolamento UE 2016/679,
                  dalla quale risulta che, nell’ambito dell’esecuzione delle
                  operazioni e del servizio richiesto, si intendono perseguire
                  le finalità di cui al punto 2.1. “Finalità Necessarie”. In
                  particolare, dichiara di aver ben compreso dalla predetta
                  informativa che è previsto il trattamento, da parte di
                  Quattrolinee s.r.l., nella sua qualità di Titolare dei Suoi
                  dati personali.
                </label>
              </div>
            </div>
          </form>
        </div>
      </section>
      <!-- personal data -->
      <section>
        <div class="text-center bg-light py-6 mt-10">
          <h3 class="text-3xl font-semibold text-gray-900 leading-tight mx-auto mt-2">
            I tuoi dati
          </h3>
        </div>

                <div class="bg-light pb-6">
                    <div class="px-6 max-w-4xl xl:max-w-6xl mx-auto flex flex-wrap justify-center">
                      <div class="w-full flex justify-center">
                        <span class="block md:w-1/6"></span>
                        <label class="w-1/4 text-lg text-gray-900 font-medium pr-5">Cognome</label>
                        <span class="w-3/4 md:w-1/4 text-lg text-gray-700 font-semibold uppercase pl-5">{{ surname }}</span>
                      </div>
                      <div class="w-full flex justify-center">
                        <span class="block md:w-1/6"></span>
                        <label class="w-1/4 text-lg text-gray-900 font-medium pr-5 mt-5">Nome</label>
                        <span class="w-3/4 md:w-1/4 text-lg text-gray-700 font-semibold uppercase pl-5 mt-5">{{ name }}</span>
                      </div>
                      <div class="w-full flex justify-center">
                        <span class="block md:w-1/6"></span>
                        <label class="w-1/4 text-lg text-gray-900 font-medium pr-5 mt-5">IBAN</label>
                        <span class="w-3/4 md:w-1/4 text-lg text-gray-700 font-semibold uppercase pl-5 mt-5">{{ maskedIban }}</span>
                      </div>
                    </div>
                </div>

                <div class="bg-yellow text-center p-6">
                    <p class="text-center font-semibold text-xl text-gray-900 leading-none">
                        Verifica che la tua e-mail sia corretta oppure inseriscila.
                    </p>
                    <div class="sm:w-96 text-center mx-auto mt-4">
                        <div class="relative">
                            <input type="email" name="email" class="w-full py-2 pl-4 pr-10 text-lg" v-model="email" v-mask="{ placeholder: '', greedy: false, casing: 'lower' }"
                                   placeholder="Se non appare la tua email, inseriscila" :class="{ 'border border-red': $v.email.$error }">
                            <i class="icon pencil absolute top-3 right-2"></i>
                        </div>
                        <div class="w-full text-red text-left" v-if="$v.email.$error">
                            <small v-if="!$v.email.required">
                                {{ $t("Campo obbligatorio") }}
                            </small>
                            <small v-else-if="!$v.email.email">
                                {{ $t("Formato e-mail non valido") }}
                            </small>
                        </div>
                    </div>
                </div>
            </section>
            <!-- image sections -->
            <section class="image-section container mx-auto my-8 px-3 sm:px-10 md:px-6">
                <div class="image-section-container overflow-hidden flex flex-col md:flex-row">
                    <div class="image-container md:w-1/2 xl:w-3/5 relative">
                        <img class="badge absolute" src="@/views/themes/club-crm/images/Club-badge-1.svg" />
                        <div class="image-sub-container overflow-hidden block h-[110%] w-full md:w-[110%] min-h-full">
                            <img class="w-full h-full object-cover object-center block max-h-[680px]" src="@/views/themes/club-crm/images/Club-image-1.jpg"/>
                        </div>
                    </div>
                    <div class="text-container md:w-1/2 xl:w-2/5 bg-white h-fit z-1">
                        <div class="pt-5 pl-5 pr-6 pb-8 md:pt-9 md:pl-9 md:pb-10">
                            <div class="">
                                <h3 class="text-dark font-semibold md:text-lg">
                                    Tantissime proposte per il tempo libero
                                </h3>
                                <p class="text-dark md:text-lg mt-1 md:mt-3">
                                    Accedi alla piattaforma e scegli la tua esperienza per due tra
                                    i circuiti a disposizione.
                                </p>
                                <a href="/#ending-cta" class="block mt-4 md:mt-5" @click="scrollToSubscribe($event)">
                                    <button class="border border-dark text-dark rounded-full text-base py-2 px-10 lg:px-10 rounded-md">
                                        Iscriviti
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="image-section container mx-auto my-8 px-3 sm:px-10 md:px-6">
                <div class="image-section-container overflow-hidden flex flex-col md:flex-row-reverse">
                    <div class="image-container md:w-1/2 xl:w-3/5 relative">
                        <img class="badge absolute" src="@/views/themes/club-crm/images/Club-badge-2.svg" />
                        <div class="image-sub-container overflow-hidden block h-[110%] w-full md:w-[110%] min-h-full">
                            <img class="w-full h-full object-cover object-center block max-h-[680px]" src="@/views/themes/club-crm/images/Club-image-2.jpg" />
                        </div>
                    </div>
                    <div class="text-container md:w-1/2 xl:w-2/5 bg-white h-fit z-1">
                        <div class="pt-5 pl-5 pr-6 pb-8 md:pt-9 md:pl-9 md:pb-10">
                            <div class="">
                                <h3 class="text-dark font-semibold md:text-lg">
                                  Uno shopping da sogno
                                </h3>
                                <p class="text-dark md:text-lg mt-1 md:mt-3 lg:mr-3">
                                  Sconti e gift card per il tuo shopping su prodotti e servizi dei migliori brand.
                                </p>
                                <a href="/#ending-cta" class="block mt-4 md:mt-5" @click="scrollToSubscribe($event)">
                                    <button type="button" class="border border-dark text-dark rounded-full text-base py-2 px-10 lg:px-10 rounded-md">
                                        Iscriviti
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="image-section container mx-auto my-8 px-3 sm:px-10 md:px-6">
                <div class="image-section-container overflow-hidden flex flex-col md:flex-row">
                    <div class="image-container md:w-1/2 xl:w-3/5 relative">
                        <img class="badge absolute" src="@/views/themes/club-crm/images/Club-badge-3.svg" />
                        <div class="image-sub-container overflow-hidden block h-[110%] w-full md:w-[110%] min-h-full">
                            <img class="w-full h-full object-cover object-center block max-h-[680px]" src="@/views/themes/club-crm/images/Club-image-3.jpg" />
                        </div>
                    </div>
                    <div class="text-container md:w-1/2 xl:w-2/5 bg-white h-fit z-1">
                        <div class="pt-5 pl-5 pr-6 pb-8 md:pt-9 md:pl-9 md:pb-10">
                            <div class="">
                                <h3 class="text-dark font-semibold md:text-lg">
                                    Cambia il tuo modo di vivere la sanità
                                </h3>
                                <p class="text-dark md:text-lg mt-1 md:mt-3">
                                    Trova la struttura sanitaria più vicina a te, prenota e paga
                                    da app a tariffe vantaggiose.
                                </p>
                                <a href="/#ending-cta" class="block mt-4 md:mt-5" @click="scrollToSubscribe($event)">
                                    <button type="button" class="border border-dark text-dark rounded-full text-base py-2 px-10 lg:px-10 rounded-md">
                                        Iscriviti
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- button and flags -->
            <section id="ending-cta" ref="endingCta" class="sm:container mx-auto px-3 sm:px-10 md:px-6">
                <form class="club-flags pt-6">
                    <button type="button" class="block bg-dark text-white rounded-full pt-2 pb-3 px-8 mx-auto" @click="submit()">
                        <span class="uppercase">Iscriviti</span>
                        <span class="block leading-none">I primi 30 giorni sono gratis</span>
                    </button>
                    <div class="mt-10 text-xs">
                      <p>
                        Prima di procedere, ti invitiamo a prendere visione del
                        <a :href="rulesClubUrl" target="_blank">Regolamento</a>
                        che riporta nel dettaglio le caratteristiche del prodotto,
                        nonché l’<a :href="privacyUrlClub" target="_blank">Informativa Privacy.</a>
                      </p>
                      <div class="mt-3 flex items-start" :class="{error: tos1.error}">
                        <input v-model="tos1.checked" type="checkbox" id="check1" class="appearance-none" @change="setConsents(tos1)"/>
                        <label for="check1" class="ml-2">Ho letto e compreso le condizioni del contratto CLUB PLUS contenute nel
                          <a :href="rulesClubUrl" target="_blank">Regolamento contrattuale</a>, accettando integralmente le sue condizioni, e dichiaro di
                          essere interessato a sottoscrivere la proposta del servizio.
                        </label>
                      </div>
                      <div class="mt-3 flex items-start" :class="{error: tos2.error}">
                        <input v-model="tos2.checked" type="checkbox" id="check2" class="appearance-none" @change="setConsents(tos2)"/>
                        <label for="check2" class="ml-2">Autorizzo: Quattrolinee s.r.l. , con il codice identificativo
                          Cofidis Club (Creditor information DE56AQJ00002197951) con
                          sede in corso Vittorio Emanuele II 25, Torino, 10125, Italia,
                          a richiedere tramite mandato SEPA Direct Debit (SDD), alla
                          banca del sottoscritto l’addebito del proprio conto corrente
                          fornito in occasione della richiesta di prestito; II) la banca
                          del sottoscritto a procedere all’addebito ricorrente, secondo
                          le disposizioni impartite da Quattrolinee s.r.l. Sono
                          consapevole di aver diritto ad ottenere il rimborso
                          dell’addebito in conto, secondo gli accordi ed alle condizioni
                          che regolano il rapporto con la mia banca, richiedendolo entro
                          8 settimane a partire dalla data di addebito. In caso di
                          modifica della data di addebito, frequenza o importo, ti verrà
                          inviata una notifica almeno 3 giorni prima del successivo
                          addebito.I pagamenti sono gestiti da GoCardless (vedi privacy
                          notice
                          <a href="https://gocardless.com/privacy/payers" target="_blank">GoCardless</a>).
                        </label>
                      </div>
                      <div class="mt-3 flex items-start" :class="{error: tos3.error}">
                        <input v-model="tos3.checked" type="checkbox" id="check3" class="appearance-none" @change="setConsents(tos3)"/>
                        <label for="check3" class="ml-2">Ai sensi degli articoli 1341 e 1342 del Codice Civile il
                          dichiaro di avere attentamente esaminato e di accettare
                          integralmente le clausole del Regolamento Contrattuale, di
                          seguito elencate: Art.4 Obblighi delle Parti, Art.5 Gestione
                          Dati, Art.7 Responsabilità, Art.8 Recesso, Art.9 Risoluzione,
                          Art.10 Durata.
                        </label>
                      </div>
                      <div class="mt-3 flex items-start" :class="{error: tos4.error}" >
                        <input v-model="tos4.checked" type="checkbox" id="check4" class="appearance-none" @change="setConsents(tos4)"/>
                        <label for="check4" class="ml-2">Dichiaro di aver ricevuto e aver ben compreso l’informativa
                          fornita ai sensi dell’art.13 del Regolamento UE 2016/679,
                          dalla quale risulta che, nell’ambito dell’esecuzione delle
                          operazioni e del servizio richiesto, si intendono perseguire
                          le finalità di cui al punto 2.1. “Finalità Necessarie”. In
                          particolare, dichiara di aver ben compreso dalla predetta
                          informativa che è previsto il trattamento, da parte di
                          Quattrolinee s.r.l., nella sua qualità di Titolare dei Suoi
                          dati personali.
                        </label>
                      </div>
                    </div>
                </form>
            </section>
        </div>
    </main>
</template>
<script>
// @ is an alias to /src
import Loader from "@/components/Loader";
//import loadData from "@/dataService.js";
import axios from "axios";
import {mapUserData} from "@/utils";
import { required, email } from "vuelidate/lib/validators";

const redirectHomepage = () => {
    location.href = "/fastcredit";
};

export default {
    name: "Home",
    components: { Loader },
    computed: {
        ...mapUserData([
            "name",
            "surname",
            "iban",
            "email"
        ]),
        maskedIban() {
            if(this.iban && this.iban.length) return `${this.iban[0]}${this.iban[1]}****************${this.iban.slice(-4)}`
            return '****************'
        },
        crmUrl() {
            return this.$store.state.crmUrl;
        }
    },
    methods: {
        async loadClientData(data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(this.crmUrl, data)
                    .then(function (response) {
                        if (response.data) resolve(response.data);
                        else reject(false);
                    })
                    .catch(function () {
                        reject(false);
                    });
            });
        },
        scrollToSubscribe(event) {
            event.preventDefault();
            window.scrollTo({
                top: document.getElementById("ending-cta").offsetTop,
                left: 0,
                behavior: "smooth",
            });
        },
        setConsents(tos) {
          if (tos.checked) {
            let allTos = [this.tos1, this.tos2, this.tos3, this.tos4]
            allTos.forEach(tos => {
              tos.error = false
              tos.checked = true
            })
          }
        },
        async submit() {
            this.$v.$touch()
            this.focusFirstError()

            if (!this.$v.$invalid) {

              let allTos = [this.tos1, this.tos2, this.tos3, this.tos4]
              let unselectedTos = allTos.filter(tos => !tos.checked) 

              // if no checkbox is selected or all are, select them and send the request
              if (unselectedTos.length === 0 || unselectedTos.length === 4) {
                allTos.forEach(tos => {
                  tos.error = false
                  tos.checked = true
                })

                setTimeout(() => {
                  window.removeEventListener("popstate", redirectHomepage);
                  this.$store.dispatch('subscribeClub')
                  this.$store.dispatch("sendData", { step: "dati_anag" });
                  this.$store.dispatch("sendData", { step: "dati_pers_3", method: "POST" });
                  this.$store.dispatch("redirect", {
                      step: "homepage",
                      route: "ThankYou",
                  });
                }, 1500)

              }
              // if some checkbox is selected and some not, show the errors
              else {
                document.querySelector('.club-flags').scrollIntoView({ behavior: 'smooth' })
                unselectedTos.forEach(tos => {
                  tos.error = true
                })
              } 
            }
        },
        reloadOnBrowserBack() {
            window.popStateDetected = false;
            window.addEventListener("popstate", redirectHomepage);
        },
    },
    validations: {
        email: {
            required,
            email
        },
    },
    data() {
        return {
            loading: true,
            distributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/fastcredit",
            commonDistributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/common",
            tos1: { 'checked': false, 'error': false},
            tos2: { 'checked': false, 'error': false},
            tos3: { 'checked': false, 'error': false},
            tos4: { 'checked': false, 'error': false},
            rulesClubUrl: "https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Regolamento_Servizio_CLUB_PLUS_DEM.pdf",
            privacyUrlClub: "https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Informativa_Servizio.pdf",
        };
    },
    async created() {
        this.reloadOnBrowserBack();
        // get params
        const urlParts = this.$store.state.urlParts
        let userId, codeCampaign, trackCode;

        if(localStorage.getItem('club_request_evaluaton')) {
            this.$store.dispatch("redirect", {step: "homepage", route: "RequestPending"});
            return;
        }

        if(urlParts[1] && urlParts[2]) {
            userId = urlParts[1]
            codeCampaign = urlParts[2]
            trackCode = urlParts[3] ?? null
        }
        else {
            userId = localStorage.getItem('club_user_id')
            codeCampaign = localStorage.getItem('club_code_campaign')
            trackCode = localStorage.getItem('club_track_code') ?? null
            // fix localstorage cast type issue
            if(trackCode === "null") trackCode = null;
        }

        try {
            localStorage.setItem('club_user_id', userId)
            localStorage.setItem('club_code_campaign', codeCampaign)
            localStorage.setItem('club_track_code', trackCode)

            const data = await this.loadClientData({userId, codeCampaign, trackCode})
            // empty data
            if(!data.Nome) {
                this.$store.dispatch("redirect", {step: "homepage", route: "RequestKO"});
            }

            this.$store.commit("setUserData", { key: 'user_id', value: userId });
            this.$store.commit("setUserData", { key: 'code_campaign', value: codeCampaign });

            this.$store.commit("setUserData", { key: 'name', value: data.Nome });
            this.$store.commit("setUserData", { key: 'surname', value: data.Cognome });
            this.$store.commit("setUserData", { key: 'email', value: data.EMail });
            this.$store.commit("setUserData", { key: 'iban', value: data.Iban });
            this.$store.commit("setUserData", { key: 'fiscal_code', value: data.CF });
            this.$store.commit("setUserData", { key: 'cellphone', value: data.Phone });
            this.$store.commit("setUserData", { key: 'address', value: data.Indirizzo });
            this.$store.commit("setUserData", { key: 'address_city', value: `${data.City} (${data.Prov})`});
            this.$store.commit("setUserData", { key: 'address_cap', value: data.CAP });
            this.$store.commit("setUserData", { key: 'service_pack', value: data.service_pack });

            // set expiry date
            //this.expDate = moment(data.dt_end_eleg_fast).locale("it").format('D MMMM')
            //this.$store.commit("setUserData", { key: 'exp_date', value: this.expDate });

            // offer expired
            // if(moment(data.dt_end_eleg_fast).endOf('day') < moment()) {
            //     this.$store.dispatch("redirect", {step: "income", route: "RequestExpired"});
            //     return;
            // }
            //pending procedure
            if(data.page_block && data.page_block === 'evaluation') {
                //this.$store.dispatch("redirect", {step: "homepage", route: "RequestPending"});
                //return;
            }

            // init robot
            this.$store.dispatch("sendData", { step: "enter_landing" });
        } catch (error) {
            //this.$store.dispatch("redirect", {step: "homepage", route: "RequestKO"});
        }
        this.loading = false;
    },
};
</script>