<template>
    <form action="next" method="POST">
        <div class="container text-center py-6 relative mx-auto">
            <i class="icon arrowleft absolute left-4 sm:left-0 top-1/2 transform -translate-y-1/2 cursor-pointer" @click="goBack()"></i>
            <Steps :count="steps" :active="steps"></Steps>
        </div>
        <div class="form-wrapper">
            <PrivacyConsent :alert-data-consent="false">
            </PrivacyConsent>
        </div>
    </form>
</template>
<script>
import PrivacyConsent from "@/views/forms/PrivacyConsent";
import Steps from '@/components/Steps'
import { mapUserData } from "@/utils"

export default {
    components: { Steps, PrivacyConsent },
    mounted() {
        this.$store.dispatch("sendData", { step: "privacy", additionalData: {
            init: true,
            codeCampaign: localStorage.getItem('topup_code_campaign'), 
            userId: localStorage.getItem('topup_user_id') 
        }});
    },
    computed: {
        ...mapUserData(["document_expired"]),
        steps() {
            return this.document_expired ? 4 : 3
        },
    }
};
</script>