<template>
    <div class="mx-auto lg:container text-sm sm:text-base leading-tight text-gray-700">
        <div class="px-6 py-6 text-center text-2xl sm:text-3xl bg-orange font-semibold">
            Gentile <strong>{{ name }}</strong>, grazie per averci scelto!
        </div>
        <h1 class="text-xl sm:text-2xl font-semibold text-center sm:mt-4 mb-6 sm:mb-8 uppercase bg-orange-1 py-2">I PROSSIMI PASSI</h1>
        <div class="max-w-4xl text-lg mx-auto py-4 px-6">
            <div class="flex mb-6 items-center">
                <div class="w-1/5 text-center">
                    <img class="h-12 mx-auto" src="@/assets/images/typ-envelope.svg" />
                </div>
                <p class="w-4/5 pl-4 sm:pl-0 text-sm">
                    A breve riceverai l’e-mail con il primo esito della tua richiesta e, in caso positivo, riceverai nella tua casella di posta il contratto da firmare in pochi click.
                </p>
            </div>
            <div class="flex mb-6 items-center">
                <div class="w-1/5 text-center">
                    <img class="h-12 mx-auto" src="@/assets/images/typ-smile.svg" />
                </div>
                <p class="w-4/5 pl-4 sm:pl-0 text-sm">
                    Ti ricordiamo anche che potrai usufruire della Polizza “MetLife Best Care” già dalle ore 24:00 di oggi e che il primo mese di copertura è gratuito.
                </p>
            </div>
            
            <div class="text-center mt-10">
                A presto<br />
                <strong>Il Team Cofidis</strong>
            </div>
            <div class="text-center mt-10">
                <span>Seguici anche su</span>
                <div class="social-links">
                    <a class="inline-block social-link p-4" target="_blank" rel="nofollow" href="https://www.facebook.com/CofidisItalia/">
                        <img class="h-12 mx-auto" src="@/assets/images/typ-fb.svg" />                
                    </a>
                    <a class="inline-block social-link p-4" target="_blank" rel="nofollow" href="https://www.instagram.com/cofidisitalia/">
                        <img class="h-12 mx-auto" src="@/assets/images/typ-ig.svg" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapUserData } from "@/utils";

export default {
    mounted() {
        this.$ga.pageview()
    },
    computed: {
        ...mapUserData(["name"])
    }
};
</script>