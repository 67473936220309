<template>
    <div class="document cursor-pointer rounded-lg px-2 sm:p-8 sm:py-4 py-4" @click="onClick">
        <div class="h-16 w-16 mx-auto rounded-full flex items-center justify-center" :class="wrapperClass">
            <img class="block h-16" src="@/assets/images/docs/ci.svg" v-if="value == 1" />
            <img class="block h-16" src="@/assets/images/docs/ci.svg" v-if="value == 2" />
            <img class="block h-16" src="@/assets/images/docs/passaporto.svg" v-if="value == 3" />
            <img class="block h-16" src="@/assets/images/docs/patente.svg" v-if="value == 4" />
        </div>
        <span class="block text-center text-xs sm:text-base h-8 sm:h-auto mt-2">{{ $t(label) }}</span>
    </div>
</template>
<script>
export default {
    model: {
        prop: "modelValue",
        event: "change"
    },
    props: {
        modelValue: {
            default: ""
        },
        value: {
            type: Number,
            required: true
        },
        label: {
            type: String,
            default: ""
        },
        hasError: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            id: `doc${this.value}`
        };
    },
    computed: {
        wrapperClass() {
            return {
                "bg-form": this.value != this.modelValue,
                "bg-checked": this.value == this.modelValue,
                "border-red": this.hasError
            };
        }
    },
    methods: {
        onClick() {
            this.$store.dispatch("event", { name: "DocumentClick", source: this });
            this.$emit("change", this.value);
        }
    }
};
</script>