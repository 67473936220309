<template>
    <div :class="`logo flex justify-end ${ !fullScreen && 'container mx-auto px-6 max-w-4xl lg:max-w-5xl xl:max-w-7xl' }`">
        <div :class="`flex items-center bg-white shadow-sm p-2 pr-3 lg:pl-5 lg:pr-8 lg:pb-3 lg:pt-2 ${ fullScreen ? 'rounded-bl-2xl' : 'rounded-b-2xl lg:-mr-6 2xl:-mr-24' } `">
            <img class="header-logo sm:hidden" src="@/assets/images/logo.svg" alt="Cofidis Logo" title="Cofidis Logo" />
            <img class="header-logo-claim hidden sm:inline-block" src="@/assets/images/logo-claim.svg" alt="Cofidis Logo" title="Cofidis Logo" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'Logo',
    props: {
        fullScreen: {
            type: Boolean,
            default: true
        },
    }
}
</script>
<style scoped>
    .header-logo {
        height: 55px
    }

    .header-logo-claim {
        height: 108px;
    } 

</style>