<template>
    <footer class="absolute bottom-0 text-center w-full">
        <div class="px-6 pb-8 pt-6 md:pt-2">
        <div class="text-sm max-w-4xl xl:max-w-6xl mx-auto">
            <a href="https://www.cofidis.it/it/privacy-policy.html" class="underline" target="_blank">Privacy Policy</a> |
            <a href="https://cofidis-docs-pp.s3.eu-central-1.amazonaws.com/agid.pdf" class="underline" target="_blank">Accessibilità: non conforme</a>
        </div>
        </div>
    </footer>
</template>
<script>

export default {
  name: "Footer",
};
</script>
