import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

const folder = store.state.envType == 'banche' ? 'banche' : 'forms';

const requireForm = path => {
    const req = require.context('@/views', true, /^.*.vue$/);
    const {default: module} = req(path)
    return module
}

const Rata = requireForm(`./${folder}/Rata.vue`);
import EmailPrivacyConsent from "@/views/forms/EmailPrivacyConsent.vue";
const Contatti = requireForm(`./${folder}/Contatti.vue`);
const PersonalData1 = requireForm(`./${folder}/PersonalData1.vue`);
import PersonalData2 from "@/views/forms/PersonalData2.vue";
const DeliveryData = requireForm(`./${folder}/DeliveryData.vue`);
const IdentityCardData = requireForm(`./${folder}/IdentityCardData.vue`);
const IncomeData = requireForm(`./${folder}/IncomeData.vue`);
const PaymentDetails = requireForm(`./${folder}/PaymentDetails.vue`);
const EmployerData = requireForm(`./${folder}/EmployerData.vue`);
const PrivacyConsent = requireForm(`./${folder}/PrivacyConsent.vue`);
import LoanProtection from "@/views/forms/LoanProtection.vue";
import LoanProtectionWarning from "@/views/forms/LoanProtectionWarning.vue";
import LoanProtectionConsent from "@/views/forms/LoanProtectionConsent.vue";
import Partners from "@/views/themes/prestito/Partners.vue";

const envType = store.state.envType
const theme = store.state.theme

Vue.use(VueRouter);

let routes = [{
        path: "/",
        name: "Homepage",
        component: require(`../views/themes/${theme}/Home.vue`).default,
        pathToRegexpOptions: { strict: true },
        beforeEnter: (to, from, next) => {
            store.commit("setAllowForms", true)
            next();
        },
        meta: {
            disableStyles: true,
            backHidden: true
        }
    },
    {
        path: "/typ",
        name: "ThankYou",
        component: require(`../views/themes/${theme}/Typ.vue`).default,
        meta: {
            resetState: true,
            backHidden: true
        }
    },
    {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: require(`../views/themes/${theme}/Privacy.vue`).default,
        meta: {
            backHidden: true
        }
    },
    {
        path: '/privacy.html',
        redirect: { name: 'PrivacyPolicy' }
    },
    {
        path: "/rata",
        name: "Rata",
        component: Rata,
        meta: {
            backHidden: true
        }
    },
    {
        path: "/rata-upgrade",
        name: "RataUpgrade",
        component: Rata,
        meta: {
            backHidden: true
        }
    },
    {
        path: "/email-privacy",
        name: "EmailPrivacyConsent",
        component: EmailPrivacyConsent,
        meta: {
            backHidden: true,
            percentage: 5,
            model: "dati_privacy"
        }
    },
    {
        path: "/contatti",
        name: "Contatti",
        component: Contatti,
        meta: {
            backHidden: true,
            percentage: 10,
            model: "dati_anag"
        }
    },
    {
        path: "/anagrafica",
        name: "PersonalData1",
        component: PersonalData1,
        beforeEnter: (to, from, next) => {
            window.onbeforeunload = function() {
                return "Are you sure you want to navigate away?";
            }
            next();
        },
        meta: {
            percentage: 20,
            model: "dati_pers_1",
            alert: true
        }
    },
    {
        path: "/abitazione",
        name: "PersonalData2",
        meta: {
            percentage: 40,
            model: "dati_pers_2",
            alert: true
        },
        component: PersonalData2
    },
    {
        path: "/residenza",
        name: "DeliveryData",
        meta: {
            percentage: envType == 'banche' ? 30 : 60,
            model: "dati_pers_3",
            alert: true
        },
        component: DeliveryData
    },
    {
        path: "/documento",
        name: "IdentityCardData",
        meta: {
            percentage: envType == 'banche' ? 40 : 70,
            model: "dati_pers_4",
            alert: true
        },
        component: IdentityCardData
    },
    {
        path: "/reddito",
        name: "IncomeData",
        meta: {
            percentage: envType == 'banche' ? 50 : 40,
            model: "dati_lav",
            alert: true
        },
        component: IncomeData
    },
    {
        path: "/assicurazione",
        name: "LoanProtection",
        meta: {
            percentage: 60,
            model: "dati_protezione",
            alert: true,
            backHidden: true
        },
        component: LoanProtection
    },
    {
        path: "/assicurazione-avviso",
        name: "LoanProtectionWarning",
        beforeEnter: (to, from, next) => {
            if(from.name === 'LoanProtectionConsent') next({ name: "LoanProtection" });
            else next();
        },
        meta: {
            percentage: 60,
            model: "dati_protezione_warning",
            alert: true,
            backHidden: true
        },
        component: LoanProtectionWarning
    },
    {
        path: "/assicurazione-consenso",
        name: "LoanProtectionConsent",
        meta: {
            percentage: 60,
            model: "dati_protezione_consent",
            alert: true
        },
        component: LoanProtectionConsent
    },
    {
        path: "/lavoro",
        alias: "/lavoro-1",
        name: "EmployerData",
        meta: {
            percentage: envType == 'banche' ? 60 : 50,
            model: "dati_lav2",
            alert: true
        },
        component: EmployerData
    },
    {
        path: "/iban",
        name: "PaymentDetails",
        meta: {
            percentage: 80,
            model: "dati_paga",
            backHidden: true,
            alert: true
        },
        component: PaymentDetails
    },
    {
        path: "/privacy",
        alias: "/privacy-1",
        meta: {
            percentage: 99,
            model: "dati_privacy",
            alert: true
        },
        name: "PrivacyConsent",
        component: PrivacyConsent
    },
    {
        path: "/partner",
        name: "Partners",
        component: Partners,
        meta: {
            backHidden: true,
            footerHidden: true,
            containerFull: true
        }
    },
];

// Add login if protection is enabled
if(store.state.protected) {
    routes.push({
        path: "/accedi",
        name: "Login",
        component: require(`../views/themes/${theme}/Login.vue`).default,
        meta: {
            backHidden: true
        }
    })
}

if(store.state.customRoutes.length) {
    store.state.customRoutes.map(obj => {
        const view = obj.view || obj.name
        routes.push({
            path: obj.path,
            name: obj.name,
            meta: obj.meta ?? {},
            component: require(`../views/themes/${theme}/${view}.vue`).default
        })
    })
}

const router = new VueRouter({
    routes,
    base: store.state.baseUrl,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    mode: "history"
});

router.beforeEach((to, from, next) => {
    // store navigation path
    store.state.navigator.from = from;
    store.state.navigator.to = to;
    // unset unload alert
    if (to.meta.resetState) window.onbeforeunload = function() {};
    //redirect to homepage when reload browser page from a form view
    if( store.state.protected && to.name !== "Login" && to.name !== "PrivacyPolicy") {
        next({ name: "Login" })
    }
    else if (from.meta.resetState && to.name != 'Homepage') {
        store.commit("resetState");
        next({ name: "Homepage" })
    }
    else if ( ['Homepage', 'Login', 'Partners'].includes(to.name) || store.state.allowForms ) {
        next()
    }
    else {
        next({ name: "Homepage" })
    }
});


router.afterEach(() => {
    // fill datalayer
    store.dispatch("event", { name: "UserPath" })
})

export default router;
