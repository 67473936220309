import Vue from 'vue';

/** Set filters */
const formatter = new Intl.NumberFormat("it-IT", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 2
});

const formatter_num = new Intl.NumberFormat("it-IT", {
  minimumFractionDigits: 0
});

const formatter_num2 = new Intl.NumberFormat("it-IT", {
  minimumFractionDigits: 2
});

const formatter_num3 = new Intl.NumberFormat("it-IT", {
  minimumFractionDigits: 3
});

const formmater_accNum = new Intl.NumberFormat("it-IT", {
  trailingZeroDisplay: "stripIfInteger",
  minimumFractionDigits: 2
})

Vue.filter("eur", function(value) {
  return formatter.format(value);
});

Vue.filter("num", function(value) {
  return formatter_num.format(value);
});

Vue.filter("num2", function(value) {
  return formatter_num2.format(value);
});

Vue.filter("num3", function(value) {
  return formatter_num3.format(value);
});

Vue.filter("accNum", function(value){
  return formmater_accNum.format(value)
})