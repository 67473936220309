<template>
    <div class="w-full">
        <FormCard @submit.prevent="onSubmit" title="CLUB PLUS">
            <p class="mb-4">
                Cofidis ti regala il primo mese di <strong>accesso gratuito al CLUB PLUS</strong>.
                Puoi scegliere <strong>esperienze uniche in formula 2x1</strong> (ad esempio cena al ristorante per 2 persone al prezzo di 1
                oppure 2 ingressi al cinema o alla SPA pagandone solo 1) a soli 9,90 euro/mese, con addebito diretto sul tuo conto corrente.
                <a :href="infoClubUrl" target="_blank" class="underline">Scopri qui</a> tutti i vantaggi del CLUB PLUS.
            </p>
            <div slot="link" class="text-center text-gray-700 mt-4 mb-8">
                <button @click="onDecline()" class="underline">Procedi senza Club</button>
            </div>
        </FormCard>
    </div>
</template>
<style scoped type="text/css">
@media screen and (max-width: 639px) {
    div >>> .form-header { font-size: 1em !important; }
}
</style>
<script>
export default {
    data() {
        return {
            infoClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Cofidis_Club_PLUS.pdf'
        }
    },
    mounted() {
        this.$ga.pageview()
        this.$store.commit("setUserData", { key: 'service_available', value: true });
        this.$store.commit("setUserData", { key: 'service_pack', value: 'CLPLS' });
        this.$store.commit("setUserData", { key: 'service_amount', value: 9.90 });

        this.$store.dispatch("sendData", { step: "club" })
    },
    methods: {
        onSubmit() {
            this.$store.dispatch("redirect", { step: "club_plus", route: "ClubPlusConsent" });
        },
        onDecline() {
            this.$store.dispatch("redirect", { step: "club_plus_ko", route: "ClubPlusWarning" });
        }
    }
};
</script>