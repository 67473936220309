<template>
    <section class="pb-8 md:pb-4">
        <Steps :count="3" :active="2" class="my-5" />
        <div class="w-11/12 sm:max-w-7xl mx-auto shadow-lg rounded-3xl bg-white mt-5 mb-10 pb-10">
            <IdentityCardData />
        </div>
    </section>
</template>
<script>
import IdentityCardData from "@/views/forms/IdentityCardData";
import Steps from '@/components/Steps'
import { mapUserData } from "@/utils";

export default {
    components: { Steps, IdentityCardData },
    computed: {
        ...mapUserData([
            "step_number",
        ]),
    },
    mounted() {
        this.$store.commit("setUserData", { key: 'step_number', value: this.step_number + 1 });
    }
};
</script>