<template>
    <div class="mx-auto lg:container text-sm sm:text-base leading-tight text-gray-700 py-4">
        <div class="px-6 py-6 text-center text-2xl sm:text-3xl font-semibold">
            <i class="icon smile block mx-auto mb-4"></i>
            <p>Grazie!</p>
            Abbiamo ricevuto regolarmente la tua richiesta di finanziamento
        </div>
        <h1 class="text-xl sm:text-2xl font-semibold text-center sm:mt-4 mb-6 sm:mb-8 uppercase bg-orange-1 py-2">Cosa succede adesso?</h1>
        <div class="max-w-4xl text-lg mx-auto py-4 px-6">            
            <div class="flex mb-6 items-center">
                <div class="w-1/5 text-center">
                    <i class="icon phone block mx-auto"></i>
                </div>
                <p class="w-4/5 pl-4 sm:pl-0 text-sm">
                    Ricevi la chiamata di un consulente che ti potrà presentare altre offerte adatte alle tue esigenze
                </p>
            </div>
            <div class="flex mb-6 items-center">
                <div class="w-1/5 text-center">
                    <i class="icon signing block mx-auto"></i>
                </div>
                <p class="w-4/5 pl-4 sm:pl-0 text-sm">
                    Ricevi via e-mail la richiesta di finanziamento da firmare digitalmente (trovi le istruzioni nell’e-mail di conferma)
                </p>
            </div>
            <div class="flex mb-6 items-center">
                <div class="w-1/5 text-center">
                    <i class="icon coins block mx-auto"></i>
                </div>
                <p class="w-4/5 pl-4 sm:pl-0 text-sm">
                    L'importo sarà disponibile entro 48 ore dall'approvazione 
                </p>
            </div>

            <div class="typ-card w-full flex flex-col md:flex-row md:justify-center md:h-auto">
                <div class="h-1/2 md:h-auto md:w-1/4">
                    <img class="object-cover h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-none" src="https://d4ybvu6hnmspp.cloudfront.net/assets_front/consolidamento-crm/testimonials/Conso_Assistenza.jpg" alt="">
                </div>
                <div class="h-1/2 text-center bg-gray-100 rounded-b-3xl md:h-auto md:w-2/4 md:text-left md:rounded-r-3xl md:rounded-l-none pb-3 lg:pb-0">
                    <div class="text-2xl font-semibold pt-2 md:pl-8">Un consulente a te dedicato</div>
                    <p class="text-base px-4 pt-3 pb-2 md:pb-0 md:pl-8">Desideri anche una <span class="font-semibold">protezione assicurativa a copertura delle rate</span>?</p>
                    <p class="text-base px-4 md:pl-8 md:pb-2">Quando riceverai la chiamata del consulente a te dedicato potrai decidere di aggiungerla alla richiesta di finanziamento.</p>
                </div>
            </div>
            <div class="text-center mt-5 text-base">
                <strong>Al processo di estinzione del tuo finanziamento ci pensiamo noi!</strong>
            </div>
        </div>
    </div>
</template>
<script>
import { mapUserData } from "@/utils";

export default {    
    mounted() {
        localStorage.setItem('conso_request_evaluation', 1)
    },
    computed: {
        ...mapUserData(["name","importo"])
    }
};
</script>