<template>
    <div class="mx-auto lg:container text-sm sm:text-base leading-tight text-gray-700 py-4">
        <div class="absolute inset-0 flex items-center justify-center z-10 top-24 sm:top-0">
            <div class="modal-wrapper bg-white p-4 mx-4 text-center rounded-3xl mb-64 max-w-xl text-gray-700 shadow-xl">
                <i class="icon sadface block mx-auto"></i>
                <h1 class="text-3xl sm:text-3xl font-semibold md:w-2/3 mx-auto mt-4">L’offerta non è attivabile.</h1>
                <p class="mt-6">Purtroppo in applicazione dei nostri criteri di valutazione del merito creditizio, non possiamo procedere con la tua richiesta di Prestito Personale.</p>
                <p class="mt-3 mb-6">Ti confermiamo che <b>hai correttamente acquistato la polizza “MetLife Best Care”</b>, e <b>sei già protetto dalle ore 24:00 di oggi</b> e che il primo mese di copertura è gratuito.</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "TypMetlife",
    mounted() {
        localStorage.setItem('fastcredit_request_evaluaton', 1)
        localStorage.setItem('fastcredit_typ_metlife', 1)
    },
};
</script>