<template>
    <a href="#" @click.prevent="scrollToTop()" class="absolute shadow-arrow rounded-full bg-white bg-center bg-no-repeat w-12 h-12 right-0 top-0 -mt-6 mr-5">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="inline-block text-gray-500 h-8 mt-2 ml-2">
            <path fill-rule="evenodd" d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
        </svg>
    </a>
</template>
<script>
export default {
    name: "ScrollTopButton",
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        }
    }
}
</script>