<template>
    <main>
        <Loader v-if="loading" />
        <div v-else>
            <!-- Header -->
            <header class="w-full relative" :style="bgHeader">
                <!-- Logo -->
                <div class="logo flex justify-end container mx-auto px-6 max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <div class="z-50 w-2/3 h-14 md:w-1/3 sm:h-20 flex justify-around bg-white rounded-b-2xl py-2 lg:-mr-6 2xl:-mr-24 shadow-sm">
                        <img class="header-logo m-auto inline-block sm:hidden" src="@/assets/images/logo.svg" alt="Cofidis Logo" title="Cofidis Logo" />
                        <img class="header-logo m-auto hidden sm:inline-block" src="@/assets/images/logo-claim.svg" alt="Cofidis Logo" title="Cofidis Logo" />
                        <div class="h-full border-r border-solid border-black border-opacity-25"></div>
                        <img class="header-logo m-auto inline-block sm:hidden" src="@/assets/images/logo-power-credit.svg" alt="Power Credit Logo" title="Power Credit Logo" />
                        <img class="header-logo m-auto hidden sm:inline-block" src="@/assets/images/logo-power-credit.svg" alt="Power Credit Logo" title="Power Credit Logo" />
                    </div>
                </div>
            </header>
            <form class="w-full mx-auto text-center px-6 lg:px-0">
                <div class="bg-white rounded-3xl shadow-2xl mx-auto">
                    <div class="px-10">
                        <p class="text-xl text-gray-5 font-semibold pt-4 sm:pt-6 px-4 text-2xl sm:px-8 lg:text-xl">{{ name }},</p>
                        <p class="font-medium">solo tu che sei già cliente Cofidis richiedi fino a</p>
                        <h2 class="price-title text-6xl sm:text-7xl font-bold mt-3">2.000€</h2>
                        <!-- <select id="credit-select" class="home-select w-48 border-2 mt-3" v-model="choosenCredit" @change="activeSolutionIndex = $event.target.selectedIndex">
                            <option v-for="price in uniquePrices" :key="price.value" :value="price.value">{{ price.value|num }}€</option>
                        </select> -->
                        <p class="font-semibold text-xl mt-3">Da usare quando e come vuoi*.</p>
                    </div>
                    <div class="section-info flex flex-wrap rounded-b-3xl mt-6 px-2">
                        <div class="w-1/3 text-grey-5 text-center py-5">
                            <i class="icon nodocument align-middle w-12 h-12 block mx-auto"></i>
                            <h4 class="block text-base xl:inline-block lg:px-2 mt-3 leading-none">Senza busta paga</h4>
                        </div>
                        <div class="w-1/3 text-grey-5 text-center py-5">
                            <i class="icon free align-middle w-12 h-12 block mx-auto"></i>
                            <h4 class="block text-base xl:inline-block lg:px-2 mt-3 leading-none">Attivazione gratuita</h4>
                        </div>
                        <div class="w-1/3 text-grey-5 text-center py-5">
                            <i class="icon twentyfourh align-middle w-12 h-12 block mx-auto"></i>
                            <h4 class="block text-base xl:inline-block lg:px-2 mt-3 leading-none">Esito in 24 ore</h4>
                        </div>
                    </div>
                </div>
                <div class="absolute w-auto container text-left text-sm mt-4 sm:mt-9">*Salvo approvazione di Cofidis</div>
            </form>
            <a @click="submit()" class="cta w-4/6 sm:w-auto font-semibold text-lg text-gray-4 bg-orange-1 max-w-xs rounded-full shadow-2xl px-6 sm:px-10 py-2 sm:max-w-none fixed bottom-6 left-1/2 transform -translate-x-1/2 z-50 cursor-pointer">
                <span class="block text-center text-xl">ATTIVA GRATIS ORA</span>
                <span class="block font-light text-base text-center -mt-1">Solo fino al <strong class="font-bold uppercase">{{ expDate }}</strong></span>
            </a>

            <!-- Section steps -->
            <section class="section-steps bg-gray-5 py-6 sm:py-10 lg:py-8">
                <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <!-- Title -->
                    <div class="text-center mb-18 sm:mb-16">
                        <h3 class="font-semibold text-3xl text-white">
                            Attiva Power Credit
                        </h3>
                    </div>
                    <!-- Steps -->
                    <!-- <div class="flex flex-wrap"> -->
                        <!-- Step 1 -->
                        <!-- <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/3 lg:flex lg:flex-1">
                            <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 sm:h-40 lg:h-auto animate__animated animateFadeInUp animate__1">
                                <span class="block font-bold text-sm text-gray-4">Step 1</span>
                                <p class="font-semibold text-3xl text-red-1">I tuoi dati</p>
                                <p class="font-medium text-gray-4">Completa i tuoi dati e invia la richiesta</p>
                            </div>
                        </div> -->
                        <!-- Step 2 -->
                        <!-- <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/3 lg:flex lg:flex-1">
                            <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 sm:h-40 lg:h-auto animate__animated animateFadeInUp animate__2">
                                <span class="block font-bold text-sm text-gray-4">Step 2</span>
                                <p class="font-semibold text-3xl text-red-1">Esito</p>
                                <p class="font-medium text-gray-4">Ricevi l’esito immediato</p>
                            </div>
                        </div> -->
                        <!-- Step 3 -->
                        <!-- <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/3 lg:flex lg:flex-1">
                            <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 sm:h-40 lg:h-auto animate__animated animateFadeInUp animate__4">
                                <span class="block font-bold text-sm text-gray-4">Step 3</span>
                                <p class="font-semibold text-3xl text-red-1">Firma digitale</p>
                                <p class="font-medium text-gray-4">Firma il contratto direttamente dal tuo smartphone</p>
                            </div>
                        </div>
                    </div> -->
                </div>
            </section>

            <!-- Section vantaggi -->
            <section class="section-feat py-6 text-center cursor-pointer" @click="scrollToAnchor()">
                <div class="max-w-4xl lg:max-w-5xl xl:max-w-7xl mx-auto">
                    <div class="w-full relative flex flex-wrap items-center px-6">
                        <h3 class="w-3/4 flex-grow font-semibold text-lg text-left px-2">Scopri tutti i vantaggi di Power Credit.</h3>
                        <i class="icon arrowdown"></i>
                    </div>
                </div>
            </section>

            <!-- Section claim -->
            <section class="py-6">
                <div class="max-w-4xl lg:max-w-5xl xl:max-w-7xl mx-auto">
                    <div class="w-full relative flex flex-wrap items-center px-6">
                        <p class="w-3/4 flex-grow text-lg text-left px-2">Power Credit è la Linea di Credito Ricaricabile disponibile per i tuoi utilizzi.</p>
                    </div>
                </div>
            </section>

            <!-- Section passaggi -->
            <section class="section-passages bg-white py-6">
                <div class="sm:container p-6 mx-auto">
                    <h3 class="font-semibold text-3xl text-gray-5 leading-none mb-6 text-center">
                        Come attivarla?<br>
                        Bastano pochi passaggi
                    </h3>
                    <!-- Elenco puntato passaggi -->
                    <div class="relative w-full xl:w-2/3 mx-auto">
                        <div class="absolute h-4/5 top-2 left-4 sm:top-0 sm:h-full w-0.5 bg-yellow  z-0"></div>
                            
                        <div class="relative w-full flex mb-6 z-1">
                            <div class="bg-white w-8 h-8 mr-5 border border-solid border-yellow rounded-full text-center">
                                <span class="align-middle font-semibold">1</span>
                            </div>
                            <div class="w-10/12 flex">
                                <p class="text-base font-bold my-auto">Controlla i tuoi dati </p>
                            </div>
                        </div>
                        <div class="relative w-full flex mb-6 z-1">
                            <div class="bg-white w-8 h-8 mr-5 border border-solid border-yellow rounded-full text-center ">
                                <span class="align-middle font-semibold">2</span>
                            </div>
                            <div class="w-10/12 flex">
                                <p class="text-base font-bold my-auto">Invia la richiesta di Power Credit</p>
                            </div>
                        </div>
                        <div class="relative w-full flex mb-6 z-1">
                            <div class="bg-white w-8 h-8 mr-5 border border-solid border-yellow rounded-full text-center">
                                <span class="align-middle font-semibold">3</span>
                            </div>
                            <div class="w-10/12 flex">
                                <p class="text-base font-bold my-auto">Attendi l’esito </p>
                            </div>
                        </div>
                        <div class="relative w-full flex mb-6 z-1">
                            <div class="bg-white w-8 h-8 mr-5 border border-solid border-yellow rounded-full text-center">
                                <span class="align-middle font-semibold">4</span>
                            </div>
                            <div class="w-10/12 flex">
                                <p class="text-base font-bold my-auto">Firma la richiesta di finanziamento digitalmente</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Section faq -->
            <section id="anchor"  class="section-faq text-center">
                <div class="sm:container p-6 mx-auto">
                    <h3 class="font-semibold text-3xl text-gray-5 leading-none px-6 mb-6 text-center">
                        Come funziona Power Credit?
                    </h3>
                    <div class="faq-container w-full xl:w-2/3 flex flex-wrap hover:text-orange cursor-pointer bg-white rounded-xl py-3 px-6 mx-auto mb-3">
                        <div class="w-full">
                            <div class="flex flex-wrap">
                                <div class="faq-question outline-none w-4/5 sm:flex-grow text-left" :class="{ active: activeFaq == 0 }" @click="toggleFaq(0)">
                                    <span class="font-semibold text-lg text-gray-5 leading-none">La Linea di Credito Ricaricabile è sempre disponibile online.</span>
                                </div>
                                <div class="flex-grow text-right rounded-full leading-10 outline-none flex items-center justify-end"  @click="toggleFaq(0)">
                                    <svg v-if="activeFaq != 0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                                    </svg>
                                </div>
                            </div>
                            <SlideUpDown class="faq-content font-medium text-gray-5 cursor-default outline-none text-left leading-0 my-2" :active="activeFaq == 0">
                                <span class="font-light text-base text-gray-5 leading-none">
                                    Power Credit è semplice! Apri Power Credit gratuitamente, scegliendo l’importo del fido che vuoi ottenere fino ad un massimo di 2.000 €, chiedi 
                                    subito di ricevere una somma sul tuo conto corrente e poi ricarichi Power Credit grazie alle rate fisse mensili di soli 60€. Così puoi tenere 
                                    sotto controllo le tue uscite!
                                </span>
                            </SlideUpDown>
                        </div>
                    </div>
                    <div class="faq-container w-full xl:w-2/3 flex flex-wrap hover:text-orange cursor-pointer bg-white rounded-xl py-3 px-6 mx-auto mb-3">
                        <div class="w-full">
                            <div class="flex flex-wrap">
                                <div class="faq-question outline-none w-4/5 sm:flex-grow text-left" :class="{ active: activeFaq == 1 }" @click="toggleFaq(1)">
                                    <span class="font-semibold text-lg text-gray-5 leading-none">L’attivazione è gratuita. Il primo utilizzo è in promozione per te.</span>
                                </div>
                                <div class="flex-grow text-right rounded-full leading-10 outline-none flex items-center justify-end"  @click="toggleFaq(1)">
                                    <svg v-if="activeFaq != 1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                                    </svg>
                                </div>
                            </div>
                            <SlideUpDown class="faq-content font-medium text-gray-5 cursor-default outline-none text-left leading-0 my-2" :active="activeFaq == 1">
                                <span class="font-light text-base text-gray-5 leading-none">
                                    Solo per te c’è un ulteriore vantaggio: <strong class="font-bold">TAN promozionale 0% sulle prime {{ prices[activeSolutionIndex].rate }} rate rimborsate.</strong> Dal 4° mese in poi, 
                                    valgono le condizioni economiche standard*.
                                    <br><br>
                                    *TAN fisso standard {{ prices[activeSolutionIndex].tan|num2 }}% - TAEG {{ prices[activeSolutionIndex].taeg|num2 }}%
                                    <!-- Restituisci l’importo in {{ prices[activeSolutionIndex].rate }} piccole rate da {{ prices[activeSolutionIndex].imp_rata }}€.
                                    Ricevi un primo esito immediato e firmi digitalmente online la richiesta di Prestito Personale. -->
                                </span>
                            </SlideUpDown>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Section widget -->
            <section class="section-widget md:mt-0 py-6 sm:py-10 lg:py-8 bg-white">
                <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <!-- Title -->
                    <div class="text-center mb-4 sm:mb-8">
                        <h3 class="font-semibold text-4xl text-gray-5 leading-none">
                            Cosa dicono i <br class="sm:hidden" />
                            nostri clienti
                        </h3>
                    </div>
                    <Trustpilot />
                </div>
            </section>

            <!-- Section trust -->
            <section class="bg-white py-6">
                <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <h3 class="font-semibold text-3xl text-gray-5 leading-none mb-6 text-center">Cofidis vuol dire fiducia!</h3>
                    <p class="text-base font-medium px-4">Siamo <strong class="font-bold">il credito al consumo che dà e ispira fiducia immediata</strong> grazie a soluzioni semplici, veloci e 100% digitali in grado di
                        fornire un aiuto immediato nella vita delle persone.</p>
                    <p class="text-base font-medium px-4 mt-4">Proprio come abbiamo fatto con te, che <strong class="font-bold">ci hai già scelto</strong> per acquistare ciò che ami. Ora vogliamo darti ulteriore fiducia
                        con questa <strong class="font-bold">soluzione pensata per te.</strong></p>
                </div>
            </section>

            <!-- Section vantaggi -->
            <section class="section-feat2 p-6">
                <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <h3 class="font-semibold text-3xl text-gray-5 leading-none mb-6 text-center">Tanti vantaggi in un lampo</h3>
                    <div class="advantages-block rounded-3xl shadow-2xl md:flex p-5 mb-4">
                        <div class="p-5 text-center border-b md:border-b-0 md:border-r border-solid border-black border-opacity-25">
                            <i class="icon twentyfourh align-middle w-16 h-16 mb-3 block mx-auto"></i>
                            <h3 class="font-semibold text-2xl text-gray-5 mb-3">Esito in 24 ore</h3>
                            <p class="text-base font-medium">Bastano 2 click per inviare la richiesta di Power Credit e per ricevere l’esito.</p>
                        </div>
                        <div class="p-5 text-center border-b md:border-b-0 md:border-r border-solid border-black border-opacity-25">
                            <i class="icon nodocument align-middle w-16 h-16 mb-3 block mx-auto"></i>
                            <h3 class="font-semibold text-2xl text-gray-5 mb-3">Senza busta paga</h3>
                            <p class="text-base font-medium">Non ti chiediamo nessun documento di reddito: ci bastano pochi dati e la tua parola.</p>
                        </div>
                        <div class="p-5 text-center">
                            <i class="icon free align-middle w-16 h-16 mb-3 block mx-auto"></i>
                            <h3 class="font-semibold text-2xl text-gray-5 mb-3">Attivazione gratuita</h3>
                            <p class="text-base font-medium">Puoi richiedere subito fino a 2.000€. Ma se poi non li utilizzi, non paghi nulla.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </main>
</template>
<script>
// @ is an alias to /src
import Trustpilot from "@/components/TrustpilotSwiper"
import Loader from "@/components/Loader"
import loadData from "@/dataService.js";
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment/moment";
import { mapUserData } from "@/utils";

const redirectHomepage = () => {    
    location.href = '/powercredit-crm'
}

export default {
    name: "Home",
    components: { Loader, Trustpilot },
    methods: {
        async loadClientData(data) {
            return new Promise((resolve, reject) => {
                axios.post(this.crmUrl, data)
                    .then(function(response) {
                        if(response.data && response.data.dt_end_eleg_fast)
                            resolve(response.data)
                        else
                            reject(false)
                    })
                    .catch(function() {
                        reject(false)
                    })
            })
        },
        scrollToAnchor() {
            window.scrollTo({
                top: document.getElementById('anchor').offsetTop,
                left: 0,
                behavior: "smooth",
            });
        },
        toggleFaq(i) {
            if (this.activeFaq == i) this.activeFaq = null;
            else this.activeFaq = i;
        },
        async submit() {
            this.$store.commit("setUserData", { key: 'importo', value: this.choosenCredit });
            this.$store.commit("setUserData", { key: 'rate', value: this.prices[this.activeSolutionIndex].rate });
            this.$store.commit("setUserData", { key: 'income_check', value: null });
            this.$store.dispatch("sendData", { step: "dati_anag" });
            this.$store.dispatch("redirect", { step: "homepage", route: "Income" });
        },
        reloadOnBrowserBack() {            
            window.popStateDetected = false
            window.addEventListener('popstate', redirectHomepage)
        }
    },
    computed: {
        ...mapGetters(["uniquePrices"]),
        ...mapUserData(["name"]),
        choosenCredit: {
            get() {
                return this.$store.state.choosenCredit;
            },
            set(val) {
                this.$store.commit("setChoosenCredit", val);
            }
        },
        bgHeader() {
            const env = process.env.VUE_APP_ENV == "production" ? 'prod' : 'stage'
            return {
                '--bg-header': `#fff url("${this.distributionUrl}/${env}/header.jpg") 55% 0% no-repeat`,
                '--bg-header-sm': `#fff url("${this.distributionUrl}/${env}/header-sm.jpg") center top no-repeat`                
            }
        },
        bgBanner() {
            return {
                '--bg-banner': `#fff url("${this.distributionUrl}/credito-cofidis-banner.png") 60% top no-repeat`,
                '--bg-banner-sm': `#fff url("${this.distributionUrl}/credito-cofidis-banner-sm.png") 60% top no-repeat`,
            }
        },
        crmUrl() {
            return this.$store.state.crmUrl;
        }
    },
    data() {
        return {
            name: '',
            loading: true,
            expDate: null,
            activeFaq: null,
            activeSolutionIndex: 0,
            maxCredit: null,
            prices: loadData("price.json").prices.reverse(),
            distributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/powercredit-crm",
            commonDistributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/common"
        };
    },
    async created() {
        this.reloadOnBrowserBack()

        // reload from thank you page
        if(localStorage.getItem('request_evaluaton')) {
            this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
            return;
        }

        // get params
        const urlParts = this.$store.state.urlParts
        let userId, codeCampaign, trackCode;

        if(urlParts[1] && urlParts[2]) {
            userId = urlParts[1]
            codeCampaign = urlParts[2]
            trackCode = urlParts[3] ?? null
        }
        else {
            userId = localStorage.getItem('powercredit_user_id')
            codeCampaign = localStorage.getItem('powercredit_code_campaign')
            trackCode = localStorage.getItem('powercredit_track_code') ?? null
            // fix localstorage cast type issue
            if(trackCode === "null") trackCode = null;
        }

        try {
            localStorage.setItem('powercredit_user_id', userId)
            localStorage.setItem('powercredit_code_campaign', codeCampaign)
            if(trackCode) localStorage.setItem('powercredit_track_code', trackCode)

            const data = await this.loadClientData({userId, codeCampaign, trackCode});
            this.$store.commit("setUserData", { key: 'user_id', value: userId });
            this.$store.commit("setUserData", { key: 'code_campaign', value: codeCampaign });
            this.$store.commit("setUserData", { key: 'prod', value: data.Prod });
            this.$store.commit("setUserData", { key: 'name', value: data.Nome });
            this.$store.commit("setUserData", { key: 'surname', value: data.Cognome });
            this.$store.commit("setUserData", { key: 'email', value: data.EMail });
            this.$store.commit("setUserData", { key: 'iban', value: data.Iban });
            this.$store.commit("setUserData", { key: 'fiscal_code', value: data.CF });
            this.$store.commit("setUserData", { key: 'cellphone', value: data.Phone });
            this.$store.commit("setUserData", { key: 'day_in_month', value: 1 });

            // set name
            this.name = data.Nome

            // set expiry date
            this.expDate = moment(data.dt_end_eleg_power).locale("it").format('D MMMM')
            this.$store.commit("setUserData", { key: 'exp_date', value: this.expDate });

            // offer expired
            if(moment(data.dt_end_eleg_power).endOf('day') < moment()) {
                this.$store.dispatch("redirect", {step: "income", route: "RequestExpired"});
                return;
            }
            // pending procedure
            if(data.page_block && data.page_block === 'evaluation') {
                this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
                return;
            }
            // failed procedure
            if(data.page_block && data.page_block === 'reddito_not_allowed') {
                this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
                return;
            }
            // set max credit available
            // this.maxCredit = Math.min(
            //     parseInt(data.Importo),
            //     loadData("price.json").default_credit
            // )
            // this.$store.commit("setChoosenCredit", this.maxCredit)

            // check expired document
            if(moment(data.data_scadenza_documento) < moment()) {
                this.$store.commit("setUserData", { key: 'document_expired', value: true });
            }
            else {
                this.$store.commit("setUserData", { key: 'document_expired', value: false });
            }

            // init robot
            this.$store.dispatch("sendData", { step: "enter_landing" });
        } catch (error) {
            // reload from thank you page
            if(localStorage.getItem('powercredit_request_evaluaton')) {
                this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
            }
            else {
                this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
            }
        }
        this.loading = false;
    },
    async mounted() {
        // animation interesection
        window.onscroll = function() {
            const animateFadeInUp = document.getElementsByClassName("animateFadeInUp");
            const windowOffsetTop = window.innerHeight + window.scrollY;

            Array.prototype.forEach.call(animateFadeInUp, (animatedBox) => {
                const animatedBoxOffsetTop = animatedBox.offsetTop;
                if (windowOffsetTop >= animatedBoxOffsetTop) {
                    animatedBox.classList.add('animate__fadeInUp')
                }
            });
        }
    }
};
</script>