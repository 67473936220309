<template>
    <footer class="bg-gray-4 text-white">
        <div class="w-11/12 py-6 mx-auto sm:max-w-7xl relative">
            <ScrollTopButton />
            <div class="footer-logo">
                <img class="w-36" src="@/views/themes/consolidamento/images/logo-claim-white.svg" />
            </div>
            <div class="footer-content mt-4">
                <h4 class="text-base font-semibold mb-5 sm:text-2xl">
                    Informazioni legali
                </h4>
                <p class="mb-5">
                    Messaggio pubblicitario con finalità promozionale.<br>
                    Al fine di gestire le tue spese in modo responsabile Cofidis S.A. ti ricorda, prima di sottoscrivere il
                    contratto, di prendere visione di tutte le condizioni economiche e contrattuali, facendo riferimento alle
                    Informazioni Europee di Base sul Credito ai Consumatori (SECCI) sul sito
                    <a href="https://www.cofidis.it" target="_blank" class="">www.cofidis.it</a> sezione trasparenza.
                    L’erogazione dei prestiti richiesti, gli importi finanziabili e i massimi erogabili sono soggetti
                    all’approvazione di Cofidis S.A..

                    {{ footer_example }}
                </p>
                <p class="border-b border-gray-6 pb-5 mb-5">
                    Offerta riservata ai nuovi clienti e valida dal {{ start_date }} fino
                    al {{ end_date }}.<br>
                    La richiesta di finanziamento è soggetta alla valutazione da parte di Cofidis S.A..
                </p>
                <p id="guide_pdf" class="mb-5">
                    <a href="https://www.cofidis.it/trasparenza/" class="underline" target="_blank">Trasparenza</a>
                </p>
                <p class="my-3">
                    <b>Cofidis Italia</b><br>&copy; Copyright {{ year }}
                </p>
                <p class="mb-3">
                    Capitale Sociale 67.500.000€ i.v., Iscritta all’Albo Banche ed altri intermediari esteri abilitati in Italia al nr. 8101 - Società soggetta a direzione e coordinamento di Banque Fédérative du Crédit Mutuel S.A. – soggetta alla vigilanza della Banca di Francia (Autorité de Contrôle Prudentiel et de Résolution) e, in qualità di succursale italiana di banca comunitaria, alla vigilanza della Banca d’Italia, per le materie a questa demandate dalle vigenti disposizioni normative. Abilitata all’esercizio dell’attività di intermediazione assicurativa in Italia, iscritta nell’Elenco degli Intermediari dell’Unione Europea annesso al Registro degli Intermediari Assicurativi nr. UE00012402, soggetta a vigilanza dell’ AUTORITÉ DE CONTROLE PRUDENTIEL (ACP).
                </p>
                <p class="mt-4">            
                    <a href="https://www.cofidis.it/it/privacy-policy.html" class="underline text-sm" target="_blank">Privacy Policy</a> |
                    <a href="https://cofidis-docs-pp.s3.eu-central-1.amazonaws.com/agid.pdf" class="underline text-sm" target="_blank">Accessibilità: non conforme</a>
                </p>
            </div>
        </div>
    </footer>
</template>
<script>
import $ from "jquery";
import ScrollTopButton from "@/components/scrollTopButton.vue";
export default {
    components: { ScrollTopButton },
    data() {
        return {
            start_date: null,
            end_date: null,
            footer_example: null,
            year: null,
        };
    },
    async mounted() {
        $(document).on("click", ".to-top", function() {
            $("html, body").animate({ scrollTop: 0 }, 1000);
            return false;
        });
        const data = await $.getJSON(
            "https://cofidis-settings.s3.eu-central-1.amazonaws.com/prestitocofidis.json"
        );
        this.start_date = data.Start_Date;
        this.end_date = data.End_Date;
        this.footer_example = data.Footer_Example;
        this.year = new Date().getFullYear();
    }
};
</script>
