<template>
    <footer class="bg-white">
        <div class="w-full p-6 mx-auto sm:max-w-7xl relative">
            <ScrollTopButton />
            <div class="footer-content mt-4">
                <div class="w-full">
                    <div class="flex flex-wrap cursor-pointer">
                        <div class="faq-question outline-none w-4/5 sm:flex-grow text-left" :class="{ active: activeFooter }" @click="activeFooter = !activeFooter">
                            <span class="text-base text-gray-5 leading-none">Messaggio pubblicitario con finalità promozionale.</span>
                        </div>
                        <div class="flex-grow outline-none flex items-center justify-center sm:justify-end sm:mr-6"  @click="activeFooter = !activeFooter">
                            <svg v-if="!activeFooter" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                            </svg>
                        </div>
                    </div>
                    <SlideUpDown class="faq-content font-medium text-gray-5 cursor-default outline-none text-left leading-0 my-2" :active="activeFooter">
                        <p class="text-base">
                            Per le condizioni economiche e contrattuali si rimanda alla documentazione in tuo possesso. 
                            Puoi utilizzare l'importo della Linea di Credito Revolving in una o più soluzioni, salvo 
                            approvazione da parte di Cofidis a fronte di ogni utilizzo. Offerta valida fino al {{ $store.state.userData.exp_date }}.
                        </p>
                    </SlideUpDown>
                </div>
                <div class="text-center sm:text-left text-base mb-24 mt-4">
                    <a href="https://www.cofidis.it/it/privacy-policy.html" class="underline text-base" target="_blank">Privacy Policy</a> |
                    <a href="https://cofidis-docs-pp.s3.eu-central-1.amazonaws.com/agid.pdf" class="underline text-base" target="_blank">Accessibilità: non conforme</a>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import $ from "jquery";
import {mapGetters, mapState} from "vuex";
import moment from 'moment';
import ScrollTopButton from "@/components/scrollTopButton.vue";

export default {
    components: { ScrollTopButton },
    data() {
        return {
            activeFooter: false,
        };
    },
    async mounted() {
        $(document).on("click", ".to-top", function() {
            $("html, body").animate({ scrollTop: 0 }, 1000);
            return false;
        });
    },
    computed: {
        ...mapGetters(["uniquePrices"]),
        ...mapState(["userData"]),
        installmentAmount() {
            console.log(this.$store.state.userData)
            return this.userData.installment_amount
        },
        providedAmount() {
            return this.userData.provided_amount
        },
        amount1() {
            return this.userData.amount_1
        },
        amount2() {
            return this.userData.amount_2
        },
        amountOwed() {
            return this.userData.amount_owed
        },
        installmentsNumber() {
            return this.userData.rate
        },
        taeg() {
            return this.userData.taeg
        },
        tan() {
            return this.userData.tan
        },
        pianoF() {
            return this.userData.piano_f
        },
        dataEnd() {
            return moment(this.userData.dt_end_conso).locale("it").format('D MMMM')
        },
        secciUrl() {
            return "https://cofidis-secci.s3.eu-central-1.amazonaws.com/" + this.pianoF + "/prod/" + this.userData.provided_amount + "_" + this.installmentsNumber + ".pdf"
        },
    },
};
</script>
