<template>
    <div>
        <form class="px-4 mx-auto lg:container py-4 text-gray-700" @submit.prevent="$emit('submit', $event)">
            <FormHeader>{{ $t(title) }}</FormHeader>
            <slot name="before"></slot>
            <div class="form-body p-4 pb-6 sm:px-6 md:px-8 flex flex-wrap items-start" :class="`${formClass}`">
                <slot></slot>
            </div>
            <slot name="after"></slot>
            <div class="form-footer text-center relative" v-if="defaultSubmit">
                <button type="submit" class="uppercase" :class="submitClass">
                    {{ $t(submitText) }}
                </button>
            </div>
        </form>
        <slot name="link"></slot>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: ""
        },
        formClass: {
            type: String,
            default: "rounded-b-lg"
        },
        defaultSubmit: {
            type: Boolean,
            default: true
        },
        submitText: {
            type: String,
            default: "CONTINUA"
        },
        submitClass: {
            type: String,
            default: "bg-accent rounded-full py-3 px-16 font-semibold shadow-xl z-100"
        }
    }
};
</script>
