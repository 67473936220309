<template>
    <section class="pb-8 md:pb-5 pt-5">
        <Steps :count="step_number" :active="step_active" />
        <div class="w-11/12 sm:max-w-7xl mx-auto shadow-lg rounded-3xl bg-white mt-5 mb-10 pb-10">
            <FormHeader class="pt-8 text-center">Controlla i tuoi dati</FormHeader>

            <!-- dati paga -->
            <div class="bg-light p-6 md:py-8">
                <div class="max-w-4xl xl:max-w-6xl mx-auto flex flex-wrap justify-center">
                    <div class="w-full flex justify-center">
                        <span class="block md:w-1/6"></span>
                        <label class="w-1/4 text-lg text-gray-900 font-medium pr-5">Cognome</label>
                        <span class="w-3/4 md:w-1/4 text-lg text-gray-700 font-semibold uppercase pl-5">{{ surname }}</span>
                    </div>
                    <div class="w-full flex justify-center">
                        <span class="block md:w-1/6"></span>
                        <label class="w-1/4 text-lg text-gray-900 font-medium pr-5 mt-2">Nome</label>
                        <span class="w-3/4 md:w-1/4 text-lg text-gray-700 font-semibold uppercase pl-5 mt-2">{{ name }}</span>
                    </div>
                    <div class="w-full flex justify-center">
                        <span class="block md:w-1/6"></span>
                        <label class="w-1/4 text-lg text-gray-900 font-medium pr-5 mt-2">IBAN</label>
                        <span class="w-3/4 md:w-1/4 text-lg text-gray-700 font-semibold uppercase pl-5 mt-2">{{ maskedIban }}</span>
                    </div>
                </div>
            </div>

            <!-- email -->
            <div class="bg-yellow text-center p-6">
                <p class="text-center font-semibold text-xl text-gray-900 leading-none">Verifica che la tua e-mail sia corretta oppure inseriscila.</p>
                <div class="sm:w-96 text-center mx-auto mt-4">
                    <div class="relative">
                        <input type="email" name="email" class="w-full py-2 pl-4 pr-10 text-lg" v-model="email" v-mask="{ placeholder: '', greedy: false, casing: 'lower' }"
                            placeholder="Se non appare la tua email, inseriscila" :class="{ 'border border-red': $v.email.$error }">
                        <i class="icon pencil absolute top-3 right-2"></i>
                    </div>
                    <div class="w-full text-red text-left" v-if="$v.email.$error">
                        <small v-if="!$v.email.required">
                            {{ $t("Campo obbligatorio") }}
                        </small>
                        <small v-else-if="!$v.email.email">
                            {{ $t("Formato e-mail non valido") }}
                        </small>
                    </div>
                </div>
            </div>

            <div class="py-4 px-6 text-gray-4 bg-gray-1">
                <div class="mx-auto flex flex-wrap text-gray-5">
                    <span><strong class="font-bold">Perché conosciamo i tuoi dati?</strong>
                        La risposta è <strong class="font-bold">semplice</strong> e <strong class="font-bold">trasparente</strong>:
                        hai già una delle soluzioni di credito con Cofidis</span>
                </div>
            </div>

            <!-- Income check -->
            <div class="w-full py-5 md:py-8 text-center px-4">
                <p class="font-semibold text-xl text-gray-900">Percepisci un reddito mensile dimostrabile superiore a 700€?</p>
                <p class="mt-2">Non ti chiediamo documenti di reddito perché ci fidiamo della tua parola.</p>
                <div class="w-full mt-5 mb-5 flex justify-center">
                    <input type="radio" class="invisible absolute" id="income_check_yes" :value="true" v-model="incomeCheck" />
                    <label for="income_check_yes" class="h-10 w-12 flex border border-black mr-16 rounded cursor-pointer" :class="{'bg-yellow': incomeCheck}">
                        <span class="m-auto text-lg font-semibold uppercase">{{ $t("sì") }}</span>
                    </label>
                    <input type="radio" class="invisible absolute" id="income_check_no" :value="false" v-model="incomeCheck" />
                    <label for="income_check_no" class="h-10 w-12 flex border border-black rounded cursor-pointer" :class="{'bg-yellow': incomeCheck == false}">
                        <span class="m-auto text-lg font-semibold uppercase">{{ $t("no") }}</span>
                    </label>
                </div>
                <div class="text-red" v-if="$v.incomeCheck.$error">
                    <p class="font-semibold px-8 leading-tight">
                        Per continuare è necessario selezionare "SI" o "NO".
                    </p>
                </div>
                <div class="w-full font-semibold" v-if="incomeCheck == false">
                        Cliccando su CONTINUA dichiari che il tuo reddito mensile è inferiore a 700€.
                </div>
            </div>

            <div class="form-footer text-center relative">
                <button type="submit" class="uppercase" @click="submit()">
                    CONFERMA
                </button>
            </div>
        </div>
    </section>
</template>
<script>

import Steps from '@/components/Steps'
import FormHeader from '@/components/FormHeader.vue'
import { mapUserData } from "@/utils";
import { required, email } from "vuelidate/lib/validators";

export default {
    components: { Steps, FormHeader },
    data() {
        return {
            incomeCheck: null,
            step_number: 3,
            step_active: 2,
        };
    },
    created() {
        if ( this.document_expired || this.incomeCheck === false )
            this.step_number = 4
    },
    computed: {
        ...mapUserData([
            "name",
            "surname",
            "iban",
            "email",
            "document_expired",
            "service",
            "is_creditline"
        ]),
        maskedIban() {
            return `${this.iban[0]}${this.iban[1]}****************${this.iban.slice(-4)}`
        },
    },
    validations: {
        email: {
            required,
            email
        },
        incomeCheck:{
            required
        },
    },
    methods: {
        submit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                this.$store.commit("setUserData", { key: 'can_request', value: this.incomeCheck });
                this.$store.commit("setUserData", { key: 'monthly_income', value: this.incomeCheck ? 701 : 0 });
                this.$store.dispatch("sendData", { step: "income" });
                if(this.incomeCheck) {
                    if(this.document_expired)
                        this.$store.dispatch("redirect", {step: "income", route: "Documents"});
                    else
                        this.$store.dispatch("redirect", {step: "income", route: "PrivacyPowerCredit"});
                }
                else {
                    if (!this.is_creditline) {
                        this.$store.dispatch("redirect", {step: "income", route: "MetlifePrecompiled"});
                    }
                    else {
                        if (this.service === 1) this.$store.dispatch('subscribeClub')
                        this.$store.dispatch("sendData", { step: "esito_ko" });
                        this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
                    } 
                }
            }
        }
    }
};
</script>