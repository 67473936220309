class Append {
    enter_landing(payload, {state}) {
        payload['CF'] = state.userData.fiscal_code
        payload['Prod'] = state.userData.cod_camp_conso
        payload['tab_finanz_conso'] = state.userData.piano_f
        payload['userId'] = state.userData.user_id
        payload['codeCampaign'] = state.userData.code_campaign
        payload['dt_end_conso'] = state.userData.dt_end_conso
        payload['pratica_conso'] = state.userData.pratica_conso
        payload['cliente_id'] = state.userData.client_id
    }

    dati_anag(payload, {state}) {
        delete(payload['EMail'])
        delete(payload['CF'])
        delete(payload['Importo'])
        payload['stepNumber'] = 0
        payload['finanziato_conso'] = state.userData.provided_amount
        payload['extra_cash_conso'] = state.userData.extra_amount
        payload['extra_rata_conso'] = state.userData.installments_diff
        payload['imp_rata_conso'] = state.userData.installment_amount
        payload['importo_1_conso'] = state.userData.amount_1
        payload['importo_2_conso'] = state.userData.amount_2
        payload['importo_totale_dovuto_conso'] = state.userData.amount_owed
        payload['taeg_conso'] = state.userData.taeg
        payload['tan_conso'] = state.userData.tan
    }

    dati_lav(payload, {state}) {
        delete(payload['Mensilita'])
        delete(payload['Attivita'])
        delete(payload['Settore'])
        delete(payload['Occupazione'])
        delete(payload['Famigliari'])
        delete(payload['Famigliari_Reddito'])
        delete(payload['Occupato_Al'])
        payload['EMail'] = state.userData.email
        payload['Iban'] = state.userData.iban
        payload['stepNumber'] = 1
        payload['Occupato_Dal'] = '01/' + state.userData.employed_since
    }

    dati_privacy(payload, {state}) {
            payload['stepNumber'] = 2
            payload['Importo'] = state.userData.extra_amount
    }
}
export default new Append();