<template>
    <FormCard @submit="onSubmit" title="Dati di pagamento">
        <div class="text-sm w-full md:w-1/2 xl:pt-2 mb-4 md:mb-0 sm:flex flex-wrap items-center text-gray-700">
            <div class="md:pr-4">
                {{ $t("Scegli il giorno del mese per l'addebito della rata") }}
            </div>
            <div class="flex mx-auto md:ml-0 justify-center mt-2 sm:mt-0">
                <DayInMonth v-for="day in debitDays" v-model="day_in_month" class="mr-2" :hasError="$v.day_in_month.$error" :value="day.value" :label="day.label" :key="day.value"></DayInMonth>
            </div>
            <div class="h-6 w-full text-red">
                <small v-if="$v.day_in_month.$error && !$v.day_in_month.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-full md:w-1/2 text-gray-700">
            <FloatingLabelInput :name="`iban`" v-model="iban" :mask="ibanMask" :label="`IBAN del tuo conto corrente italiano`" :error="$v.iban.$error"/>            
            <div class="h-auto w-full text-red leading-4 sm:leading-5 mt-1 sm:mt-0">
                <small v-if="$v.iban.$error && !$v.iban.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
                <small v-else-if="$v.iban.$error && !$v.iban.validIBAN">
                    {{ $t("Codice IBAN non valido") }}
                </small>
                <small v-else-if="$v.iban.$error && !$v.iban.prepaidIBAN">
                    {{ $t("IBAN carte prepagate e conti deposito non accettati") }}
                </small>
            </div>
            <small v-if="!prepaidCards" class="block mt-2 mb-1">{{ $t("(IBAN carte prepagate e conti deposito non accettati)") }}</small>
            <div class="text-xs">
                {{ $t("Non ricordi l’IBAN?") }}
                <a href="#" @click.prevent="showHelp = !showHelp" class="underline">{{ $t("Clicca qui") }}</a>
                {{ $t("per un aiuto") }}
            </div>
        </div>
        <SlideUpDown :active="showHelp" class="w-full">
            <div class="text-gray-700 border-t mt-5 pt-5 flex flex-wrap">
                <div class="w-full md:w-1/3">
                    <h3 class="font-semibold mb-4">{{ $t("Dove posso trovare il codice IBAN?") }}</h3>
                </div>
                <div class="w-full md:w-1/3">
                    <p class="text-sm">{{ $t("Puoi trovare il tuo codice IBAN:") }}</p>
                    <ul class="list-disc px-6 my-2 text-sm">
                        <li>{{ $t("all’interno del servizio home banking della tua banca") }}</li>
                        <li>{{ $t("sull’estratto conto fornito dalla tua banca") }}</li>
                        <li>{{ $t("su una tua recente busta paga") }}</li>
                    </ul>
                </div>
                <div class="w-full md:w-1/3">
                    <span class="block pb-4 text-sm">{{ $t("Ad esempio, un codice IBAN corretto è:") }}<br>{{ $t(" IT 12 A 12345 54321 123456789012") }}</span>
                </div>
            </div>
        </SlideUpDown>
        <div class="w-full flex flex-wrap mt-8 sm:mt-4">
            <div class="flex flex-wrap sm:flex-nowrap">
                <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                    <input type="radio" v-model="politics" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
                </div>
                <div class="relative sm:flex-shrink-0 mr-2 order-2 sm:order-1">
                    <input type="radio" v-model="politics" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
                </div>
                <div class="w-full sm:flex-grow order-1 sm:order-3 mb-2 text-sm sm:text-base">
                    {{ $t("Sono una persona politicamente esposta (PEP)") }}
                </div>
            </div>
            <div class="w-full mb-4 text-red">
                <div v-if="$v.politics.$error && !$v.politics.required">
                    {{ $t("Campo obbligatorio") }}
                </div>
                <div v-if="politics === true">
                    {{ $t("Per favore, verifica la definizione di Persona Politicamente Esposta") }}
                </div>
            </div>
            <div class="text-xs">
                <a href="#" class="underline" @click.prevent="showPEPExplanation = !showPEPExplanation">{{ $t("Clicca qui") }}</a>
                {{ $t("per leggere la definizione di PEP") }}
            </div>
            <SlideUpDown :active="showPEPExplanation" :class="{'mb-5': showPEPExplanation}">
                <div class="py-5 text-gray-600 border-t border-b mt-5">
                    {{ $t("Sono ”persone politicamente esposte” (PEP), le persone fisiche che occupano o hanno cessato di occupare da meno di un anno importanti cariche pubbliche, nonché i loro familiari e coloro che con i predetti soggetti intrattengono notoriamente stretti legami. A titolo esemplificativo (elenco completo indicato nel D.lgs. 231/2007 e successivi aggiornamenti): capi di Stato o di governo, ministri, vice-ministri o sottosegretari, parlamentari, Presidente di Regione, assessore regionale, Sindaco di capoluogo di provincia o città metropolitana, Sindaco di comune con popolazione non inferiore a 15.000 abitanti, nonché cariche analoghe in Stati esteri membri delle corti supreme, delle corti costituzionali o di altri organi giudiziari di alto livello, i membri delle Corti dei Conti e dei Consigli di Amministrazione delle Banche centrali, gli ambasciatori, gli incaricati d’affari e gli ufficiali di alto livello delle forze armate, i membri del Consiglio di Amministrazione, direzione o vigilanza delle imprese possedute dallo Stato, Regioni, Comuni, direttore generale di ATS (ex ASL) e di azienda ospedaliera, di azienda ospedaliera universitaria e degli altri enti del servizio sanitario nazionale.") }}
                </div>
            </SlideUpDown>
        </div>
        <div class="spacer w-full mt-4"></div>
    </FormCard>
</template>
<script>
import { mapUserData } from "@/utils";
import { ibanMask } from "@/masks";
import { validIBAN, notPrepaidIBAN } from "@/utils";
import { required } from "vuelidate/lib/validators";
import DayInMonth from "@/components/DayInMonth";
import FloatingLabelInput from "@/components/FloatingLabelInput";

export default {
    components: { FloatingLabelInput, DayInMonth },
    mounted() {
        this.$ga.pageview()
        // select default when only one option is provided
        if (this.debitDays.length == 1) {
            this.$store.state.userData.day_in_month = this.debitDays[0].value
        }
        if (this.$store.state.precompiled) {
            if (this.iban) this.disabled.iban = true
        }
    },
    data() {
        return {
            ibanMask,
            showHelp: false,
            showPEPExplanation: false,
            disabled: { iban: false }
        };
    },    
    computed: {
        debitDays() {
            return this.$store.state.debitDays
        },
        prepaidCards() {
            return this.$store.state.prepaidCards
        },
        ...mapUserData(["day_in_month", "iban", "politics"])
    },
    validations: {
        day_in_month: {
            required
        },
        iban: {
            required,
            validIBAN,
            notPrepaidIBAN
        },
        politics: {
            required
        }
    },
    watch: {
        politics() {
            this.$store.dispatch("event", { name: "PEPClick" })
        }
    },
    methods: {
        onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                this.$store.dispatch("event", { name: "PaymentDetailsSubmit" })
                this.$store.dispatch("sendData", { step: "dati_paga" });
                this.$store.dispatch("redirect", { step: "dati_paga", route: "PrivacyConsent" });
            }
        }
    }
};
</script>