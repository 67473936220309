<template>
<div>
    <FormBox title="Controlla i tuoi dati" submitText="Conferma" @submit="submit()" @decline="goBack()" >

        <ProgressPz slot="form-header" progress="25" />

        <div class="mx-auto text-gray-4 pb-6">
            <p class="font-light"><strong class="font-semibold">Perché conosciamo i tuoi dati?</strong>
                La risposta è <strong class="font-semibold">semplice</strong> e <strong class="font-semibold">trasparente</strong>:
                hai già una delle soluzioni di credito con Cofidis</p>
        </div>

        <PrecompiledBox v-show="!editPersonal" v-model="editPersonal">
                <PrecompiledField label="E-mail" :value="email" class="lg:w-1/2" />
                <PrecompiledField label="Cellulare" :value="cellphone" class="lg:w-1/2" />
        </PrecompiledBox>
        
        <div v-show="editPersonal" class="w-full border-b border-gray-2 px-1 pt-4 pb-6 mb-6 flex flex-wrap lg:px-8">
            <div class="w-full lg:w-1/2 sm:pr-4 lg:pl-2 mb-2">   
                <FloatingLabelInput :name="`email`" :type="`text`" v-model="email" :mask="{ placeholder: '', greedy: false, casing: 'lower' }" label="E-mail" :error="$v.email.$error"/>
                <div class="w-full text-red" v-if="$v.email.$error">
                    <small v-if="!$v.email.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                    <small v-else-if="!$v.email.email">
                        {{ $t("Formato e-mail non valido") }}
                    </small>
                </div>
            </div>
            <div class="w-full lg:w-1/2 mb-2">                                  
                <FloatingLabelInput :name="`cellphone`" :type="`tel`" v-model="cellphone" :mask="cellphoneMask" :label="`Cellulare`" :error="$v.cellphone.$error" />
                <div class="w-full text-red" v-if="$v.cellphone.$error">
                    <small v-if="!$v.cellphone.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                    <small v-else-if="$v.cellphone.$error && !$v.cellphone.phone">
                        {{ $t("Numero di telefono non valido") }}
                    </small>
                </div>
            </div>
        </div>

        <PrecompiledBox v-show="!editResidence" v-model="editResidence">
            <PrecompiledField label="Indirizzo di residenza" :value="address" class="sm:w-1/2"  />
            <PrecompiledField label="Comune di residenza" :value="address_city" class="sm:w-2/6"  />
            <PrecompiledField label="CAP" :value="`${address_cap}`" class="sm:w-1/6" />
            <div class="sm:mt-2">
                <p class="text-gray-3 text-sm font-semibold leading-none">Il domicilio coincide con la residenza?</p>
                <div class="uppercase flex mt-2 gap-1" @click="editResidence = true">
                    <div class="px-2 py-1 text-sm w-fit font-semibold leading-none rounded-sm" :class="{ 'bg-gray-2': same_addresses }">sì</div>
                    <div class="px-2 py-1 text-sm w-fit font-semibold leading-none rounded-sm" :class="{ 'text-gray-3': !same_addresses }">no</div>
                </div>
            </div>
            <template v-if="!same_addresses">
                <PrecompiledField label="Indirizzo di domicilio" :value="address2"  />
                <div class="flex justify-between">
                    <PrecompiledField label="Comune di domicilio" :value="address_city2"  />
                </div>
                <PrecompiledField label="CAP" :value="`${address_cap2}`"  />
            </template>
        </PrecompiledBox>

        <div v-show="editResidence" class="w-full border-b border-gray-2 px-1 pb-6 mb-6 pt-5">
            <div class="lg:px-8 flex flex-wrap">
                <div class="w-full sm:w-1/2 sm:pr-4 mb-2">
                    <FloatingLabelInput :name="`address`" v-model="address" :mask="{
                        mask: 'A{+}',
                        placeholder: '',
                        definitions: { A: { validator: '[A-Z0-9ÀÈÌÒÙÁÉÍÓÚ .\'°^/-]' } },
                        casing: 'upper'
                        }" :label="`Indirizzo di residenza`" :error="$v.address.$error" />
                    <div class="h-6 w-full text-red" v-if="$v.address.$error">
                        <small v-if="!$v.address.required">
                            {{ $t("Campo obbligatorio") }}
                        </small>
                    </div>
                </div>
                <div class="w-full sm:w-2/6 mb-2 sm:pr-4">
                    <div class="label-floating" :class="{ 'border-red': $v.address_city.$error,'border-gray-700': focusInput == 'address_city' }" >                
                        <Autocomplete :class="{ 'border-red': $v.address_city.$error,'border-gray-700': focusInput == 'address_city' }" :placeholder="$t('Comune residenza')" v-model="address_city" :search="citySearch" @focus="setFocusInput('address_city')" @blur="unsetFocusInput()">
                        </Autocomplete>
                        <label>{{ $t("Comune residenza") }}</label>
                    </div>
                    <div class="h-6 w-full text-red" v-if="$v.address_city.$error">
                        <small v-if="!$v.address_city.required">
                            {{ $t("Campo obbligatorio") }}
                        </small>
                    </div>
                </div>
                <div class="w-1/2 sm:w-1/6 mb-2">
                    <FloatingLabelSelect v-if="address_multicap.length" :name="`address_cap`" v-model="address_cap" :items="address_multicap" :label="`CAP`" :error="$v.address_cap.$error" :disableZeroValue="true"/>
                    <FloatingLabelInput v-else :name="`address_cap`" v-model="address_cap" :error="$v.address_cap.$error" :type="`tel`" :className="`placeholder-sm`" :label="`CAP`"/>
                    <div class="h-6 w-full text-red" v-if="$v.address_cap.$error">
                        <small v-if="!$v.address_cap.required">
                            {{ $t("Campo obbligatorio") }}
                        </small>
                    </div>
                </div>
            </div>

            
            <div class="w-full mb-2 lg:px-8 mt-4 sm:mt-0">
                <label class="text-xs">{{ $t("Il domicilio coincide con la residenza") }}</label>
                <div class="flex mt-1">
                    <div class="relative mr-4">
                        <input type="radio" name="Domicilio" v-model="same_addresses" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
                    </div>
                    <div class="relative">
                        <input type="radio" name="Domicilio" v-model="same_addresses" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
                    </div>
                </div>
                <div class="cont_fail fail_Domicilio"></div>
            </div>
            <SlideUpDown :active="!same_addresses">
                <div class="lg:px-8 pt-5 flex flex-wrap">
                    <div class="w-full sm:w-3/6 sm:pr-4 mb-2"> 
                        <FloatingLabelInput :name="`address2`" v-model="address2" :mask="{
                          mask: 'A{+}',
                          placeholder: '',
                          definitions: {
                            A: { validator: '[A-Z0-9ÀÈÌÒÙÁÉÍÓÚ .\'°^/-]' }
                          },
                          casing: 'upper'
                        }" :label="`Indirizzo di domicilio`" :error="$v.address2.$error" />
                        <div class="h-6 w-full text-red" v-if="$v.address2.$error">
                            <small v-if="!$v.address2.required">
                                {{ $t("Campo obbligatorio") }}
                            </small>
                        </div>
                    </div>
            
                    <div class="w-full sm:w-2/6 sm:pr-4 mb-2">
                        <div class="label-floating" :class="{ 'border-red': $v.address_city2.$error,'border-gray-700': focusInput == 'address_city2' }" >                
                            <Autocomplete :class="{ 'border-red': $v.address_city2.$error,'border-gray-700': focusInput == 'address_city2' }" :placeholder="$t('Comune domicilio')" v-model="address_city2" :search="citySearch" @focus="setFocusInput('address_city2')" @blur="unsetFocusInput()">
                            </Autocomplete>
                            <label>{{ $t("Comune domicilio") }}</label>
                        </div>
                        <div class="h-6 w-full text-red" v-if="$v.address_city2.$error">
                            <small v-if="!$v.address_city2.required">
                                {{ $t("Campo obbligatorio") }}
                            </small>
                        </div>
                    </div>
                    <div class="w-1/2 sm:w-1/6 mb-2">
                        <FloatingLabelSelect v-if="address_multicap2.length" :name="`address_cap2`" v-model="address_cap2" :items="address_multicap2" :label="`CAP`" :error="$v.address_cap2.$error" :disableZeroValue="true"/>
                        <FloatingLabelInput v-else :name="`address_cap2`" v-model="address_cap2" :label="`CAP`" :error="$v.address_cap2.$error" :type="`tel`" :disabled="true"/>
                        <div class="h-6 w-full text-red" v-if="$v.address_cap2.$error">
                            <small v-if="!$v.address_cap2.required">
                                {{ $t("Campo obbligatorio") }}
                            </small>
                        </div>
                    </div>
                </div>
            </SlideUpDown>
        </div>

        <PrecompiledBox v-if="!editIban && iban" v-model="editIban" :class="{'mt-2': editResidence}" >
            <PrecompiledField label="IBAN" :value="maskedIban"  />
        </PrecompiledBox>
        <div v-else class="w-full px-1 pb-6 mb-6 pt-3">
            <div class="w-full lg:w-1/2 lg:px-8">
                <FloatingLabelInput :name="`iban`" v-model="iban" :mask="ibanMask" :label="`IBAN del tuo conto corrente`" :error="$v.iban.$error"/>
                <div class="h-auto w-full text-red leading-4 sm:leading-5 mt-1 sm:mt-0">
                    <small v-if="$v.iban.$error && !$v.iban.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                    <small v-else-if="$v.iban.$error && !$v.iban.validIBAN">
                        {{ $t("Codice IBAN non valido") }}
                    </small>
                    <small v-else-if="$v.iban.$error && !$v.iban.prepaidIBAN">
                        {{ $t("Codice IBAN non valido") }}
                    </small>
                </div>
                <small v-if="!prepaidCards" class="block text-xs mt-2 mb-1">{{ $t("IBAN carte prepagate e conti deposito non accettati") }}</small>
            </div>
        </div>
        
    </FormBox>
</div>
</template>
<script>
import FormBox from "./FormBox"
import ProgressPz from "./ProgressPz";
import PrecompiledBox from "@/components/PrecompiledBox.vue";
import PrecompiledField from "@/components/PrecompiledField.vue";
import FloatingLabelInput from "@/components/FloatingLabelInput";
import FloatingLabelSelect from "@/components/FloatingLabelSelect";
import { mapUserData, validPhone, validIBAN, notPrepaidIBAN } from "@/utils";
import { required, requiredIf, email } from "vuelidate/lib/validators";
import { cellphoneMask, ibanMask } from "@/masks";

import $ from "jquery";
import Fuse from "fuse.js";

export default {
    components: { FormBox, ProgressPz, PrecompiledBox, PrecompiledField, FloatingLabelInput, FloatingLabelSelect },
    data() {
        return {
            editPersonal: false,
            editResidence: false,
            editIban: false,
            cellphoneMask,
            ibanMask,
            search: [],
            fuser: null
        }
    },
    computed: {
        ...mapUserData([
            "name",
            "surname",
            "iban",
            "email",
            "cellphone",
            "address",
            "address_number",
            "address_city",
            "address_cap",
            "address_multicap",
            "same_addresses",
            "address2",
            "address_number2",
            "address_city2",
            "address_cap2",
            "address_multicap2",
        ]),
        maskedIban() {
            return `${this.iban[0]}${this.iban[1]}****************${this.iban.slice(-4)}`
        },
        prepaidCards() {
            return this.$store.state.prepaidCards
        },
    },
    validations: {
        email: {
            required,
            email
        },
        cellphone: {
            required,
            validPhone
        },
        address: {
            required
        },
        address_cap: {
            required
        },
        address_city: {
            required
        },
        address2: {
            required: requiredIf(function() {
                return !this.same_addresses;
            })
        },
        address_cap2: {
            required: requiredIf(function() {
                return !this.same_addresses;
            })
        },
        address_city2: {
            required: requiredIf(function() {
                return !this.same_addresses;
            })
        },
        iban: {
            required,
            validIBAN,
            notPrepaidIBAN
        },
    },
    watch: {
        address_city(val) {
            const city = val.slice(0, -5)   
            this.address_cap = null
            this.address_multicap = []
            this.search.map(res => {
                if(res.item.Citta == city) {                    
                    if(res.item.Multicap) {
                        this.address_multicap.push({key: null, value: 'CAP'})
                        res.item.Multicap.split(',').map(cap => {
                            const value = '0'.repeat(5 - cap.toString().length) + cap.toString()
                            this.address_multicap.push({key: cap, value })
                        })
                    }
                    else {
                        this.address_cap = res.item.Cap
                    }
                }
            })
        },
        address_city2(val) {
            const city = val.slice(0, -5)   
            this.address_cap2 = null
            this.address_multicap2 = []                   
            this.search.map(res => {
                if(res.item.Citta == city) {                    
                    if(res.item.Multicap) {
                        this.address_multicap2.push({key: null, value: 'CAP'})
                        res.item.Multicap.split(',').map(cap => {
                            const value = '0'.repeat(5 - cap.toString().length) + cap.toString()
                            this.address_multicap2.push({key: cap, value })
                        })
                    }
                    else {
                        this.address_cap2 = res.item.Cap
                    }
                }
            })
        },
        same_addresses(val) {
            if (val === false) {
                this.$v.address2.$reset();
                this.$v.address_cap2.$reset();
                this.$v.address_city2.$reset();
            }
        },
        editIban(val) {
            if(val === true) {
                this.iban = null
                this.$v.iban.$reset();
            }
        }
    },
    methods: {
        submit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                this.$store.dispatch("sendData", { step: "dati_anag" });
                this.$store.dispatch("sendData", { step: "dati_pers_3" });
                this.$store.dispatch("sendData", { step: "dati_paga" });

                this.$store.dispatch("redirect", { step: "precompiled", route: "IncomePz" });
            }
        },
        citySearch(input) {
            if (input.length < 2) return [];
            this.search = this.fuser.search(input)
            return this.search.map(i => `${i.item.Citta} (${i.item.Prov})`);
        }
    },
    async mounted() {
        
        // show editable fields if some data is missing
        this.$v.$touch()
        if (this.$v.$invalid)
        {
            if (this.$v.email.$error || this.$v.cellphone.$error )
                this.editPersonal = true

            if (this.$v.address.$error || this.$v.address_cap.$error || this.$v.address_city.$error )
                this.editResidence = true
                
            if (this.$v.iban.$error)
                this.editIban = true
        }

        const city_feevo = await $.getJSON("https://cofidis-settings.s3.eu-central-1.amazonaws.com/city_feevo_2022.json")
        this.fuser = new Fuse(city_feevo, {
            keys: ["Citta"],
            threshold: 0.0,
            location: 0,
            distance: 0,
            maxPatternLength: 32,
            minMatchCharLength: 0,
            caseSensitive: false
        })  
    },
};
</script>