class Append {
    enter_landing(payload, {state}) {
        payload['CF'] = state.userData.fiscal_code
        payload['userId'] = state.userData.user_id
        payload['codeCampaign'] = state.userData.code_campaign
        payload['cod_camp_rev'] = state.userData.code_campaign_rev
        payload['pratica_id_rev'] = state.userData.prat_id_rev
        payload['stepNumber'] = 0
    }

    dati_anag(payload, {state}) {
        delete(payload['EMail'])
        delete(payload['CF'])
        delete(payload['Rate'])
        payload['aumento_fido'] = state.userData.aum_fido
        payload['fido_old_af'] = state.userData.fido_old
        payload['fido_new_af'] = state.userData.fido_new
        payload['rata_old_af'] = state.userData.rata_old
        payload['rata_new_af'] = state.userData.rata_new
        payload['tan_af'] = state.userData.tan
        payload['taeg_af'] = state.userData.taeg
        payload['dispo_old_af'] = state.userData.dispo_old
        payload['dispo_new_af'] = state.userData.dispo_new
        payload['dt_start_af'] = state.userData.dt_start
        payload['dt_end_af'] = state.userData.dt_end
        payload['data_scadenza_documento'] = state.userData.scadenza_documento
        payload['stepNumber'] = state.userData.step_number
    }

    dati_lav(payload, {state}) {
        delete(payload['Mensilita'])
        payload['EMail'] = state.userData.email
        payload['stepNumber'] = state.userData.step_number
        payload['service'] = +state.userData.service
        // convert yyyy to mm/yyyy if needed
        if(payload['Occupato_Dal']?.toString().length === 4)
            payload['Occupato_Dal'] = `01/${payload['Occupato_Dal']}`
    }

    dati_paga(payload) {
        delete(payload['Scadenza_Day'])
    }

    dati_pers_4(payload, {state}) {
        payload['stepNumber'] = state.userData.step_number
    }
}
export default new Append();