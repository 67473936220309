<template>
    <form class="metlife" action="next" method="POST">
        <div class="container text-center py-6 relative mx-auto">
            <i class="icon arrowleft absolute left-4 sm:left-0 top-1/2 transform -translate-y-1/2 cursor-pointer" @click="goBack()"></i>
            <Steps :count="steps" :active="steps - 1"></Steps>
        </div>

        <div class="form-wrapper">
            <MetlifeConsent />
        </div>
    </form>
</template>
<script>
import MetlifeConsent from "@/views/forms/MetlifeBestCareConsent";
import Steps from '@/components/Steps'
import { mapUserData } from "@/utils";
export default {
    components: { Steps, MetlifeConsent },
    mounted() {
        this.$store.dispatch("sendData", { step: "dati_metlife_2" });
    },
    computed: {
        ...mapUserData([
            "document_expired"
        ]),
        steps() {
            return this.document_expired ? 4 : 3
        }
    }
};
</script>