<template>
    <main>
        <Loader v-if="loading" />
        <div v-else>
            <!-- Header -->
            <header class="w-full relative" :style="bgHeader">
                <Logo />
            </header>
            <div class="w-full mx-auto text-center px-6 lg:px-0">
                <!-- Hero -->
                <div class="form bg-white relative rounded-3xl shadow-2xl mx-auto pb-8 mb-14 -mt-18 sm:-mt-40">
                    <form @submit.prevent>
                        <div class="px-4 pt-4 md:pt-0">
                            <p class="text-2xl font-semibold pt-4 sm:pt-6 px-4 sm:px-8">{{ name }},</p>
                            <p class="text-lg px-5 mt-1">per te nuova disponibilità! Richiedila in pochi click</p>
                            <div class="offer-wrapper mt-8 md:mt-5 border border-yellow rounded-t-2xl rounded-b-lg md:rounded-b-2xl overflow-hidden mx-auto">
                                <div class="text-center bg-yellow pt-1 pb-2 md:pb-1 px-2">
                                    <h1 class="font-bold text-5xl">{{ importo|num }}€</h1>
                                    <p class="font-bold lg:text-lg leading-tight mt-0.5">direttamente sul conto corrente</p>
                                </div>
                                <p class="px-3 pt-2 leading-tight text-sm xl:px-8">+ attivazione del Servizio CLUB a {{ monthlyClubPrice }}€ al mese con addebito annuale*</p>
                                <ClubToggle class="pt-7 pb-12">
                                    <p class="text-xs">L’acquisto del servizio CLUB è facoltativo e non è necessario per accedere all'aumento fido e al riutilizzo della linea di credito di Cofidis alle condizioni proposte, né per ottenerli a condizioni più vantaggiose. Puoi visionare qui il <a :href="rulesClubUrl" target="_blank" class="underline">Regolamento</a> e l’<a :href="privacyClubUrl" target="_blank" class="underline">Informativa Privacy</a>.</p>
                                    <p class="text-xs mt-2">* Il servizio è finanziato mediante apposito utilizzo della Linea di Credito Revolving concessa da Cofidis. Il Cliente autorizza Cofidis a procedere al pagamento per proprio conto del costo annuale del servizio anche in caso di rinnovo a Quattrolinee S.r.l per l’erogazione del Servizio.</p>
                                </ClubToggle>
                            </div>  
                        </div> 
                        <a @click="submit(true)" class="block cta first-cta text-lg rounded-full px-10 py-2 z-50 cursor-pointer bg-green-1 text-white">
                            <span class="block text-center text-xl font-semibold">RICHIEDI ORA</span>
                            <span class="block font-light text-base text-center -mt-1">Solo fino al <strong class="font-bold uppercase">{{ expDate }}</strong></span>
                        </a>
                    </form>
                    <div class="text-center mt-16 md:mt-10">
                        <a class="underline cursor-pointer" @click="submit(false)">
                            Procedi senza CLUB
                        </a>
                    </div>
                </div>
            </div>

            <Accordion title="Come funziona?" :tabs="[
                {
                    title: 'Continua ad utilizzare Power Credit per i tuoi acquisti', 
                    content: 'Continua a fare acquisti in modo semplice e veloce grazie alla disponibilità della tua Linea di Credito Ricaricabile. Invia la richiesta di aumento fido in pochi click e se approvata ricevi una nuova somma di denaro sul tuo conto corrente.'
                },
                {
                    title: 'I dettagli della tua offerta', 
                    content: `Con la tua richiesta di aumento fido, l’importo della tua Linea di Credito Ricaricabile passerà da ${ $options.filters.num(fido_old) } € a ${ $options.filters.num(fido_new) } €, 
                        con una nuova rata minima fissa di ${ $options.filters.num2(rata_new) }€ al mese. 
                        TAN FISSO ${ $options.filters.num2(tan) }% - TAEG max ${ $options.filters.num2(taeg) }%.
                        Richiesta soggetta ad approvazione da parte di Cofidis S.A..`
                }]" 
            />
            
        </div>
    </main>
</template>
<script>
// @ is an alias to /src
import Loader from "@/components/Loader"
import Logo from "@/components/LogoPowerCredit"
import Accordion from "@/components/Accordion"
import ClubToggle from "@/components/ClubToggle"
import { mapUserData } from "@/utils";
import axios from "axios";
import moment from "moment/moment";

const redirectHomepage = () => {    
    location.href = '/aumento-fido-club'
}

export default {
    name: "Home",
    components: { Loader, ClubToggle, Logo, Accordion },
    methods: {
        async loadClientData(data) {
            return new Promise((resolve, reject) => {
                axios.post(this.crmUrl, data)
                    .then(function(response) {
                        if(response.data && response.data.dt_end_afcl)
                            resolve(response.data)
                        else
                            reject(false)
                    })
                    .catch(function() {
                        reject(false)
                    })
            })
        },
        async submit(wantClub) {

            window.removeEventListener('popstate', redirectHomepage)
            this.$store.commit("setUserData", { key: 'step_number', value: 1 });

            if (wantClub) {
                this.$store.dispatch("sendData", { step: "club_consent" });
                this.$store.dispatch("redirect", { step: "club_consent", route: "Consents" });

            } else {
                this.$store.dispatch("sendData", { step: "club_warning" });
                this.$store.dispatch("redirect", { step: "club_warning", route: "ClubWarning" });
            }

        },
        reloadOnBrowserBack() {            
            window.popStateDetected = false
            window.addEventListener('popstate', redirectHomepage)
        },
    },
    computed: {
        ...mapUserData([
            "name",
            "fido_old",
            "fido_new",
            "rata_new",
            "taeg",
            "tan",
            "service_amount",
            "importo"
        ]),
        monthlyClubPrice() {
            return this.service_amount % 12 === 0 ? this.service_amount / 12 : parseFloat(this.service_amount / 12).toFixed(2);
        },
        bgHeader() {
            const env = process.env.VUE_APP_ENV == "production" ? 'prod' : 'stage'
            return {
                '--bg-header': `#fff url("${this.distributionUrl}/${env}/header.jpg") 55% 0% no-repeat`,
                '--bg-header-sm': `#fff url("${this.distributionUrl}/${env}/header-sm.jpg") center top no-repeat`,
            }
        },
        crmUrl() {
            return this.$store.state.crmUrl;
        }
    },
    data() {
        return {
            loading: true,
            expDate: null,
            distributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/aumento-fido-club",
            commonDistributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/common",
            rulesClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Regolamento_Servizio_CLUB.pdf',
            privacyClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Informativa_Servizio.pdf',
        };
    },
    async created() {
        this.reloadOnBrowserBack()

        // get params
        const urlParts = this.$store.state.urlParts
        let userId, codeCampaign, trackCode;

        if(urlParts[1] && urlParts[2]) {
            userId = urlParts[1]
            codeCampaign = urlParts[2]
            trackCode = urlParts[3] ?? null
        }
        else {
            userId = localStorage.getItem('aumento_fido_cl_user_id')
            codeCampaign = localStorage.getItem('aumento_fido_cl_code_campaign')
            trackCode = localStorage.getItem('aumento_fido_cl_track_code') ?? null
            if(trackCode === "null") trackCode = null;
        }

        try {
            localStorage.setItem('aumento_fido_cl_user_id', userId)
            localStorage.setItem('aumento_fido_cl_code_campaign', codeCampaign)
            if(trackCode) localStorage.setItem('aumento_fido_cl_track_code', trackCode)

            const data = await this.loadClientData({userId, codeCampaign, trackCode})

            this.$store.commit("setUserData", { key: 'user_id', value: userId });
            this.$store.commit("setUserData", { key: 'code_campaign', value: codeCampaign });
            this.$store.commit("setUserData", { key: 'code_campaign_rev', value: data.cod_camp_rev });
            this.$store.commit("setUserData", { key: 'prat_id_rev', value: data.pratica_id_rev });

            this.$store.commit("setUserData", { key: 'aum_fido', value: data.aumento_fido });
            this.$store.commit("setUserData", { key: 'fido_old', value: data.fido_old_af });
            this.$store.commit("setUserData", { key: 'fido_new', value: data.fido_new_af });
            this.$store.commit("setUserData", { key: 'rata_old', value: data.rata_old_af });
            this.$store.commit("setUserData", { key: 'rata_new', value: data.rata_new_af });
            this.$store.commit("setUserData", { key: 'tan', value: data.tan_af });
            this.$store.commit("setUserData", { key: 'taeg', value: data.taeg_af });
            this.$store.commit("setUserData", { key: 'dispo_old', value: data.dispo_old_af });
            this.$store.commit("setUserData", { key: 'dispo_new', value: data.dispo_new_af });
            this.$store.commit("setUserData", { key: 'dt_start', value: moment(data.dt_start_afcl).locale("it").format('DD-MM-YYYY') });
            this.$store.commit("setUserData", { key: 'dt_end', value: moment(data.dt_end_afcl).locale("it").format('DD-MM-YYYY') });
            this.$store.commit("setUserData", { key: 'document_expire', value: moment(data.data_scadenza_documento).locale("it").format('DD-MM-YYYY') });
            this.$store.commit("setUserData", { key: 'name', value: data.Nome });
            this.$store.commit("setUserData", { key: 'surname', value: data.Cognome });
            this.$store.commit("setUserData", { key: 'email', value: data.EMail });
            this.$store.commit("setUserData", { key: 'iban', value: data.Iban });
            this.$store.commit("setUserData", { key: 'fiscal_code', value: data.CF });
            this.$store.commit("setUserData", { key: 'cellphone', value: data.Phone });
            this.$store.commit("setUserData", { key: 'day_in_month', value: 1 });
            this.$store.commit("setUserData", { key: 'service_available', value: 1 });
            this.$store.commit("setUserData", { key: 'service', value: 0 });
            this.$store.commit("setUserData", { key: 'service_pack', value: data.service_pack });
            this.$store.commit("setUserData", { key: 'service_amount', value: data.im_pacchetto });
            this.$store.commit("setUserData", { key: 'step_number', value: 0 });
            this.$store.commit("setUserData", { key: 'importo', value: data.importo_3_af - data.im_pacchetto });

            // set expiry date
            this.expDate = moment(data.dt_end_afcl).locale("it").format('D MMMM')
            this.$store.commit("setUserData", { key: 'exp_date', value: this.expDate });

            // offer expired
            if(moment(data.dt_end_afcl).endOf('day') < moment()) {
                this.$store.dispatch("redirect", {step: "income", route: "RequestExpired"});
                return;
            }
            // pending procedure
            if(data.page_block && data.page_block === 'evaluation') {
                this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
                return;
            }
            // failed procedure
            if(data.page_block && data.page_block === 'reddito_not_allowed') {
                this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
                return;
            }

            // set expired document
            this.$store.commit("setUserData", { 
                key: 'document_expired', 
                value: moment(data.data_scadenza_documento) < moment() 
            });

            // init robot
            this.$store.dispatch("sendData", { step: "enter_landing" });

        } catch (error) {
            // reload from thank you page
            if(localStorage.getItem('aumento_fido_cl_request_evaluaton')) {
                this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
            }
            else {
                this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
            }
        }
        this.loading = false;
    }
};
</script>