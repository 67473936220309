<template>
    <form action="next" method="POST">
        <div class="bg-yellow text-center py-6">
            <h3 class="font-semibold text-xl text-gray-900">Stai richiedendo</h3>
            <h2 class="text-6xl font-bold text-gray-900 leading-tight mx-auto">
                <span v-if="choosenCredit > 0">{{ choosenCredit|num }}€</span>
                <span v-else>Solo attivazione</span>
            </h2>
            <h3 class="font-semibold text-xl text-gray-900">Con attivazione rapida e gratuita</h3>
        </div>
        <div class="container text-center py-6 relative mx-auto">
            <i class="icon arrowleft absolute left-0 top-1/2 transform -translate-y-1/2 cursor-pointer" @click="goBack()"></i>
            <Steps :count="steps" :active="steps"></Steps>
        </div>

        <div class="form-wrapper">
            <PrivacyConsent :alert-data-consent="false"/>
        </div>
    </form>
</template>
<script>
import PrivacyConsent from "@/views/forms/PrivacyConsent";
import Steps from '@/components/Steps'

export default {
    components: { Steps, PrivacyConsent },
    computed: {
        steps() {
            return this.$store.state.userData.document_expired ? 3 : 2
        },
        choosenCredit() {
            return this.$store.state.choosenCredit;
        },
        incomeCheck(){
            return this.$store.state.incomeCheck;
        }
    }
};
</script>