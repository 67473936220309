<template>
    <ul class="flex justify-center py-5 stars">
        <li v-for="(item, index) in starsArray" :key="index" :class="item" ref="stars" class="star transition-opacity opacity-0 duration-300"></li>
    </ul>
</template>
<script>
export default {
    name: 'Stars',
    props: {
        count: {
            type: Number,
            default: 5
        },
        vote: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            starsArray: []
        }
    },
    created() {
        for(let i = 0; i < this.count; i++) {
            this.starsArray.push(i < this.vote ? 'on' : 'off')
        }
    },
    mounted() {
        // stagger animation
        this.$refs.stars.forEach((star, index) => {
            setTimeout(() => {
                star.classList.remove('opacity-0')
            }, index * 100)
        })
    }
}
</script>