<template>
    <form class="club" @submit.prevent>
        <div class="container text-center py-6 relative mx-auto">
            <!-- <i class="icon arrowleft absolute left-4 sm:left-0 top-1/2 transform -translate-y-1/2 cursor-pointer" @click="lastStep()"></i> -->
            <Steps :count="steps" :active="steps - 1"></Steps>
        </div>

        <div class="form-wrapper">
            <Promo>
                <template #subtitle>
                    <p class="text-base text-center sm:text-left mt-3">Prosegui con una rata più leggera o conferma la scelta precedente</p>
                </template>
            </Promo>
        </div>
    </form>
</template>
<script>
import Promo from "@/views/forms/Promo";
import Steps from '@/components/Steps'
import { mapUserData } from "@/utils";
export default {
    components: { Steps, Promo },
    computed: {
        ...mapUserData(["document_expired"]),
        steps() {
            return this.document_expired ? 4 : 3
        }
    },
    methods: {
        lastStep() {
            if (this.service)
                this.$store.dispatch('redirect', {step: 'back', route: 'ClubFastCredit'})
            else
                this.$store.dispatch('redirect', {step: 'back', route: 'ClubFastCreditWarning'})
        }
    },
    mounted() {
        this.$store.dispatch("sendData", { step: "promo_offer", additionalData: {
            init: true,
            codeCampaign: localStorage.getItem('fastcredit_code_campaign'), 
            userId: localStorage.getItem('fastcredit_user_id') 
        }});
    },

};
</script>