<template>
    <div class="mx-auto lg:container text-sm sm:text-base leading-tight text-gray-900 py-4">
        <div class="px-6 py-6 text-center text-2xl sm:text-3xl font-semibold">
            <i class="icon smile block mx-auto mb-4"></i>
            Complimenti, la tua richiesta è stata presa in carico.
        </div>
        <h1 class="text-xl sm:text-2xl font-semibold text-center sm:mt-4 mb-6 sm:mb-8 uppercase bg-orange-1 py-2">Cosa succede adesso?</h1>
        <div class="max-w-4xl text-lg mx-auto py-4 px-6">            
            <div class="flex mb-6 items-center">
                <div class="w-1/5 text-center">
                    <i class="icon email block mx-auto"></i>
                </div>
                <p class="w-4/5 pl-4 sm:pl-0 text-sm">
                    Ricevi via e-mail l'esito della tua richiesta.
                </p>
            </div>
            <div class="flex mb-6 items-center">
                <div class="w-1/5 text-center">
                    <i class="icon twentyfourh block mx-auto"></i>
                </div>
                <p class="w-4/5 pl-4 sm:pl-0 text-sm">
                    Entro 24 ore vedrai l’importo accreditato sul tuo conto corrente
                </p>
            </div>

            <!-- <div class="text-center mt-10 text-base">
                <strong>Appena firmerai la richiesta di finanziamento, riceverai sbito credito sul tuo conto corrente.</strong>
            </div> -->
        </div>
    </div>
</template>
<script>
import { mapUserData } from "@/utils";

export default {    
    mounted() {
        localStorage.setItem('aumento_fido_request_evaluaton', 1)
    },
    computed: {
        ...mapUserData(["name","importo"])
    }
};
</script>