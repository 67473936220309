<template>
    <div class=" md:pt-2 pb-8 md:pb-4">
        <div class="progress-bar" :class="{'complete' : percentage === maxPercentage}">
            <Progress :percentage="percentage" ></Progress>
        </div>
        <h1 class="text-2xl md:text-3xl font-semibold text-center my-8 lg:my-11">Solo un'ultima conferma</h1>
        <form class="relative text-sm container mx-auto pl-5 pr-6 md:px-12 pb-8 text-gray-700 leading-6">
            <p class="text-base mb-4">
                Prima di procedere ti invitiamo a prendere visione del <a :href="rulesClubUrl" target="_blank" class="underline">Regolamento</a>
                che riporta nel dettaglio, le caratteristiche del prodotto, nonché l’<a :href="privacyClubUrl" target="_blank" class="underline">Informativa Privacy</a>. Il servizio CLUB è facoltativo e non è necessario per ottenere il riutilizzo della linea di credito, né per ottenerlo a condizioni più vantaggiose.
            </p>
            <ConsentItemCheck v-model="consent_club1" :v="$v.consent_club1" :mustBeTrueError="mustBeTrueError">
                <span class="leading-6">
                    Ho letto e compreso le condizioni del Servizio CLUB contenute nel Regolamento contrattuale, accettando integralmente le sue condizioni senza riserva alcuna, compresa l’autorizzazione a Cofidis a procedere al pagamento per mio conto del costo annuale del servizio anche in caso di rinnovo a Quattrolinee S.r.l. con sede in corso Vittorio Emanuele II 25, Torino, e dichiaro di essere interessato a sottoscrivere la proposta del servizio.
                </span>
            </ConsentItemCheck>
            <ConsentItemCheck v-model="consent_club2" :v="$v.consent_club2" :mustBeTrueError="mustBeTrueError">
                <span class="leading-6">
                    Ai sensi degli articoli 1341 e 1342 del Codice Civile dichiaro di avere attentamente esaminato e di accettare integralmente le clausole del Contratto riportate nel Regolamento contrattuale, di seguito elecante: <i>Art. 4 Obblighi delle Parti, Art. 5 Gestione Dati, Art. 6 Corrispettivo e modalità di pagamento, Art. 7 Responsabilità, Art. 8 Recesso, Art. 9 Risoluzione, Art. 10 Foro competente.</i>
                </span>
            </ConsentItemCheck>
            <ConsentItemCheck v-model="consent_club3" :v="$v.consent_club3" :mustBeTrueError="mustBeTrueError">
                <span class="leading-6">
                    Dichiaro di aver ricevuto e aver ben compreso l’<a :href="privacyClubUrl" target="_blank" class="underline">Informativa</a> fornita ai sensi dell’art. 13 del Regolamento UE 2016/679, dalla quale risulta che, nell’ambito dell’esecuzione delle operazioni e del servizio richiesto, si intendono perseguire le finalità di cui al punto 2.1. “Finalità Necessarie”. In particolare, dichiaro di aver ben compreso dalla predetta Informativa che è previsto il trattamento, da parte di Quattrolinee S.r.l., nella sua qualità di Titolare, dei miei dati personali.                
                </span>
            </ConsentItemCheck>
            <ClubOptionalConsents />
        </form>
        <a @click="submit()" class="cta text-lg rounded-full px-12 py-3 z-50 cursor-pointer block fixed md:relative left-1/2 md:left-0 transform -translate-x-1/2 md:translate-x-0 bottom-12 md:bottom-0">
            <span class="text-center text-xl font-semibold">CONFERMA</span>
        </a>
    </div>
</template>

<script>
import Progress from "@/components/Progress"
import ConsentItemCheck from "@/components/RequiredItemCheck.vue"
import { required } from "vuelidate/lib/validators"
import { mapUserData } from "@/utils";
import ClubOptionalConsents from "@/components/ClubOptionalConsents.vue";

const mustBeTrue = value => value === true;

const updateProgress = (val, number, increment) => {
    return val ? number + increment : number - increment;
}

export default {
    name: "Consents",
    components: { Progress, ConsentItemCheck, ClubOptionalConsents },
    data() {
       return {
            percentage: 87,
            increment: 4,
            maxPercentage: 99,
            rulesClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Regolamento_Servizio_CLUB.pdf',
            privacyClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Informativa_Servizio.pdf',
            mustBeTrueError: 'ATTENZIONE: non sarà possibile procedere all\'adesione al servizio'
       } 
    },
    computed: {
        ...mapUserData([
            "consent_club1",
            "consent_club2",
            "consent_club3",
        ]),
    },
    validations: {
        consent_club1: {
            required,
            mustBeTrue
        },
        consent_club2: {
            required,
            mustBeTrue
        },
        consent_club3: {
            required,
            mustBeTrue
        },
    },
    watch: {
        consent_club1(val) {
            this.percentage = updateProgress(val, this.percentage, this.increment);
        },
        consent_club2(val) {
            this.percentage = updateProgress(val, this.percentage, this.increment);
        },
        consent_club3(val) {
            this.percentage = updateProgress(val, this.percentage, this.increment);
        }
    },
    methods: {
        async submit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                this.$store.commit("setUserData", { key: 'service', value: 1 });
                let importo = this.$store.state.userData.max_amount - this.$store.state.userData.service_amount
                this.$store.commit("setChoosenCredit", importo);
                // send data
                this.$store.dispatch('subscribeClub');
                this.$store.dispatch("sendData", { step: "dati_privacy" });
                this.$store.dispatch("redirect", { step: "homepage", route: "ThankYou" });
            }
        }
    }
}
</script>