<template>
    <main>
        <Loader v-if="loading" />
        <div v-else>
            <!-- Header -->
            <header class="w-full relative">
                <LogoPowerCredit />
            </header>
            <div class="w-full mx-auto text-center px-6 lg:px-0">
                <!-- Hero -->
                <div class="form bg-white relative rounded-3xl shadow-2xl mx-auto pb-8 mb-14 -mt-18 sm:-mt-40">
                    <form @submit.prevent>
                        <div class="px-4 pt-4 md:pt-0">
                            <p class="text-xl font-semibold pt-4 sm:pt-6 px-4 sm:px-8">{{ name }},</p>
                            <p class="text-lg px-5 mt-1">continua a utilizzare Power Credit, la tua Linea di Credito Ricaricabile</p>
                            <p class="font-semibold text-lg md:-mt-1">da usare come e quando vuoi</p>
                            <div class="offer-wrapper mt-8 md:mt-5 border border-yellow rounded-t-2xl rounded-b-lg md:rounded-b-2xl overflow-hidden mx-auto">
                                <div class="text-center bg-yellow pt-1 pb-2 md:pb-1 px-2">
                                    <h1 class="font-bold text-4xl">{{ importo|num }}€</h1>
                                    <p class="font-bold lg:text-lg leading-tight mt-0.5">direttamente sul conto corrente</p>
                                </div>
                                <p class="px-3 pt-2 leading-tight text-sm xl:px-8">+ attivazione del Servizio CLUB a {{ monthlyClubPrice }} euro al mese con addebito annuale*</p>
                                <ClubToggle class="pt-7 pb-12">
                                    <p class="text-xs">L’acquisto del servizio CLUB è facoltativo e non è necessario per ottenere il riutilizzo della linea di credito, né per ottenerlo a condizioni più vantaggiose. Puoi visionare qui il <a :href="rulesClubUrl" target="_blank" class="underline">Regolamento</a> e l’<a :href="privacyClubUrl" target="_blank" class="underline">Informativa Privacy</a>.</p>
                                    <p class="text-xs mt-2">* Il servizio è finanziato mediante apposito utilizzo della Linea di Credito Revolving concessa da Cofidis. Il Cliente autorizza Cofidis a procedere al pagamento per proprio conto del costo annuale del servizio anche in caso di rinnovo a Quattrolinee S.r.l per l’erogazione del Servizio.</p>
                                </ClubToggle>
                            </div>  
                        </div> 
                        <a @click="submit(true)" class="block cta first-cta text-lg rounded-full px-12 py-3 z-50 cursor-pointer">
                            <span class="text-center text-xl font-semibold">CONTINUA</span>
                        </a>
                    </form>
                    <div class="text-center mt-16 md:mt-10">
                        <a class="underline cursor-pointer" @click="submit(false)">
                            Procedi al riutilizzo senza CLUB
                        </a>
                    </div>
                </div>
            </div>

            <!-- Section faq -->
            <Accordion id="anchor" title="Come funziona?" :tabs="[
                {
                    title: 'Riutilizza Power Credit per i tuoi acquisti', 
                    content: `Continua a fare acquisti in modo semplice e veloce grazie alla disponibilità della 
                            tua Linea di Credito Ricaricabile. Invia la richiesta in pochi click e ricevi una nuova 
                            somma di denaro sul tuo conto corrente.`
                },
                {
                    title: 'I dettagli della tua offerta', 
                    content: `Hai utilizzato ${ $options.filters.num(owedAmount) }€ della tua Linea di Credito Ricaricabile.
                            Con questa richiesta avrai nuova disponibilità. Per le condizioni economiche
                            e contrattuali si rimanda alla documentazione in tuo possesso.`
                }]" 
            />

            <div class="text-center py-6">
                <h3 class="text-3xl font-semibold text-gray-900 leading-tight mx-auto">Controlla i tuoi dati</h3>
            </div>

            <div class="bg-light pb-8">
                    <div class="px-6 max-w-4xl xl:max-w-6xl mx-auto flex flex-wrap justify-center">
                      <div class="w-full flex justify-center">
                        <span class="block md:w-1/6"></span>
                        <label class="w-1/4 text-lg text-gray-900 font-medium pr-5">Cognome</label>
                        <span class="w-3/4 md:w-1/4 text-lg text-gray-700 font-semibold uppercase pl-5">{{ surname }}</span>
                      </div>
                      <div class="w-full flex justify-center">
                        <span class="block md:w-1/6"></span>
                        <label class="w-1/4 text-lg text-gray-900 font-medium pr-5 mt-5">Nome</label>
                        <span class="w-3/4 md:w-1/4 text-lg text-gray-700 font-semibold uppercase pl-5 mt-5">{{ name }}</span>
                      </div>
                      <div class="w-full flex justify-center">
                        <span class="block md:w-1/6"></span>
                        <label class="w-1/4 text-lg text-gray-900 font-medium pr-5 mt-5">IBAN</label>
                        <span class="w-3/4 md:w-1/4 text-lg text-gray-700 font-semibold uppercase pl-5 mt-5">{{ maskedIban }}</span>
                      </div>
                    </div>
                </div>

            <div id="email-container" class="bg-yellow text-center p-6">
                <p class="text-center font-semibold text-xl text-gray-900 leading-none">Verifica che la tua e-mail sia corretta oppure inseriscila.</p>
                <div class="sm:w-96 text-center mx-auto mt-4">
                    <div class="relative">
                        <input type="email" id="email" name="email" class="w-full py-2 pl-4 pr-10 text-lg" v-model.trim="email" v-mask="{ placeholder: '', greedy: false, casing: 'lower' }"
                            placeholder="Se non appare la tua email, inseriscila" :class="{ 'border border-red': $v.email.$error }" @blur="$v.email.$touch">
                        <label for="email"><i class="icon pencil absolute top-3 right-2 cursor-pointer"></i></label>
                    </div>
                    <div class="w-full text-red text-left" v-if="$v.email.$error">
                        <small v-if="!$v.email.required">
                            Campo obbligatorio
                        </small>
                        <small v-else-if="!$v.email.email">
                            Formato e-mail non valido
                        </small>
                    </div>
                </div>
            </div>

            <div class="p-6">
                <div class="max-w-4xl xl:max-w-6xl mx-auto flex flex-wrap text-base">
                    <span><strong class="font-bold">Perché conosciamo i tuoi dati?</strong>
                        La risposta è <strong class="font-bold">semplice</strong> e <strong class="font-bold">trasparente</strong>:
                        hai già una delle soluzioni di credito con Cofidis</span>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
// @ is an alias to /src
import Loader from "@/components/Loader"
import LogoPowerCredit from "@/components/LogoPowerCredit"
import Accordion from "@/components/Accordion"
import { mapUserData } from "@/utils";
import axios from "axios";
import moment from "moment/moment";
import { required, email } from "vuelidate/lib/validators";
import ClubToggle from "@/components/ClubToggle.vue";

const redirectHomepage = () => {    
    location.href = '/riutilizzi-club'
}

export default {
    name: "Home",
    components: { Loader, ClubToggle, LogoPowerCredit, Accordion },
    data() {
        return {
            commonDistributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/common",
            disponibilita: null,
            expDate: null,
            loading: true,
            owedAmount: null,
            rulesClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Regolamento_Servizio_CLUB.pdf',
            privacyClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Informativa_Servizio.pdf',
        };
    },
    methods: {
        async loadClientData(data) {
            return new Promise((resolve, reject) => {
                axios.post(this.crmUrl, data)
                    .then(function(response) {
                        if(response.data && response.data.dt_end_riucl)
                            resolve(response.data)
                        else
                            reject(false)
                    })
                    .catch(function() {
                        reject(false)
                    })
            })
        },
        scrollToEmail() {
            window.scrollTo({
                top: document.getElementById('email-container').offsetTop,
                left: 0,
                behavior: "smooth",
            });
        },
        submit(wantClub) {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                window.removeEventListener('popstate', redirectHomepage)
                this.$store.commit("setUserData", { key: "step_number", value: 1 });
                if (wantClub) {
                    this.$store.dispatch("sendData", { step: "club_consent" });
                    this.$store.dispatch("redirect", { step: "homepage", route: "Consents" });
                } else {
                    this.$store.dispatch("sendData", { step: "club_warning", additionalData: {
                        init: true,
                        codeCampaign: localStorage.getItem('riutilizzi_cl_code_campaign'), 
                        userId: localStorage.getItem('riutilizzi_cl_code_campaign') 
                    }});
                    this.$store.dispatch("redirect", { step: "homepage", route: "ClubRiuWarning" });
                }
            }else{
                if(this.$v.email.$error){
                    this.scrollToEmail()
                }
            }
        },
        reloadOnBrowserBack() {            
            window.popStateDetected = false
            window.addEventListener('popstate', redirectHomepage)
        }
    },
    computed: {
        ...mapUserData([
            "name",
            "surname",
            "iban",
            "email",
            "max_amount",
            "service_amount",
            "importo"
        ]),
        monthlyClubPrice() {
            return this.service_amount % 12 === 0 ? this.service_amount / 12 : parseFloat(this.service_amount / 12).toFixed(2);
        },
        maskedIban() {
            return `${this.iban[0]}${this.iban[1]}****************${this.iban.slice(-4)}`
        },
        crmUrl() {
            return this.$store.state.crmUrl;
        }
    },
    async created() {
        this.reloadOnBrowserBack()

        // get params
        const urlParts = this.$store.state.urlParts
        let userId, codeCampaign, trackCode;

        if(urlParts[1] && urlParts[2]) {
            userId = urlParts[1]
            codeCampaign = urlParts[2]
            trackCode = urlParts[3] ?? null
        }
        else {
            userId = localStorage.getItem('riutilizzi_cl_user_id')
            codeCampaign = localStorage.getItem('riutilizzi_cl_code_campaign')
            trackCode = localStorage.getItem('riutilizzi_cl_track_code') ?? null
            // fix localstorage cast type issue
            if(trackCode === "null") trackCode = null;
        }

        // redirect to pending modal if already compiled
        if(localStorage.getItem('riu_cl_request_evaluation')) {
            this.loading = false;
            this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
            return;
        }

        try {

            localStorage.setItem('riutilizzi_cl_user_id', userId)
            localStorage.setItem('riutilizzi_cl_code_campaign', codeCampaign)
            if(trackCode) localStorage.setItem('riutilizzi_cl_track_code', trackCode)

            const data = await this.loadClientData({userId, codeCampaign, trackCode});
            this.$store.commit("setUserData", { key: 'user_id', value: userId });
            this.$store.commit("setUserData", { key: 'client_id', value: data.cliente_id });
            this.$store.commit("setUserData", { key: 'code_campaign', value: codeCampaign });
            this.$store.commit("setUserData", { key: 'name', value: data.Nome });
            this.$store.commit("setUserData", { key: 'surname', value: data.Cognome });
            this.$store.commit("setUserData", { key: 'email', value: data.EMail });
            this.$store.commit("setUserData", { key: 'iban', value: data.Iban });
            this.$store.commit("setUserData", { key: 'fiscal_code', value: data.CF });
            this.$store.commit("setUserData", { key: 'cellphone', value: data.Phone });
            this.$store.commit("setUserData", { key: 'day_in_month', value: 1 });
            this.$store.commit("setUserData", { key: 'owed_amount', value: data.importo_tot_dovuto_riu});
            this.$store.commit("setUserData", { key: 'max_amount', value: data.disponibilita_riu});
            this.$store.commit("setUserData", { key: 'cod_camp_rev', value: data.cod_camp_rev});
            this.$store.commit("setUserData", { key: 'dt_start_riucl', value: moment(data.dt_start_riu).locale("it").format('DD-MM-YYYY')});
            this.$store.commit("setUserData", { key: 'dossier_id', value: data.pratica_id_rev});
            this.$store.commit("setUserData", { key: 'output_date', value: moment(new Date()).locale("it").format('DD-MM-YYYY')});
            this.$store.commit("setUserData", { key: 'importo', value: data.disponibilita_riu - data.im_pacchetto });
            this.$store.commit("setChoosenCredit", data.disponibilita_riu - data.im_pacchetto )
            this.$store.commit("setUserData", { key: 'service', value: 0 });
            this.$store.commit("setUserData", { key: 'service_pack', value: data.service_pack });
            this.$store.commit("setUserData", { key: 'service_amount', value: data.im_pacchetto ?? 120 });
            this.$store.commit("setUserData", { key: 'step_number', value: 0 });

            // set expiry date
            this.expDate = moment(data.dt_end_riucl).locale("it").format('D MMMM');
            this.$store.commit("setUserData", { key: 'exp_date', value: this.expDate });

            // offer expired
            if(moment(data.dt_end_riucl).endOf('day') < moment()) {
                this.$store.dispatch("redirect", {step: "income", route: "RequestExpired"});
                return;
            }
            // pending procedure
            if(data.page_block && data.page_block === 'evaluation') {
                this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
                return;
            }

            // init robot
            this.$store.dispatch("sendData", { step: "enter_landing" });

            this.owedAmount = data.importo_tot_dovuto_riu;
            this.disponibilita = data.disponibilita_riu;
            this.name = data.Nome;

        } catch (error) {
            this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
        }
        this.loading = false
    },
    validations: {
        email: {
            required,
            email,
        },
    },
};
</script>