<template>
    <section class="pb-8 md:pb-5 pt-5">
        <Steps :count="step_number" :active="step_number" />
        <div class="w-11/12 sm:max-w-7xl mx-auto shadow-lg rounded-3xl bg-white mt-5 mb-10 pb-10">
            <PrivacyConsent :alert-data-consent="false"/>
        </div>
    </section>
</template>
<script>
import PrivacyConsent from "@/views/forms/PrivacyConsent";
import Steps from '@/components/Steps'
import { mapUserData } from "@/utils"

export default {
    components: { Steps, PrivacyConsent },
    data() {
        return {
            step_number: 3
        };
    },
    computed: {
        ...mapUserData([
            "service",
            "document_expired",
            "can_request"
        ]),
    },
    created() {
        if ( this.document_expired || !this.can_request ) 
            this.step_number = 4
    }
};
</script>