<template>
    <FormBox title="CLUB PLUS" @submit="onSubmit()" @decline="goBack()" >
    
        <ProgressPz slot="form-header" progress="80" />

        <p class="mb-4 text-base">
                Prima di procedere, ti invitiamo a prendere visione del <a :href="rulesClubUrl" target="_blank" class="underline">Regolamento</a>
                che riporta nel dettaglio le caratteristiche del prodotto, nonché
                l’<a :href="privacyClubUrl" target="_blank" class="underline">Informativa Privacy</a>.
                Il servizio CLUB PLUS è facoltativo e non è necessario per ottenere il prestito o per ottenerlo a condizioni più vantaggiose.
            </p>
            <ConsentItemCheck v-model="consent_club1" :v="$v.consent_club1" :mustBeTrueError="mustBeTrueError">
                <span class="text-sm">Ho letto e compreso le condizioni del contratto CLUB PLUS contenute nel
                <a :href="rulesClubUrl" target="_blank" class="underline">Regolamento contrattuale</a>,
                accettando integralmente le sue condizioni, e dichiaro di essere interessato a sottoscrivere la proposta del servizio; comprendo che
                CLUB PLUS è facoltativo e non collegato al finanziamento.</span>
            </ConsentItemCheck>
            <ConsentItemCheck v-model="consent_club2" :v="$v.consent_club2" :mustBeTrueError="mustBeTrueError">
                <span class="text-sm">Autorizzo:<br>
                Quattrolinee s.r.l. , con il codice identificativo Cofidis Club (Creditor information DE56AQJ00002197951) con sede in corso Vittorio Emanuele II 25, Torino, 10125, Italia, 
                a richiedere tramite mandato SEPA Direct Debit (SDD), alla banca del sottoscritto l’addebito del proprio conto corrente fornito in occasione della richiesta di prestito; II) 
                la banca del sottoscritto a procedere all’addebito ricorrente, secondo le disposizioni impartite da Quattrolinee s.r.l. 
                Sono consapevole di aver diritto ad ottenere il rimborso dell’addebito in conto, secondo gli accordi ed alle condizioni che regolano il rapporto con la mia banca, 
                richiedendolo entro 8 settimane a partire dalla data di addebito. In caso di modifica della data di addebito, frequenza o importo, ti verrà inviata una notifica almeno 
                3 giorni prima del successivo addebito.<br>
                    I pagamenti sono gestiti da GoCardless (vedi <a href="https://gocardless.com/privacy/payers" target="_blank">privacy notice</a> GoCardless).</span>
            </ConsentItemCheck>
            <ConsentItemCheck v-model="consent_club3" :v="$v.consent_club3" :mustBeTrueError="mustBeTrueError">
                <span class="text-sm">Ai sensi degli articoli 1341 e 1342 del Codice Civile il  dichiaro di avere attentamente esaminato e di accettare integralmente le clausole del Regolamento Contrattuale,
                    di seguito elencate: Art.4 Obblighi delle Parti, Art.5 Gestione Dati, Art.7 Responsabilità, Art.8 Recesso, Art.9 Risoluzione, Art.10 Durata.</span>
            </ConsentItemCheck>
            <ConsentItemCheck v-model="consent_club4" :v="$v.consent_club4" :mustBeTrueError="mustBeTrueError">
                <span class="text-sm">Dichiaro di aver ricevuto e aver ben compreso l’informativa fornita ai sensi dell’art.13 del Regolamento UE 2016/679, dalla quale risulta che,
                nell’ambito dell’esecuzione delle operazioni e del servizio richiesto, si intendono perseguire le finalità di cui al punto 2.1. “Finalità Necessarie”.
                In particolare, dichiara di aver ben compreso dalla predetta informativa che è previsto il trattamento, da parte di Quattrolinee s.r.l.,
                    nella sua qualità di Titolare dei Suoi dati personali.</span>
            </ConsentItemCheck>
            <ClubOptionalConsents />

    </FormBox>
</template>
<script>
import FormBox from "./FormBox";
import ProgressPz from "./ProgressPz";
import ClubOptionalConsents from "@/components/ClubOptionalConsents"
import ConsentItemCheck from "@/components/RequiredItemCheck.vue";
import { mapUserData } from "@/utils";
import { required, helpers } from "vuelidate/lib/validators";

const mustBeTrue = value => !helpers.req(value) || value === true;

export default {
    components: { FormBox, ProgressPz, ConsentItemCheck, ClubOptionalConsents },
    data() {
        return {
            mustBeTrueError: 'ATTENZIONE: non sarà possibile procedere all’adesione al servizio',
            rulesClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Regolamento_Servizio+CLUB_PLUS.pdf',
            privacyClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Informativa_Servizio.pdf',
        }
    },
    computed: {
        ...mapUserData([
            "consent_club1",
            "consent_club2",
            "consent_club3",
            "consent_club4",
        ])
    },
    validations: {
        consent_club1: {
            required,
            mustBeTrue
        },
        consent_club2: {
            required,
            mustBeTrue
        },
        consent_club3: {
            required,
            mustBeTrue
        },
        consent_club4: {
            required,
            mustBeTrue
        },
        consent_club5: {}
    },
    mounted() {
        this.$store.dispatch("sendData", { step: "club_consent" })
    },
    methods: {
        onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                this.$store.commit("setUserData", { key: 'service', value: true });
                this.$store.dispatch("redirect", { step: "club_plus_consent", route: "PrivacyPz" });
            }
        }
    }
};
</script>