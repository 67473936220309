import store from "@/store";
import IBAN from "iban";
import axios from "axios";
import moment from "moment";
import numeral from "numeral";
import { helpers } from "vuelidate/lib/validators";
const cf_lib = require("codice-fiscale-js");
import loadData from "@/dataService.js";

numeral.register("locale", "it", {
    delimiters: {
        thousands: ".",
        decimal: ","
    },
    currency: {
        symbol: "€"
    }
});
numeral.locale("it");


const ibanLen = {
    AT: 20,
    BE: 16,
    BG: 22,
    CH: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    EE: 20,
    ES: 24,
    FI: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GR: 27,
    HR: 21,
    HU: 28,
    IE: 22,
    IS: 26,
    IT: 27,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MT: 31,
    NL: 18,
    NO: 15,
    PL: 28,
    PT: 25,
    RO: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    VA: 22
}

// Preload blocked iban
/* Todo: refactor with constants */
let blockedIBAN = []
axios.get('https://cofidis-settings.s3.eu-central-1.amazonaws.com/iban_prepaid.json').then(function (response) {
    blockedIBAN = response.data
})

export const getBirthday = () => {
    let birthday = store.getters["fiscalCodeExtracted"].birthday;
    if (birthday === null) return "01/1900";
    birthday = birthday.split("-");

    return `${birthday[1]}/${birthday[0]}`;
};

export const mapUserData = arr => {
    let mapped = {};
    arr.forEach(field => {
        mapped[field] = {
            get() {
                return this.$store.state.userData[field];
            },
            set(value) {
                this.$store.commit("setUserData", { key: field, value });
            }
        };
    });
    return mapped;
};

export const isNumber = value => {
    return typeof value === "number";
}

export const formatPriceValues = (val)=> {
    try {
        if(isNumber(val.imp_rata)) val.imp_rata = numeral(val.imp_rata).format('0.00');
        if(isNumber(val.tot_rata)) val.tot_rata = val.tot_rata ? numeral(val.tot_rata).format('0.00') : val.imp_rata;
        if(isNumber(val.club_rata)) val.club_rata = val.club_rata ? numeral(val.club_rata).format('0.00') : 0;
        if(isNumber(val.insurance)) val.insurance = val.insurance ? numeral(val.insurance).format('0.00') : 0;
        if(isNumber(val.tan)) val.tan = numeral(val.tan).format('0.000');
        if(isNumber(val.taeg)) val.taeg = numeral(val.taeg).format('0.00');
        if(isNumber(val.imp_finanz)) val.imp_finanz_text = numeral(val.imp_finanz).format("0,0 $");
        if(isNumber(val.dovuto)) val.dovuto = numeral(val.dovuto).format("0,0.00");
        if(isNumber(val.imposta)) val.imposta = numeral(val.imposta).format("0.00");
        return val;
    } catch (e) {
        return val;
    }
}

export const formatPromo = (promo) => {

    promo.imposta = parseInt(promo.imp_finanz) * 0.0025;

    if(!promo.secciUrl) {
      promo.secciUrl = `${store.state.secciUrl}${promo.secci}`
    }

    return formatPriceValues(promo)
}

export const formatImpFinanz = (val) => {
    return numeral(parseInt(val)).format("$0,0");
}

export const mapJsonToObject = json => {
    return json.map(
        (o, index) => {
            let value = o;
            let key = index;
            if (typeof value === "object") {
                key = Object.keys(value)[0];
                value = Object.values(value)[0];
            }
            return {
                key,
                value
            };
        }
    );
}
export const nonZero = field => {
    return parseInt(field) !== 0;
};

export const nonZeroIf = locator => {
    return (field, vm) => {
        if (!vm[locator]) return true;
        return parseInt(field) !== 0;
    };
};

export const validIBAN = value => {
    // remove spaces
    value = value.replace(/ +/g, "")
    // check that the iban is not one of ours
    const isOurs = loadData("iban_cofidis.json").includes(value)
    const validLen = ibanLen[value.substring(0, 2)] === value.length

    return IBAN.isValid(value) && !value.includes('_') && !isOurs && validLen
};

export const notPrepaidIBAN = value => {
    // always validate if theme config prepaidCards is set
    if (store.state.prepaidCards) return true

    if(!value) return false;

    const ABI = value.replace(/ +/g, "").substring(5, 10)
    const CAB = value.replace(/ +/g, "").substring(10, 15)
    const CC = value.replace(/ +/g, "").slice(15, 27)

    // test if iban match prepaid iban cards
    const isPrepaid = blockedIBAN.map(iban => {
        iban.Abi = iban.Abi.toString()
        iban.Cab = iban.Cab.toString()
        iban.CC = iban.CC.toString()

        if(iban.CC && iban.Cab && iban.Abi)
            return iban.Abi == ABI && iban.Cab == CAB && iban.CC == CC.substring(0, iban.CC.length)
        if(iban.Abi && iban.Cab)
            return iban.Abi == ABI && iban.Cab == CAB
        if(iban.Abi && iban.CC)
            return iban.Abi == ABI && iban.CC == CC.substring(0, iban.CC.length)

        return false
    })
    return !isPrepaid.includes(true)
};

export const validPhone = value => {
    let re = /^(\+39[. ]??)??3\d{2}[. ]??\d{6,7}$/
    return re.test(value)
}

export const extractFiscalCode = code => {
    try {
        let cf_data = cf_lib.computeInverse(code);
        cf_data.country =
            cf_data.birthplaceProvincia === "EE" ? cf_data.birthplace : "ITALIA";
        cf_data.city =
            cf_data.birthplaceProvincia === "EE" ? "EE" : cf_data.birthplace;
        return cf_data;
    } catch (e) {
        return {
            birthday: null,
            birthplace: null,
            birthplaceProvincia: null,
            cf: code,
            day: null,
            gender: null,
            month: null,
            name: null,
            surname: null,
            year: null
        };
    }
};

export const fiscal_code_valid = code => {
    return !helpers.req(code) || cf_lib.check(code);
};

export const fiscal_code_name = (code, vm) => {
    if (!helpers.req(code)) return true;
    if (!fiscal_code_valid(code)) return true;
    let cf_data = cf_lib.computeInverse(code);

    // Add exception for wrong code Z254
    if(cf_data.birthplace == 'GEORGIA') cf_data.birthplace = 'SERBIA'
    if(cf_data.birthplace == 'KAZAKISTAN') cf_data.birthplace = 'SERBIA'

    const cf = cf_lib.compute({
        name: vm.name,
        surname: vm.surname,
        gender: cf_data.gender,
        day: cf_data.day,
        month: cf_data.month,
        year: cf_data.year,
        birthplace: cf_data.birthplace,
        birthplaceProvincia: cf_data.birthplaceProvincia
    });

    return cf.substr(0, 6) === code.substr(0, 6);
};

export const fiscal_code_age = (code) => {
    if (!helpers.req(code)) return true;
    if (!fiscal_code_valid(code)) return true;
    let cf_data = cf_lib.computeInverse(code);
    const birthDate = [cf_data.year,cf_data.month,cf_data.day].join('-')
    const years = moment().diff(moment(birthDate,'YYYY-MM-DD'), 'years')

    return years >= 18
}
