<template>
    <div class="flex-grow text-right secure-form relative">
        <div class="float-right cursor-pointer flex items-center justify-right"  @click="closeIcon = !closeIcon">
            <img src="@/assets/images/secure-form.svg"/>
            <span class="ml-2 font-semibold underline whitespace-nowrap text-sm">Form sicuro</span>
        </div>
        <div v-if="closeIcon" class="absolute right-0 top-8 shadow-lg rounded-3xl p-4 bg-white text-left text-sm w-56 z-20">
            <div class="w-full text-right">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" @click="closeIcon = false">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
            <p class="mt-2">I dati personali che condividi con Cofidis come parte della propria applicazione credito:</p>
            <ul class="list-disc px-4 my-2 text-sm">
                <li class="mt-2">Sono confinati in un database sicuro</li>
                <li class="mt-2">Sono criptati utilizzando la tecnologia SSL</li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            closeIcon: false
        };
    }
}
</script>
