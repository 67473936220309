<template>
    <div v-if="visible" :class="position" class="shadow-lg rounded-3xl p-4 bg-white text-left text-sm w-56 z-20">
        <div class="w-full text-right">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" @click="$emit('hide')">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </div>
        <p class="mt-2 text-gray-7"><slot></slot></p>
    </div>
</template>
<script>
export default {
    props: {
        position: {
            type: String,
            required: false,
            default: 'absolute right-0 top-8'
        },
        visible: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>