<template>
    <div class="precompiled-field">
        <p class="text-gray-3 text-sm font-semibold leading-none pointer-events-none" >{{ label }}</p>
        <input type="text" disabled :value="value" class="text-base mt-1 pointer-events-none bg-none w-full" />
    </div>
</template>
<script>
export default {
    name: "PrecompiledField",
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: [String, Number]
        }
    }
}
</script>