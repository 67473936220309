<template>
        <div class="w-full pt-3">
        <ProgressPz slot="form-header" progress="85" />
        <div class="w-11/12 sm:max-w-7xl mx-auto shadow-lg rounded-3xl bg-white mt-5 mb-10 pb-10">
            <PromoConfirm />
        </div>
    </div>

</template>

<script>
import ProgressPz from "./ProgressPz.vue";
import PromoConfirm from "@/views/forms/PromoConfirm.vue"

export default {
    components: { ProgressPz, PromoConfirm },
};
</script>
