<template>
    <main>
        <!-- Header -->
        <header class="w-full relative" :style="bgHeader">
            <!-- Side band -->
            <div class="band w-2 h-full absolute bg-yellow-3"></div>
            <!-- Logo -->
            <div class="logo flex justify-end container mx-auto px-6 max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                <div class="z-50 bg-white rounded-b-2xl py-1 px-4 md:px-4 lg:-mr-6 2xl:-mr-24 shadow-sm">
                    <img class="w-20 inline-block sm:hidden" src="@/assets/images/logo.svg" alt="Cofidis Logo" title="Cofidis Logo" />
                    <img class="w-40 hidden sm:inline-block" src="@/assets/images/logo-claim.svg" alt="Cofidis Logo" title="Cofidis Logo" />
                </div>
            </div>
            <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl px-6 2xl:px-0 mt-28 sm:mt-4">
                <div class="px-4 md:px-14 lg:px-2">
                    <h1 class="w-40 slanted sm:ml-4 px-0 sm:px-4 uppercase">consolida</h1>
                    <br class="hidden sm:block">
                    <h1 class="w-56 slanted px-2 sm:pr-4 uppercase ml-2">i tuoi prestiti</h1>
                </div>
            </div>
            <div class="md:container mx-auto text-center px-6 sm:px-10 md:px-6 mt-10">
                <div class="bg-white rounded-3xl shadow-2xl px-4 mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl pb-6">
                    <p class="text-xl text-gray-5 font-semibold pt-4 sm:pt-6 pb-2 sm:mb-4 px-4 text-2xl sm:px-8 lg:text-3xl">
                        Quanti prestiti<br class="sm:hidden"> hai in corso?
                    </p>
                    <form class="flex justify-center my-5 sm:my-10 mx-auto w-60 sm:w-72">
                        <button v-for="i in [1,2,3]"
                                :key="i"
                                :class="{'bg-yellow-3': loans_count == i,'border border-gray-5': loans_count != i}"
                                type="button"
                                class="w-12 sm:w-16 h-12 sm:h-16 flex items-center justify-center text-3xl sm:text-5xl rounded-lg mx-auto font-semibold"
                                @click="loans_count = i">{{ i }}</button>
                    </form>
                    <button @click="submit()" type="submit" class="bg-green-500 px-6 sm:px-14 py-2 rounded-full text-white uppercase shadow-lg w-full sm:w-auto">
                        <span class="block font-bold text-xl leading-5">Inizia subito</span>
                        <span class="block leading-5 text-base">pre-esito immediato</span>
                    </button>

                    <h2 class="mt-6 sm:mt-8 text-lg xs:text-xl text-gray-5 font-light">
                        Ti bastano <strong class="font-bold">3 minuti</strong>,<br class="sm:hidden"> ovunque tu sia
                    </h2>

                </div>
            </div>
        </header>

        <!-- Section claim -->
        <section class="section-claim mx-auto">
            <div class="mx-auto text-center px-6 sm:px-10 md:px-6">
                <div class="mx-auto max-w-4xl lg:max-w-5xl xl:max-w-4xl py-6 sm:pt-10 lg:pt-14 sm:pb-6">
                    <p class="text-xl text-gray-5 font-semibold px-4 sm:px-8 text-3xl md:text-4xl lg:text-3xl">
                        Una rata, zero pensieri
                    </p>
                    <h2 class="mt-4 sm:mt-6 text-lg sm:text-2xl text-gray-5 font-light px-0 sm:px-16">
                        Con Consolidamento Prestito di Cofidis ottieni liquidità aggiuntiva o una rata più leggera con la comodità di pagare un’unica rata.
                    </h2>
                </div>
            </div>
        </section>

        <!-- Section icons -->
        <section class="section-icons mx-auto mt-10">
            <div class="bg-white mx-auto text-center px-6 sm:px-10 md:px-6 py-14 sm:pb-14 sm:py-0">
                <div class="mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl sm:pt-10 lg:pt-14">
                    <p class="text-xl text-gray-5 font-semibold px-4 sm:px-8 text-3xl md:text-4xl lg:text-3xl">
                        Perché consolidare<br class="sm:hidden"> i tuoi prestiti
                    </p>
                    <div class="flex flex-wrap items-start py-4 sm:py-4 lg:px-32">
                        <!-- Icons -->
                        <div class="w-full sm:w-1/4 text-center my-4 animate__animated animateFadeInUp animate__1">
                            <i class="icon calendar align-middle w-20 h-20 block mx-auto"></i>
                            <h4 class="block text-sm lg:text-xl xl:inline-block lg:px-2 font-semibold">
                                La comodità di un’unica <br>
                                rata mensile
                            </h4>
                        </div>
                        <div class="w-full sm:w-1/4 text-center my-4 animate__animated animateFadeInUp animate__2">
                            <i class="icon euro align-middle w-20 h-20 block mx-auto"></i>
                            <h4 class="block text-sm lg:text-xl xl:inline-block lg:px-2 font-semibold">
                                Nuova liquidità <br>con la stessa rata
                            </h4>
                        </div>
                        <div class="w-full sm:w-1/4 text-center my-4 animate__animated animateFadeInUp animate__3">
                            <i class="icon barchart align-middle w-20 h-20 block mx-auto"></i>
                            <h4 class="block text-sm lg:text-xl xl:inline-block lg:px-2 font-semibold">
                                La possibilità di una rata <br>più leggera
                            </h4>
                        </div>
                        <div class="w-full sm:w-1/4 text-center my-4 animate__animated animateFadeInUp animate__4">
                            <i class="icon linechart align-middle w-20 h-20 block mx-auto"></i>
                            <h4 class="block text-sm lg:text-xl xl:inline-block lg:px-2 font-semibold">
                                Il risparmio sulle spese <br>di gestione di più prestiti
                            </h4>
                        </div>
                    </div>
                </div>

                <button @click="scrollToTop()" class="font-semibold text-lg text-white bg-green-500 uppercase max-w-xs rounded-full shadow-2xl px-14 py-3 sm:max-w-none sm:w-auto animate__animated animateFadeInUp animate__5 animate__fadeInUp">inizia subito</button>
            </div>
        </section>

        <!-- Section steps -->
        <section class="section-steps pt-10 pb-0 sm:py-10">
            <div class="container mx-auto max-w-7xl">
                <!-- Title -->
                <div class="text-center mb-6 sm:mb-14">
                    <h3 class="font-semibold text-3xl text-white md:text-3xl text-gray-5">
                        Puoi scegliere
                    </h3>
                </div>
                <!-- Steps -->
                <div class="sm:mb-6 flex flex-wrap bg-gray-1 mt-16 sm:mt-20">
                    <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/4 lg:flex lg:flex-1">
                        <div @click="scrollToTop()" class="cursor-pointer bg-white w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 h-48 sm:h-56 animate__animated animateFadeInUp animate__1 relative flex items-center">
                            <div class="absolute -top-6 left-0 w-full text-center">
                                <h3 class="w-auto sm:w-40 slanted px-2 sm:px-4 uppercase mx-auto">rata leggera</h3>
                            </div>
                            <p class="text-xl sm:text-2xl font-light text-gray-4 mt-2 text-center">Riduci la tua spesa mensile con una rata di rimborso più leggera</p>
                        </div>
                    </div>
                    <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/4 lg:flex lg:flex-1 mt-10 sm:mt-0">
                        <div @click="scrollToTop()" class="cursor-pointer bg-white w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 h-48 sm:h-56 animate__animated animateFadeInUp animate__2 relative flex items-center">
                            <div class="absolute -top-6 left-0 w-full text-center">
                                <h3 class="w-auto sm:w-40 slanted px-2 sm:px-4 uppercase mx-auto">più liquidità</h3>
                            </div>
                            <p class="-mt-6 sm:-mt-4 text-xl sm:text-2xl font-light text-gray-4 mt-2 text-center">Richiedi maggiore liquidità per i tuoi progetti con la comodità di una sola rata</p>
                            <div class="absolute bottom-5 left-0 w-full text-center bg-green-500">
                                <span class="text-white text-xl font-semibold py-1 block uppercase">più richiesto</span>
                            </div>
                        </div>
                    </div>
                    <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/4 lg:flex lg:flex-1 mt-10 sm:mt-0">
                        <div @click="scrollToTop()" class="cursor-pointer bg-white w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 h-48 sm:h-56 animate__animated animateFadeInUp animate__4 relative flex items-center">
                            <div class="absolute -top-6 left-0 w-full text-center">
                                <h3 class="w-auto sm:w-40 slanted px-2 sm:px-4 uppercase mx-auto">su misura</h3>
                            </div>
                            <p class="text-xl sm:text-2xl font-light text-gray-4 mt-2 text-center">Personalizza la tua soluzione in base alle tue esigenze</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Section icons -->
        <section class="section-icons mx-auto mt-10">
            <div class="bg-white mx-auto text-center px-6 sm:px-10 md:px-6 py-10 sm:py-4 sm:pb-10">
                <div class="mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl sm:pt-10 lg:pt-14">
                    <p class="text-xl text-gray-5 font-semibold px-4 sm:px-8 text-3xl md:text-4xl lg:text-3xl">
                        Cosa puoi consolidare?
                    </p>
                    <div class="flex flex-wrap items-start py-4 sm:pb-4 sm:pt-10 lg:px-32">
                        <!-- Icons -->
                        <div class="w-1/2 sm:w-1/4 text-center my-4 animate__animated animateFadeInUp animate__1">
                            <i class="bg-yellow-3 rounded-full icon moneybag align-middle w-24 h-24 block mx-auto"></i>
                            <h4 class="block text-sm sm:text-xl xl:inline-block lg:px-2 font-bold mt-4">
                                Prestito personale
                            </h4>
                        </div>
                        <div class="w-1/2 sm:w-1/4 text-center my-4 animate__animated animateFadeInUp animate__2">
                            <i class="bg-yellow-3 rounded-full icon shop align-middle w-24 h-24 block mx-auto"></i>
                            <h4 class="block text-sm sm:text-xl xl:inline-block lg:px-2 font-bold mt-4">
                                Prestiti nei negozi
                            </h4>
                        </div>
                        <div class="w-1/2 sm:w-1/4 text-center my-4 animate__animated animateFadeInUp animate__3">
                            <i class="bg-yellow-3 rounded-full icon car align-middle w-24 h-24 block mx-auto"></i>
                            <h4 class="block text-sm sm:text-xl xl:inline-block lg:px-2 font-bold mt-4">
                                Prestiti auto
                            </h4>
                        </div>
                        <div class="w-1/2 sm:w-1/4 text-center my-4 animate__animated animateFadeInUp animate__4">
                            <i class="bg-yellow-3 rounded-full icon house align-middle w-24 h-24 block mx-auto"></i>
                            <h4 class="block text-sm sm:text-xl xl:inline-block lg:px-2 font-bold mt-4">
                                Piccoli mutui
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Section steps -->
        <section class="section-steps bg-gray-5 pt-10 pb-10">
            <div class="container mx-auto max-w-7xl">
                <!-- Title -->
                <div class="text-center mb-8 sm:mb-14">
                    <h3 class="font-semibold text-3xl text-white md:text-3xl">
                        Come funziona il rifinanziamento<br class="sm:hidden" />
                        dei tuoi prestiti?
                    </h3>
                </div>
                <!-- Steps -->
                <div class="sm:mb-6 flex flex-wrap">
                    <!-- Step 1 -->
                    <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/3 lg:flex lg:flex-1">
                        <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 sm:h-40 lg:h-auto animate__animated animateFadeInUp animate__1">
                            <span class="block font-bold text-sm lg:text-lg text-gray-4 mb-2">Step 1</span>
                            <p class="font-semibold text-3xl text-red-1">Inserisci i tuoi prestiti in corso</p>
                            <p class="font-light text-gray-4 mt-2">Compila i dettagli dei tuoi prestiti e scegli l’opzione più adatta per te</p>
                        </div>
                    </div>
                    <!-- Step 2 -->
                    <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/3 lg:flex lg:flex-1">
                        <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 sm:h-40 lg:h-auto
                            animate__animated animateFadeInUp animate__2">
                            <span class="block font-bold text-sm lg:text-lg text-gray-4 mb-2">Step 2</span>
                            <p class="font-semibold text-3xl text-red-1">Compila il form</p>
                            <p class="font-light text-gray-4 mt-2">Inserisci i dati richiesti, ti bastano pochi minuti</p>
                        </div>
                    </div>
                    <!-- Step 3 -->
                    <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/3 lg:flex lg:flex-1">
                        <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 sm:h-40 lg:h-auto animate__animated animateFadeInUp animate__4">
                            <span class="block font-bold text-sm lg:text-lg text-gray-4 mb-2">Step 3</span>
                            <p class="font-semibold text-3xl text-red-1">Ricevi un pre-esito immediato</p>
                            <p class="font-light text-gray-4 mt-2">Ricevi subito via email una prima risposta di esito alla tua richiesta</p>
                        </div>
                    </div>
                    <!-- Step 4 -->
                    <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/3 lg:flex lg:flex-1">
                        <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 sm:h-40 lg:h-auto animate__animated animateFadeInUp animate__4">
                            <span class="block font-bold text-sm lg:text-lg text-gray-4 mb-2">Step 4</span>
                            <p class="font-semibold text-3xl text-red-1">Per te un consulente dedicato</p>
                            <p class="font-light text-gray-4 mt-2">Entro 24 ore un nostro consulente ti contatta e ti accompagna nella finalizzazione della richiesta</p>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <button @click="scrollToTop()" class="font-semibold text-lg text-white bg-green-500 uppercase max-w-xs rounded-full shadow-2xl px-14 py-3 sm:max-w-none sm:w-auto animate__animated animateFadeInUp animate__5 animate__fadeInUp">inizia subito</button>
                </div>
            </div>
        </section>

        <!-- Section reviews -->
        <section class="section-steps pt-10 pb-10">
            <div class="container mx-auto max-w-7xl">
                <!-- Title -->
                <div class="text-center mb-8 sm:mb-14">
                    <h3 class="font-semibold text-3xl text-white md:text-3xl text-gray-5">
                        La soddisfazione<br class="sm:hidden" />
                        dei nostri clienti
                    </h3>
                </div>
                <!-- Steps -->
                <div class="sm:mb-6 flex flex-wrap bg-gray-1">
                    <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/4 lg:flex lg:flex-1">
                        <div class="bg-white w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 h-48 sm:h-56 animate__animated animateFadeInUp animate__1 relative">
                            <p class="font-light text-gray-4 mt-2">"Grazie al consulente di Cofidis che mi ha seguito sono riuscito ad avere i miei prestiti sotto controllo pagando una sola rata. Molto comodo!!"</p>
                            <strong class="block font-bold absolute bottom-6 left-6">Mario M.</strong>
                        </div>
                    </div>
                    <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/4 lg:flex lg:flex-1">
                        <div class="bg-white w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 h-48 sm:h-56 animate__animated animateFadeInUp animate__2 relative">
                            <p class="font-light text-gray-4 mt-2">"Avevo tanti prestiti e pagavo tanto al mese. Con Cofidis ho una sola rata più bassa. Grazie!!"</p>
                            <strong class="block font-bold absolute bottom-6 left-6">Elisa D.</strong>
                        </div>
                    </div>
                    <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/4 lg:flex lg:flex-1">
                        <div class="bg-white w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 h-48 sm:h-56 animate__animated animateFadeInUp animate__4 relative">
                            <p class="font-light text-gray-4 mt-2">"Cofidis mi ha dato fiducia dandomi nuova liquidità anche con altri prestiti. Ora pago una rata unica!"</p>
                            <strong class="block font-bold absolute bottom-6 left-6">Tommaso C.</strong>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Section banner -->
        <section class="section-banner py-10 md:py-16 lg:py-20 sm:px-4" :style="bgBanner">
            <div class="container mx-auto flex flex-wrap">
                <div class="w-full lg:w-1/2 text-center px-6 mb-8 md:inline-block md:px-2 lg:mb-0">
                    <div class="bg-orange-1 rounded-3xl shadow-2xl px-10 sm:px-4 py-4 mx-auto xl:w-3/4">
                        <div class="">
                            <p class="text-xl text-gray-5 font-semibold mb-6 sm:mb-10 px-4 lg:text-3xl sm:px-8">
                                Abbiamo dato fiducia a
                            </p>
                        </div>
                        <div class="w-full pb-5 mb-5 border-b-2 border-gray-700 border-opacity-30 sm:p-0 sm:w-1/2 sm:border-b-0 sm:border-r-2 sm:inline-block">
                            <img class="mx-auto h-14" alt="Group" title="Group" src="@/views/themes/consolidamento/images/cofidis-milioni-clienti.svg" />
                            <h3 class="lg:mt-2">
                                <strong class="font-semibold text-2xl text-gray-4 sm:text-3xl lg:text-4xl block">30 milioni</strong>
                                <span class="text-lg uppercase"> di clienti </span>
                            </h3>
                        </div>
                        <div class="w-full sm:w-1/2 sm:inline-block">
                            <img class="mx-auto h-14" alt="Pin" title="Pin" src="@/views/themes/consolidamento/images/cofidis-nove-paesi-europa.svg" />
                            <h3 class="lg:mt-2">
                                <strong class="font-semibold text-2xl text-gray-4 sm:text-3xl lg:text-4xl block">in 9 paesi</strong>
                                <span class="text-lg uppercase"> in Europa </span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="w-full lg:w-1/2 text-center px-6 md:inline-block md:px-2">
                    <div class="bg-orange-1 rounded-3xl shadow-2xl px-10 sm:px-4 pt-4 pb-3 mx-auto xl:w-3/4">
                        <div class="">
                            <p class="text-xl text-gray-5 font-semibold mb-4 px-4 lg:text-3xl sm:px-8">
                                Con ottimi risultati
                            </p>
                        </div>
                        <div class="flex flex-wrap items-center sm:pb-4">
                            <div class="google-rating-wrapper">
                                <img class="mx-auto w-36" alt="Prestito Personale – Google Rating" title="Google Rating" :src="`${commonDistributionUrl}/Google_rating_stelle_bianche.png`" />
                            </div>
                            <div class="w-full sm:w-1/2 sm:inline-block sm:px-4">
                                <img class="mx-auto" alt="Prestito Personale – TrustPilot 2022" title="TrustPilot 2022" :src="`${commonDistributionUrl}/TrustPilot_Cofidis_2022.png`" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Section cofidis leader -->
        <section class="section-leader bg-gray-5 py-8">
            <div class="container mx-auto">
                <div class="w-full relative flex flex-wrap items-center justify-center lg:h-48">
                    <img class="sm:mr-10 h-40" alt="Prestito Personale – Cofidis Certificazione Ottima Finanziaria" title="Logo Certificazione Ottima Finanziaria" :src="`${commonDistributionUrl}/ottima_finanziaria_2022.png`" />
                    <h3 class="w-full sm:w-auto font-semibold text-2xl text-white leading-none md:text-3xl lg:text-3xl mt-6 sm:mt-0 text-center">Cofidis è il leader del credito a distanza</h3>
                </div>
            </div>
        </section>

        <!-- Section widget -->
        <section class="section-widget md:mt-0 py-6 sm:py-10 lg:py-16 bg-white">
            <div class="container mx-auto">
                <!-- Title -->
                <div class="text-center mb-8 sm:mb-16">
                    <h3 class="font-semibold text-3xl text-gray-5 leading-none md:text-3xl">
                        Cosa dicono i <br class="sm:hidden" />
                        nostri clienti
                    </h3>
                </div>
                <Trustpilot />
            </div>
        </section>

        <!-- Section faq -->
        <section class="section-faq pt-4 sm:pt-10 pb-4 bg-gray-1 lg:pt-16">
            <div class="sm:container px-6 py-6 mx-auto">
                <h3 class="font-semibold text-3xl text-gray-5 leading-none px-6 mb-14 text-center lg:text-3xl">
                    Trova la risposta <br class="sm:hidden" />
                    alle tue domande
                </h3>
                <div class="faq-container w-full flex flex-wrap hover:text-orange cursor-pointer mb-6" :class="{ hidden: i > 3 && !allFaqs }" v-for="(faq, i) in faqs" :key="i">
                    <div class="h-10 w-10 text-center rounded-full leading-10 mr-5 -mt-2 outline-none flex items-center justify-center"
                         :class="{
                        'bg-gray-2': activeFaq != i,
                        'bg-orange-1 shadow-xl': activeFaq == i
                    }"
                         @click="toggleFaq(i)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="text-white h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" :class="{'transform rotate-180': activeFaq != i}">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                        </svg>
                    </div>
                    <div class="w-4/5">
                        <div class="faq-question mb-4 outline-none" :class="{ active: activeFaq == 1 }" @click="toggleFaq(i)">
                            <span class="font-semibold text-2xl text-gray-5 leading-none">{{
                                    faq.q
                                }}</span>
                        </div>
                        <SlideUpDown class="faq-content mb-4 font-medium text-gray-5 cursor-default outline-none" :active="activeFaq == i"><span class="font-light text-lg text-gray-5" v-html="faq.a"></span></SlideUpDown>
                    </div>
                </div>
                <div class="text-center" v-if="allFaqs" @click="allFaqs = false">
                    <p class="inline font-semibold text-lg text-red-1 text-center cursor-pointer mt-6 uppercase border-b-4 border-red-1">
                        Mostra meno
                    </p>
                </div>
                <div v-else class="text-center" @click="allFaqs = true">
                    <p class="inline font-semibold text-lg text-red-1 text-center cursor-pointer mt-6 uppercase border-b-4 border-red-1">
                        Carica altre
                    </p>
                </div>
            </div>
        </section>

        <!-- Section amount -->
        <section class="section-amount bg-white">
            <div class="container mx-auto px-2 sm:px-10 text-center py-10">
                <p class="text-gray-5 text-white mb-5 text-2xl">
                    Rifinanzia i tuoi debiti in pochi<br class="sm:hidden"> click comodamente da casa
                </p>

                <div class="text-center mt-4 sm:mt-10">
                    <button @click="scrollToTop()" class="font-semibold text-lg text-white bg-green-500 uppercase max-w-xs rounded-full shadow-2xl px-14 py-3 sm:max-w-none sm:w-auto animate__animated animateFadeInUp animate__5 animate__fadeInUp">inizia subito</button>
                </div>
            </div>
        </section>

        <!-- Section logos -->
        <section class="section-logos">
            <div class="container flex flex-wrap items-center mx-auto px-8 py-10 md:py-16 lg:px-32">
                <div class="w-1/2 text-center mb-6 sm:w-1/4 sm:mb-0">
                    <img class="mx-auto w-40" alt="Prestito Personale – Google Rating" title="Google Rating" :src="`${commonDistributionUrl}/Google_rating_stelle_gialle.png`" />
                </div>
                <div class="w-1/2 text-center mb-6 sm:w-1/4 sm:mb-0">
                    <img class="mx-auto w-52" alt="Prestito Personale – Best Workplaces" title="Logo Best Workplaces" :src="`${commonDistributionUrl}/logo_best_workplaces.png`" />
                </div>
                <div class="w-1/2 text-center mb-6 sm:w-1/4 sm:mb-0">
                    <img class="mx-auto w-24 lg:w-32" alt="Prestito Personale – Cofidis Certificazione Ottima Finanziaria" title="Logo Certificazione Ottima Finanziaria" :src="`${commonDistributionUrl}/ottima_finanziaria_2022.png`" />
                </div>
                <div class="w-1/2 text-center mb-6 sm:w-1/4 sm:mb-0">
                    <img class="mx-auto" alt="Prestito Personale – TrustPilot 2022" title="TrustPilot 2022" :src="`${commonDistributionUrl}/TrustPilot_Cofidis_2022.png`" />
                </div>
            </div>
        </section>
        <YouTubeModal :videoId="`7xj8ZX57zYo`" ref="videoFirmaDigitale" />
    </main>
</template>
<script>
// @ is an alias to /src
import YouTubeModal from "@/views/themes/consolidamento/YouTubeModal";
import Trustpilot from "@/components/TrustpilotSwiper.vue";

export default {
    name: "Home",
    components: {
    YouTubeModal,
    Trustpilot
},
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        },
        toggleFaq(i) {
            if (this.activeFaq == i) this.activeFaq = null;
            else this.activeFaq = i;
        },
        submit() {
            if(this.loans_count) {
                if (process.env.NODE_ENV !== "development") {
                    let loans_data = [];
                    for (let i = 0; i < this.loans_count; i++) {
                        loans_data.push({
                            amount: null,
                            installments_amount: null,
                            installments_count: null,
                            first_installment: null,
                            provider: null
                        });
                    }
                    this.$store.state.userData.loans_count = this.loans_count
                    this.$store.state.userData.loans_data = loans_data
                }
                this.$store.dispatch("event", { name: "ConsolidamentoHome" });
                this.$store.dispatch("redirect", { step: "homepage", route: "Email" });
            }
        }
    },
    computed: {
        bgHeader() {
            const env = process.env.VUE_APP_ENV == "production" ? 'prod' : 'stage'
            return {
                '--bg-header': `#fff url("${this.distributionUrl}/${env}/header.jpg") 55% 0% no-repeat`,
                '--bg-header-sm': `#fff url("${this.distributionUrl}/${env}/header-sm.jpg") center top no-repeat`,
            }
        },
        bgBanner() {
            return {
                '--bg-banner': `#fff url("${this.distributionUrl}/credito-cofidis-banner.png") 60% top no-repeat`,
                '--bg-banner-sm': `#fff url("${this.distributionUrl}/credito-cofidis-banner-sm.png") 60% top no-repeat`,
            }
        }
    },
    data() {
        return {
            rating: 0,
            ratingsTotal: 0,
            activeFaq: null,
            allFaqs: false,
            loans_count: null,
            distributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/consolidamento",
            commonDistributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/common",
            faqs: [{
                q: "Quali finanziamenti posso consolidare?",
                a: "È possibile consolidare prestiti personali, prestiti finalizzati, piccoli mutui, leasing."
            },
                {
                    q: "Devo chiedere io i conteggi o ci pensate voi?",
                    a: "Il consulente supporta il cliente nella raccolta dei conteggi: gli invia le istruzioni con l’indicazione dei dati che ci servono e cosa deve chiedere alla sua banca/finanziaria; se la banca/finanziaria lo accetta, si fa delegare per chiedere i conteggi al suo posto.",
                },
                {
                    q: "Chi si occupa di estinguere il prestito da consolidare?",
                    a: "Il consulente si occupa anche dell’estinzione dei debiti da consolidare."
                },
                {
                    q: "Quali sono le tempistiche?",
                    a: "L’operazione si chiude in 72 ore (3gg) dalla richiesta web, al netto dei tempi di attesa dei documenti e dei conteggi: il cliente compila la richiesta oggi, domani lo chiama il consulente, poi da quando riceviamo documenti e conteggi in altre 48 ore il cliente ha la disponibilità in conto ed estinguiamo i debiti da consolidare.",
                },
                {
                    q: "Nel prestito da consolidare era presente un garante, sono costretto a mantenerlo?",
                    a: "Tutti i parametri dei prestiti da consolidare sono modificabili, compresa la figura del garante. Solo il dato del debito residuo non può essere modificato e viene accorpato nel consolidamento."
                },
                {
                    q: "Devo mantenere la stessa durata del finanziamento da consolidare o posso cambiarla?",
                    a: "In linea generale il consolidamento permette di rivedere tutti i parametri dei prestiti da consolidare: tasso, durata, scadenza rata. L’unico dato che resta invariato è il debito residuo che viene accorpato nel consolidamento.",
                }
            ],
        };
    },
    created() {
        const vm = this;
        window.event.$on('cookiePreferencesUpdated', () => {
            if (!window.dataLayer) return;

            for (var i in window.dataLayer) {
                if (window.dataLayer[i].event && window.dataLayer[i].event == "OneTrustLoaded") {
                    const consentGroups = window.dataLayer[i].OnetrustActiveGroups.split(',')
                    if (consentGroups && consentGroups.includes('C0004')) {
                        let script = document.createElement("script");
                        script.src = "//www.youtube.com/iframe_api";
                        window.onYouTubeIframeAPIReady = function() {
                            vm.$refs.videoComeFunziona.init()
                            vm.$refs.videoFirmaDigitale.init()
                            vm.$refs.videoCosaServe.init()
                        };
                        document.head.appendChild(script);

                        return true;
                    }
                }
            }
        })
    },
    async mounted() {

        // animation interesection
        window.onscroll = function() {
            const animateFadeInUp = document.getElementsByClassName("animateFadeInUp");
            //const animateFadeIn = document.getElementsByClassName("animateFadeIn");
            const windowOffsetTop = window.innerHeight + window.scrollY;

            Array.prototype.forEach.call(animateFadeInUp, (animatedBox) => {
                const animatedBoxOffsetTop = animatedBox.offsetTop;
                if (windowOffsetTop >= animatedBoxOffsetTop) {
                    animatedBox.classList.add('animate__fadeInUp')
                }
            });
        }
    }
};
</script>