<template>
    <section>
        <div class="bg-yellow text-center py-6">
            <h3 class="font-semibold text-xl text-gray-700">Subito per te</h3>
            <h2 class="text-6xl font-bold text-gray-900 leading-tight mx-auto">{{ userData.extra_amount|num }}€</h2>
        </div>

        <div class="text-center py-6">
            <Steps :count="steps"></Steps>
            <h3 class="text-3xl font-semibold text-gray-900 leading-tight mx-auto mt-2">Controlla i tuoi dati</h3>
        </div>

        <div class="bg-white p-6">
            <div class="max-w-4xl xl:max-w-6xl mx-auto flex flex-wrap">
                <label class="w-1/4 text-lg text-gray-900 font-medium">Cognome</label>
                <span class="w-3/4 text-lg text-gray-700 font-semibold uppercase">{{ surname }}</span>
                <label class="w-1/4 text-lg text-gray-900 font-medium mt-2">Nome</label>
                <span class="w-3/4 text-lg text-gray-700 font-semibold uppercase mt-2">{{ name }}</span>
                <label class="w-1/4 text-lg text-gray-900 font-medium mt-2">IBAN</label>
                <span class="w-3/4 text-lg text-gray-700 font-semibold uppercase mt-2">{{ maskedIban }}</span>
            </div>
        </div>

        <div class="bg-yellow text-center p-6">
            <p class="text-center font-semibold text-xl text-gray-900 leading-none">Verifica che la tua e-mail sia corretta oppure inseriscila.</p>
            <div class="sm:w-96 text-center mx-auto mt-4">
                <div class="relative">
                    <input type="email" id="email" name="email" class="w-full py-2 pl-4 pr-10 text-lg" v-model="email" v-mask="{ placeholder: '', greedy: false, casing: 'lower' }"
                           placeholder="Se non appare la tua email, inseriscila" :class="{ 'border border-red': $v.email.$error }">
                    <label for="email"><i class="icon pencil absolute top-3 right-2 cursor-pointer"></i></label>
                </div>
                <div class="w-full text-red text-left" v-if="$v.email.$error">
                    <small v-if="!$v.email.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                    <small v-else-if="!$v.email.email">
                        {{ $t("Formato e-mail non valido") }}
                    </small>
                </div>
            </div>
        </div>

        <div class="bg-white p-6">
            <div class="max-w-4xl xl:max-w-6xl mx-auto flex flex-wrap">
                <span><strong class="font-bold">Perché conosciamo i tuoi dati?</strong>
                    La risposta è <strong class="font-bold">semplice</strong> e <strong class="font-bold">trasparente</strong>:
                    hai già una delle soluzioni di credito con Cofidis</span>
            </div>
        </div>

        <div class="text-center pt-6 mb-4">
            <h1 class="text-3xl font-semibold text-gray-900 leading-tight mx-auto">Completa i tuoi dati</h1>
        </div>

        <div class="form-wrapper py-6 px-4">
            <div v-if="showHiringType" class="w-full lg:w-1/3 lg:pr-4 mb-2" :class="{'lg:mt-6': showEmployedSince}">
                <FloatingLabelSelect :name="`hiring_type`" v-model="hiring_type" :items="hiring_types" :label="`Assunzione a tempo`" :disableZeroValue="true" :error="$v.hiring_type.$error"/>
                <div class="h-6 w-full text-red" v-if="$v.hiring_type.$error">
                    <small v-if="!$v.hiring_type.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                </div>
            </div>
            <div v-if="showEmployedSince" class="w-full lg:w-1/3 lg:pr-4 mb-2 lg:mr-2">
                <label class="text-xs">{{ $t("Occupazione dal") }}</label>
                <div class="date-select" :class="{ 'border-red': $v.employed_since.$error }" >
                    <DateSelect :value="employed_since" :showDay="false" :showMonth="false" :inputClass="{ 'border-red': $v.employed_since.$error }" :startYear="employedSinceStartYear" @change="updateEmployedSinceValue" ref="emplyoedSinceComponent" />
                </div>
                <div class="h-6 w-full text-red" v-if="$v.employed_since.$error">
                    <small v-if="!$v.employed_since.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                    <small v-else-if="!$v.employed_since.minValue">
                        {{ $t("Data non valida") }}
                    </small>
                    <small v-else-if="!$v.employed_since.maxValue">
                        {{ $t("Data non valida") }}
                    </small>
                </div>
            </div>
            <div v-if="showMonthlyIncome" class="w-full lg:w-1/3 lg:pr-4 mt-4 mb-2">
                <FloatingLabelInput :name="`monthly_income`" :type="`tel`" v-model="monthly_income" :mask="currencyMask" :label="`Reddito mensile netto`" :error="$v.monthly_income.$error"/>
                <div class="h-6 w-full text-red" v-if="$v.monthly_income.$error">
                    <small v-if="!$v.monthly_income.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                </div>
                <div class="text-xs">Inserisci il reddito mensile netto che risulta dalla tua ultima busta paga, ci sarà utile per valutare correttamente la tua richiesta.</div>
            </div>
        </div>

        <div class="form-footer">
            <button @click="submit()">CONTINUA</button>
        </div>
    </section>
</template>
<script>
import FloatingLabelInput from "@/components/FloatingLabelInput";
import FloatingLabelSelect from "@/components/FloatingLabelSelect";
import DateSelect from "@/components/DateSelect";
import Steps from '@/components/Steps'

import { getBirthday, mapUserData, mapJsonToObject, nonZeroIf } from "@/utils";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import { currencyMask, shortDateMask } from "@/masks";
import { JOB_TYPES } from '@/constants.js';
import loadData from "@/dataService.js";
import moment from "moment/moment";
import { mapState } from 'vuex';

export default {
    components: { Steps, FloatingLabelInput, FloatingLabelSelect, DateSelect },
    mounted() {
        this.$ga.pageview()
    },
    data() {
        return {
            hiring_types: loadData("assunzione.json"),
            monthly_payments: loadData("mensilita.json"),
            currencyMask,
            shortDateMask
        };
    },
    computed: {
        ...mapUserData([
            "name",
            "surname",
            "iban",
            "email",
            "employment_type",
            "employment_sector",
            "occupation",
            "monthly_income",
            "hiring_type",
            "employed_since",
            "document_expired"
        ]),
        ...mapState(["userData"]),
        steps() {
            return this.document_expired ? 3 : 2
        },
        maskedIban() {
            return `${this.iban[0]}${this.iban[1]}****************${this.iban.slice(-4)}`
        },
        choosenCredit() {
            return this.$store.state.choosenCredit;
        },
        showMonthlyPayment() {
            return [JOB_TYPES.PENSIONATO,JOB_TYPES.PENSIONATO_INVALIDO].includes(this.employment_type) ||
                (   this.employment_type === JOB_TYPES.DIPENDENTE && this.occupation)
        },
        showEmployedSince() {
            if(this.hiring_type && this.hiring_type != '') return true; else return false
        },
        showHiringType() {
            return true
        },
        showMonthlyIncome() {
            if(this.employed_since && this.employed_since != '') return true; else return false
        },
        employmentSectors() {
            return mapJsonToObject(this.all_employment_sectors[this.employment_type])
        },
        employedSinceStartYear() {
            return parseInt(moment(getBirthday(), 'MM/YYYY').format('YYYY'))
        }
    },
    validations: {
        email: {
            required,
            email
        },
        monthly_income: {
            required: requiredIf("showMonthlyIncome")
        },
        hiring_type: {
            nonZero: nonZeroIf("showHiringType")
        },
        employed_since: {
            required: requiredIf("showEmployedSince"),
            minValue: (value, vm) => {
                return vm.showEmployedSince ? moment(value, 'YYYY') >= moment(getBirthday(), 'MM/YYYY') : true
            },
            maxValue: (value, vm) => {
                return vm.showEmployedSince ? moment(value, 'YYYY') <= moment() : true
            }
        },
    },
    watch: {
        hiring_type() {
            this.employed_to = null
        },
        showMonthlyIncome(val) {
            if (val) {
                this.$v.monthly_income.$reset();
            }
        },
        showHiringType(val) {
            if (val) {
                this.$v.hiring_type.$reset();
            }
        },
        employment_type() {
            this.employment_sector = 0;
            this.occupation = 0;
            this.monthly_payment = 0;
            this.monthly_income = null;
            this.employed_since = null;
            this.hiring_type = 0;
            if (this.$refs.emplyoedSinceComponent) this.$refs.emplyoedSinceComponent.reset();
            if (this.$refs.emplyoedToComponent) this.$refs.emplyoedToComponent.reset();
        },
        showEmployedSince(val) {
            if (val) {
                this.$v.employed_since.$reset();
            }
        },
    },
    methods: {
        updateEmployedSinceValue(value) {
            this.employed_since = value
        },
        submit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                this.$store.dispatch("redirect", {step: "income", route: "PrivacyConso"});
                this.$store.dispatch("sendData", { step: "dati_lav" });
            }
        }
    }
};
</script>