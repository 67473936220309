import { JOB_TYPES } from '@/constants.js';

class Hooks {
    proposal_our(router, {dispatch}) {
        dispatch("sendData", { step: "dati_anag" });
        router.push({ name: "Contatti" })
    }
    proposal_other(router, {dispatch}) {
        dispatch("sendData", { step: "dati_anag" });
        router.push({ name: "Contatti" })
    }
    proposal_limited(router, {dispatch}) {
        dispatch("sendData", { step: "dati_anag" });
        router.push({ name: "RataUpgrade" })
    }
    dati_pers_1(router) {
        router.push({ name: "IncomeData" })
    }
    dati_lav(router, {state}) {
        const userData = state.userData

        switch(state.PATH) {
            case 'FE':
            case 'CQ':
                if ([JOB_TYPES.AUTONOMO, JOB_TYPES.DIPENDENTE, JOB_TYPES.STAGIONALE, JOB_TYPES.PROFESSIONISTA].includes(userData.employment_type))
                    router.push({ name: "EmployerData" })
                else
                    router.push({ name: "DeliveryData" })
                break;
            case 'ML':
            case 'FAM':
                router.push({ name: "DeliveryData" })
                break;
            default:
                router.push({ name: "TypKO" })
            break;
        }
    }
    dati_lav_2(router, {state, getters}) {
        if(state.PATH == 'FE')
            if(getters.insuranceAvailable)
                router.push({ name: "LoanProtection" })         
            else
                router.push({ name: "DeliveryData" })
        else
            router.push({ name: "TypKO" })
    }
    dati_pers_4(router) {
        router.push({ name: "PaymentDetailsConsolidamento" })
    } 
    dati_paga(router, {state, dispatch, commit}) {
        if(state.PATH == 'FE' || state.PATH == 'CQ') {
            dispatch("sendData", { step: "dati_privacy" });
            router.push({name: "ThankYou"})
        }
        else {
            commit('setEnvName', 'metlife')
            router.push({name: "MetlifeProtection"})
        }
    }
    insurance_consent(router) {
        router.push({ name: "DeliveryData" })
    }
    insurance_warning(router) {
        router.push({ name: "DeliveryData" })
    }

    metlife(router) {
        router.push({ name: "MetlifeProtectionConsent"})
    }
    metlife_ko(router) {
        router.push({ name: "MetlifeProtectionWarning"})
    }
    metlife_warning(router) {
        router.push({ name: "MetlifeProtectionConsent"})
    }
    metlife_submit(router) {
        router.push({ name: "TypMetlifeKO"})
    }
    metlife_consent(router) {
        router.push({ name: "TypMetlifeOK"})
    }
    metlife_back(router) {
        router.push({ name: "MetlifeProtection" })
    }
}
export default new Hooks();