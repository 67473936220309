<template>
    <FormCard title="Conferma la tua scelta" class="w-full" @submit.prevent>
        <div class="w-full mt-12 mb-8">
            <PromoBox
                :model="promoModel"
                :hasDetails="service || insurance"
                :loading="loading"
                :closed="showUpgrade"
                :showFlag="false"
                :cta="ctaText"
                @open="showUpgrade = !showUpgrade"
                @click="submit(false)"
            >
                <template #details>
                    <span v-html="detailsHtml(false)"></span>
                </template>
            </PromoBox>

            <p class="text-base text-center" :class="showUpgrade ? 'mt-8 mb-12' : 'mt-16 mb-10'">{{ $t(choiceText) }}</p>

            <PromoBox
                :model="promoUpgradeModel"
                :hasDetails="service || insurance"
                :flagText="flagText"
                :loading="loading"
                :cta="ctaText"
                :closed="!showUpgrade"
                @open="showUpgrade = !showUpgrade"
                @click="submit(true)"
            >
                <template #details>
                    <span v-html="detailsHtml(true)"></span>
                </template>
            </PromoBox>
        </div>

    </FormCard>
</template>
<style scoped type="text/css">
    div >>> .form-body { @apply p-0 pb-6 sm:px-6 md:px-8; }
    div >>> .form-footer { display: none !important; }
</style>
<script>
import { mapUserData } from "@/utils";
import PromoBox from "@/components/PromoBox.vue";

export default {
    data() {
        return {
            showUpgrade: true,
            loading: false,
            infoSetUrl: "https://cofidis-docs-pp.s3.eu-central-1.amazonaws.com/Set_informativi_assicurazione.pdf"
        }
    },
    props: {
        ctaText: {
            type: String,
            default: "CONTINUA"
        },
        choiceText: {
            type: String,
            default: "Oppure richiedi più liquidità"
        }
    },
    mounted() {
        this.$ga.pageview()
    },
    components: { PromoBox },
    computed: {
        ...mapUserData(["name", "importo", "service", "insurance"]),
        promoModel() {
            return this.getPromoOffer(false)
        },
        promoUpgradeModel() {
            return this.getPromoOffer(true)
        },
        flagText() {
            if (this.promoUpgradeModel.type == 'rata') return 'Rata più leggera'
            return 'Più liquidità per i tuoi progetti'
        }
    },
    methods: {
        async submit(upgrade = false) {
            this.loading = true

            this.$store.commit("setUserData", { key: 'promoUpgraded', value: upgrade });

            if (upgrade) {
                this.$store.commit("setChoosenCredit", this.promoUpgradeModel.imp_finanz);
                this.$store.commit("setUserData", { key: 'rate', value: this.promoUpgradeModel.rate });
            } else {
                this.$store.commit("setChoosenCredit", this.promo);
                this.$store.commit("setUserData", { key: 'rate', value: this.promoModel.rate });
            }
            
            await this.$store.dispatch("sendData", { step: "promo" });
            this.$store.dispatch("redirect", { step: "promo_confirm", route: "PrivacyConsent" });
        },
        detailsHtml(upgrade = false) {
            const model = upgrade ? this.promoUpgradeModel : this.promoModel;
            let html = `Rata composta da ${model.imp_rata}€ di rimborso prestito`
            if (this.insurance && this.service) {
                html += `, circa ${model.insurance}€ di premio assicurativo
                    (<a href="${this.infoSetUrl}" target="_blank" class="underline">set informativo</a>)
                    e circa ${model.club_rata}€ di servizio Club. Totale dovuto: ${model.dovuto}€.
                    Se vuoi modificare le tue scelte sui servizi assicurativi e su Club torna indietro e ricomincia`
            }
            else if (this.insurance) {
                html += ` e circa ${model.insurance}€ di premio assicurativo
                    (<a href="${this.infoSetUrl}" target="_blank" class="underline">set informativo</a>).
                    Totale dovuto: ${model.dovuto}€.
                    Se vuoi modificare le tue scelte sui servizi assicurativi torna indietro e ricomincia`
            }
            else if (this.service) {
                html += ` e circa ${model.club_rata}€ di servizio Club.
                    Totale dovuto: ${model.dovuto}€.
                    Se vuoi modificare le tue scelte sul servizio Club torna indietro e ricomincia`
            }
            return html;
        }
    }
};
</script>
