<template>
    <ClubPlusConsent />
</template>
<script>
import ClubPlusConsent from "@/views/forms/ClubPlusConsent.vue"

export default {
    components: { ClubPlusConsent }
}

</script>