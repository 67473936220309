<template>
    <form action="next" method="POST">
        <div class="container text-center py-6 relative mx-auto">
            <i class="icon arrowleft absolute left-4 sm:left-0 top-1/2 transform -translate-y-1/2 cursor-pointer" @click="goBack()"></i>
            <Steps :count="steps" :active="steps"></Steps>
        </div>
        <div class="form-wrapper">
            <PrivacyConsent :alert-data-consent="false">
                <template #disclaimer>
                    <div v-if="showPromoNotAvailable" class="mt-8">
                        <p class="text-gray-700 text-base ">
                            Cliccando su Invia richiesta prendo atto che il mio profilo non soddisfa i <span @click="showPromoModal" class="underline cursor-pointer">Termini e condizioni</span>
                            per richiedere l’offerta promo e che invio richiesta di {{ promoKO.imp_finanz_text }} TAEG {{ promoKO.taeg }}%.
                        </p>
                    </div>
                </template>
            </PrivacyConsent>
        </div>
    </form>
</template>
<script>
import PrivacyConsent from "@/views/forms/PrivacyConsent";
import Steps from '@/components/Steps'
import { mapUserData } from "@/utils"
import { mapGetters } from "vuex";

export default {
    components: { Steps, PrivacyConsent },
    mounted() {
        this.$store.dispatch("sendData", { step: "privacy", additionalData: {
            init: true,
            codeCampaign: localStorage.getItem('fastcredit_code_campaign'), 
            userId: localStorage.getItem('fastcredit_user_id') 
        }});
    },
    computed: {
        ...mapUserData(["document_expired", "importo", "can_request"]),
        ...mapGetters(["promoAvailable", "promoKO"]),
        steps() {
            return this.document_expired ? 4 : 3
        },
        showPromoNotAvailable() {
            return this.can_request && this.importo === this.promo && !this.promoAvailable
        },
    }
};
</script>