<template>
    <FormBox title="Non perdere questa occasione!" @submit="onSubmit()" declineText="Procedi senza Club" @decline="onDecline()" >
    
        <ProgressPz slot="form-header" progress="80" />

        <p class="mb-4 text-base">
            Cofidis ti regala il primo mese di <strong>accesso gratuito</strong> al CLUB PLUS, in cui puoi usufruire di esperienze uniche in formula 2x1:
            paghi per uno e invita chi vuoi! Dopo il mese gratuito, soli 9,90 € al mese. Non lasciarti sfuggire questa occasione!
        </p>

    </FormBox>
</template>
<script>
import FormBox from "./FormBox";
import ProgressPz from "./ProgressPz";

export default {
    components: { FormBox, ProgressPz },
    mounted() {
        this.$store.dispatch("sendData", { step: "club_warning" })
    },
    methods: {
        onSubmit() {
            this.$store.dispatch("redirect", { step: "club_plus_warning", route: "ClubPlusPzConsent" });
        },
        onDecline() {
            this.$store.commit("setUserData", { key: 'service', value: false });
            this.$store.dispatch("redirect", { step: "club_plus_warning_ko", route: "PrivacyPz" })
        }
    }
};
</script>