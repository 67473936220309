<template>
    <section>
        <div class="bg-yellow text-center p-6">
            <h3 class="font-semibold text-xl text-gray-700">Seleziona la cifra che vuoi ricevere</h3>
            <!-- <h2 class="text-6xl font-bold text-gray-900 leading-tight mx-auto">{{ choosenCredit|num }}€</h2> -->
            <select id="credit-select" class="home-select w-56 border-0 py-1 mt-3" v-model="choosenCredit" @change="activeSolutionIndex = $event.target.selectedIndex">
                <option v-for="price in prices" :class="{'hidden': price.imp_finanz > maxCredit}" :key="price.imp_finanz" :value="price.imp_finanz">
                    <span v-if="price.imp_finanz > 0">{{ price.imp_finanz|num }}€</span>
                    <span v-else>Solo attivazione</span>
                </option>
            </select>
        </div>

        <div class="text-center py-6">
            <Steps :count="steps"></Steps>
            <h3 class="text-3xl font-semibold text-gray-900 leading-tight mx-auto mt-2">Controlla i tuoi dati</h3>
        </div>

        <div class="bg-white p-6">
            <div class="max-w-4xl xl:max-w-6xl mx-auto flex flex-wrap">
                <label class="w-1/4 text-lg text-gray-900 font-medium">Cognome</label>
                <span class="w-3/4 text-lg text-gray-700 font-semibold uppercase">{{ surname }}</span>
                <label class="w-1/4 text-lg text-gray-900 font-medium mt-2">Nome</label>
                <span class="w-3/4 text-lg text-gray-700 font-semibold uppercase mt-2">{{ name }}</span>
                <label class="w-1/4 text-lg text-gray-900 font-medium mt-2">IBAN</label>
                <span class="w-3/4 text-lg text-gray-700 font-semibold uppercase mt-2">{{ maskedIban }}</span>
            </div>
        </div>

        <div class="bg-yellow text-center p-6">
            <p class="text-center font-semibold text-xl text-gray-900 leading-none">Verifica che la tua e-mail sia corretta oppure inseriscila.</p>
            <div class="sm:w-96 text-center mx-auto mt-4">
                <div class="relative">
                    <input type="email" name="email" class="w-full py-2 pl-4 pr-10 text-lg" v-model="email" v-mask="{ placeholder: '', greedy: false, casing: 'lower' }"
                           placeholder="Se non appare la tua email, inseriscila" :class="{ 'border border-red': $v.email.$error }">
                    <i class="icon pencil absolute top-3 right-2"></i>
                </div>
                <div class="w-full text-red text-left" v-if="$v.email.$error">
                    <small v-if="!$v.email.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                    <small v-else-if="!$v.email.email">
                        {{ $t("Formato e-mail non valido") }}
                    </small>
                </div>
            </div>
        </div>

        <div class="bg-white p-6">
            <div class="max-w-4xl xl:max-w-6xl mx-auto flex flex-wrap">
                <span><strong class="font-bold">Perché conosciamo i tuoi dati?</strong>
                    La risposta è <strong class="font-bold">semplice</strong> e <strong class="font-bold">trasparente</strong>:
                    hai già una delle soluzioni di credito con Cofidis</span>
            </div>
        </div>

        <!-- <div class="text-center pt-6 mb-4">
            <h1 class="text-3xl font-semibold text-gray-900 leading-tight mx-auto">Completa i tuoi dati</h1>
        </div> -->

        <div class="w-full xl:w-2/3 mx-auto mt-6 mb-40 px-6 py-3 text-gray-900">
            
            <div class="w-full">
                <p class="font-semibold text-xl text-gray-900">Percepisci un reddito mensile dimostrabile superiore a 700€?</p>
                <p class="mt-2">Non ti chiediamo documenti di reddito perché ci fidiamo della tua parola.</p>
            </div>

            <div class="w-full mt-4 mb-2 flex justify-center">
                <input type="radio" class="invisible absolute" id="income_check_yes" :value="true" v-model="incomeCheck" />
                <label for="income_check_yes" class="h-10 w-12 flex border border-black mr-16 rounded cursor-pointer" :class="{'bg-yellow': incomeCheck}">
                    <span class="m-auto text-lg font-semibold uppercase">{{ $t("sì") }}</span>
                </label>
                <input type="radio" class="invisible absolute" id="income_check_no" :value="false" v-model="incomeCheck" />
                <label for="income_check_no" class="h-10 w-12 flex border border-black rounded cursor-pointer" :class="{'bg-yellow': incomeCheck == false}">
                    <span class="m-auto text-lg font-semibold uppercase">{{ $t("no") }}</span>
                </label>
            </div>
            <div class="h-6 w-full text-red" v-if="$v.incomeCheck.$error">
                <span class="font-semibold" v-if="!$v.incomeCheck.required">
                    <!-- {{ $t("Campo obbligatorio") }} -->
                    Per continuare è necessario selezionare "SI" o "NO".
                </span>
            </div>
            <div class="h-6 w-full font-semibold" v-if="incomeCheck == false">
                    Cliccando su CONTINUA dichiari che il tuo reddito mensile è inferiore a 700€.
            </div>
        </div>

        <div class="form-footer">
            <button @click="submit()">CONTINUA</button>
        </div>
    </section>
</template>
<script>
import Steps from '@/components/Steps'

import { mapUserData } from "@/utils";
import { required, email } from "vuelidate/lib/validators";
import loadData from "@/dataService.js";

export default {
    components: { Steps },
    mounted() {
        this.$ga.pageview()
    },
    data() {
        return {
            maxCredit: loadData("price.json").default_credit,
            activeSolutionIndex: 0,
            prices: loadData("price.json").prices.reverse(),
            incomeCheck: null
        };
    },
    async created() {
        this.$store.commit("setChoosenCredit", this.selectValue);
        this.incomeCheck = this.income_check
    },
    computed: {
        ...mapUserData([
            "name",
            "surname",
            "iban",
            "email",
            "monthly_income",
            "document_expired",
            "income_check"
        ]),
        steps() {
            return this.document_expired ? 3 : 2
        },
        selectValue() {
            return this.choosenCredit ? this.choosenCredit : this.maxCredit
        },
        maskedIban() {
            return `${this.iban[0]}${this.iban[1]}****************${this.iban.slice(-4)}`
        },
        choosenCredit: {
            get() {
                return this.$store.state.choosenCredit;
            },
            set(val) {
                this.$store.commit("setChoosenCredit", val);
            }
        },
        numInstallments() {
            return this.prices[this.activeSolutionIndex].rate
        }
    },
    validations: {
        email: {
            required,
            email
        },
        incomeCheck:{
            required
        },
    },
    methods: {
        submit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                //this.$store.dispatch("event", { name: "ConsolidamentoEmail" });
                this.$store.commit("setUserData", { key: 'can_request', value: this.incomeCheck });
                this.$store.commit("setUserData", { key: 'income_check', value: this.incomeCheck });
                this.$store.commit("setUserData", { key: 'importo', value: this.choosenCredit });
                this.$store.commit("setUserData", { key: 'monthly_income', value: this.incomeCheck ? 701 : 0 });
                this.$store.dispatch("sendData", { step: "dati_lav" });
                if(this.incomeCheck) {
                    if(this.document_expired)
                        this.$store.dispatch("redirect", {step: "income", route: "Documents"});
                    else
                        this.$store.dispatch("redirect", {step: "income", route: "PrivacyPowerCredit"});
                }
                else {
                    this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
                }
            }
        }
    }
};
</script>