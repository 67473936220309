<template>
    <div>
        <input type="hidden" v-model="value">
        <div class="flex">
            <select v-model="dayValue" v-if="showDay" :class="inputClass" class="form-select mr-2 border" @change="setValue()" :disabled="disabled" style="height:50px">
                <option v-for="day in days" :value="day" :disabled="!day" :key="day">{{ day ? day : $t("GIORNO") }}</option>
            </select>
            <select v-model="monthValue" v-if="showMonth" :class="inputClass" class="form-select mr-2 border" @change="setValue()" :disabled="disabled" style="height:50px">
                <option v-for="month in months" :value="month" :disabled="!month" :key="month">{{ month ? month : $t("MESE") }}</option>
            </select>
            <select v-model="yearValue" v-if="showYear" :class="inputClass" class="form-select border" @change="setValue()" :disabled="disabled" style="height:50px">
                <option v-for="year in years" :value="year" :disabled="!year" :key="year">{{ year ? year : $t("ANNO") }}</option>
            </select>
        </div>
    </div>
</template>
<script>
import moment from "moment";

export default {
    props: {
        showDay: {
            default: true,
            type: Boolean,
            required: false
        },
        showMonth: {
            default: true,
            type: Boolean,
            required: false
        },
        showYear: {
            default: true,
            type: Boolean,
            required: false
        },
        value: {
            default: null,
            required: false
        },
        startYear: {
            default: 1970,
            type: Number,
            required: false
        },
        endYear: {
            default: moment().year(),
            type: Number,
            required: false
        },
        inputClass: {
            default: null,
            type: Object,
            required: false
        },
        disabled: {
        	default: false,
        	type: Boolean,
        	required: false
        }
    },    
    data() {
    	return {
    		dayValue: 0,
    		monthValue: 0,
    		yearValue: 0
    	}
    },
    mounted() {
    	// get dayValue
        if (this.value && this.value.toString().split('/').length == 3) {
            this.dayValue = this.value.toString().split('/')[0]
        }
        // get monthValue
        if (this.value && this.value.toString().split('/').length == 3) {
            this.monthValue = this.value.toString().split('/')[1]
        }
        else if (this.value && this.value.toString().split('/').length == 2) {
            this.monthValue = this.value.toString().split('/')[0]
        }        
        // get yearValue
        if (this.value && this.value.toString().split('/').length == 3) {
            this.yearValue = this.value.toString().split('/')[2]
        }
        else if (this.value && this.value.toString().split('/').length == 2) {
            this.yearValue = this.value.toString().split('/')[1]
        }
        else if (this.value) {
            this.yearValue = this.value
        }
    },
    computed: {
        days() {
            let arr = [0];
            for (let i = 1; i <= 31; i++) arr.push(i < 10 ? "0" + i : String(i));
            return arr;
        },
        months() {
            let arr = [0];
            for (let i = 1; i <= 12; i++) arr.push(i < 10 ? "0" + i : String(i));
            return arr;
        },
        years() {
            let arr = [0];
            for (let i = this.startYear; i <= this.endYear; i++) arr.push(i);
            return arr;
        }
    },
    methods: {
    	reset() {
    		this.dayValue = 0
    		this.monthValue = 0
    		this.yearValue = 0
    	},
    	setValue() {    		
    		if(this.showDay && this.showMonth && this.showYear) {
    			if(this.dayValue && this.monthValue && this.yearValue) 
    				this.$emit('change', [this.dayValue,this.monthValue,this.yearValue].join('/'))
    		}
    		else if(this.showMonth && this.showYear) {
    			if(this.monthValue && this.yearValue) 
    				this.$emit('change', [this.monthValue,this.yearValue].join('/'))
    		}
    		else if(this.showYear && this.yearValue) {
    			this.$emit('change', this.yearValue)
    		}
    	}
    }
}
</script>