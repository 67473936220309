<template>
    <div class="steps-wrapper flex flex-wrap justify-center w-full">
        <span class="step" v-for="i in count" :key="i" v-html="i" :class="{'active': i <= active, 'next': i == active + 1}"></span>
    </div>
</template>
<style>
.steps-wrapper .step {
    color: #d1d1d1;
    display: flex;
}
.steps-wrapper .step.active {
    @apply text-gray-900
}
.steps-wrapper .step::before {
    content: '';
    margin: 12px 5px 0;
    width: 30px;
    height: 2px;
    border: none;
    display: block;
    background-image: linear-gradient(90deg, #d1d1d1, #d1d1d1);
}
.steps-wrapper .step.next::before {
    background-image: linear-gradient(90deg, #252525, #fff);
}
.steps-wrapper .step.active::before {
    background-image: linear-gradient(90deg, #000, #000)
}
.steps-wrapper .step:first-child:before {
    content: none;
}
</style>
<script>
export default {
    props: {
        count: {
            type: Number,
            required: true,
            default: null
        },
        active: {
            type: Number,
            required: false,
            default: 1
        }
    }
};
</script>