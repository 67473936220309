import { extractFiscalCode } from "@/utils";

class Append {
    enter_landing(payload, {state}) {
        payload['CF'] = state.userData.fiscal_code
        payload['Iniz'] = state.userData.iniz
        payload['PianoF'] = state.userData.piano_f
        payload['userId'] = state.userData.user_id
        payload['codeCampaign'] = state.userData.code_campaign
        payload['StampaContratto'] = true
    }

    dati_anag(payload) {
        delete(payload['EMail'])
        delete(payload['CF'])
        payload['stepNumber'] = 0
    }

    rata(payload, {state}) {
        payload['Importo'] = state.userData.importo
        payload['Rate'] = state.userData.rate
    }

    dati_lav(payload, {state}) {
        delete(payload['Mensilita'])
        payload['EMail'] = state.userData.email
        payload['stepNumber'] = 1
        // convert yyyy to mm/yyyy if needed
        if(payload['Occupato_Dal']?.toString().length === 4)
            payload['Occupato_Dal'] = `01/${payload['Occupato_Dal']}`
    }

    dati_pers_4(payload) {
        payload['stepNumber'] = 2
    }
    
    promo_offer(payload, {state}) {
        payload['userId'] = state.userData.user_id
        payload['codeCampaign'] = state.userData.code_campaign
    }

    promo(payload, {state}) {
        if(!state.userData.promoUpgraded) 
            payload.PianoF = 'X40'
        else
            payload.StampaContratto = true
    }

    assicurazione_metlife(payload) {
        payload.init = true
        payload.codeCampaign = localStorage.getItem('fastcredit_code_campaign')
        payload.userId = localStorage.getItem('fastcredit_user_id') 
    }

    assicurazione_metlife_warning(payload) {
        payload.init = true
        payload.codeCampaign = localStorage.getItem('fastcredit_code_campaign')
        payload.userId = localStorage.getItem('fastcredit_user_id') 
    }

    dati_metlife_1(payload, {state}) {
        const phone = state.userData.cellphone.substring(3).replace(/ +/g, "")
        const prv = /\(([^)]+)\)/.exec(state.userData.address_city);
        const cap = '0'.repeat(5 - state.userData.address_cap.toString().length) + state.userData.address_cap.toString()
        const matches = state.userData.address.match(/^(.*\S)\s+(\d+[A-Z]?)$/);
        
        payload['Indirizzo'] = matches[1].trim()
        payload['N'] = matches[2].trim()
        payload['Phone'] = phone
        payload['CAP'] = cap
        payload['Comune'] = state.userData.address_city
        payload['Prov'] = prv ? prv[1] : null
        payload['City'] = state.userData.address_city.slice(0, -5)
    }

    dati_metlife_2(payload, {state}) {
        const cf = extractFiscalCode(state.userData.fiscal_code);

        payload['Sex'] = cf.gender
        payload['Birthday_Day'] = cf.day
        payload['Birthday_Month'] = cf.month
        payload['Birthday_Year'] = cf.year
        payload['stepNumber'] = 3
    }

    dati_privacy(payload, {state}) {
        payload['init'] = true
        payload['userId'] = state.userData.user_id
        payload['codeCampaign'] = state.userData.code_campaign
        payload['stepNumber'] = state.userData.document_expired ? 3 : 2
        payload['Esito'] = !state.userData.can_request ? 'reddito_not_allowed' : null
    }

}
export default new Append();
