import loadData from "@/dataService";
import store from "./store";
import i18n from './i18n'

// Load data dinamically get theme config files
const config = loadData("config.json");
const activeTheme = window.activeTheme || process.env.VUE_APP_THEME || "prestito";
const urlVars = (window.baseUrl || window.location.href).split('?');

require(`@/assets/css/style.css`);
require(`@/views/themes/${activeTheme}/css/style.css`);

// save url parts in store
const urlParts = window.location.pathname.split('/').filter(Boolean)
store.commit("setUrlParts", urlParts);

// save url query params in store
if(urlVars.length == 2) {  
  const vars = {}
  urlVars[1].split('&').map(v => { 
    const chunks = v.split("=") 
    vars[chunks[0]] = chunks[1] ?? true
  })
  store.commit("setUrlVars", vars)
  // enable/disable dev test mode
  if(vars.dev && vars.dev == 1) { console.log('Data Dev Mode enabled'); store.commit("loadTestData") }
  if(vars.dev && vars.dev == 0) { console.log('Data Dev Mode disabled'); store.commit("loadEmptyData") }
}

// localization
if(config.locales) {
  if(urlParts[1] && config.locales.includes(urlParts[1]) ) {
    i18n.locale = urlParts[1].toLowerCase()
  } else {
    i18n.locale = config.locales[0]
  }
  store.state.lang = i18n.locale
}

/** Set custom Meta */
document.title = i18n.t(config.meta.title);
document.getElementById("jsonld").innerHTML = JSON.stringify(config.meta.jsonld);
document
  .querySelector('meta[name="description"]')
  .setAttribute("content", i18n.t(config.meta.description));

if(config.favicon)
  document.getElementById("favicon").href = `/${config.favicon}`;

if(config.meta.robots)
  document
    .querySelector('meta[name="robots"]')
    .setAttribute("content", config.meta.robots);

// Precompiled access
if(config.precompiled && config.precompiled.enabled) {  
  if(urlParts[0] == config.precompiled.param && urlParts[2]) {
    // disable login
    store.commit('setProtected', false) 
    // set as precompiled
    store.commit('setPrecompiled', true)    
    const payload = {url: [store.state.serverUrl, urlParts[2], 'banche'].join('/')}
    store.dispatch("loadPrecompiledData", payload)
        .then((data) => {
            for(var key in data) {              
              if(config.precompiled.mapping[key])
                store.commit('setUserData', { key: config.precompiled.mapping[key], value: data[key] })
            }            
        })
  }
}