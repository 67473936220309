<template>
	<div class="label-floating" :class="{ 'border-red': error,'border-gray-700': focusInput == name }" >                 
	    <select :id="id" class="form-select" @focus="setFocusInput(name)" @blur="unsetFocusInput()" v-bind:value="value" v-on:input="$emit('input', valueType == 'Number' ? parseInt($event.target.value) : $event.target.value)"
        :class="{'selected': value != items[0].key}">
	        <option v-for="(item, i) in items" :key="item[itemKey]" :disabled="(disableZeroValue && i == 0) || item.disabled" :value="item[itemKey]">{{ $t(item[itemValue]) }}</option>
	    </select>
	    <label>{{ $t(label) }}</label>
	</div>
</template>
<script>
export default {
	props: {
        id: {
            type: String,
            required: false
        },
        name: {
        	type: String,
            required: true
        },
        value: {
        	required: true
        },
        items: {
            type: Array,
            required: true
        },
        itemKey: {
            type: String,
            required: false,
            default: 'key'
        },
        itemValue: {
            type: String,
            required: false,
            default: 'value'
        },
        valueType: {
            type: String,
            required: false,
            default: 'Number'
        },
        label: {
            type: String,
            required: true
        },
        error: {
        	type: Boolean,
        	required: false,
        	default: false
        },
        disableZeroValue: {
        	type: Boolean,
        	required: false,
        	default: false
        },
        
    }
}
</script>