<template>
    <main>
        <div v-if="showDebtSavingPercentage" class="bg-green-500 text-center lg:text-left flex flex-wrap items-center justify-center py-3 px-2 sm:px-12">
            <h1 class="text-center text-base sm:text-3xl font-semibold text-white leading-tight px-3 sm:px-6 relative">
                <span>Affrettati! Puoi ridurre la tua rata fino a {{ debtSavingPercentage }}%</span>
            </h1>
            <h3 class="text-xl sm:text-2xl text-white md:text-3xl text-gray-5 relative">
                <a class="cursor-pointer inline-block w-6 h-6 sm:w-8 sm:h-8 rounded-full bg-white text-green-500 font-bold text-center text-lg sm:text-2xl leading-6" @click="tooltip1 = true">?</a>
                <Tooltip @hide="tooltip1 = false" :visible="tooltip1" class="absolute top-4 sm:top-10 right-10 sm:right-1/2 sm:transform sm:translate-x-1/2 text-black-50">La variazione percentuale della rata è
                    un valore percentuale che esprime la differenza tra il valore finale e quello iniziale in termini percentuali, considerando come valore di riferimento
                    quello iniziale.</Tooltip>
            </h3>
        </div>

        <section class="section-summary">
            <div class="text-center my-6">
                <h3 class="text-xl sm:text-2xl text-white md:text-3xl text-gray-5 relative">
                    <span class="font-semibold">La tua situazione</span>
                    <a class="ml-3 cursor-pointer inline-block w-8 h-8 rounded-full bg-gray-6 text-white font-bold text-center text-2xl" @click="tooltip2 = true">?</a>
                    <Tooltip @hide="tooltip2 = false" :visible="tooltip2" class="absolute top-4 sm:-top-6 right-1/2 transform translate-x-1/2">La tua situazione è calcolata in base ai dati che hai inserito nella schermata precedente</Tooltip>
                </h3>
            </div>

            <div class="bg-white text-left flex flex-wrap lg:flex-no-wrap items-center py-6 px-4 sm:px-12">
                <div class="sm:max-w-4xl mx-auto">
                    <p v-if="showAverageApr" class="text-base sm:text-lg font-light text-gray-4 mb-4 w-80 sm:w-96">Tasso medio ponderato* <span class="float-right">{{ averageApr|num2 }}%</span></p>
                    <p class="text-base sm:text-lg font-light text-gray-4 mb-4 w-80 sm:w-96">Debito residuo totale <span class="float-right">{{ totalDebt|eur }}</span></p>
                    <p class="text-base sm:text-lg font-light text-gray-4 w-80 sm:w-96">Somma delle rate che stai pagando <span class="float-right">{{ totalInstallment|eur }}</span></p>
                </div>
            </div>
        </section>

        <!-- Section steps -->
        <section class="section-steps pt-10 pb-0 sm:py-10">
            <div class="container mx-auto max-w-7xl">
                <!-- Title -->
                <div class="text-center mb-6 sm:mb-14">
                    <h3 class="font-semibold text-xl sm:text-2xl text-white md:text-3xl text-gray-5">
                        Ora scegli la soluzione che fa per te
                    </h3>
                </div>
                <!-- Steps -->
                <div class="sm:mb-6 flex flex-wrap bg-gray-1 mt-10 sm:mt-16">
                    <div v-if="showPayLess" class="text-center px-2 mb-20 w-full sm:w-1/2 sm:px-4 lg:w-1/4 lg:flex lg:flex-1">
                        <div class="bg-white w-full text-center bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 h-48 sm:h-60 relative pt-9 sm:pt-14">
                            <div class="absolute -top-6 left-0 w-full text-center">
                                <h3 class="w-60 sm:w-40 slanted px-0 sm:px-4 uppercase mx-auto">rata leggera</h3>
                            </div>

                            <div class="w-full text-xl font-light text-gray-4 text-center mx-auto">
                                <p class="w-full border-b border-gray-3 pb-2 sm:pb-4">
                                    <span>Rata</span>
                                    <span class="font-semibold ml-1">{{ payLess.imp_rata|eur }}</span>
                                    <span v-if="totalInstallment - payLess.imp_rata > 0" class="font-semibold text-red line-through ml-1">{{ totalInstallment|eur }}</span>
                                </p>
                                <p class="w-full pt-2 sm:pt-4 text-base md:text-lg">
                                    <span class="font-semibold ml-1">{{ payLess.erogato|num }}</span>
                                    <span class="ml-1">in</span>
                                    <span class="font-semibold ml-1">{{ payLess.rate }}</span>
                                    <span class="ml-1">mesi TAEG</span>
                                    <span class="font-semibold ml-1">{{ payLess.taeg|num }}%</span>
                                    <a class="underline uppercase ml-6" target="_blank" :href="secciUrl(payLess)">SECCI</a>
                                </p>
                            </div>

                            <div class="absolute bottom-10 left-0 w-full text-center bg-gray-1">
                                <span class="text-red text-lg font-semibold py-1 block">Riduci {{ (totalInstallment - payLess.imp_rata)|eur }}/mese</span>
                            </div>

                            <div class="absolute -bottom-10 left-0 text-center w-full">
                                <button class="submit" @click="requestLoan(payLess,'Rata leggera')">richiedi</button>
                            </div>
                        </div>
                    </div>

                    <div v-if="borrowMore" class="text-center px-2 mb-20 w-full sm:w-1/2 sm:px-4 lg:w-1/4 lg:flex lg:flex-1">
                        <div class="bg-white w-full text-center bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 h-48 sm:h-60 relative pt-9 sm:pt-14">
                            <div class="absolute -top-6 left-0 w-full text-center">
                                <h3 class="w-60 sm:w-40 slanted px-0 sm:px-4 uppercase mx-auto">più liquidità</h3>
                            </div>

                            <div class="w-full text-xl font-light text-gray-4 text-center mx-auto">
                                <p class="w-full border-b border-gray-3 pb-2 sm:pb-4">
                                    <span>Rata</span>
                                    <span class="font-semibold ml-1">{{ borrowMore.imp_rata|eur }}</span>
                                    <span v-if="totalInstallment - borrowMore.imp_rata > 0" class="font-semibold text-red line-through ml-1">{{ totalInstallment|eur }}</span>
                                </p>
                                <p class="w-full pt-2 sm:pt-4 text-base md:text-lg">
                                    <span class="font-semibold ml-1">{{ borrowMore.erogato|num }}</span>
                                    <span class="ml-1">in</span>
                                    <span class="font-semibold ml-1">{{ borrowMore.rate }}</span>
                                    <span class="ml-1">mesi TAEG</span>
                                    <span class="font-semibold ml-1">{{ borrowMore.taeg|num }}%</span>
                                    <a class="underline uppercase ml-6" target="_blank" :href="secciUrl(borrowMore)">SECCI</a>
                                </p>
                            </div>

                            <div class="absolute bottom-10 left-0 w-full text-center bg-gray-1">
                                <span v-if="totalInstallment - borrowMore.imp_rata > 0" class="text-red text-base sm:text-lg font-semibold py-1 block">Riduci {{ (totalInstallment - borrowMore.imp_rata)|eur }}/mese e hai {{ 5000|num }} € in più</span>
                                <span v-else-if="borrowMore.erogato - roundedDebt > 0" class="text-red text-base sm:text-lg font-semibold py-1 block">Hai {{ (borrowMore.erogato - roundedDebt)|num }} € in più</span>
                            </div>

                            <div class="absolute -bottom-10 left-0 text-center w-full">
                                <button class="submit" @click="requestLoan(borrowMore,'Più liquidità')">richiedi</button>
                            </div>
                        </div>
                    </div>

                    <div v-if="showOnDemand" class="text-center px-2 mb-20 w-full sm:w-1/2 sm:px-4 lg:w-1/4 lg:flex lg:flex-1">
                        <div class="bg-white w-full text-center bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 h-48 sm:h-60 relative pt-9 sm:pt-14">
                            <div class="absolute -top-6 left-0 w-full text-center">
                                <h3 class="w-60 sm:w-40 slanted px-0 sm:px-4 uppercase mx-auto">su misura</h3>
                            </div>

                            <div class="w-full text-xl font-light text-gray-4 text-center mx-auto">
                                <p class="w-full border-b border-gray-3 pb-2 sm:pb-4">
                                    <span>Rata</span>
                                    <span class="font-semibold ml-1">{{ onDemand.imp_rata|eur }}</span>
                                    <span v-if="totalInstallment - onDemand.imp_rata > 0" class="font-semibold text-red line-through ml-1">{{ totalInstallment|eur }}</span>
                                </p>
                                <p class="w-full pt-2 sm:pt-4 text-base">
                                    <select class="form-select inline-block w-24 sm:w-28 h-9 sm:h-10" v-model="onDemandIndex">
                                        <option v-for="(item, key) in onDemandItems" :key="key" :value="key">{{ item.erogato|num }} €</option>
                                    </select>
                                    <span class="ml-1">in</span>
                                    <span class="font-semibold ml-1">{{ onDemand.rate }}</span>
                                    <span class="ml-1">mesi TAEG</span>
                                    <span class="font-semibold ml-1">{{ onDemand.taeg|num }}%</span>
                                    <a class="underline uppercase ml-2" target="_blank" :href="secciUrl(onDemand)">SECCI</a>
                                </p>
                            </div>

                            <div class="absolute bottom-8 sm:bottom-10 left-0 w-full text-center bg-gray-1">
                                <span v-if="totalInstallment - onDemand.imp_rata > 0" class="text-red text-base sm:text-lg font-semibold py-1 block">Riduci {{ (totalInstallment - onDemand.imp_rata)|eur }}/mese e hai {{ (onDemand.erogato - roundedDebt)|num }} € in più</span>
                                <span v-else-if="onDemand.erogato - roundedDebt > 0" class="text-red text-base sm:text-lg font-semibold py-1 block">Hai {{ (onDemand.erogato - roundedDebt)|num }} € in più</span>
                            </div>

                            <div class="absolute -bottom-10 left-0 text-center w-full">
                                <button class="submit" @click="requestLoan(onDemand,'Su misura')">richiedi</button>
                            </div>
                        </div>
                    </div>
                </div>
                <p v-if="showAverageApr" class="px-6 text-sm sm:text-base mb-10">* Il tasso medio ponderato è calcolato con le stesse modalità del TAN, è una stima e può essere diverso dalla realtà per eventuali arrotondamenti nei calcoli e/o per dati non corretti. Il tasso medio ponderato è calcolato sulla base dei dati che hai inserito ed è una media del TAN pesata (ponderata appunto) a seconda dell'importo dei prestiti finanziati.</p>
            </div>
        </section>
    </main>
</template>
<script>
import Tooltip from "@/components/Tooltip";
import Calculator from "@/views/themes/consolidamento/ext/calculator";
import { mapUserData } from "@/utils";

export default {
    data() {
        return {
            Calculator,
            capLoan: Calculator.capLoan(),
            onDemandIndex: 0,
            tooltip1: false,
            tooltip2: false
        }
    },
    components: { Tooltip },
    computed: {
        ...mapUserData([
            "rate",
            "importo",
            "loans_count",
            "loans_data"
        ]),
        debtSavingPercentage() {
            return Math.abs(
                parseFloat(
                    (this.payLess.imp_rata - this.totalInstallment) / this.totalInstallment * 100
                ).toFixed(0)
            )
        },
        showDebtSavingPercentage() {
            return this.debtSavingPercentage >= 5 && this.payLess && this.totalInstallment - this.payLess.imp_rata > 0
        },
        totalDebt() {
            return Calculator.totalDebt(this.loans_data)
        },
        averageApr() {
            return Calculator.averageApr(this.loans_data)
        },
        showAverageApr() {
            return this.averageApr > Math.min(this.payLess?.tan, this.borrowMore?.tan, this.onDemandItems[0]?.tan)
        },
        totalInstallment() {
            return Calculator.totalInstallment(this.loans_data)
        },
        roundedDebt() {
            return Math.ceil(this.totalDebt/1000) * 1000
        },
        payLess() {
            let debt = Math.min(this.roundedDebt, this.capLoan)
            let item = null
            while(!item && debt <= this.capLoan) {
                item = this.getAvailableSolutions(debt)[0] ?? null
                debt += 1000
            }
            return item
        },
        showPayLess() {
            return this.payLess && this.totalInstallment - this.payLess.imp_rata > 0
        },
        borrowMore() {
            let debt = Math.min(this.roundedDebt + 5000, this.capLoan)
            let item = null
            while(!item && debt <= this.capLoan) {
                item = this.getAvailableSolutions(debt)[0] ?? null
                debt += 1000
            }
            return item
        },
        onDemandItems() {
            let debt = Math.min(this.roundedDebt + 12000, this.capLoan)
            let items = []
            while(debt <= this.capLoan) {
                let item = this.getAvailableSolutions(debt)[0] ?? null
                items.push(item)
                debt += 1000
            }
            return items
        },
        onDemand() {
            return this.onDemandItems[this.onDemandIndex] ?? null
        },
        showOnDemand() {
            return this.onDemand && this.borrowMore && this.borrowMore.erogato < this.capLoan
        },
        baseUrl() {
            return this.$store.state.secciUrl
        }
    },
    methods: {
        secciUrl(model) {
            return `${this.baseUrl}${model.imp_finanz}_${model.rate}.pdf`
        },
        requestLoan(item, name) {
            this.rate = item.rate
            this.importo = item.erogato
            this.$store.commit("setChoosenRata", item.rate);
            this.$store.commit("setChoosenCredit", item.erogato);
            this.$store.commit("setChoosenSolution", name);
            this.$store.commit("setPrecompiled", true);
            this.$store.dispatch("sendData", { step: "dati_anag" });
            this.$store.dispatch("event", { name: "ConsolidamentoSoluzioni" });
            this.$store.dispatch("redirect", { step: "solutions", route: "Contatti" });
        },
        getAvailableSolutions(debt) {
            const simulator = [...this.$store.state.prices]
            let items = [];
            simulator.reverse().map(item => {
                if(parseInt(item.erogato) === parseInt(debt)) {
                    items.push(item);
                }
            })
            return items
        }
    }
}
</script>