<template>
    <section class="section-contest py-6 sm:py-12">
        <div class="md:container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl md:flex">
            <div class="w-full lg:w-1/3 flex items-center justify-center md:justify-start pl-4 py-6 md:py-8">
                <img alt="Amazon buono regalo" class="transform rotate-6 w-44 md:w-64" src="@/assets/images/Amazon_buono_regalo.svg" />
            </div>
            <div class="w-full lg:w-2/3 pt-8 md:pt-2 pb-8 md:pb-0">
                <h3 class="ml-4 text-3xl md:text-4xl font-bold text-red-1">Vinci 1.000€ con CLUB!</h3>
                <div class="mx-4 mt-8 lg:mt-10 lg:mr-0">
                    <p class="text-lg md:text-xl">
                        Grazie a CLUB accedi a un <strong class="font-semibold">mondo di sconti</strong> e <strong class="font-semibold">vantaggi</strong> esclusivi  per shopping, tempo libero e salute.
                    </p>
                    <p class="text-lg mt-8 md:text-xl">
                        Richiedi una <strong class="font-semibold">soluzione di credito</strong>, aderisci a <strong class="font-semibold">CLUB</strong> e prova a vincere  un <strong class="font-semibold">buono Amazon</strong> dal valore di <strong class="font-semibold">1.000€</strong> in palio ogni mese!*
                    </p>
                    <slot></slot>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "ClubContest"
}
</script>