<template>
    <div class=" md:pt-2 pb-8 md:pb-4">
        <div class="progress-bar" :class="{'complete' : percentage === maxPercentage}">
            <Progress :percentage="percentage" ></Progress>
        </div>
        <h1 class="text-2xl md:text-3xl font-semibold text-center my-8 lg:my-11">Solo un'ultima conferma</h1>
        <form class="relative text-sm container mx-auto pl-5 pr-6 md:px-12 pb-8 text-gray-700 leading-6">
            <p class="text-base mb-4">
                Prima di procedere ti invitiamo a prendere visione del <a :href="rulesClubUrl" target="_blank" class="underline">Regolamento</a>
                che riporta nel dettaglio, le caratteristiche del prodotto, nonché l’<a :href="privacyClubUrl" target="_blank" class="underline">Informativa Privacy</a>. Il servizio CLUB è facoltativo.
            </p>
            <ConsentItemCheck v-model="consent_club1" :v="$v.consent_club1" :mustBeTrueError="mustBeTrueError">
                <span class="leading-6">
                    Ho letto e compreso le condizioni del servizio contenute nel <a :href="rulesClubUrl" target="_blank" class="underline">Regolamento contrattuale</a>, accettando integralmente le sue condizioni, compreso il tacito rinnovo al termine dei primi 12 mesi del servizio (passaggio alla formula mensilizzata).
                </span>
            </ConsentItemCheck>
            <ConsentItemCheck v-model="consent_club2" :v="$v.consent_club2" :mustBeTrueError="mustBeTrueError">
                <span class="leading-6">
                    Autorizzo: Quattrolinee s.r.l., con il codice identificativo Cofidis Club (Creditor information DE56AQJ00002197951) con sede in corso Vittorio Emanuele II 25, Torino, 10125, Italia, a richiedere tramite mandato SEPA Direct Debit (SDD), alla banca del sottoscritto l’addebito del proprio conto corrente fornito in occasione della richiesta di prestito; II) la banca del sottoscritto a procedere all’addebito ricorrente, secondo le disposizioni impartite da Quattrolinee s.r.l. Sono consapevole di aver diritto ad ottenere il rimborso dell’addebito in conto, secondo gli accordi ed alle condizioni che regolano il rapporto con la mia banca, richiedendolo entro 8 settimane a partire dalla data di addebito. In caso di modifica della data di addebito, frequenza o importo, ti verrà inviata una notifica almeno 3 giorni prima del successivo addebito. I pagamenti sono gestiti da GoCardless (vedi <a href="https://gocardless.com/privacy/payers" target="_blank" class="underline">privacy notice</a> GoCardless).
                </span>
            </ConsentItemCheck>
            <ConsentItemCheck v-model="consent_club3" :v="$v.consent_club3" :mustBeTrueError="mustBeTrueError">
                <span class="leading-6">
                    Ai sensi degli articoli 1341 e 1342 del Codice Civile dichiaro di avere attentamente esaminato e di accettare integralmente le clausole del Contratto riportate nel Regolamento contrattuale, di seguito elecante: <i>Art. 4 Obblighi delle Parti, Art. 5 Gestione Dati, Art. 6 Corrispettivo e modalità di pagamento, Art. 7 Responsabilità, Art. 8 Recesso, Art. 9 Risoluzione, Art. 10 Foro competente.</i>
                </span>
            </ConsentItemCheck>
            <ConsentItemCheck v-model="consent_club4" :v="$v.consent_club4" :mustBeTrueError="mustBeTrueError">
                <span class="leading-6">
                    Dichiaro di aver ricevuto e aver ben compreso l’<a :href="privacyClubUrl" target="_blank" class="underline">Informativa</a> fornita ai sensi dell’art. 13 del Regolamento UE 2016/679, dalla quale risulta che, nell’ambito dell’esecuzione delle operazioni e del servizio richiesto, si intendono perseguire le finalità di cui al punto 2.1. “Finalità Necessarie”. In particolare, dichiaro di aver ben compreso dalla predetta Informativa che è previsto il trattamento, da parte di Quattrolinee S.r.l., nella sua qualità di Titolare, dei miei dati personali.                
                </span>
            </ConsentItemCheck>
            <ClubOptionalConsents />
        </form>
        <StickyButton :clickHandler="submit">
            <span class="text-center text-xl font-semibold">CONFERMA</span>
        </StickyButton>
    </div>
</template>

<script>
import Progress from "@/components/Progress"
import StickyButton from "@/components/StickyButton.vue";
import ConsentItemCheck from "@/components/RequiredItemCheck.vue"
import { required } from "vuelidate/lib/validators"
import { mapUserData } from "@/utils";
import ClubOptionalConsents from "@/components/ClubOptionalConsents.vue";

const mustBeTrue = value => value === true;

const updateProgress = (val, number, increment) => {
    return val ? number + increment : number - increment;
}

export default {
    name: "Consents",
    components: { Progress, ConsentItemCheck, StickyButton, ClubOptionalConsents },
    data() {
       return {
            percentage: 83,
            increment: 4,
            maxPercentage: 99,
            rulesClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Regolamento_Servizio_CLUB.pdf',
            privacyClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Informativa_Servizio.pdf',
            mustBeTrueError: 'ATTENZIONE: non sarà possibile procedere all\'adesione al servizio'
       } 
    },
    computed: {
        ...mapUserData([
            "consent_club1",
            "consent_club2",
            "consent_club3",
            "consent_club4"
        ]),
    },
    validations: {
        consent_club1: {
            required,
            mustBeTrue
        },
        consent_club2: {
            required,
            mustBeTrue
        },
        consent_club3: {
            required,
            mustBeTrue
        },
        consent_club4: {
            required,
            mustBeTrue
        },
    },
    watch: {
        consent_club1(val) {
            this.percentage = updateProgress(val, this.percentage, this.increment);
        },
        consent_club2(val) {
            this.percentage = updateProgress(val, this.percentage, this.increment);
        },
        consent_club3(val) {
            this.percentage = updateProgress(val, this.percentage, this.increment);
        },
        consent_club4(val) {
            this.percentage = updateProgress(val, this.percentage, this.increment);
        }
    },
    methods: {
        async submit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                this.$store.commit("setUserData", { key: "service", value: 1 });
                this.$store.dispatch('subscribeClub');
                this.$store.dispatch("sendData", { step: "dati_pers_3", method: "POST" });
                this.$store.dispatch("redirect", { step: "homepage", route: "ThankYou" });
            }
        }
    }
}
</script>