import _ from "lodash";
import {extractFiscalCode, formatImpFinanz, formatPriceValues, formatPromo } from "@/utils";
import moment from "moment";
import loadData from "@/dataService";
import {JOB_TYPES, HIRING_TYPES, METLIFE_PRV, DWELLING_TYPES, CLUB_PLATINUM} from '@/constants.js';

const config = loadData("config.json");

export default {
  uniquePrices: state => {
    const ret = state.prices.reduce((red, val) => {
      if (!(val.imp_finanz in red)) {
        red[val.imp_finanz] = formatImpFinanz(val.imp_finanz);
        if(val.notes) red[val.imp_finanz] += `-${val.notes}`
      }
      return red;
    }, {});
    return Object.keys(ret)
      .reverse()
      .map(key => {
        return { value: parseInt(key), name: ret[key] };
      });
  },

  // v2 getter
  userPath:  (state, getters) => {
    const userData = state.userData
    const AGE = getters.userAge
    const REDDITO = userData.monthly_income ? userData.monthly_income.replace(/\D/g,'') : 0
    const IS = arr => arr.includes(userData.employment_type)
    const DIPENDENTE_DETERMINATO = [JOB_TYPES.DIPENDENTE].includes(userData.employment_type) && userData.hiring_type == HIRING_TYPES.DETERMINATO
    const DIPENDENTE_INDETERMINATO = [JOB_TYPES.DIPENDENTE].includes(userData.employment_type) && userData.hiring_type == HIRING_TYPES.INDETERMINATO
    const DIPENDENTE_DETERMINATO_FEEVO_PP = moment() > moment(userData.employed_since, 'MM/YYYY').add(3, 'months') && moment().add(state.choosenRata, 'months') <= moment(userData.employed_to, 'MM/YYYY')
    const PROFESSIONISTA_FEEVO_PC = moment() > moment(userData.employed_since, 'MM/YYYY').add(12, 'months')
    const PROFESSIONISTA_FEEVO_PP = moment() > moment(userData.employed_since, 'MM/YYYY').add(18, 'months')
    const DIPENDENTE_INDETERMINATO_FEEVO_PC = moment() > moment(userData.employed_since, 'MM/YYYY').add(6, 'months')
    const DIPENDENTE_INDETERMINATO_FEEVO_PP = moment() > moment(userData.employed_since, 'MM/YYYY').add(6, 'months')
    const AUTONOMO_FEEVO_PC = moment() > moment(userData.employed_since, 'MM/YYYY').add(18, 'months')
    const CREDIT_RISK = userData.financial_problems || (state.choosenCredit <= 2000 && AGE > 65) || (state.choosenCredit > 2000 && (AGE + userData.rate/12) > 80);

    // PrestitoPersonale + PowerCredit (with financial problems | overaged) path
    if(CREDIT_RISK) {
        if(IS([JOB_TYPES.PENSIONATO,JOB_TYPES.DIPENDENTE]) && REDDITO > 699) return 'CQ'
        else if(IS([JOB_TYPES.PENSIONATO_INVALIDO,JOB_TYPES.STAGIONALE,JOB_TYPES.AUTONOMO,JOB_TYPES.REDDITIERE,JOB_TYPES.PROFESSIONISTA]) && REDDITO > 699) return 'FAM'
    }
    // PrestitoPersonale path
    else if(state.choosenCredit > 2000) {
        if((DIPENDENTE_INDETERMINATO && DIPENDENTE_INDETERMINATO_FEEVO_PP && REDDITO > 699) || (DIPENDENTE_DETERMINATO && DIPENDENTE_DETERMINATO_FEEVO_PP && REDDITO > 699)) return 'FE'
        else if(IS([JOB_TYPES.PENSIONATO,JOB_TYPES.REDDITIERE]) && REDDITO > 699) return 'FE'
        else if(IS([JOB_TYPES.AUTONOMO,JOB_TYPES.PROFESSIONISTA]) && REDDITO > 699 && PROFESSIONISTA_FEEVO_PP) return 'FE'
        else if(IS([JOB_TYPES.PENSIONATO_INVALIDO,JOB_TYPES.STAGIONALE]) && REDDITO > 699) return 'FAM'
    }
    // PC (Revolving) path
    else {
        if(IS([JOB_TYPES.AUTONOMO]) && AUTONOMO_FEEVO_PC) return 'FE'
        else if(IS([JOB_TYPES.PENSIONATO,JOB_TYPES.REDDITIERE]) && REDDITO > 699) return 'FE'
        else if(IS([JOB_TYPES.PENSIONATO_INVALIDO,JOB_TYPES.STAGIONALE]) && REDDITO > 699) return 'FAM'
        else if(IS([JOB_TYPES.PROFESSIONISTA]) && PROFESSIONISTA_FEEVO_PC && REDDITO > 699) return 'FE'
        else if(DIPENDENTE_INDETERMINATO && DIPENDENTE_INDETERMINATO_FEEVO_PC && REDDITO > 699) return 'FE'
    }
    // default ML
    return 'ML'
  },

  userAge: (state, getters) => {
    const birthDate = [getters.fiscalCodeExtracted.year,getters.fiscalCodeExtracted.month,getters.fiscalCodeExtracted.day].join('-')
    return moment().diff(moment(birthDate,'YYYY-MM-DD'), 'years')
  },

  insuranceAvailable: (state, getters) => {
    const years = getters.userAge

    if(getters.userPath != 'FE') return false
    if (!config.insurance) return false
    if (!getters.fiscalCodeExtracted) return false
    if (!state.userData.rate) return false

    if (state.userData.importo <= 2000) return false
    if([JOB_TYPES.CASALINGA, JOB_TYPES.DISOCCUPATO, JOB_TYPES.REDDITIERE].includes(state.userData.employment_type)) return false

    return (years + 1 + state.userData.rate / 12) <= 65
  },

  metlifeAvailable: (state, getters) => {
    const years = getters.userAge
    const PRV = /\(([^)]+)\)/.exec(state.userData.address_city);

    const AGE = (years >= 18 && years < 74)
    const ML = (state.userData.monthly_income < 700 || (years < 50 && PRV && METLIFE_PRV.includes(PRV[1])))
    const CQ = (state.PATH == 'CQ')

    return AGE && (ML || CQ)
  },

  promoAvailable: (state) => {
    const userData = state.userData
    const RISK = userData.financial_problems
    const IMPORTO = userData.importo
    const REDDITO = userData.monthly_income ? userData.monthly_income.replace(/\D/g,'') : 0
    const DIPENDENTE_INDETERMINATO = [JOB_TYPES.DIPENDENTE].includes(userData.employment_type) && userData.hiring_type == HIRING_TYPES.INDETERMINATO
    const PROPRIETARIO_CASA = userData.dwelling_type == DWELLING_TYPES.PROPRIETARIO

    const employed_since = userData.employed_since ?? moment().format('Y');
    const HIRING_YEAR = parseInt(employed_since.length > 4 ? employed_since.slice(3) : employed_since);
    const MIN_HIRING_YEAR = parseInt(moment().subtract(10, 'years').format('Y'))

    if (state.baseUrl == 'fs') {
      return IMPORTO === state.promo && REDDITO >= 2500 && DIPENDENTE_INDETERMINATO && HIRING_YEAR <= MIN_HIRING_YEAR
    }

    return !RISK && IMPORTO === state.promo && REDDITO >= 2500 && DIPENDENTE_INDETERMINATO && PROPRIETARIO_CASA
  },

  isFeevo: (state) => {
    return state.PATH == 'FE' && state.userData.importo > 2000
  },

  clubPlusAvailable: (state, getters) => {
    const years = getters.userAge
    return (years >= 18 && years < 74)
  },

  insuranceRate: (state, getters) => {
    let multiplier = 0.00169
    if (getters.insurancePackage == "D") multiplier = 0.00048
    if (getters.insurancePackage == "E") multiplier = 0.000613

    // ceil 2 decimal
    return (Math.ceil(parseFloat(state.userData.importo) * multiplier * 10) / 10).toFixed(2)
  },

  insurancePackage: state => {
    if (state.userData.rate <= 24) return "D";

    if (state.userData.employment_type == 2) {
      if (state.userData.employment_sector == 4) {
        if (
          state.userData.occupation !== 2 &&
          state.userData.hiring_type == 2
        ) {
          return "G";
        }
      }
      if (
        [1, 2, 3, 5, 7, 10].indexOf(state.userData.employment_sector) !== -1
      ) {
        if (
          (state.userData.occupation !== 1 &&
            state.userData.hiring_type == 2) ||
          state.userData.occupation == 2
        ) {
          return "G";
        }
      }
      if (state.userData.employment_sector == 8) {
        if (
          state.userData.occupation == 4 ||
          (state.userData.hiring_type == 2 && state.userData.occupation !== 1)
        ) {
          return "G";
        }
      }
    }
    return "E";
  },

  rateOffers: (state) => ({ importo, justone = true }) => {
    {
      const res = _.orderBy(state.prices, ["rate"], ["desc"]);
      const ret = _.reduce(
        res,
        (red, val) => {
          if (val.imp_finanz === importo) {
            if (justone === true && red.length > 0) return red;
            red.push(
                formatPriceValues(val)
            );
          }
          return red;
        },
        []
      );
      if (justone === false) ret.shift()
      return ret;
    }
  },

  promoOffer: (state) => ({upgrade = false, services = true}) => {
    const prices = loadData("promo.json")
    const promoModels = prices.promo
    const upgradeModels = prices.upgrade

    const userData = state.userData
    const CLUB = userData.service && userData.service_pack === CLUB_PLATINUM.code
    const INSURANCE_PACKAGE_E = userData.insurance && userData.insurance_pack === 'E'
    const INSURANCE_PACKAGE_G = userData.insurance && userData.insurance_pack === 'G'
    const PROMO_TYPE_RATA = promoModels.default.rate != upgradeModels.default.rate && promoModels.default.imp_finanz === upgradeModels.default.imp_finanz

    const modelGroup = upgrade ? upgradeModels : promoModels

    let promo = modelGroup.default
    if (services) {
      if(CLUB) promo = modelGroup.club
      if(INSURANCE_PACKAGE_E) promo = modelGroup.packE
      if(CLUB && INSURANCE_PACKAGE_E) promo = modelGroup.club_packE
      if(INSURANCE_PACKAGE_G) promo = modelGroup.packG
      if(CLUB && INSURANCE_PACKAGE_G) promo = modelGroup.club_packG
    }

    promo.type = PROMO_TYPE_RATA ? 'rata' : 'importo';

    return formatPromo(promo)
  },

  promoKO: () => {
    const prices = loadData("promo.json")
    const model = prices.promo.ko

    return formatPromo(model)
  },

  fiscalCodeExtracted: state => {
    return extractFiscalCode(state.userData.fiscal_code);
  }
};
