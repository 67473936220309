class Append {
    enter_landing(payload, {state}) {
        const iban = state.userData.iban.replace(/ +/g, "");
        payload['userId'] = state.userData.user_id
        payload['CF'] = state.userData.fiscal_code
        payload['codeCampaign'] = state.userData.code_campaign
        payload['dt_start_ppcl'] = state.userData.dt_start
        payload['stepNumber'] = 0
        payload['Phone'] = state.userData.cellphone.substring(3).replace(/ +/g, "")
        payload['Nome'] = state.userData.name
        payload['Cognome'] = state.userData.surname
        payload['Permesso'] = 1
        payload['Iban'] = state.userData.iban
        payload['Iban_Paese'] = iban.slice(0, 2)
        payload['Iban_CinEu'] = iban.slice(2, 4)
        payload['Iban_Cin'] = iban.slice(4, 5)
        payload['Iban_Abi'] = iban.slice(5, 10)
        payload['Iban_Cab'] = iban.slice(10, 15)
        payload['Iban_Cc'] = iban.slice(15, 27)
    }

    club_consent(payload) {
        payload['stepNumber'] = 1
    }

    dati_pers_3(payload, {state}) {
        delete(payload['Abitazione_Dal'])
        delete(payload['Domicilio'])
        delete(payload['Indirizzo_Dom'])
        delete(payload['N_Dom'])
        delete(payload['CAP_Dom'])
        delete(payload['Comune_Dom'])
        delete(payload['City_Dom'])
        delete(payload['Prov_Dom'])
        payload['stepNumber'] = 2
        payload['EMail'] = state.userData.email
        payload['cliente_id'] = state.userData.client_id
        payload['pratica_id'] = state.userData.dossier_id
        payload['service'] = +state.userData.service
        payload['service_pack'] = state.userData.service_pack
    }
}
export default new Append();