class Hooks {

    dati_lav(router, {state}) {
        if (state.userData.document_expired) 
            router.push({ name: "DocumentsTopUp" })

        else if (state.userData.can_request) 
            router.push({ name: "ClubTopUp" })

        else if (!state.userData.can_request && !state.userData.is_creditline)
            router.push({ name: "ClubPlusTopUp" })
        
        else
            router.push({ name: "PrivacyTopUp" })
    }

    dati_pers_4(router, {state}) {

        if (state.userData.can_request)
            router.push({ name: "ClubTopUp" })

        else if (!state.userData.is_creditline)
            router.push({ name: "ClubPlusTopUp" })
        
        else
            router.push({ name: "PrivacyTopUp" })
    }

    club(router) {
        router.push({ name: "ClubTopUpConsent" })
    }
    club_ko(router) {
        router.push({ name: "ClubTopUpWarning" })
    }
    club_consent(router) {
        router.push({ name: "PrivacyTopUp" })
    }
    club_consent_back(router) {
        router.push({ name: "ClubTopUp" })
    }
    club_warning(router) {
        router.push({ name: "ClubTopUpConsent" })
    }
    club_warning_ko(router) {
        router.push({ name: "PrivacyTopUp" })
    }
    
    club_plus(router) {
        router.push({ name: "ClubPlusTopUpConsent" })
    }
    club_plus_ko(router) {
        router.push({ name: "ClubPlusTopUpWarning" })
    }
    club_plus_consent(router) {
        router.push({ name: "PrivacyTopUp" })
    }
    club_plus_warning(router) {
        router.push({ name: "ClubPlusTopUpConsent" })
    }
    club_plus_warning_ko(router) {
        router.push({ name: "PrivacyTopUp" })
    }

    dati_privacy(router, {state}) {
        
        if (state.userData.can_request)
            router.push({name: "ThankYou"})

        else if(state.userData.service)
            router.push({name: "TypClub"})

        else 
            router.push({name: "RequestKO"})
    }
}
export default new Hooks();