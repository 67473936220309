<template>
    <FormCard @submit="onSubmit" title="Disguidi finanziari">
        <div class="w-full mb-8">
            <label class="text-base">{{ $t("In passato hai avuto problemi con il pagamento delle rate di altri finanziamenti?") }}</label>
            <div class="flex mt-6 justify-center">
              <div class="relative mr-6">
                  <input type="radio" name="financial_problems" v-model="financial_problems" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
              </div>
              <div class="relative">
                  <input type="radio" name="financial_problems" v-model="financial_problems" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
              </div>
            </div>
            <div class="text-red text-center" v-if="$v.financial_problems.$error">
                <small v-if="!$v.financial_problems.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
    </FormCard>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { mapUserData } from "@/utils";

export default {
    mounted() {
        this.$ga.pageview()
    },
    computed: mapUserData(["financial_problems"]),
    validations: {
        financial_problems: {
            required
        }
    },
    watch: {
      financial_problems() {
        this.$v.$reset()
      }
    },
    methods: {
        onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                //this.$store.dispatch("event", { name: "CreditRiskSubmit" });
                this.$store.dispatch("sendData", { step: "dati_disguidi_finanziari" });
                this.$store.dispatch("redirect", { step: "credit_risk", route: "IncomeData" });
            }
        }
    }
};
</script>