<template>
    <main>
        <Loader v-if="loading" />
        <div v-else>
            <div class="form w-full mx-auto text-center px-6 lg:px-0 relative my-12 z-2">
                <div class="bg-white rounded-3xl shadow-2xl mx-auto pt-2 pb-8">
                    <form class="" @submit.prevent>
                        <div class="px-4">
                            <div class="px-4">
                                <p class="text-xl text-gray-5 font-semibold pt-4 sm:pt-6 px-4 text-2xl sm:px-8 lg:text-xl">{{ name }},</p>
                                <p class="font-medium mb-2 mt-1 text-lg max-w-lg mx-auto">abbiamo ricevuto la tua richiesta di riutilizzo della Linea di Credito Revolving Power Credit.</p>
                            </div>
                            <div class="offer-wrapper mt-8 lg:mt-12 border border-yellow rounded-t-xl rounded-b-lg md:rounded-b-xl overflow-hidden mx-auto">
                                <div class="text-center bg-yellow pt-1 pb-3 px-2">
                                    <p class="font-bold lg:text-lg leading-tight mt-0.5">Ricevi direttamente sul tuo conto corrente</p>
                                    <h1 class="font-bold text-3xl lg:text-4xl mt-1">{{ clubCredit|num }}€</h1>
                                </div>
                                <p class="px-3 pt-2 leading-tight">+ attivazione del Servizio CLUB a {{ monthlyClubPrice }} euro al mese con addebito annuale*</p>
                                <ClubToggle class="pt-7 pb-10">
                                    <p class="text-xs">L’acquisto del servizio CLUB è facoltativo e non è necessario per ottenere il riutilizzo della linea di credito, né per ottenerlo a condizioni più vantaggiose. Puoi visionare qui il <a :href="rulesClubUrl" target="_blank" class="underline">Regolamento</a> e l’<a :href="privacyClubUrl" target="_blank" class="underline">Informativa Privacy</a>.</p>
                                    <p class="text-xs mt-2">* Il servizio è finanziato mediante apposito utilizzo della Linea di Credito Revolving concessa da Cofidis. Il Cliente autorizza Cofidis a procedere al pagamento per proprio conto del costo annuale del servizio anche in caso di rinnovo a Quattrolinee S.r.l per l’erogazione del Servizio.</p>
                                </ClubToggle>
                            </div>                        
                        </div>
                        <a @click="submit(true)" class="block cta first-cta text-lg rounded-full px-12 py-3 z-50 cursor-pointer">
                            <span class="text-center text-xl font-semibold">CONFERMA</span>
                        </a>
                    </form>
                    <form class="text-center mt-16">
                        <a class="underline cursor-pointer" @click="submit(false)">
                            Procedi al riutilizzo senza CLUB
                        </a>
                    </form>
                </div>
            </div>
            <div id="email-container" class="bg-yellow text-center p-6" :class="{'hidden': !showEmailInput}">
                <p class="text-center font-semibold text-xl text-gray-900 leading-none">Inserisci la tua e-mail</p>
                <div class="sm:w-96 text-center mx-auto mt-4">
                    <div class="relative">
                        <input type="email" id="email" name="email" class="w-full py-2 pl-4 pr-10 text-lg" v-model.trim="email" v-mask="{ placeholder: '', greedy: false, casing: 'lower' }"
                            placeholder="Se non appare la tua email, inseriscila" :class="{ 'border border-red': $v.email.$error }" @blur="$v.email.$touch">
                        <label for="email"><i class="icon pencil absolute top-3 right-2 cursor-pointer"></i></label>
                    </div>
                    <div class="w-full text-red text-left" v-if="$v.email.$error">
                        <small v-if="!$v.email.required">
                            Campo obbligatorio
                        </small>
                        <small v-else-if="!$v.email.email">
                            Formato e-mail non valido
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
// @ is an alias to /src
import Loader from "@/components/Loader"
import ClubToggle from "@/components/ClubToggle.vue";
import { mapUserData } from "@/utils";
import { mapState } from "vuex";
import axios from "axios";
import moment from "moment/moment";
import { required, email } from "vuelidate/lib/validators";

const redirectHomepage = () => {    
    location.href = '/riutilizzi-ar'
}

export default {
    name: "Home",
    components: { Loader, ClubToggle },
    data() {
        return {
            commonDistributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/common",
            disponibilita: null,
            distributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/powercredit-crm",
            loading: true,
            owedAmount: null,
            clubPrice: null,
            showEmailInput: true,
            rulesClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Regolamento_Servizio_CLUB.pdf',
            privacyClubUrl: 'https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Informativa_Servizio.pdf',
        };
    },
    methods: {
        async loadClientData(data) {
            return new Promise((resolve, reject) => {
                axios.post(this.crmUrl, data)
                    .then(function(response) {
                        if(response.data && response.data.dt_end_riuar)
                            resolve(response.data)
                        else
                            reject(false)
                    })
                    .catch(function() {
                        reject(false)
                    })
            })
        },
        scrollToEmail() {
            window.scrollTo({
                top: document.getElementById('email-container').offsetTop,
                left: 0,
                behavior: "smooth",
            });
        },
        async submit(wantClub) {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                window.removeEventListener('popstate', redirectHomepage)
                if (wantClub) {
                    this.$store.dispatch("sendData", { step: "club_consent" });
                    this.$store.dispatch("redirect", { step: "homepage", route: "Consents" });
                } else {
                    this.$store.dispatch("sendData", { step: "dati_privacy" });
                    this.$store.dispatch("redirect", { step: "homepage", route: "ThankYou" });
                }
            }else{
                if(this.$v.email.$error){
                    this.scrollToEmail()
                }
            }
        },
        reloadOnBrowserBack() {            
            window.popStateDetected = false
            window.addEventListener('popstate', redirectHomepage)
        }
    },
    computed: {
        ...mapState(["choosenCredit"]),
        ...mapUserData([
            "name",
            "surname",
            "email",
        ]),
        choosenCredit: {
            get() {
                return this.$store.state.choosenCredit;
            },
            set(val) {
                this.$store.commit("setChoosenCredit", val);
            }
        },
        monthlyClubPrice() {
            return this.clubPrice % 12 === 0 ? this.clubPrice / 12 : parseFloat(this.clubPrice / 12).toFixed(2);
        },
        clubCredit() {
            return this.disponibilita - this.clubPrice;
        },
        crmUrl() {
            return this.$store.state.crmUrl;
        }
    },
    async created() {
        this.reloadOnBrowserBack()

        // get params
        const urlParts = this.$store.state.urlParts
        let userId, codeCampaign, trackCode;

        if(urlParts[1] && urlParts[2]) {
            userId = urlParts[1]
            codeCampaign = urlParts[2]
            trackCode = urlParts[3] ?? null
        }
        else {
            userId = localStorage.getItem('riutilizzi_ar_user_id')
            codeCampaign = localStorage.getItem('riu_ar_code_campaign')
            trackCode = localStorage.getItem('riu_ar_track_code') ?? null
            // fix localstorage cast type issue
            if(trackCode === "null") trackCode = null;
        }

        // redirect to pending modal if already compiled
        if(localStorage.getItem('riu_ar_request_evaluation')) {
            this.loading = false;
            this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
            return;
        }

        try {

            localStorage.setItem('riutilizzi_ar_user_id', userId)
            localStorage.setItem('riu_ar_code_campaign', codeCampaign)
            if(trackCode) localStorage.setItem('riu_ar_track_code', trackCode)

            const data = await this.loadClientData({userId, codeCampaign, trackCode});
            this.$store.commit("setUserData", { key: 'user_id', value: userId });
            this.$store.commit("setUserData", { key: 'client_id', value: data.cliente_id });
            this.$store.commit("setUserData", { key: 'code_campaign', value: codeCampaign });
            this.$store.commit("setUserData", { key: 'name', value: data.Nome });
            this.$store.commit("setUserData", { key: 'surname', value: data.Cognome });
            this.$store.commit("setUserData", { key: 'email', value: data.EMail });
            this.$store.commit("setUserData", { key: 'iban', value: data.Iban });
            this.$store.commit("setUserData", { key: 'fiscal_code', value: data.CF });
            this.$store.commit("setUserData", { key: 'cellphone', value: data.Phone });
            this.$store.commit("setUserData", { key: 'day_in_month', value: 1 });
            this.$store.commit("setUserData", { key: 'owed_amount', value: data.importo_tot_dovuto_riu});
            this.$store.commit("setUserData", { key: 'max_amount', value: data.disponibilita_riu});
            this.$store.commit("setUserData", { key: 'cod_camp_rev', value: data.cod_camp_rev});
            this.$store.commit("setUserData", { key: 'dt_start_riuar', value: moment(data.dt_start_riuar).locale("it").format('DD-MM-YYYY')});
            this.$store.commit("setUserData", { key: 'dossier_id', value: data.pratica_id_rev});
            this.$store.commit("setUserData", { key: 'output_date', value: moment(new Date()).locale("it").format('DD-MM-YYYY')});
            this.$store.commit("setChoosenCredit", data.disponibilita_riu);
            // Club data
            this.$store.commit("setUserData", { key: 'service', value: 0 });
            this.$store.commit("setUserData", { key: 'service_pack', value: data.service_pack });
            this.$store.commit("setUserData", { key: 'service_amount', value: data.im_pacchetto });

            // set expiry date
            this.$store.commit("setUserData", { key: 'exp_date', value: this.expDate });

            // offer expired
            if(moment(data.dt_end_riuar).endOf('day') < moment()) {
                this.$store.dispatch("redirect", {step: "income", route: "RequestExpired"});
                return;
            }
            // pending procedure
            if(data.page_block && data.page_block === 'evaluation') {
                this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
                return;
            }

            // init robot
            this.$store.dispatch("sendData", { step: "enter_landing" });

            this.owedAmount = data.importo_tot_dovuto_riu;
            this.disponibilita = data.disponibilita_riu;
            this.clubPrice = data.im_pacchetto;
            this.name = data.Nome;
            this.showEmailInput = !data.EMail;

        } catch (error) {
                this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
        }
        this.loading = false
    },
    validations: {
        email: {
            required,
            email,
        },
    },
};
</script>