import getData from "./data/default.js";
import getDataDev from "./data/dev.js";
import loadData from "@/dataService.js";

const prices = loadData("price.json").prices
const config = loadData("config.json")

const isDev = process.env.NODE_ENV === "development"
const version = config.version ?? 1
const baseUrl = window.baseUrl ? window.baseUrl.split('?')[0] : '/'
const activeTheme = window.activeTheme || process.env.VUE_APP_THEME || "prestito"
const defaultDebitDays = [{value:1, label: "1"}, {value:2, label: "10"}]
const userData = isDev ? getDataDev() : getData()
const allowForms = false
const logo = require(`@/views/themes/${activeTheme}/images/logo.svg`)
const locales = config.locales ? config.locales : [process.env.VUE_APP_I18N_LOCALE]
const privacyEmail = config.privacyEmail ? config.privacyEmail : false
const promo = config.promo ? config.promo : false

export default {
  promo,
  isDev,
  PATH: null,
  envType: config.envType ?? null,
  envName: config.envName ?? null,
  version,
  prices,
  fixedCredit: false,
  choosenCredit: null,
  limitedCredit: null,
  choosenRata: null,
  allowForms,
  privacyEmail,
  precompiled: false,
  crmRedirect: config.crmRedirect ?? false,
  redirect: false,
  redirectType: null,
  redirectUrl: null,
  redirectTimeout: null,
  redirectVerified: false,
  prepaidCards: config.prepaidCards ?? true,
  protected: config.protected ?? false,
  customRoutes: config.routes ?? [],
  userData,
  urlVars: [],
  urlParts: [],
  steps: [],
  baseUrl,
  secciUrl: config.secci[process.env.VUE_APP_ENV] ?? null,
  serverUrl: config.server[process.env.VUE_APP_ENV] ?? null,
  clubUrl: config.club ? config.club[process.env.VUE_APP_ENV] ?? null : null,
  crmUrl: config.crm ? config.crm[process.env.VUE_APP_ENV] ?? null : null,
  privacyUrl: config.privacyUrl ?? null,
  debitDays: config.debitDays ?? defaultDebitDays,
  theme: activeTheme,
  logo,
  locales,
  lang: process.env.VUE_APP_I18N_LOCALE || 'it',
  flashMessage: null,
  flashMessageShowed: false,
  promoModal: false,
  navigator: { from: null, to: null }
};
